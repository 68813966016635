import R from 'ramda';
import { Component, PropTypes } from 'react';
import select from '../../shared/components/select';
import input from '../../shared/components/input';
import MaterialCheckbox from '../../shared/components/material_checkbox';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { isNotAlreadyChosen, isNotEmpty } from '../../shared/validations';

const renderCompetitors = ({ competitors, fields }) => {

  const options = R.pipe(
    R.sortBy(R.prop('name')),
    R.map((c) => ({ value: c.id, label: c.name }))
  )(competitors)

  return (
    <div className="podium-form__medals">
      {
        fields.map((member, i) => {
          return (
            <div className="row">
              <div className="col-sm-2">
                <Field
                  name={`${member}.place`}
                  component={select}
                  options={[
                    { value: 1, label: 1 },
                    { value: 2, label: 2 },
                    { value: 3, label: 3 },
                  ]}
                  label={`Place`}
                  validate={[isNotEmpty]}
                  props={{ disabled: !R.isNil(fields.get(i)._destroy) }}
                />
              </div>
              <div className="col-sm-7">
                <Field
                  name={`${member}.competitor_id`}
                  component={select}
                  options={options}
                  label={`Competitor`}
                  props={{ disabled: !R.isNil(fields.get(i).id) }}
                  validate={[isNotAlreadyChosen('medals', 'competitor_id')]}
                />
              </div>
              <div className="col-sm-3">
                <Field
                  name={`${member}._destroy`}
                  component={MaterialCheckbox}
                  options={options}
                  label={`Remove`}
                />
              </div>
            </div>
          )
        })
      }
      <div className="row">
        <div className="col-sm-3 col-sm-offset-9">
          <div className="form-group">
            <button
              type="button"
              className="btn btn-sm"
              disabled={fields.length >= 4}
              onClick={() => fields.push({ id: undefined, competitor_id: undefined, _destroy: undefined })}>
              Add Medal
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const podiumForm = props => {
  const {
    onCancel,
    competitors,
    error,
    valid,
    handleSubmit,
    pristine,
    submitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="medals"
        component={renderCompetitors}
        props={{ competitors: competitors }}
      />
      <button type="submit" className="btn btn-primary" disabled={!valid || submitting}>
        Submit
      </button>
      <button type="button" className="btn btn-secondary" disabled={submitting} onClick={onCancel}>
        Cancel
      </button>
    </form>
  )
}

export default reduxForm({
  form: "PodiumForm"
})(podiumForm);
