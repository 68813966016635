import { PropTypes } from 'react';
import R from 'ramda';

function CategoryPanel(props) {
  return (
    <div className="category-panel">
      {props.children}
    </div>
  )
}

CategoryPanel.propTypes = {
  rounds: PropTypes.array
}

CategoryPanel.defaultProps = {
  rounds: []
}

export default CategoryPanel;
