
import R from 'ramda';
import { Component, PropTypes } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

class MergePopover extends Component {
  submit(isMerging) {

    isMerging ?
      this.props.onSubmit({ type: "MERGE", payload: this.props.options }) :
      this.props.onSubmit({ type: "UNMERGE" });

    this.refs.overlay.hide();
  }

  handleChange(ev) {
    this.props.changeMergeOptions({
      ...this.props.options,
      [ev.target.name]: parseInt(ev.target.value, 10)
    });
  }

  render() {
    const { standardRest, finalRest } = this.props.options;

    const popover =
      <Popover id={"merge-popover"} title={this.props.title}>
        <div className="form-group">
          <label className="control-label">No of fights between qualifiers</label>
          <input className="form-control" type="number" name="standardRest" value={standardRest} onChange={this.handleChange.bind(this)}/>
        </div>
        <div className="form-group">
          <label className="control-label">No of fights before final</label>
          <input className="form-control" type="number" name="finalRest" value={finalRest} onChange={this.handleChange.bind(this)}/>
        </div>
        <div className="form-group">
          <button className="btn btn-primary btn-raised" onClick={() => this.submit(true)}>Merge</button>
          <button className="pull-right btn btn-danger btn-raised" onClick={() => this.submit(false)}>Undo</button>
        </div>
      </Popover>

    return(
      <OverlayTrigger ref="overlay" trigger="click" placement="bottom" overlay={popover}>
        <i className="fa fa-random"/>
      </OverlayTrigger>
    )
  }
}

MergePopover.defaultProps = {
  title: "Merge matches"
}

export default MergePopover;
