import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MatList from '../../shared/components/mat_list';
import StatelessMatContainer from '../../shared/containers/stateless_mat_container';
import CableSubscriber from '../../shared/components/cable_subscriber';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import ColorLegend from '../../shared/components/color_legend';
import PublicCheckin from '../components/public_checkin';
import StandBy from '../../shared/components/stand_by';

const CheckinMatList = MatList(StatelessMatContainer);

import {
  loadData,
} from '../../presence/actions';

import {
  updateMatch,
  reloadMat,
  reloadMatch,
} from '../../shared/actions/api';

import {
  checkin,
  reportNoShow,
} from '../../modules/competitor_action_params';

class CheckinContainer extends Component {
  componentDidMount() {
    this.props.loadData(this.props.tournamentId, this.props.tournamentDayId, this.props.matNumbers)
  }

  checkinCompetitor(fn, props) {
    return this.props.updateMatch(checkin(props.matchId, props.matchCompetitorId));
  }

  reportNoShow(fn, props) {
    return this.props.updateMatch(reportNoShow(props.matchId, props.matchCompetitorId));
  }

  render() {
    const {
      mats,
      matchStatesShown,
      reloadMat,
      reloadMatch,
      tournamentDayId,
      tournamentId
    } = this.props;

    return (
      <div className="checkin-container">
        <PublicCheckin
          tournamentId={tournamentId}
          tournamentDayId={tournamentDayId}
          mats={mats} />

        <CableSubscriber tournamentId={+tournamentId} subscriptions={[
          { name: "MatsChannel", received: reloadMat, room_id: "+" },
          { name: "MatchesChannel", received: reloadMatch, room_id: "+" }
        ]} />

        <ContextMenu id="ASSIGNED_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.checkinCompetitor.bind(this)}>Checkin</MenuItem>
          <MenuItem onClick={this.reportNoShow.bind(this)}>Report No Show</MenuItem>
        </ContextMenu>

        <ContextMenu id="NO_SHOW_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.checkinCompetitor.bind(this)}>Checkin</MenuItem>
        </ContextMenu>

        <ContextMenu id="WEIGHTED_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.checkinCompetitor.bind(this)}>Checkin</MenuItem>
          <MenuItem onClick={this.reportNoShow.bind(this)}>Report No Show</MenuItem>
        </ContextMenu>

        <ColorLegend label="CHECKIN"/>

        {mats.length ? (
          <CheckinMatList mats={mats} slug={"checkin-mat-list"} />
        ) : (
          <StandBy standBy="Loading mats, please wait..." />
        )}
      </div>
    )
  }
}

CheckinContainer.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  mats: PropTypes.array.isRequired,

  updateMatch: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
  return {
    mats: state.app.mats.result,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadData,
    updateMatch,
    reloadMat,
    reloadMatch,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckinContainer);
