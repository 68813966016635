import { connect } from 'react-redux';
import MatchTooltip from '../../shared/components/match_tooltip';

function mapStateToProps(state, ownProps) {
  const overlayMatch = state.ui.matchOverlay.result;
  const match        = ownProps.match;

  return {
    match: overlayMatch !== undefined && overlayMatch.id === match.id ?
      overlayMatch :
      undefined
  }
}

export default connect(mapStateToProps)(MatchTooltip);
