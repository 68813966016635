import R from 'ramda';

const mountQuery = (params) => {
  let query = new URLSearchParams();

  const isArrayParam = (v, k) => R.isArrayLike(v)
  const valueParam = (value, param) => !R.isNil(value) ? query.append(param, value) : value;
  const collectionParam = (values, param) =>
    R.map((value) => query.append(`${param}[]`, value))(values)

  R.mapObjIndexed(R.ifElse(isArrayParam, collectionParam, valueParam))(params)

  return query;
}

const mountFetch = (endpoint, options = {}) => {
  let headers = new Headers();
  headers.set('Content-Type', 'application/json');

  let _options = {
    credentials: "same-origin",
    headers: headers,
    ...options
  }

  let query = mountQuery(options.query);

  let authenticityToken = document.getElementsByName("csrf-token")[0].content;
  query.append('authenticity_token', authenticityToken);

  return fetch(endpoint + "?" + query.toString(), _options)
}

export default mountFetch;
