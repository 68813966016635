import moment from 'moment-timezone';

const MatchSchedule = props => {
  const { match: {
    original_mat,
    original_order,
    original_estimated_start,

    mat,
    order,
    current_start_time
  } } = props;

  const scheduleTime = (className, mat, order, time) => {
    return (
      <div className={`match-schedule__${className}`}>
        <span className="match-schedule__mat">
          {`M${mat}:${order + 1}`}
        </span>
        <span className="match-schedule__time">
          {moment.parseZone(time).format('h:mm A')}
        </span>
      </div>
    )
  }

  const originalSchedule = original_estimated_start ?
    scheduleTime('original-time', original_mat, original_order, original_estimated_start) :
    <div className='match-schedule__original-time'>
      Snapshot not taken
    </div>

  const diff = moment(original_estimated_start || current_start_time).diff(current_start_time, 'minutes')

  return (
    <span className="match-schedule">
      <i className="fa fa-clock-o fa-2x"></i>
      <span className="match-schedule__times">
        {originalSchedule}
        {scheduleTime('current-time', mat, order, current_start_time)}
      </span>
      <span className="match-schedule__bracket">}</span>
      <span className={`match-schedule__difference ${diff >= 0 ? 'positive' : 'negative'}`}>
        {`+${diff}min`}
      </span>
    </span>
  )
}

export default MatchSchedule;
