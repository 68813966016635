import React, { Component, PropTypes } from 'react';

import MatchCompetitorsContainer from '../containers/match_competitors_container';

const StatelessMat = props => {
  const { tournamentId, mat, matches, slug } = props;

  const render = {
    "Gap": (m, i) => (
      <div key={i} className="gap-card panel panel-default">
        <span style={{ marginRight: 5 }}>{m.description}</span>
        <span>({m.duration} minutes)</span>
      </div>
    ),
    "Match": (m, i) => (
      <MatchCompetitorsContainer
        slug={slug}
        key={i}
        index={i}
        order={m.order}
        matchId={m.id}
        matId={mat.id}
        categoryName={m.category_name}
        weightLimit={m.weight_limit}
        estimatedStart={m.current_start_time}
        competitors={m.competitors}
        />
    )
  }

  const matchesCompetitors = matches.map((m, i) => render[m.kind](m, i))

  return (
    <div className={'mat'}>
      <span className="mat__title">
        Mat {mat.number}
      </span>

      <ul className="mat__items">
        {matchesCompetitors}
      </ul>
    </div>
  )
}

StatelessMat.propTypes = {
  mat: PropTypes.shape({
    number: PropTypes.number.isRequired,
  }),
  matches: PropTypes.array.isRequired,
}

export default StatelessMat;
