
const StandBy = (props) => {
  return (
    <div className="stand-by">
      <span className="fa fa-cog fa-spin"></span>
      <span className="stand-by__description">{props.standBy}</span>
    </div>
  )
}

export default StandBy;
