import React from 'react';

export default class ReceiptPrintingTester extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { isSubmitting: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.setState({ isSubmitting: true });

    fetch(`http://localhost:${this.props.printingServerPort}/ping?location=${location.pathname}`, {
      method: 'GET',
    })
      .then((res) => {
        if (res.ok) {
          confirm('Please, check the print. You should see the word "PONG" on paper.');
        } else {
          alert('Printing server is not working properly.');
        }
      })
      .catch((error) => {
        alert('Printing server is not working properly.');
        console.error(error);
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  }

  render() {
    return (
      <div className="receipt-print-tester">
        <form onSubmit={this.handleSubmit}>
          <button
            className="btn btn-default btn-block"
            type="submit"
            disabled={this.isSubmitting}
            style={{padding: '8px 2px'}}>

            <i className="fa fa-print fa-lg" />
            <span>&nbsp;&nbsp;Test Receipt Printing</span>
          </button>
        </form>
      </div>
    );
  }
}
