import React, { Component, PropTypes } from 'react';
import matchCompetitorState from '../../modules/match_competitor_state';
import { ContextMenuTrigger } from 'react-contextmenu';
import R from 'ramda';

function collect(params) {
  const { attributes } = params;

  return {
    matchCompetitorId: attributes.matchCompetitorId,
    matchId: attributes.matchId,
    competitorId: attributes.competitorId,
    tournamentSubscriptionId: attributes.tournamentSubscriptionId,
  }
}

function MatchCompetitor(props) {
  let contextTrigger = null;

  const toggleMenu = e => {
    e.preventDefault();

    if(contextTrigger) {
      contextTrigger.handleContextClick(e);
    }
  };

  return (
    <div className={`match-competitor match-competitor--${props.state}`}>
      <img className="match-competitor__picture" src={props.competitorPhoto}/>

      <span className="match-competitor__description">
        <span className="match-competitor__competitor-name">{props.competitorName}</span>
        <span className="match-competitor__club-name">{props.clubName}</span>
      </span>

      <span className="match-competitor-actions">
        <ContextMenuTrigger
          id={`${R.toUpper(props.state)}_MATCH_COMPETITOR_CONTEXT_MENU`}
          collect={collect}
          attributes={props}
          ref={c => contextTrigger = c}>
          <span onClick={toggleMenu}>
            <i className="fa fa-gear fa-lg"></i>
          </span>
        </ContextMenuTrigger>
      </span>
    </div>
  );
}

MatchCompetitor.propTypes = {
  matchCompetitorId: PropTypes.number.isRequired,
  competitorName: PropTypes.string.isRequired,
  competitorId: PropTypes.number.isRequired,
  matchId: PropTypes.number.isRequired,
  disqualificationId: PropTypes.number,
  noShowReportedAt: PropTypes.string,
}

export default MatchCompetitor;
