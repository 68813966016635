import moment from 'moment-timezone';
import { Col } from 'react-bootstrap';
import TimezoneSelect from '../../settings/components/timezone_select';
import { DatePickerInput } from 'rc-datepicker';

const groupStateClass = (valid, error, warning) => {
  if (error) { return 'has-error' }
  if (warning) { return 'has-warning' }
  if (valid) { return 'has-success' }
}

export const StatusMessage = (props) => {
  const { touched, warning, error } = props;

  return (
    <span className="help-block">
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </span>
  );
}

export const renderField = (props) => { 
  const {
    input,
    type,
    min
  } = props;

  return inputWrapper(
    <input {...input} type={type} min={min} className="form-control"/>
  )(props);
}

export const renderSelect = (props) => {
  const {
    input,
    options,
  } = props;

  const _opts = options.map((option, index) => 
    (<option key={index} value={option.value}>{option.label}</option>))

  return inputWrapper(
    <select {...input} className="form-control">
      {_opts}
    </select>
  )(props);
};

export const renderTextarea = (props) => {
  const {
    input,
    rows,
  } = props;
  return inputWrapper(<textarea {...input} rows={rows} className="form-control"/>)(props);
}

export const renderTimezoneSelect = (props) => {
  return inputWrapper(
    <TimezoneSelect {...props.input} className="form-control"/>
  )(props);
}

export class FileField  extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    const { input: { onChange } } = this.props
    onChange(e.target.files[0])
  }

  render(){
    const { input } = this.props;

    return inputWrapper(
      <span>
        <input
          name={input.name}
          type='file'
          accept='.jpg, .png, .jpeg'
          onChange={this.onChange}
        />
      </span>
    )(this.props)
  }
}

export const inputWrapper = (component) => (props) => {
  const {
    label,
    hint,
    isSize,
    meta: { valid, touched, error, warning }
  } = props;

  return (
    <Col className={`form-group ${groupStateClass(valid, error, warning)}`} md={isSize || 12}>
      <label>{label}</label>
      {component}
      {(error || warning) && <StatusMessage touched={touched} warning={warning} error={error} />}
      {hint && <span className="help-block">{hint}</span>}
    </Col>
  );
};

export const renderCheckbox = ({
  input,
  label,
  meta: { valid, warning, error }
}) => (
  <div className="checkbox">
    <label>
      <input {...input} type="checkbox" checked={input.value}/>
      {label}
    </label>
  </div>
)


export const renderDateInput = (props) => {
  const {
    input,
  } = props;

  return inputWrapper(
    <DatePickerInput
      {...input}
      displayFormat='YYYY-MM-DD'
      onChange={(v) => input.onChange(moment(v).format('YYYY-MM-DD'))}
      showOnInputClick
    />
  )(props);
}