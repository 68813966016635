import { connect } from 'react-redux';
import StatelessMat from '../components/stateless_mat'
import R from 'ramda';
import moment from 'moment-timezone';

function mapStateToProps(state, ownProps) {
  const {
    mat,
    slug
  } = ownProps;

  const {
    tournament,
    matchStates
  } = state.app;

  const filter = (orderedItem) =>
    orderedItem.kind === "Gap" ||
    (orderedItem.kind === "Match" && R.contains(R.prop('state', orderedItem), matchStates))
  const matches = R.pipe(
    R.filter(filter),
    R.take(5)
  )(mat.matches);

  return {
    mat,
    matches,
    slug
  }
}

export default connect(mapStateToProps)(StatelessMat);
