import React, { Component } from 'react';

const AutoOrderCategoriesProgress = (props) => {
  const progress = Math.round(((props.current / props.total) * 100) || 0);

  return (
    <div className="category-group-progress progress">
      <div className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%` }}>
        <span className="sr-only">{`${progress}% Complete`}</span>
        {`${progress}%`}
      </div>
    </div>
  )
}

export default AutoOrderCategoriesProgress;
