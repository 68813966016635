import { hydrateCategories } from '../../modules/hydrate_categories';

import {
  HYDRATE_CATEGORIES,
} from '../../shared/constants';

export function doHydrateCategories() {
  return (dispatch, getState) => {
    const {
      categories,
      filters,
      timeLimits,
    } = getState().app;

    const hydratedCategories = hydrateCategories(categories.result, {
      ageDivisions: filters.result[0].options,
      weightDivisions: filters.result[1].options,
      belts: filters.result[2].options,
      genders: filters.result[3].options,
      timeLimits: timeLimits ? timeLimits.result : []
    })

    return dispatch({
      type: HYDRATE_CATEGORIES,
      payload: hydratedCategories,
    })
  }
}

