import formatMinutes from '../../modules/format_minutes';
import { Component, PropTypes } from 'react';
import R from 'ramda';

class EditableEstimateDisplay extends Component {
  onChange(e) {
    this.props.onChange({
      categoryIds: R.map(R.prop('id'))(this.props.categories),
      value: e.target.value
    })
  }

  render() {
    const {
      children,
      time,
      categories,
    } = this.props;

    return (
      <span className="estimate-display editable-estimate-display">
        {children}
        <span className="estimate-display__results">
          <span className="estimate-display__categories">{categories[0].name}</span>
          <div className="form-inline editable-estimate-display__form">
            <div className="form-group">
              <input
                type="number"
                min="1"
                className="input-sm editable-estimate-display__competitors form-control estimate-display__competitors"
                onChange={this.onChange.bind(this)}
                value={this.props.competitors}
                />
              <span className="estimate-display__competitors">
                competitors
              </span>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

EditableEstimateDisplay.propTypes = {
  time: PropTypes.number,
  competitors: PropTypes.number,
  categories: PropTypes.number,
}

EditableEstimateDisplay.defaultProps = {
  time: 0,
  competitors: 0,
  categories: 0,
}

export default EditableEstimateDisplay;
