import { PropTypes } from 'react';
import R from 'ramda';
import formatMinutes from '../../modules/format_minutes';

function MatCalculator(props) {
  const { matches, interval } = props;

  const unfinishedTotalTime = R.pipe(
    R.filter((m) => m.state !== 'finished'),
    R.reduce((acc, m) => acc + m.duration + interval, 0)
  )(matches)

  return (
    <span className="mat-calculator">
      <i className="fa fa-list"/>
      {matches.length}
      <i className="fa fa-clock-o"/>
      {formatMinutes(unfinishedTotalTime)}
    </span>
  )
}

MatCalculator.propTypes = {
  matches: PropTypes.array.isRequired,
  interval: PropTypes.number.isRequired
}

MatCalculator.defaultProps = {
  matches: [],
  interval: 0,
}

export default MatCalculator;
