import lensMatching from '../modules/lens_matching';
import { createSelector } from 'reselect';
import R from 'ramda';

import {
  TOURNAMENT_REQUEST,
  TOURNAMENT_RESPONSE_SUCCESS,
  TOURNAMENT_RESPONSE_FAIL,

  CALL_API,

  MATCHES_REQUEST,
  MATCHES_RESPONSE_SUCCESS,
  MATCHES_RESPONSE_FAIL,

  AGE_DIVISIONS_REQUEST,
  AGE_DIVISIONS_RESPONSE_SUCCESS,
  AGE_DIVISIONS_RESPONSE_FAIL,

  WEIGHT_DIVISIONS_REQUEST,
  WEIGHT_DIVISIONS_RESPONSE_SUCCESS,
  WEIGHT_DIVISIONS_RESPONSE_FAIL,

  MEDAL_DELIVERIES_REQUEST,
  MEDAL_DELIVERIES_RESPONSE_SUCCESS,
  MEDAL_DELIVERIES_RESPONSE_FAIL,

  TIME_LIMITS_REQUEST,
  TIME_LIMITS_RESPONSE_SUCCESS,
  TIME_LIMITS_RESPONSE_FAIL,

  BELTS_REQUEST,
  BELTS_RESPONSE_SUCCESS,
  BELTS_RESPONSE_FAIL,

  GENDERS_REQUEST,
  GENDERS_RESPONSE_SUCCESS,
  GENDERS_RESPONSE_FAIL,

  ESTIMATE_REQUEST,
  ESTIMATE_RESPONSE_SUCCESS,
  ESTIMATE_RESPONSE_FAIL,

  UPDATE_ESTIMATE_DAY_RESPONSE_SUCCESS,

  MATS_REQUEST,
  MATS_RESPONSE_SUCCESS,
  MATS_RESPONSE_FAIL,

  MAT_REQUEST,
  MAT_RESPONSE_SUCCESS,
  MAT_RESPONSE_FAIL,

  UPDATE_MAT_REQUEST,
  UPDATE_MAT_RESPONSE_SUCCESS,
  UPDATE_MAT_RESPONSE_FAIL,

  UPDATE_MATCH_REQUEST,
  UPDATE_MATCH_RESPONSE_SUCCESS,
  UPDATE_MATCH_RESPONSE_FAIL,

  FILTERS_REQUEST,
  FILTERS_RESPONSE_SUCCESS,
  FILTERS_RESPONSE_FAIL,

  CHANGE_FILTER,
  CHANGE_DATE,

  CATEGORIES_REQUEST,
  CATEGORIES_RESPONSE_SUCCESS,
  CATEGORIES_RESPONSE_FAIL,

  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_RESPONSE_SUCCESS,
  UPDATE_CATEGORY_RESPONSE_FAIL,

  MATCH_DECISIONS_REQUEST,
  MATCH_DECISIONS_RESPONSE_SUCCESS,
  MATCH_DECISIONS_RESPONSE_FAIL,

  DISQUALIFICATIONS_REQUEST,
  DISQUALIFICATIONS_RESPONSE_SUCCESS,
  DISQUALIFICATIONS_RESPONSE_FAIL,

  COMPETITORS_REQUEST,
  COMPETITORS_RESPONSE_SUCCESS,
  COMPETITORS_RESPONSE_FAIL,

  PODIUMS_REQUEST,
  PODIUMS_RESPONSE_SUCCESS,
  PODIUMS_RESPONSE_FAIL,

  UPDATE_PODIUM_REQUEST,
  UPDATE_PODIUM_RESPONSE_SUCCESS,
  UPDATE_PODIUM_RESPONSE_FAIL,

  TOURNAMENT_SUBSCRIPTIONS_REQUEST,
  TOURNAMENT_SUBSCRIPTIONS_RESPONSE_SUCCESS,
  TOURNAMENT_SUBSCRIPTIONS_RESPONSE_FAIL,

  CREATE_TOURNAMENT_SUBSCRIPTION_REQUEST,
  CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS,
  CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL,

  DESTROY_TOURNAMENT_SUBSCRIPTION_REQUEST,
  DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS,
  DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL,

  UPDATE_MEDAL_RESPONSE_SUCCESS,

  TOURNAMENT_TEMPLATES_REQUEST,
  TOURNAMENT_TEMPLATES_RESPONSE_SUCCESS,
  TOURNAMENT_TEMPLATES_RESPONSE_FAIL,

  CREATE_TOURNAMENT_TEMPLATE_REQUEST,
  CREATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
  CREATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL,

  UPDATE_TOURNAMENT_TEMPLATE_REQUEST,
  UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
  UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL,

  DESTROY_TOURNAMENT_TEMPLATE_REQUEST,
  DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
  DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_FAIL,

  CATEGORY_GROUPS_REQUEST,
  CATEGORY_GROUPS_RESPONSE_SUCCESS,
  CATEGORY_GROUPS_RESPONSE_FAIL,

  UPDATE_TOURNAMENT_DAY_RESPONSE_SUCCESS,

  RELOAD_MATCH,
  RELOAD_MAT,

  HYDRATE_CATEGORIES,

} from '../shared/constants';

export const categories = (categories, action) => {
  switch (action.type) {
    case CATEGORIES_REQUEST:
      return { ...categories, isFetching: true }
      break;
    case CATEGORIES_RESPONSE_SUCCESS:
      return {
        ...categories,
        isFetching: false,
        result: action.payload,
        meta: action.meta
      }
      break;
    case CHANGE_FILTER:
      return {
        ...categories,
        meta: {
          ...categories.meta,
          current_page: 1
        }
      }
      break;
    case CATEGORIES_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    case HYDRATE_CATEGORIES:
      return { ...categories, isFetching: false, result: action.payload, meta: action.meta }
      break;
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...categories,
        isFetching: true
      }
      break;
    case UPDATE_CATEGORY_RESPONSE_SUCCESS:
      return {
        ...categories,
        isFetching: false,
        result: R.update(
          R.findIndex(R.propEq('id', action.payload.id), categories.result),
          action.payload,
          categories.result
        )
      }
    case UPDATE_CATEGORY_RESPONSE_FAIL:
      alert('Error updating category!\nPlease try again and contact tech support if needed.')
      return { ...categories, isFetching: false }
    default:
      return categories;
  }
}


export const filters = (filters, action) => {
  switch (action.type) {
    case FILTERS_REQUEST:
      return { ...filters, isFetching: true };
    case FILTERS_RESPONSE_SUCCESS:
      return { ...filters, isFetching: false, result: action.payload };
    case FILTERS_RESPONSE_FAIL:
      return new Error('Not implemented!')
    default:
      return filters;
  }
}

export const tournament = (state, action) => {
  switch (action.type) {
    case TOURNAMENT_REQUEST:
      return { ...state, isFetching: true };
    case TOURNAMENT_RESPONSE_SUCCESS:
      return { ...state, isFetching: false, result: action.payload };
    case TOURNAMENT_RESPONSE_FAIL:
      return new Error('Not implemented!')
    case UPDATE_TOURNAMENT_DAY_RESPONSE_SUCCESS:
      const id = R.findIndex(R.propEq('id', action.payload.id), state.result.tournament_days)

      const result = id !== -1 ?
         R.over(
          R.lensProp('tournament_days'),
          R.update(id, action.payload),
          state.result
        ) : state.result;

      return { result: result, isFetching: false };
    default:
      return state;
  }
}

export const podiums = (podiums, action) => {
  switch (action.type) {
    case PODIUMS_REQUEST:
    case UPDATE_PODIUM_REQUEST:
      return { ...podiums, isFetching: true };
    case PODIUMS_RESPONSE_SUCCESS:
      return { ...podiums, isFetching: false, result: action.payload };
    case UPDATE_PODIUM_RESPONSE_SUCCESS:
      const podiumIndex = R.findIndex((m) => m.id === action.payload.id)(podiums.result)

      return {
        ...podiums,
        isFetching: false,
        result: R.update(podiumIndex, action.payload)(podiums.result)
      };
    case UPDATE_PODIUM_RESPONSE_FAIL:
    case PODIUMS_RESPONSE_FAIL:
      return new Error('Not implemented!')
    case UPDATE_MEDAL_RESPONSE_SUCCESS:
      const podIndex = R.findIndex((m) => m.id === action.payload.podium_id)(podiums.result)
      const medalIndex = R.findIndex((m) => m.id === action.payload.id)(podiums.result[podIndex].medals)

      return {
        ...podiums,
        isFetching: false,
        result: R.over(
          R.compose(
            R.lensIndex(podIndex),
            R.lensProp('medals'),
          ),
          R.update(medalIndex, action.payload),
          podiums.result
        )
      }
    default:
      return podiums;
  }
}

export const mats = (state, action) => {
  switch (action.type) {
    case MATS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case UPDATE_MAT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case CHANGE_DATE:
      return {
        ...state,
        result: []
      };
    case MATS_RESPONSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        result: R.pipe(
          R.unionWith(R.eqBy(R.prop('number')), R.__, state.result),
          R.sortBy(R.prop('number'))
        )(action.payload)
      };
    case MATS_RESPONSE_FAIL:
      return new Error('Not implemented!')
    case RELOAD_MAT:
    case UPDATE_MAT_RESPONSE_SUCCESS:
      const matIndex = R.findIndex((m) => m.id === action.payload.id)(state.result)

      return {
        ...state,
        isFetching: false,
        result: matIndex !== -1 ? R.update(matIndex, action.payload, state.result) : state.result,
      };
    case UPDATE_MAT_RESPONSE_FAIL:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

export const mat = (mat, action) => {
  switch (action.type) {
    case MAT_REQUEST:
      return {
        ...mat,
        isFetching: true
      };
    case MAT_RESPONSE_SUCCESS:
      return {
        ...mat,
        isFetching: false,
        result: action.payload
      };
    case MAT_RESPONSE_FAIL:
      return new Error('Not implemented!')
    case UPDATE_MAT_REQUEST:
      return {
        ...mat,
        isFetching: true
      };
    case UPDATE_MAT_RESPONSE_SUCCESS:

      return {
        ...mat,
        isFetching: false,
        result: action.payload
      };
    case UPDATE_MAT_RESPONSE_FAIL:
      return new Error('Not implemented!')
    default:
      return mat;
  }
}

export const matches = (matches, action) => {
  switch (action.type) {
    case MATCHES_REQUEST:
    case UPDATE_MATCH_REQUEST:
      return { ...matches, isFetching: true }
      break;
    case MATCHES_RESPONSE_SUCCESS:
      return {
        ...matches,
        isFetching: false,
        result: R.pipe(
          R.concat(action.payload),
          R.uniqBy(R.prop('id'))
        )(matches.result)
      }
      break;
    case MATCHES_RESPONSE_FAIL:
    case UPDATE_MATCH_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    case RELOAD_MATCH:
    case UPDATE_MATCH_RESPONSE_SUCCESS:
      return {
        ...matches,
        result: R.over(
          lensMatching(R.propEq('id', action.payload.id)),
          () => action.payload,
          matches.result
        )
      }
      break
    default:
      return matches;
  }
}

export const ageDivisions = (ageDivisions, action) => {
  switch (action.type) {
    case AGE_DIVISIONS_REQUEST:
      return { ...ageDivisions, isFetching: true }
      break;
    case AGE_DIVISIONS_RESPONSE_SUCCESS:
      return { ...ageDivisions, isFetching: false, result: action.payload }
      break;
    case AGE_DIVISIONS_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    default:
      return ageDivisions;
  }
}

export const weightDivisions = (weightDivisions, action) => {
  switch (action.type) {
    case WEIGHT_DIVISIONS_REQUEST:
      return { ...weightDivisions, isFetching: true }
      break;
    case WEIGHT_DIVISIONS_RESPONSE_SUCCESS:
      return { ...weightDivisions, isFetching: false, result: action.payload }
      break;
    case WEIGHT_DIVISIONS_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    default:
      return weightDivisions;
  }
}

export const timeLimits = (timeLimits, action) => {
  switch (action.type) {
    case TIME_LIMITS_REQUEST:
      return { ...timeLimits, isFetching: true }
      break;
    case TIME_LIMITS_RESPONSE_SUCCESS:
      return { ...timeLimits, isFetching: false, result: action.payload }
      break;
    case TIME_LIMITS_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    default:
      return timeLimits;
  }
}

export const belts = (belts, action) => {
  switch (action.type) {
    case BELTS_REQUEST:
      return { ...belts, isFetching: true }
      break;
    case BELTS_RESPONSE_SUCCESS:
      return { ...belts, isFetching: false, result: action.payload }
      break;
    case BELTS_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    default:
      return belts;
  }
}

export const genders = (genders, action) => {
  switch (action.type) {
    case GENDERS_REQUEST:
      return { ...genders, isFetching: true }
      break;
    case GENDERS_RESPONSE_SUCCESS:
      return { ...genders, isFetching: false, result: action.payload }
      break;
    case GENDERS_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    default:
      return genders;
  }
}

export const estimate = (estimate, action) => {
  switch (action.type) {
    case ESTIMATE_REQUEST:
      return { ...estimate, isFetching: true }
      break;
    case ESTIMATE_RESPONSE_SUCCESS:
      return { ...estimate, isFetching: false, result: action.payload }
      break;
    case ESTIMATE_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    case UPDATE_ESTIMATE_DAY_RESPONSE_SUCCESS:
      const dayIndex =
        R.findIndex((day) => day.id === action.payload.id)(estimate.result.days)

      return {
        ...estimate,
        isFetching: false,
        result: R.over(
          R.lensProp('days'),
          R.update(dayIndex, action.payload)
        )(estimate.result)
      }
      break;
    default:
      return estimate;
  }
}

export const ui = (ui, action) => {
  return {
    ...ui,
    filters: uiFilters(ui.filters, action),
  }
}

export const uiFilters = (state, action) => {
  switch (action.type) {
    case CHANGE_FILTER:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}

export function matchDecisions(state, action) {
  switch (action.type) {
    case MATCH_DECISIONS_REQUEST:
      return { ...state, isFetching: true }
      break;
    case MATCH_DECISIONS_RESPONSE_SUCCESS:
      return { ...state, result: action.payload, isFetching: false }
      break;
    case MATCH_DECISIONS_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    default:
      return state;
  }
}

export function disqualifications(state, action) {
  switch (action.type) {
    case DISQUALIFICATIONS_REQUEST:
      return { ...state, isFetching: true }
      break;
    case DISQUALIFICATIONS_RESPONSE_SUCCESS:
      return { ...state, result: action.payload, isFetching: false }
      break;
    case DISQUALIFICATIONS_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    default:
      return state;
  }
}

export function competitors(state, action) {
  switch (action.type) {
    case COMPETITORS_REQUEST:
      return { ...state, isFetching: true }
      break;
    case COMPETITORS_RESPONSE_SUCCESS:
      return { ...state, result: action.payload, isFetching: false }
      break;
    case COMPETITORS_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    default:
      return state;
  }
}

export function tournamentSubscriptions(state, action) {
  switch (action.type) {
    case TOURNAMENT_SUBSCRIPTIONS_REQUEST:
      return { ...state, isFetching: true }
      break;
    case TOURNAMENT_SUBSCRIPTIONS_RESPONSE_SUCCESS:
      return { ...state, result: action.payload, isFetching: false }
      break;
    case TOURNAMENT_SUBSCRIPTIONS_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    case CREATE_TOURNAMENT_SUBSCRIPTION_REQUEST:
    case DESTROY_TOURNAMENT_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true
      }
      break;
    case CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL:
    case DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL:
      return {
        ...state,
        isFetching: true
      }
      break;
    case CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.append(
          action.payload,
          state.result
        ),
        isFetching: false }
      break;
    case DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.filter(
          (s) => !R.propEq('id', action.payload.id, s),
          state.result
        ),
        isFetching: false }
      break;
    default:
      return state;
  }
}


export function medalDeliveries(state, action) {
  switch (action.type) {
    case MEDAL_DELIVERIES_REQUEST:
      return { ...state, isFetching: true }
      break;
    case MEDAL_DELIVERIES_RESPONSE_SUCCESS:
      return { ...state, result: action.payload, isFetching: false }
      break;
    case MEDAL_DELIVERIES_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    default:
      return state;
  }
}

export const matsWithMatchUpdate = (state, action) => {
  let matIndex;

  switch (action.type) {
    case RELOAD_MATCH:
    case UPDATE_MATCH_RESPONSE_SUCCESS:
      matIndex = R.findIndex(R.propEq('number', action.payload.mat), state.result)
      const matchIndex = R.findIndex(R.propEq('id', action.payload.id), state.result[matIndex].matches)

      return matchIndex !== -1 ?
        {
          ...state,
          result: R.over(
            R.compose(R.lensIndex(matIndex), R.lensProp('matches')),
            R.update(matchIndex, action.payload),
            state.result
          )
        } :
        state;
    default:
      return mats(state, action);
  }
}


export function tournamentTemplates(state, action) {
  switch (action.type) {
    case TOURNAMENT_TEMPLATES_REQUEST:
      return { ...state, isFetching: true }
      break;
    case TOURNAMENT_TEMPLATES_RESPONSE_SUCCESS:
      return { ...state, result: action.payload, isFetching: false }
      break;
    case TOURNAMENT_TEMPLATES_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    case CREATE_TOURNAMENT_TEMPLATE_REQUEST:
    case DESTROY_TOURNAMENT_TEMPLATE_REQUEST:
      return {
        ...state,
        isFetching: true
      }
      break;
    case CREATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL:
    case DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_FAIL:
      return {
        ...state,
        isFetching: false
      }
      break;
    case UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS:
      const dayIndex =
        R.findIndex((group) => group.id === action.payload.id)(state.result)

      return {
        ...state,
        isFetching: false,
        result: R.update(dayIndex, action.payload, state.result)
      }

      break;
    case CREATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.append(
          action.payload,
          state.result
        ),
        isFetching: false }
      break;
    case DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.filter(
          (s) => !R.propEq('id', action.payload.id, s),
          state.result
        ),
        isFetching: false }
      break;
    default:
      return state;
  }
}
