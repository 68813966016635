import { Component } from 'react';

class NoMatch extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="no-match">
        <img src="/images/logo-ibjjf.svg" className="no-match__logo"/>
        <h1 className="no-match__mat">Mat {this.props.matNumber}</h1>
      </div>
    )
  }
}

export default NoMatch;
