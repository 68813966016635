import { transliterate } from 'transliteration';
import moment from 'moment-timezone';

export default class PodiumReceipt {
  printInstructions(podium) {
    return {
      epson_epagente_instructions: this.epsonEpagenteInstructions(podium),
      esc_pos_instructions: this.escPosInstructions(podium)
    }
  }

  escPosInstructions(podium) {
    return `
      printer
        .align('ct')

        .size(2, 2)
        .style('b')
        .text('------------------------')
        .text('${this.receiptPresenter(podium).podium_id}')
        .text('------------------------')

        .text('')

        .size(1, 1)
        .align('lt')

        .style('b')
        .text('CATEGORY')
        .style('NORMAL')
        .text("${this.sanitizeForEscPosInstructions(this.receiptPresenter(podium).category)}")

        .text('')

        .style('b')
        .text('COMPETITORS')
        .style('NORMAL')
        ${
          this.receiptPresenter(podium).medals
            .sort(function(a, b) { return a.place - b.place; })
            .map(this.escPosMedal.bind(this))
            .join("")
        }

        .text('')

        .align('ct')
        .text('----------------')
        .text('')
        .text('${this.receiptPresenter(podium).updated_at}')
        .text('')

        .cut()
        .close()
    `
  }

  escPosMedal(medal) {
    return `
      .text("${medal.place}- ${this.sanitizeForEscPosInstructions(medal.competitor_name)}")
      .text("  ${this.sanitizeForEscPosInstructions(medal.club_name)}")

      .text('')
    `;
  }

  epsonEpagenteInstructions(podium) {
    return `
      ImprimeTextoTag|<ce><bmp></bmp></ce>
      |ImprimeTextoTag|<ce><b>--------------</b></ce>
      |ImprimeTextoTag|<ce><xl>${this.receiptPresenter(podium).podium_id}</xl></ce>
      |ImprimeTextoTag|<ce><b>--------------</b></ce>
      |ImprimeTextoTag|
        <b>Category:</b>
        <n>${this.receiptPresenter(podium).category}</n>

      |ImprimeTextoTag|
        <b>Competitors:</b>
        <n>${
          this.receiptPresenter(podium).medals
            .sort(function(a, b) { return a.place - b.place; })
            .map(this.epsonEpagenteMedals.bind(this))
        }</n>

      |ImprimeTextoTag|<ce><b>--------------</b></ce>
      |ImprimeTextoTag|<ce><b>#{receipt_presenter.updated_at}</b></ce>
      |ImprimeTextoTag|<gui></gui>
      |
      .
    `
  }

  epsonEpagenteMedals(medal) {
    return `
      |ImprimeTextoTag|
        <n>${medal.place}- ${this.sanitizeForEscPosInstructions(medal.competitor_name)}</n>
      |ImprimeTextoTag|
        <n>${this.sanitizeForEscPosInstructions(medal.club_name)}</n>
    `;
  }

  sanitizeForEscPosInstructions(content) {
    return transliterate(content).replace("\"", "'");
  }

  receiptPresenter(podium) {
    return {
      podium_id: String(podium.id).padStart(12, '0'),
      medals: podium.medals,
      category: podium.category_name,
      updated_at: podium.updated_at
    }
  }
}
