
import {
  MEDAL_DELIVERED,
  MEDAL_DELIVERED_TO_REPRESENTATIVE,
  MEDAL_COMPETITOR_NOT_FOUND
} from '../../shared/constants';

import {
  isUnchecked
} from '../../modules/podium_utils';

import R from 'ramda';
import moment from 'moment-timezone';
import Popout from 'react-popout';

PublicCheckin.defaultProps = {
  autoscroll: true
}

function PublicCheckin(props) {
  const {
    tournamentId,
  } = props;

  return (
    <Popout
      url={`/admin/tournaments/${tournamentId}/podiums/public`}
      title='Public Podium'
      options={{
        height: (options, w) => { return w.outerHeight },
        width: (options, w) => { return w.outerWidth },
        scrollbars: false
      }}>
      <div className="containers-fluid">
        <div className="podiums podiums--public">
          {podiums(props.podiums)}
        </div>
      </div>

    </Popout>
  )
}

const podiums = R.pipe(
  R.filter(isUnchecked),
  R.addIndex(R.map)(podium)
)

function podium(p, i) {
  return (<div key={i} className="podiums__podium">
    <div className="podiums__size">{`Total: ${p.medals.length}`}</div>
    <div className="podiums__category-name">{p.category_name}</div>
    {medals(p.medals)}
  </div>)
}

const medals = R.pipe(
  R.sortBy(R.prop('place')),
  R.addIndex(R.map)(medal)
)

function medal(m, i) {
  return (
    <div key={i} className="podium">
      <div className={`podium__step panel panel-default ${medalStatusClass(m)}`}>
        <div className={`podium__medal ${medalGradeClass(m)}`}>
          <div className="podium__place">{podium.place}</div>
          <i className="fa fa-trophy"></i>
        </div>
        <div className="podium__competitor">
          <div className="podium__competitor-name">{m.competitor_name}</div>
          <div className="podium__club-name">{m.club_name}</div>
        </div>
        <i className="podium__found-icon fa fa-lg fa-check-circle-o"/>
      </div>
    </div>
  );
}

function medalGradeClass(medal) {
  switch (medal.place) {
    case 1:
      return "podium__gold"
      break;
    case 2:
      return "podium__silver"
      break;
    case 3:
      return "podium__bronze"
      break;

  }
}

function medalStatusClass(medal) {
  switch (medal.medal_delivery_id) {
    case MEDAL_DELIVERED:
    case MEDAL_DELIVERED_TO_REPRESENTATIVE:
      return "podium__competitor-found"
      break;
    case MEDAL_COMPETITOR_NOT_FOUND:
      return "podium__competitor-not-found"
      break;
    default:
      return ""
  }
}


export default PublicCheckin;
