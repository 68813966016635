import React, { Component, PropTypes } from 'react';
import moment from 'moment-timezone';

const ScheduleActionButton = (props) => {
  const {
    redoText,
    confirmText,
    scheduledAtText,
    scheduledText,
    notScheduledText,
    doScheduleText,
    value,
    isScheduled,
    onClick
  } = props;

  const btnClasses = value ?
    "btn-success" : "btn-danger";

  const btnText = value ?
    redoText : doScheduleText;

  const confirmationText = value ?
    confirmText : false;

  const span = value ?
      <span className="text-success">
        <i className="fa fa-lg fa-check-circle-o"></i>
        {scheduledAtText} {moment(value).format("ddd, h:mm a")}
      </span> :
      <span className="text-danger">
        <i className="fa fa-lg fa-times-circle-o"></i>
        {notScheduledText}
      </span>

  const button = isScheduled ?
    <a className={`btn btn-default`}
      disabled>{scheduledText}</a> :
    <a
      href="#"
      className={`btn ${btnClasses}`}
      data-confirm={confirmationText}
      onClick={onClick}>{btnText}</a>;

  return (
    <div className="schedule-action-button">
      {button}
      {!isScheduled && span}
    </div>
  )
}

ScheduleActionButton.propTypes = {
  redoText: PropTypes.string,
  confirmText: PropTypes.string,
  scheduledAtText: PropTypes.string,
  scheduledText: PropTypes.string,
  doScheduleText: PropTypes.string,
  notScheduledText: PropTypes.string,
}

export default ScheduleActionButton;
