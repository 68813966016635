import R from 'ramda';

var findById = R.curry(function(collection, id) {
  return R.find(R.propEq('id', id), collection)
})

function DurationHelper(data) {
  this.categories = data.categories
  this.timeLimits = data.timeLimits
  this.belts = data.belts
}

DurationHelper.fn = DurationHelper.prototype;

DurationHelper.fn.category = function(categoryId) {
  return findById(this.categories, categoryId);
}

DurationHelper.fn.wrapCategory = function(category) {
  return {
    id: category.id,
    name: category.name,
    fight_time: this.timeInMinutes(category),
    belt_name: this.nameOfHighestBelt(category),
    competitors_count: this.competitorsCount(category),
    age_division_id: category.age_division_id,
    weight_division_id: category.weight_division_id,
    gender_id: category.gender_id,
  }
}

DurationHelper.fn.wrappedCategories = function() {
  return R.map(this.wrapCategory.bind(this), this.categories);
}

DurationHelper.fn.timeInMinutes = function(category) {
  var timeLimitByCategory = R.curry(function(category, timeLimit) {
        return category.age_division_id === timeLimit.age_division_id &&
               R.last(category.belt_ids) === timeLimit.belt_id
      });

  return R.find(timeLimitByCategory(category), this.timeLimits).time_in_minutes;
}

DurationHelper.fn.nameOfHighestBelt = function(category){
  return findById(this.belts, R.last(category.belt_ids)).name
}

DurationHelper.fn.competitorsCount = function(category) {
  return category.tournament_subscription_ids.length;
}

module.exports = DurationHelper;
