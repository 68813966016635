
class OpenClassSubscriptionForm {
  constructor() {
    $('.opc-subscription__unsubscribe-btn')
      .on('click', this.unsubscribeCompetitor.bind(this));
  }

  unsubscribeCompetitor(ev) {
    const $target = $(ev.currentTarget);

    if (!confirm(`Remove ${$target.data('competitor-name')}?`)) {
      return false;
    }
  }
}

export default OpenClassSubscriptionForm;
