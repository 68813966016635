import R from 'ramda';
import NotificationsPopover from '../components/notifications_popover';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setCategoryFilter,
  dismissNotification,
} from '../actions';

function mapStateToProps(state, ownProps) {
  return {
    notifications: state.app.ui.notifications
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setCategoryFilter,
    dismissNotification,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPopover);
