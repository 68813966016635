import { Component, PropTypes } from 'react';
import Match from './match';
import MatchDisplay from './match_display';
import { DragSource } from 'react-dnd';
import { ROUND } from '../../shared/constants';
import R from 'ramda';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { sortAssignedMatches } from '../../modules/sort_assigned_matches';

const roundSource = {
  beginDrag(props) {
    return {
      selectedMatches: sortAssignedMatches(props.matches)
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}

class MatchGroup extends Component {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }

  render() {
    const {
      connectDragSource,
      isDragging,

      round,
      matches,
      categoryMatchesCount,
      selectedMatches,
      selectMatches,
    } = this.props;

    const cards = R.pipe(
      sortAssignedMatches,
      R.addIndex(R.map)((match, i) =>
        <Match key={i}
        match={match}
        selectedMatches={selectedMatches}
        onClick={selectMatches}
        />)
    )(matches)

    return connectDragSource(
      <div className="round">
        <this.props.label matches={matches}/>
        {cards}
      </div>
    )
  }
}

MatchGroup.propTypes = {
  round: PropTypes.string.isRequired,
  matches: PropTypes.array.isRequired,
  label: PropTypes.node.isRequired,
}

export default DragSource(ROUND, roundSource, collect)(MatchGroup);
