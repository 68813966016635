export default function matchCompetitorState (props, prefix) {
  if (props.disqualificationId) {
    return 'disqualified';
  }

  if (props.checkedInAt) {
    return 'checked_in';
  }

  if (props.noShowReportedAt) {
    return 'no-show';
  }

  return 'assigned';
}

