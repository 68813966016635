import React, { Component, PropTypes } from 'react';
import R from 'ramda';
import lensById from '../../modules/lens_by_id';
import MaterialCheckbox from '../../shared/components/material_checkbox';
import { isPrinted, isChecked, allDelivered, firstMedalCheckedIn } from '../../modules/podium_utils';
import moment from 'moment-timezone';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import CountDownTimer from './countdown_timer';

export default function Podium(props) {
  const {
    podium,
    medalDeliveries,
    deliver,
    onTimeout,
    onReprint,
  } = props;

  const deliverButtonText = (c) =>
    R.isNil(c.medal_delivery_id) ? 'Deliver' : R.view(lensById(c.medal_delivery_id), medalDeliveries).name

  const medalDeliveriesListItems = (medal, medalDeliveries) =>
    R.addIndex(R.map)((medalDelivery, i) =>
      <li key={i}>
        <a href="#"
        onClick={(e) => {
          props.onDeliverMedal(medal.id, medalDelivery.id, podium.id)

          e.preventDefault()
        }}>
          {medalDelivery.name}
        </a>
      </li>
    )(medalDeliveries)

  const medalGradeClass = (place) => {
    switch (place) {
      case 1:
        return "podium__gold"
      case 2:
        return "podium__silver"
      default:
        return "podium__bronze"
    }
  }

  const medalRows = R.pipe(
    R.sortBy(R.prop('place')),
    R.addIndex(R.map)((medal, i) => {
      const place = medal.medal_delivery_id ?
        <div className={`podium__medal ${medalGradeClass(medal.place)}`}>
          <span className="podium__place">{medal.place}</span>
          <i className="fa fa-trophy"/>
        </div> :
        <div className="podium__medal">
          <span className="podium__place">{medal.place}</span>
        </div>;

      const popover =
        <Popover id="podium__picture-popover">
          <img className="podium__picture--bigger" src={medal.competitor_photo}/>
        </Popover>

      return (<div key={i} className="podium__step panel panel-default">
        {place}

        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
          <img className="podium__picture" src={medal.competitor_photo}/>
        </OverlayTrigger>
        <div className="podium__competitor">
          <div className="podium__competitor-name">
            {medal.competitor_name}
          </div>
          <div className="podium__club-name">
            {medal.club_name}
          </div>
        </div>
        <div className="podium__deliver-btn dropdown">
          <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {deliverButtonText(medal)}
            <span className="caret"></span>
          </button>
          <ul className="dropdown-menu">
            {medalDeliveriesListItems(medal, medalDeliveries)}
            <li key={i}>
              <a href="#"
                className="podium__option"
                onClick={(e) => {
                  props.onDeliverMedal(medal.id, null, podium.id)

                  e.preventDefault();
                  return false;
                }}>
                Undo result
              </a>
            </li>
          </ul>
        </div>
      </div>)
      }
    )
  )


  const hidePodiumButton = allDelivered(podium.medals) ?
    <MaterialCheckbox
      checked={isChecked(podium)}
      label={'Hide podium'}
      onClick={() => props.onCheck(podium.id, !isChecked(podium))} ></MaterialCheckbox> :
    null;

  return (
    <div className="col-md-8 col-md-offset-2 podiums__podium">
      <span className="podium__header">
        <span>
          {hidePodiumButton}
          <div className="podiums__category-name">
            {podium.category_name}
          </div>
        </span>
        {
          firstMedalCheckedIn(podium.medals) &&
          !isPrinted(podium) &&
          <CountDownTimer
            intervalInMinutes={20}
            time={firstMedalCheckedIn(podium.medals).updated_at}
            onTimeup={() => onTimeout(podium)}/>
        }
        {
          isPrinted(podium) &&
          <a className="podiums__reprint pull-right btn btn-sm btn-default"
            onClick={() => onReprint(podium)}>Reprint</a>
        }
      </span>
      <div className="podium podium--admin">
        {medalRows(podium.medals)}
      </div>
    </div>
  )
}

Podium.propTypes = {
  podium: PropTypes.object,
  onCheck: PropTypes.func,
  onDeliver: PropTypes.func,
  onTimeout: PropTypes.func,
  onPrint: PropTypes.func
}
