import R from 'ramda';
import moment from 'moment-timezone';
import lensMatching from './lens_matching';

//match stuff

export const isMatchFinished = R.propEq('state', 'finished')
export const isMatchReady = R.propEq('state', 'weighted')
export const isMatchInPreparation = (match) => R.contains(match.state, ['created', 'assigned', 'checked_in'])
export const isMatchStarted = R.propEq('state', 'started')
export const isFinal = R.propEq('phase', 'F');
export const isOrdered = R.compose(R.not, R.isNil, R.prop('mat'))

export const isWinner = R.propEq('winner', true)
export const isLoser  = R.propEq('winner', false)

export const winner = R.pipe(R.prop('competitors'), R.filter(isWinner), R.head)
export const loser = R.pipe(R.prop('competitors'), R.filter(isLoser), R.head)

const competitors = R.prop('competitors')
const isDisciplinarilyDisqualified = R.propEq('disqualification_id', 5)

export const fetchFinalMedalists = R.pipe(
  competitors,
  R.filter(R.pipe(isDisciplinarilyDisqualified, R.not)),
  R.sortBy(R.prop('winner')),
  R.reverse
)

export const fetchSemiFinalMedalists = R.pipe(
  competitors,
  R.filter(isLoser),
  R.filter(R.pipe(isDisciplinarilyDisqualified, R.not)),
)

export const medalists = R.pipe(
  R.ifElse(isFinal, fetchFinalMedalists, fetchSemiFinalMedalists)
)

export const childMatchLabel = (child) => {
  return child.current_start_time ?
    `${child.origin === 'loser' ? 'Defeated' : 'Winner'} of fight ${child.order + 1}, Mat ${child.mat}, ${moment(child.current_start_time).format("ddd")}` :
    ""
}

// competitor stuff
export const hasDisciplinaryDisqualification =
  R.ifElse(
    R.propEq('state', 'finished'),
    R.pipe(competitors, R.any(isDisciplinarilyDisqualified)),
    () => false
  )

export const isDisqualified =
  R.pipe(
    R.prop('disqualification_id'),
    R.isNil,
    R.not
  )
