import R from 'ramda';
import { Modal, Button } from 'react-bootstrap';
import { Component, PropTypes } from 'react';

class GapModal extends Component {
  constructor(props) {
    super(props);

    this.state = { gap: props.gap };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ gap: newProps.gap });
  }

  submitGap(ev) {
    this.props.submitGap(this.state.gap);
    this.props.hideGapModal();
  }

  handleChange(ev) {
    this.setState({ gap: R.assoc(ev.target.name, ev.target.value, this.state.gap) });
  }

  render() {
    const {
      show,
      close,

      hideGapModal,
    } = this.props;

    const gap = this.state.gap;

    return (
      <Modal show={!R.isEmpty(gap)} onHide={hideGapModal} className="modal gap-modal">
        <Modal.Header closeButton>
          <Modal.Title className="gap-modal__title">Insert Gap</Modal.Title>
        </Modal.Header>
        <Modal.Body className="gap-modal__content">
          <div className="row">
            <div className="col-md-12">
              <div className="gap-modal__description form-group">
                <label className="control-label">Description</label>
                <input className="form-control" name="description" value={gap.description} onChange={this.handleChange.bind(this)}/>
              </div>
              <div className="gap-modal__duration form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label className="control-label">Duration</label>
                    <input className="form-control" name="duration" value={gap.duration} onChange={this.handleChange.bind(this)}/>
                  </div>
                  <div className="col-md-6">
                    <label className="control-label">Duration Unit</label>
                    <select className="form-control" name="unit" selected={gap.unit} onChange={this.handleChange.bind(this)}>
                      <option value="minutes">Minutes</option>
                      <option value="hours">Hours</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-primary btn-raised" onClick={this.submitGap.bind(this)}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

React.propTypes = {
  gap: PropTypes.object.isRequired,
}

export default GapModal;
