import R from 'ramda';

const isNotAlreadyChosen = (collectionField, keyField) =>
  (value, allValues) =>
    R.pipe(
      R.filter((c) => parseInt(R.prop(keyField, c), 10) === parseInt(value, 10)),
      R.length,
      (x) => x > 1 ? 'Already chosen' : undefined
    )(R.prop(collectionField, allValues))

const isNotEmpty = (value) =>
  R.isNil(value) ? 'Field cannot be empty' : undefined

export {
  isNotAlreadyChosen,
  isNotEmpty
};
