import R from 'ramda';
import PageFlipper from './page_flipper';

const pageSlider = R.curry((pageSize, pageCount, currentPosition, direction) => {
  console.log('currentPosition', currentPosition);
  console.log('direction', direction);

  return (currentPosition == 0 && direction > 0 ||
      Math.abs(currentPosition) >= (pageCount - pageSize) && direction < 0) ?
    currentPosition :
    currentPosition + direction;
})

class SlidingColumns  {
  constructor(_options = {}) {
    this.options = _options;
    this.slidingColumns = $(".sliding-columns")
    this.column = this.slidingColumns.find(".sliding-columns__column")
    this.cursor = this.slidingColumns.find(".sliding-columns__cursor")
    this.paginator = this.options.timer ?
      new PageFlipper({
        containerClass: '.sliding-columns',
        itemClass: '.sliding-columns__column',
        interval: this.options.timer
      }) :
      pageSlider(this.pageSize(), this.column.length);

    this.currentPosition = this.options.position || 0;

    this.slidingColumns.find(".sliding-columns__button-left").on("click", () => {
      this.moveCursor(1);
    })

    this.slidingColumns.find(".sliding-columns__button-right").on("click", () => {
      this.moveCursor(-1);
    })

    this.scrollToPosition(this.currentPosition);
  }

  moveCursor(direction) {
    const newPosition = this.paginator(this.currentPosition, direction)

    this.scrollToPosition(newPosition);
  }

  scrollToPosition(position) {
    this.cursor.css("margin-left", position * this.columnWidth());

    this.currentPosition = position;

    this.slidingColumns
      .trigger('sliding-columns:change', { position: position })
  }

  columnWidth() {
    return this.column.outerWidth(true)
  }

  pageSize() {
    return Math.floor(this.slidingColumns.outerWidth(true) / this.columnWidth());
  }

}

export default SlidingColumns;
