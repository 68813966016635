import R from 'ramda';

function state(state) {
  switch (state) {
    case 'assigned':
      return 'Waiting check-in';
    case 'checked_in':
      return 'Checked in. Waiting weighing';
    case 'weighted':
      return 'Weighted. Waiting checkin';
    case 'ready':
      return 'Ready to fight';
    case 'disqualified':
      return 'Disqualified';
    case 'no_show':
      return 'No show reported.';
    default:
      return '...'
  }
}

function competitor(competitor, i) {
  const color = i === 0 ? 'score-competitor--blue' : 'score-competitor--white';
  const stateColor = `match-competitor--${competitor.state}`;

  return (
    <div
      className={`row score-competitor score-competitor--waiting ${color}`}
      key={i}>
      <div className="col-xs-6">
        <span className="score-competitor__club-icon">
        </span>
        <div className="score-competitor__description">
          <img className="score-competitor__picture" src={competitor.photo}/>
          <div className="score-competitor__name">
            {competitor.name}
          </div>
          <div className="score-competitor__club-name">
            {competitor.club}
          </div>
        </div>
      </div>
      <div className="col-xs-6">
        <div className={`score-competitor__state ${stateColor}`}>
          {state(competitor.state)}
        </div>
      </div>
    </div>
  )
}

function WaitingMatch(props) {
  const {
    match
  } = props;

  return (
    <div className="waiting-match">
      {
        R.addIndex(R.map)((c, i) => competitor(c, i), match.competitors)
      }
    </div>
  )
}

export default WaitingMatch;
