import { PropTypes, Component } from 'react';

class Pagination extends Component {

  onAdvance() {
    const { currentPage } = this.props;

    if (currentPage < this.totalPages()) {
      this.props.advancePage()
    }
  }

  onRewind() {
    const { currentPage } = this.props;

    if (currentPage > 1) {
      this.props.rewindPage()
    }
  }

  totalPages() {
    const { collection, pageSize } = this.props;

    return Math.ceil(collection.length / pageSize)
  }

  render() {
    return (
      <nav className="pagination row">
        <div className="col-md-4 pagination__previous">
          <a href="#" onClick={this.onRewind.bind(this)}><i className="fa fa-angle-left pagination__previous-btn"/></a>
        </div>
        <div className="col-md-4">
          <span className="pagination__current-page">{`${this.props.currentPage}/${this.totalPages()}`}</span>
        </div>
        <div className="col-md-4 pagination__next">
          <a href="#" onClick={this.onAdvance.bind(this)}><i className="fa fa-angle-right pagination__next-btn"/></a>
        </div>
      </nav>
    );
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  rewindPage: PropTypes.func,
  advancePage: PropTypes.func,
}

Pagination.defaultProps = {
  currentPage: 1,
  totalPages: 0,
}

export default Pagination;
