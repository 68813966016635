import R from 'ramda';
import { Component, PropTypes } from 'react';

export default function ModalPodium(props) {
  const { podium } = props;

  const medals = R.pipe(
    R.sortBy(R.prop('place')),
    R.addIndex(R.map)((m, i) => (
      <div key={i} className="modal-podium__medal">
        <span className="modal-podium__place">{m.place}</span>
        <span className="modal-podium__competitor">
          <div className="modal-podium__competitor-name">{m.competitor_name}</div>
          <div className="modal-podium__club-name">{m.club_name}</div>
        </span>
      </div>
    ))
  );

  return (
    <div className="modal-podium__medals">
      {
        R.length(podium.scenario) > 0 &&
        <div className="modal-podium__scenario">
          <i className="fa fa-info-circle"></i>
          { "This podium has an unusual bracket scenario" }
        </div>
      }
      {medals(podium.medals)}
    </div>
  );
}
