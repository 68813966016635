import { Component } from 'react';
import R from 'ramda';
import moment from 'moment-timezone';

import TimePicker from 'rc-time-picker';
import I18n from 'i18n-js';

function StartTimeSelectorDisplayHOC(WrappedComponent) {
  return class StartTimeSelector extends Component {
    onChangeDay (e) {
      this.props.onChangeDay(this.props.categories, e.target.value)
    }

    onChangeEstimatedStartTime(value) {
      this.props.onChangeEstimatedStartTime(this.props.categories, value.format("HH:mm"))
    }

    onChangeSubscriptionDeadline(value) {
      this.props.onChangeSubscriptionDeadline(this.props.categories, value.format("HH:mm"))
    }

    render() {
      const {
        categories,
        tournamentDays
      } = this.props;

      const {
        subscription_tournament_day_id,
        estimated_start_time_hour,
        estimated_start_time_minute,
        subscription_deadline_hour,
        subscription_deadline_minute
      } = categories[0];

      const firstMemberDate = subscription_tournament_day_id || "";
      const firstMemberTime = estimated_start_time_hour ? moment().hour(estimated_start_time_hour).minute(estimated_start_time_minute) : null;
      const firstMemberDeadlineTime = subscription_deadline_hour ? moment().hour(subscription_deadline_hour).minute(subscription_deadline_minute) : null;

      const dayValue = R.all((c) => {
        return c.subscription_tournament_day_id &&
          subscription_tournament_day_id === c.subscription_tournament_day_id
      }, categories) ? firstMemberDate : ""

      const startTimeValue = R.all((c) => {
        return estimated_start_time_hour === c.estimated_start_time_hour &&
        estimated_start_time_minute === c.estimated_start_time_minute
      }, categories) ? firstMemberTime : null

      const subscriptionDeadlineValue = R.all((c) => {
        return subscription_deadline_hour === c.subscription_deadline_hour &&
        subscription_deadline_minute === c.subscription_deadline_minute
      }, categories) ? firstMemberDeadlineTime : null

      const dates =
        R.addIndex(R.map)((td, i) => <option key={i} value={td.id}>{moment(td.date).format('dddd')}</option>, tournamentDays)

      return (
        <span>
          <WrappedComponent {...this.props}/>
          <div className="form-inline start-time-selector-display__form">
            <div className="form-group">
              <label>{I18n.t("activerecord.attributes.tournament.estimated_start_time")}</label>
              <TimePicker
                showSecond={false}
                allowEmpty={true}
                defaultValue={startTimeValue}
                inputClassName={"form-control"}
                onChange={this.onChangeEstimatedStartTime.bind(this)}
                format={"hh:mm A"}
                use12Hours
                inputReadOnly
              />
            </div>
            <div className="form-group">
              <label>{I18n.t("activerecord.attributes.tournament.subscription_deadline")}</label>
              <select defaultValue={dayValue} className="form-control input-sm" onChange={this.onChangeDay.bind(this)}>
                <option value="">--</option>
                {dates}
              </select>
              <TimePicker
                showSecond={false}
                defaultValue={subscriptionDeadlineValue}
                onChange={this.onChangeSubscriptionDeadline.bind(this)}
                inputClassName={"form-control"}
                format={"hh:mm A"}
                use12Hours
                inputReadOnly
              />
            </div>
          </div>
        </span>
      )
    }
  }
}

StartTimeSelectorDisplayHOC.defaultProps = {
  categories: [],
  tournamentDays: []
}

export default StartTimeSelectorDisplayHOC;
