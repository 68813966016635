import R from 'ramda';
import { Component, PropTypes } from 'react';
import select from '../../shared/components/select';
import input from '../../shared/components/input';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { isNotAlreadyChosen, isNotEmpty } from '../../shared/validations';

const renderCompetitors = ({ competitors, fields }) => {
  const options = R.pipe(
    R.sortBy(R.prop('name')),
    R.map((c) => ({ value: c.id, label: c.name }))
  )(competitors)

  return (
    <div>
      {
        fields.map((member, i) => {
          return (
            <Field
              name={`${member}.competitor_id`}
              component={select}
              options={options}
              label={`Competitor ${i + 1}`}
              validate={[isNotAlreadyChosen('competitors', 'competitor_id')]}
            />
          )
        })
      }
    </div>
  )
}

const matchForm = props => {
  const {
    onCancel,
    competitors,
    error,
    valid,
    handleSubmit,
    pristine,
    submitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="competitors"
        component={renderCompetitors}
        props={{ competitors }}
      />
      <button type="submit" className="btn btn-primary" disabled={!valid || submitting}>
        Submit
      </button>
      <button type="button" className="btn btn-secondary" disabled={submitting} onClick={onCancel}>
        Cancel
      </button>
    </form>
  )
}

export default reduxForm({
  form: "MatchForm"
})(matchForm);

