
export function TournamentIndex() {
  const select = $(".tournaments__select")

  select
    .on('change', function(e) {
      $('.tournament-display').hide()
      $(`#tournament-display-${e.target.value}`).show()
    })

  setTimeout(() => select.trigger('change'), 500);
}

