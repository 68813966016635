import React, { Component } from 'react';
import R from 'ramda';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import apiMiddleware from '../middleware/api_middleware';

import WeighingContainer from './containers/weighing_container';
import { presence as reducer } from '../presence/reducers';

const logger = createLogger();

const store = createStore(
  combineReducers({ app: reducer }),
  applyMiddleware(thunkMiddleware, apiMiddleware, logger)
);

class WeighingApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <WeighingContainer
          tournamentId={this.props.tournament_id}
          tournamentDayId={this.props.tournament_day_id}
          matNumbers={R.map((n) => parseInt(n, 10), this.props.mat_numbers || [])}
          />
      </Provider>
    )
  }
}

export default WeighingApp;
