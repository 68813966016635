import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import estimates from './reducers';
import { reducer as form } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import EstimatesContainer from './containers/estimates_container';
import createLogger from 'redux-logger';
import apiMiddleware from '../middleware/api_middleware';

const logger = createLogger();

const store = createStore(
  combineReducers({ app: estimates, form }),
  applyMiddleware(thunkMiddleware, apiMiddleware, logger)
);

class EstimatesApp extends Component {
  render() {
    return (
      <Provider store={store}>
        <EstimatesContainer
          tournamentId={this.props.tournament_id}
          estimateId={this.props.estimate_id}
        />
      </Provider>
    )
  }
}

export default EstimatesApp;
