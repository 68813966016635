import React, { Component, PropTypes } from 'react';
import moment from 'moment-timezone';
import R from 'ramda';
import lensMatching from '../../modules/lens_matching';

import MatchCompetitor from './match_competitor';
import ChildMatch from './child_match';

import {
  isMatchCompetitor,
  matchCompetitorTournamentSubscription,
  matchCompetitorClubName,
} from '../../modules/match_lenses';

const competitor = (matchId, competitor) => {
  if (!competitor) {
    return null;
  }

  return competitor.kind === 'competitor' ?
    <MatchCompetitor
      matchId={matchId}
      competitorName={competitor.name}
      competitorPhoto={competitor.photo}
      clubName={competitor.club}
      competitorId={competitor.id}
      matchCompetitorId={competitor.match_competitor_id}
      disqualificationId={competitor.disqualification_id}
      noShowReportedAt={competitor.no_show_reported_at}
      checkedInAt={competitor.checked_in_at}
      tournamentSubscriptionId={competitor.subscription_id}
      state={competitor.state} /> :
    <ChildMatch
      child={competitor}/>

}

export default function MatchCompetitors(props) {
  const {
    matchId,
    matId,
    order,
    index,
    categoryName,
    weightLimit,
    estimatedStart,
    competitors,
    slug,
    isFetchingMat,

    onChangeMatchOrder
  } = props;

  const date = moment.parseZone(estimatedStart);

  return (
    <div className="match-competitors">
      <span className="match-competitors__category-name">{`${categoryName} (${weightLimit})`}</span>
      <div className="match-competitors__estimated-start"> {date.format('ddd h:mm A')} </div>
      <div className="match-competitors__content">
        <div className="match-competitors__order">
          { slug === "checkin-mat-list" && index > 0 &&
            <button
              disabled={isFetchingMat}
              className="btn btn-xs match-competitors__move-up-btn"
              onClick={() => onChangeMatchOrder(matId, matchId , order - 1)}>
              <i className="fa fa-chevron-up"/>
            </button>}

          <span className="match-competitors__order">{order + 1}</span>

          { slug === "checkin-mat-list" && index < 4 &&
            <button
              disabled={isFetchingMat}
              className="btn btn-xs match-competitors__move-down-btn"
              onClick={() => onChangeMatchOrder(matId, matchId, order + 1)}>
              <i className="fa fa-chevron-down"/>
            </button>}
        </div>
        <div className="match-competitors__competitors">
          {competitor(matchId, competitors[0])}
          {competitor(matchId, competitors[1])}
        </div>
      </div>
    </div>
  )
}

MatchCompetitors.propTypes = {
  order: PropTypes.number.isRequired,
  matchId: PropTypes.number.isRequired,
  categoryName: PropTypes.string.isRequired,
  estimatedStart: PropTypes.string.isRequired,
  matchCompetitors: PropTypes.arrayOf(PropTypes.shape({
    competitor_id: PropTypes.number.isRequired,
    competitor_name: PropTypes.string.isRequired,
  })),
}

MatchCompetitors.defaultProps = {
  matchCompetitors: [{}, {}]
}
