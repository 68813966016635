import React, { Component, PropTypes } from 'react';

import {
  childMatchLabel,
} from '../../modules/match_lenses';

export default function ChildMatch(props) {
  return (
    <span className="child-match">
      <img className="child-match__picture" src="/images/competitor-placeholder.png"/>
      <span className="child-match__description">
        {childMatchLabel(props.child)}
      </span>
    </span>
  )
}
