import R from 'ramda';
import snakeToCamel from '../modules/snake_to_camel';

import {
  CHANGE_FILTER,

  CHANGE_DATE,

  ADVANCE_PAGE,
  REWIND_PAGE,
  RESET_PAGE,

  HIDE_MATS,

  TOGGLE_HIGHLIGHT,

  MATCH_RESPONSE_SUCCESS,
  HIDE_MODAL,

  MATS_RESPONSE_SUCCESS,
  UPDATE_MAT_RESPONSE_SUCCESS,

  UPDATE_MATCH_RESPONSE_SUCCESS,

  SELECT_MATCHES,
  UNSELECT_MATCHES,

  FETCH_MODAL_MATCH,
  FETCH_OVERLAY_MATCH,

  INSERT_GAP,
  EDIT_GAP,

  HIDE_GAP_MODAL,

  CHANGE_MATCH_GROUPING,
  PROCESS_NOTIFICATIONS,
  DISMISS_NOTIFICATION,
  PHASE,

  CHANGE_MERGE_OPTIONS,

  UPDATE_SCHEDULE_SETUP,

  SELECT_TOURNAMENT_TEMPLATE,
  TOURNAMENT_TEMPLATES_RESPONSE_SUCCESS,
  DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,

  CREATE_CATEGORY_GROUP_REQUEST,
  CREATE_CATEGORY_GROUP_RESPONSE_SUCCESS,
  CREATE_CATEGORY_GROUP_RESPONSE_FAIL,

  UPDATE_CATEGORY_GROUP_REQUEST,
  UPDATE_CATEGORY_GROUP_RESPONSE_SUCCESS,
  UPDATE_CATEGORY_GROUP_RESPONSE_FAIL,

  DESTROY_CATEGORY_GROUP_REQUEST,
  DESTROY_CATEGORY_GROUP_RESPONSE_SUCCESS,
  DESTROY_CATEGORY_GROUP_RESPONSE_FAIL,

  SHOW_STAND_BY,
  HIDE_STAND_BY,
} from '../shared/constants';

import {
  tournament,
  categories,
  matsWithMatchUpdate,
  filters,
  uiFilters,
  matchDecisions,
  disqualifications,
  competitors,
  tournamentTemplates,
} from '../shared/reducers';

const initialState = {
  tournamentDayIndex: 0,
  pageOffset: 0,
  ui: {
    categoriesPage: 1,
    categoriesPageSize: 20,
    selectedTournamentTemplateId: null,
    standBy: "",
    autoOrder: {
      showNewGroupModal: false,
      showNewTemplateModal: false,
    },
    merge: {
      standardRest: 2,
      finalRest: 2
    },
    filters: {
      age_division_ids: [],
      weighte_division_ids: [],
      belt_ids: [],
      gender_ids: [],
    },
    gap: {},
    matchModal: {
      isFetching: false,
      result: undefined,
    },
    matchOverlay: {
      isFetching: false,
      result: undefined,
    },
    hiddenMats: [],
    highlight: {
      kind: null,
      id: null
    },
    selectedMatches: [],
    matchGrouping: PHASE,
    notifications: []
  },
  tournament: {
    isFetching: false,
    result: {},
  },
  filters: {
    isFetching: false,
    result: [],
  },
  mats: {
    isFetching: false,
    result: [],
  },
  categories: {
    isFetching: false,
    meta: { current_page: 1, total_pages: 0 },
    result: [],
  },
  matchDecisions: {
    isFetching: false,
    result: [],
  },
  disqualifications: {
    isFetching: false,
    result: [],
  },
  competitors: {
    isFetching: false,
    result: [],
  },
  categoryGroups: {
    isFetching: false,
    result: [],
  },
  tournamentTemplates: {
    isFetching: false,
    result: [],
  }
}

export const schedule = (state = initialState, action) => {
  return {
    ...state,
    tournamentDayIndex: tournamentDayIndex(state.tournamentDayIndex, action),
    tournament: tournament(state.tournament, action),
    filters: filters(state.filters, action),
    mats: matsWithMatchUpdate(state.mats, action),
    categories: categories(state.categories, action),
    matchDecisions: matchDecisions(state.matchDecisions, action),
    disqualifications: disqualifications(state.disqualifications, action),
    competitors: competitors(state.competitors, action),
    categoryGroups: categoryGroups(state.categoryGroups, action),
    tournamentTemplates: tournamentTemplateWrapper(state.tournamentTemplates, action),
    ui: ui(state.ui, action),
  }
}

const tournamentDayIndex = (state, action) => {
  switch (action.type) {
    case CHANGE_DATE:
      return action.payload;
    default:
      return state;
  }
}

export const ui = (ui, action) => {
  return {
    ...ui,
    selectedTournamentTemplateId: selectedTournamentTemplateId(ui.selectedTournamentTemplateId, action),
    categoriesPage: categoriesPage(ui.categoriesPage, action),
    standBy: standBy(ui.standBy, action),
    autoOrder: autoOrder(ui.autoOrder, action),
    merge: merge(ui.merge, action),
    filters: uiFilters(ui.filters, action),
    hiddenMats: hiddenMats(ui.hiddenMats, action),
    highlight: highlight(ui.highlight, action),
    matchModal: matchModal(ui.matchModal, action),
    matchOverlay: matchOverlay(ui.matchOverlay, action),
    selectedMatches: selectedMatches(ui.selectedMatches, action),
    gap: gap(ui.gap, action),
    matchGrouping: matchGrouping(ui.matchGrouping, action),
    notifications: notifications(ui.notifications, action),
  }
}

const categoriesPage = (state, action) => {
  switch (action.type) {
    case ADVANCE_PAGE:
      return state + 1;

      break;
    case REWIND_PAGE:
      return state - 1;

      break;
    case RESET_PAGE:
      return 1;

      break;
    default:
      return state;
  }
}

const standBy = (state, action) => {
  switch (action.type) {
    case SHOW_STAND_BY:
      return action.payload;

      break;
    case HIDE_STAND_BY:
      return "";

      break;
    default:
      return state;
  }
}

const autoOrder = (state, action) => {
  switch (action.type) {
    case UPDATE_SCHEDULE_SETUP:
      return action.payload;

      break;
    default:
      return state;
  }
}

const merge = (merge, action) => {
  switch (action.type) {
    case CHANGE_MERGE_OPTIONS:
      return action.payload

      break;
    default:
      return merge;
  }
}

export const hiddenMats = (state, action) => {
  switch (action.type) {
    case HIDE_MATS:
      console.log('PAYLOAD: ' + action.payload)

      return action.payload
      break;
    default:
      return state;
  }
}

export const highlight = (state, action) => {
  switch (action.type) {
    case TOGGLE_HIGHLIGHT:
      if (state.kind === action.payload.kind && R.equals(state.id, action.payload.id)) {
        return { kind: null, id: null };
      } else {
        return action.payload;
      }
    default:
      return state;
  }
}

export const matchModal  = (state, action) => {
  switch (action.type) {
    case FETCH_MODAL_MATCH:
      return {
        ...state,
        isFetching: true,
      }
    case MATCH_RESPONSE_SUCCESS:
      return state.isFetching == true ?
      {
        ...state,
        isFetching: false,
        result: action.payload
      } : state;
    case UPDATE_MATCH_RESPONSE_SUCCESS:
      if (!(action.payload.id === state.result.id)) {
        return state;
      }

      return {
        ...state,
        result: action.payload
      };
    case HIDE_MODAL:
      return {
        ...state,
        result: undefined,
      };
    default:
      return state;
  }
}

export const matchOverlay  = (state, action) => {
  switch (action.type) {
    case FETCH_OVERLAY_MATCH:
      return {
        ...state,
        isFetching: true,
      }
    case MATCH_RESPONSE_SUCCESS:
      return state.isFetching == true ?
      {
        ...state,
        isFetching: false,
        result: action.payload
      } : state;
    case UPDATE_MATCH_RESPONSE_SUCCESS:
      if (!(action.payload.id === R.path(['result', 'id'], state))) {
        return state;
      }

      return {
        ...state,
        result: action.payload
      };
    default:
      return state;
  }
}

export const selectedMatches = (state, action) => {
  switch (action.type) {
    case SELECT_MATCHES:
      return action.payload.matches;
    case UNSELECT_MATCHES:
      return [];
    default:
      return state;
  }
}

export const gap = (state, action) => {
  switch (action.type) {
    case INSERT_GAP:
      return {
        ...action.payload,
        duration: 5,
        unit: 'minutes'
      }
    case EDIT_GAP:
      return action.payload;
    case HIDE_GAP_MODAL:
      return {}
    default:
      return state;
  }
}

export const matchGrouping = (state, action) => {
  switch (action.type) {
    case CHANGE_MATCH_GROUPING:
      return action.payload
    default:
      return state;
  }
}

export const notifications = (state, action) => {
  switch (action.type) {
    case PROCESS_NOTIFICATIONS:
      const res = R.reduce((acc, n) => {
        const { name, payload } = n;
        const [ subject, message ] = R.split(":", name)

        return R.over(R.lensProp(snakeToCamel(`${subject}_${message}`)), R.append(payload), acc)
      }, state, action.payload)

      return res;
    case DISMISS_NOTIFICATION:
      return R.over(R.lensProp(action.payload.subject), R.remove(action.payload.index, 1), state);
    default:
      return state;
  }
}

export const selectedTournamentTemplateId = (state, action) => {
  switch (action.type) {
    case SELECT_TOURNAMENT_TEMPLATE:
      return action.payload
    default:
      return state;
  }
}

export function categoryGroups(state, action) {
  switch (action.type) {
    case TOURNAMENT_TEMPLATES_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.pipe(
          R.map(R.prop('category_groups')),
          R.flatten
        )(action.payload),
        isFetching: false
      }
      break;
    case DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.concat(
          action.payload.category_groups,
          state.result
        ),
        isFetching: false
      }
      break;
    case CREATE_CATEGORY_GROUP_REQUEST:
    case DESTROY_CATEGORY_GROUP_REQUEST:
      return {
        ...state,
        isFetching: true
      }
      break;
    case CREATE_CATEGORY_GROUP_RESPONSE_FAIL:
    case DESTROY_CATEGORY_GROUP_RESPONSE_FAIL:
      return {
        ...state,
        isFetching: false
      }
      break;
    case UPDATE_CATEGORY_GROUP_RESPONSE_SUCCESS:
      const dayIndex =
        R.findIndex((group) => group.id === action.payload.id)(state.result)

      return {
        ...state,
        isFetching: false,
        result: R.update(dayIndex, action.payload, state.result)
      }

      break;
    case CREATE_CATEGORY_GROUP_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.append(
          action.payload,
          state.result
        ),
        isFetching: false }
      break;
    case DESTROY_CATEGORY_GROUP_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.filter(
          (s) => !R.propEq('id', action.payload.id, s),
          state.result
        ),
        isFetching: false }
      break;
    default:
      return state;
  }
}

export function tournamentTemplateWrapper(state, action) {
  switch (action.type) {
    case DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.append(
          action.payload,
          state.result
        ),
        isFetching: false
      }
      break;
    default:
      return tournamentTemplates(state, action);
  }
}
