import React, { Component, PropTypes } from 'react';

class DraggableCategoryList extends Component {
  renderCategories(categories, competitors, display) {
    return categories.map((category, i) => {

      return (
        <div key={i} className="draggable-category-list__category panel panel-default">
          <div className={`panel-heading panel-heading--${category.belt_slug}`}>
            <this.props.display
              categories={[category]}
              competitors={competitors[category.id]}
              onChange={this.props.onChange.bind(this)}
              />
          </div>
        </div>
      )
    })
  }

  render () {
    return (
      <div className="draggable-category-list panel-group">
        {this.renderCategories(this.props.collection, this.props.competitorsCount, this.props.display)}
      </div>
    )
  }
}

export default DraggableCategoryList;
