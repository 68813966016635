
require("@rails/ujs").start();
import 'jquery-ui-dist/jquery-ui.min.js'

import '../src/stylesheets/application.sass';

import '../src/javascripts/application.js';
import 'bootstrap-sass/assets/javascripts/bootstrap.js';
import 'cocoon-js'

// Support component names relative to this directory:
//
var componentRequireContext = require.context("../src/javascripts/", true);
// var componentRequireContext = require.context("../components", true);

// import all image files in a folder:
require.context('../images', true)

var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
