
import UserGroupsForm from '../../modules/user_groups_form';

class AdminUserGroupNew {
  constructor() {
    this.form = new UserGroupsForm();
  }
}

export default AdminUserGroupNew;
