import { Component, PropTypes } from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import R from 'ramda';
import moment from 'moment-timezone';
import { getEmptyImage } from 'react-dnd-html5-backend';

import formatMinutes from '../../modules/format_minutes';
import { roundLabel, roundLabelAbbrev } from '../../modules/round_label';

import { ORDERED_MATCH, ROUND, CATEGORY, MATCH } from '../../shared/constants';

import MatchState from './match_state';
import { ContextMenuTrigger } from 'react-contextmenu';
import { throttle } from 'throttle-debounce';

const orderedMatchSource = {
  beginDrag(props, monitor, component) {
    const { match, matId } = props;

    component.props.onBeginDrag([match]);

    return {
      matId: matId,
      order: match.order,
      selectedMatches: [match],
    };
  },
}

function collectContextMenu(params) {
  const {
    attributes
  } = params;

  return {
    matId: attributes.matId,
    match: attributes.match
  }
}

function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}

const orderedMatchTarget = {
  hover: throttle(100, false, (props, monitor, component) => {
    const dropped = monitor.getItem();

    if (dropped === null) return;

    // index of the dragged item
    const dragIndex = dropped.matId === props.matId ?
                      dropped.order || -1 :
                      -1;

    // index of the hovered item
    const hoverIndex = props.match.order;

    if (dragIndex === hoverIndex) {
      return;
    }

    // rectangle of the hovered item
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // vertical middle of the hovered item
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Mouse position
    const clientOffset = monitor.getClientOffset();

    // how many pixels to the top of the target is the dragged object
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // dragging downwards
    if (dragIndex !== -1 && dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Uncomment for sorting
    props.onMove(hoverIndex, monitor.getItem().selectedMatches);
  }),
}

function collectDropTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

function highlightedClass(phase) {
  switch (phase) {
    case 'QF':
      return 'quarter';
      break;
    case 'SF':
      return 'semi-final';
      break;
    case 'F':
      return 'final';
      break;
    default:
      return 'elim';
  }
}


class OrderedMatch extends Component {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }

  styles(match) {
    return `
    ${match.selected    ? 'match-display--selected' : ''}
    ${match.highlighted || match.ordering ? `match-display--${highlightedClass(match.phase)}` : ''}
    ${match.switchColor ? 'match-display--switch-color' : ''}
    `;
  }

  handleClick(ev) {
    this.props.onOrderedMatchClick(this.props.match.id);
  }

  handleMouseOver(ev) {
    this.props.onHover(this.props.match);
  }

  render() {
    const {
      match,
      connectDragSource,
      connectDropTarget,
      onHover,
      isDragging,
      switchColor
    } = this.props;

    return connectDragSource(connectDropTarget(
      <div onClick={this.handleClick.bind(this)}>
        <ContextMenuTrigger holdToDisplay={-1} id={ORDERED_MATCH} collect={collectContextMenu} attributes={this.props}>
          <div className={`match-display ${this.styles(match)}`}>
            <span title={"Order on mat"} className="match-display__order">{match.order + 1}</span>
            <span className="match-display__category">{match.category_abbr}</span>
            <span title={"Phase in the bracket"} className="match-display__phase">{match.phase}</span>
            <span title={"Bracket Page / Total pages in the bracket"} className="match-display__pagination">{match.page}</span>
            <span><MatchState state={match.state}/></span>
          </div>
        </ContextMenuTrigger>
      </div>
    ));
  }
}

OrderedMatch.propTypes = {
  match: PropTypes.object.isRequired,

  onClick: PropTypes.func,
}

export default DropTarget([CATEGORY, ROUND, ORDERED_MATCH, MATCH], orderedMatchTarget, collectDropTarget)(DragSource(ORDERED_MATCH, orderedMatchSource, collectDragSource)(OrderedMatch));
