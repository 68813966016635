import React, { Component, PropTypes } from 'react';
import ScoreControl from './score_control';
import R from 'ramda';

export default function ScoreCompetitorControls(props) {
  const {
    match,
    competitor,

    scorePoint,
    scoreAdvantage,
    scoreFoul,
  } = props;

  return (
    <div className="score-competitor-controls">
      <div className="row">
        <div className="col-xs-4">
          <div className="score-competitor__points-controls">
            <ScoreControl
              upFn={() => scorePoint(competitor.id, 4)}
              downFn={() => scorePoint(competitor.id, -4)}
              description={'+4'} />
            <ScoreControl
              upFn={() => scorePoint(competitor.id, 3)}
              downFn={() => scorePoint(competitor.id, -3)}
              description={'+3'} />
            <ScoreControl
              upFn={() => scorePoint(competitor.id, 2)}
              downFn={() => scorePoint(competitor.id, -2)}
              description={'+2'} />
          </div>
        </div>
        <div className="col-xs-4">
          <div className="score-competitor__advantages-controls">
            <ScoreControl
              upFn={() => scoreAdvantage(competitor.id, 1)}
              downFn={() => scoreAdvantage(competitor.id, -1)} />
          </div>
          <a onClick={R.isNil(match.start_time) ? () => {} : () => props.openVictoryModal(competitor.id) } disabled={R.isNil(match.start_time)} className="btn btn-default btn-raised score-competitor__victory-btn">Victory</a>
        </div>
        <div className="col-xs-4">
          <div className="score-competitor__fouls-controls">
            <ScoreControl
              upFn={() => scoreFoul(competitor.id, 1)}
              downFn={() => scoreFoul(competitor.id, -1)} />
          </div>
        </div>
      </div>
      <div className="row">
      </div>
    </div>
  )
}

ScoreCompetitorControls.propTypes = {
  match: PropTypes.object.isRequired,
  competitor: PropTypes.object.isRequired,

  scorePoint: PropTypes.func.isRequired,
  scoreAdvantage: PropTypes.func.isRequired,
  scoreFoul: PropTypes.func.isRequired,
  openVictoryModal: PropTypes.func.isRequired,
}
