import R from 'ramda';
import React, { Component, PropTypes } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Notifications from './notifications';

class NotificationsPopover extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      notifications
    } = this.props;

    const popover =
      <Popover title={"Notifications"} >
        <Notifications {...this.props}/>
      </Popover>;

    const totalNotifications = R.pipe(
      R.values,
      R.flatten,
      R.length
    )(notifications)

    return (
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover} show={notifications.length > 0}>
        <span>
          <i className="fa fa-bell-o"/>
          {(!R.isEmpty(notifications)) && <span className="badge">{totalNotifications}</span>}
        </span>
      </OverlayTrigger>
    )
  }
}

export default NotificationsPopover;
