import R from 'ramda';

import {
  tournament,
  podiums,
  filters,
  medalDeliveries,
} from '../shared/reducers';

import {
  CHANGE_PODIUM_FILTER,
  RELOAD_PODIUMS,
  RELOAD_PODIUM,
} from '../shared/constants';

const initialState = {
  ui: {
    filters: {
      status: 'UNCHECKED',
      belt_ids: [],
      weight_division_ids: [],
      age_division_ids: [],
      gender_ids: []
    }
  },
  tournament: {
    isFetching: false,
    result: {}
  },
  filters: {
    isFetching: false,
    result: [],
  },
  medalDeliveries: {
    isFetching: false,
    result: [],
  },
  podiums: {
    isFetching: false,
    result: [],
  },
}

export function podium(state = initialState, action) {
  return {
    ...state,
    tournament: tournament(state.tournament, action),
    podiums: reloadablePodiums(state.podiums, action),
    filters: filters(state.filters, action),
    medalDeliveries: medalDeliveries(state.medalDeliveries, action),
    ui: ui(state.ui, action),
  }
}

function ui(state, action) {
  switch (action.type) {
    case CHANGE_PODIUM_FILTER:
      return {
        ...state,
        filters: R.merge(state.filters, action.payload),
      }
    default:
      return state;
  }
}

function reloadablePodiums(state, action) {
  switch (action.type) {
    case RELOAD_PODIUMS:
      return {
        ...state,
        result: action.payload,
      }
    case RELOAD_PODIUM:
      const index = R.findIndex((p) => p.id === action.payload.id, state.result)

      return {
        ...state,
        result: R.update(index, action.payload, state.result),
      }
    default:
      return podiums(state, action);
  }
}
