
class PageFlipper {
  constructor(options) {
    this.opt = Object.assign({
      interval: 3,
      containerClass: '.page-flipper',
      itemClass: '.page-flipper__item'
    }, options);

    this.container = $(this.opt.containerClass);
    this.items = $(this.opt.itemClass);
    this.cursor = this.items.first();
    this.interval = this.opt.interval * 1000;
    this.itemsWidth = this.items.outerWidth() * this.items.length;
    this.pages = Math.ceil(this.itemsWidth / this.container.outerWidth());
    this.itemsPerPage = Math.floor(this.container.outerWidth() / this.cursor.outerWidth());
    this.offset = 0;
    this.currentPage = 1;

    if (this.pages > 1) {
      this.intervalId = this.start();
    }
  }

  start() {
    return setInterval(() => {
      this.cursor.css(
        'margin-left',
        `${(this.itemsPerPage * this.cursor.outerWidth() * this.currentPage) * -1}px`
      );

      this.currentPage = (this.currentPage + 1) % this.pages;
    }, this.interval);
  }

  stop() {
    if (this.intervalId) {
      endInterval(this.intervalId);
    }
  }
}

export default PageFlipper;
