import { PropTypes, Component } from 'react';
import { DropTarget } from 'react-dnd';
import R from 'ramda';

import { ORDERED_MATCH, MATCH, ROUND, CATEGORY } from '../../shared/constants';
import MatCalculator from './mat_calculator';
import OrderedMatch from './ordered_match';
import Gap from './gap';
import MergePopoverContainer from '../containers/merge_popover_container';

import MatchTooltipContainer from '../../shared/containers/match_tooltip_container';
import { throttle } from 'throttle-debounce';

const matTarget = {
  hover: throttle(100, true, (props, monitor, component) => {
    // executed when hovering a mat, but not hovering an ordered match
    if (monitor.isOver({ shallow: true })) {
      component.props.onHover()
    }
  }),

  drop(props, monitor, component) {
    const dropped = monitor.getItem();

    // Only orders a match if it was dropped directly over the mat and not over
    // another ordered_match (which is handled by the ordered match itself)
    if (monitor.isOver({ shallow: true })) {
      component.props.onDrop(dropped.selectedMatches);
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class Mat extends Component {

  renderMatches(matches) {
    const {
      mat,

      onOrderedMatchBeginDrag,
      onOrderedMatchHover,
      onMoveMatches,
      onMatchDrop,
      onOrderedMatchClick
    } = this.props;

        // <MatchTooltipContainer match={m}>
        // </MatchTooltipContainer>
    return R.addIndex(R.map)((m, i) => {
      const component = (m) =>
        m.kind === "Match" ?
        <OrderedMatch
          match={m}
          matId={mat.id}

          onBeginDrag={onOrderedMatchBeginDrag}
          onMove={onMoveMatches}
          onDrop={onMatchDrop}
          onHover={onOrderedMatchHover}
          onOrderedMatchClick={onOrderedMatchClick}
          /> :
        <Gap gap={m}/>;

      return (
        <li key={i}>
          {component(m)}
        </li>
      )
    }, matches)
  }

  render () {
    const {
      number,
      matches,
      mergeOptions,
      interval,

      connectDropTarget,
      isOver,
    } = this.props;

    return connectDropTarget(
      <div className={`mat ${isOver ? 'mat--hovered' : ''}`}>
        <span className="mat__title">
          Mat {number}
          <span className="mat__merge">
            <MergePopoverContainer onSubmit={this.props.onMergeMat.bind(this)} />
          </span>
          <span className="mat__print">
            <div className="dropdown">
              <a role="button" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fa fa-print"/>
              </a>
              <ul className="dropdown-menu">
                <li><a href="#" onClick={() => { this.props.onPrintMat() }}>Print all fights</a></li>
                <li><a href="#" onClick={() => { this.props.onPrintMat({ onlyUnfinishedMatches: true }) }}>Print unfinished fights</a></li>
              </ul>
            </div>
          </span>
        </span>
        <span className="mat__time">
          <MatCalculator matches={matches} interval={interval} />
        </span>

        <ul className="mat__items">
          {this.renderMatches(matches)}
        </ul>
      </div>
    );
  }
}

Mat.propTypes = {
  number: PropTypes.number.isRequired,
  matches: PropTypes.array.isRequired,

  onOrderedMatchClick: PropTypes.func.isRequired,
  onOrderedMatchBeginDrag: PropTypes.func.isRequired,
  onMoveMatches: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onMatchDrop: PropTypes.func.isRequired,
  onPrintMat: PropTypes.func.isRequired,
}

export default DropTarget([ORDERED_MATCH, ROUND, MATCH, CATEGORY], matTarget, collect)(Mat);
