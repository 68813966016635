import R from 'ramda';
import update from 'react/lib/update';

export default function moveOrderedMatches(matches, targetIndex, selectedMatches) {
  const index = targetIndex >= 0 ? targetIndex : matches.length;

  const matchesWithoutSelected =
    R.reject(R.propEq('ordered_item_id', undefined), matches)

  const newMatches = R.pipe(
    R.differenceWith((x, y) => x.id === y.id, selectedMatches),
    R.map(R.pipe(R.dissoc('ordered_item_id'), R.assoc('ordering', true)))
  )(matchesWithoutSelected)

  const reorderedMatches = R.pipe(
    R.intersectionWith((x, y) => x.id === y.id, selectedMatches),
    R.map(R.assoc('ordering', true)),
  )(matchesWithoutSelected)

  const addedMatches = R.concat(reorderedMatches, newMatches);

  return R.pipe(
    R.differenceWith((x, y) => x.id === y.id, R.__, addedMatches),
    R.insertAll(index, addedMatches),
    R.addIndex(R.map)((m, i) => R.assoc('order', i, m))
  )(matchesWithoutSelected)
}

