import Routes from './routes.js.erb';
import QueryStringUpdater from './query_string_updater';

class TournamentDaySelector {
  constructor() {
    this.$daySelect = $('.tournament-day__day-select');
    this.$matSelect = $('.tournament-day__mat-select');

    this.$daySelect
      .on('change', this.changeSelectedDay.bind(this))

    this.$matSelect
      .on('change', this.changeSelectedDay.bind(this))
  }

  changeSelectedDay(ev) {
    const dayId = this.$daySelect.val();
    const matNumber = this.$matSelect.val();
    const tournamentId = this.$daySelect.data('tournament-id');
    const currentDayId = this.$daySelect.data('tournament-day-id');

    window.location
      .assign(Routes.tournament_tournament_day_path(tournamentId, dayId) + "?page=" + (currentDayId === parseInt(dayId, 10) ? matNumber : 1));
  }
}

export default TournamentDaySelector;
