import R from 'ramda';
import React, { Component } from 'react';
import Select from 'react-select';

class SearchField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      competitors: R.pipe(
        R.map((c) => ({ value: c.id, label: c.name }))
      )(JSON.parse(props.competitors))
    }
  }

  onChange(selected) {
    if (selected.value !== undefined) {
      const { param, path } = this.props;

      var searchParams = new URLSearchParams(window.location.search)

      searchParams.append(param, selected.value)

      window.location.href = window.location.origin + path + "?" + searchParams.toString()
    }
  }

  render() {
    const { competitors } = this.state;
    const { placeholder } = this.props;

    return (
      <div className="competitor-search">
        <Select
          isMulti={false}
          isSearchable={true}
          options={competitors}
          placeholder={placeholder}
          onChange={this.onChange.bind(this)}/>
      </div>
    )
  }
}

SearchField.defaultProps = {
  competitors: []
}

export default SearchField;
