import React from 'react';

const label = (page) => page !== null ? `Pg ${page}` : 'Finals';
const handle = (page) => page !== null ? `round__handle--semi-final` : 'round__handle--final';

const MatchGroupPageLabel = (props) => (
  <span className={`round__handle ${handle(props.matches[0].page)}`}>
    <span className="round__label">{`${label(props.matches[0].page)}`}</span>
  </span>
)

export default MatchGroupPageLabel;
