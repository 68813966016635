import R from 'ramda';

const lensMatching = pred => (
  toF =>
    entities => {
      const index = R.findIndex(pred, entities);

      return R.map(entity => R.update(index, entity, entities), toF(entities[index]));
    }
);

export default lensMatching;
