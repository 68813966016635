import React, { Component } from 'react';
import R from 'ramda';
import { OverlayTrigger } from 'react-bootstrap';
import * as C from '../modules/category_constants';
import Phase from '../modules/phase_constants';

import AutoOrderCategoriesPopover from './auto_order_categories_popover';
import AutoOrderCategoriesProgress from './auto_order_categories_progress';
import TournamentTemplateSelect from './tournament_template_select';
import AutoOrderSetup from './auto_order_setup';
import AutoOrderMats from './auto_order_mats';
import TemplateModal from './template_modal';
import GroupModal from './group_modal';

class AutoOrderContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewGroupModal: false,
      showNewTemplateModal: false,
      editableTemplate: {
        id: null,
        name: "",
      },
      editableGroup: {
        id: null,
        name: "",
        age_division_ids: [],
        belt_ids: [],
        gender_ids: [],
        mat_numbers: [],
        weight_division_ids: R.values(C.Weight),
        phases: ["F", "SF", "QF", "EL"],
        order: this.props.categoryGroups.length,
      }
    };
  }

  toggleNewGroupModal(show = false) {
    this.setState({ showNewGroupModal: show })
  }

  toggleNewTemplateModal(show = false) {
    this.setState({ showNewTemplateModal: show })
  }

  onNewTemplate() {
    this.setState({
      editableTemplate: {
        id: null,
        name: "",
      }
    })

    this.toggleNewTemplateModal(true);
  }

  onDuplicateTemplate() {
    const {
      selectedTournamentTemplateId,
      tournamentTemplates
    } = this.props;

    if (selectedTournamentTemplateId === null) {
      alert("You must have a template selected to duplicate")

      return;
    }

    const selectedTournamentTemplate =
      R.find(
        R.propEq('id', selectedTournamentTemplateId),
        tournamentTemplates
      )

    this.props.onDuplicateTournamentTemplate(selectedTournamentTemplate);
  }

  onHighlightGroup(cg) {
    this.props.onHighlightCategoryGroup(
      R.pipe(
        R.filter((c) =>
          R.contains(c.age_division_id, cg.age_division_ids) &&
          R.contains(c.gender_id, cg.gender_ids) &&
          R.intersection(c.belt_ids, cg.belt_ids).length > 0
        ),
        R.map(R.prop('id'))
      )(this.props.categories)
    )
  }

  onNewGroup() {
    this.setState({
      editableGroup: {
        tournament_template_id: this.props.selectedTournamentTemplateId,
        id: null,
        name: "",
        age_division_ids: [],
        belt_ids: [],
        gender_ids: [],
        mat_numbers: [],
        weight_division_ids: R.values(C.Weight),
        phases: R.values(Phase),
        order: this.props.categoryGroups.length,
      }
    })

    this.toggleNewGroupModal(true);
  }

  onSubmitGroup(group) {
    const existingIndex = R.findIndex(R.propEq('id', group.id), this.props.categoryGroups)

    return existingIndex === -1 ?
      this.props.onCreateGroup(group) :
      this.props.onUpdateGroup(group)
  }

  onSubmitTemplate(template) {
    const existingIndex = R.findIndex(R.propEq('id', template.id), this.props.tournamentTemplates)

    return existingIndex === -1 ?
      this.props.onCreateTournamentTemplate(template) :
      this.props.onUpdateTournamentTemplate(template)
  }

  onEditTemplate(id) {
    this.setState({
      editableTemplate: R.find(R.propEq('id', id), this.props.tournamentTemplates)
    })

    this.toggleNewTemplateModal(true);
  }

  onEditGroup(id) {
    this.setState({
      editableGroup: R.find(R.propEq('id', id), this.props.categoryGroups)
    })

    this.toggleNewGroupModal(true);
  }

  onDeleteGroup(id) {
    this.props.onDeleteGroup(id)
  }

  onDeleteTournamentTemplate(id) {
    if (confirm("Are you sure you want to delete the Template? All category groups will be deleted as well")) {
      this.props.onDeleteTournamentTemplate(id)
    }
  }

  onDropCategoryGroup(droppedIndex, dropIndex) {
    if (droppedIndex === dropIndex) { return; }

    const {
      categoryGroups,
      selectedTournamentTemplateId,
      onUpdateGroup,
    } = this.props;

    const selectedCategoryGroups = R.filter(
      R.propEq('tournament_template_id', selectedTournamentTemplateId),
      categoryGroups
    );

    const dropped = selectedCategoryGroups[droppedIndex]
    const dropTarget = selectedCategoryGroups[dropIndex]

    R.pipe(
      R.remove(droppedIndex, 1),
      (cgs) => {
        const targetIndex = R.findIndex(R.propEq('id', dropTarget.id), cgs);

        return R.insert(dropIndex < droppedIndex ? targetIndex : targetIndex + 1, dropped, cgs)
      },
      R.addIndex(R.map)((cg, i) => { onUpdateGroup(R.assoc("order", i, cg)) }),
    )(selectedCategoryGroups)
  }

  render() {
    const {
      mats,
      categoryGroups,
      selectedTournamentTemplateId,
      tournamentTemplates,
      categories,
      onChangeTournamentTemplate
    } = this.props;

    const {
      showNewGroupModal,
      showNewTemplateModal,
      editableGroup,
      editableTemplate,
    } = this.state;

    const selectedCategoryGroups = R.filter(
      R.propEq('tournament_template_id', selectedTournamentTemplateId),
      categoryGroups
    );

    const categoryIdsPerGroup = R.pipe(
      R.reject((cg) => R.isEmpty(cg.mat_numbers)),
      R.reduce((acc, cg) => {
        return R.assoc(
          cg.id,
          R.pipe(
            R.filter((c) => R.contains(c.age_division_id, cg.age_division_ids) &&
                R.contains(c.gender_id, cg.gender_ids) &&
                R.intersection(c.belt_ids, cg.belt_ids).length > 0
            ),
            R.map(R.prop('id'))
          )(categories),
          acc
        )
      }, {})
    )(selectedCategoryGroups)

    const categoriesNotSelected = R.filter((c) => !R.contains(c.id, R.pipe(R.values, R.flatten)(categoryIdsPerGroup)), categories)

    const categoriesPopover = <AutoOrderCategoriesPopover
      categories={categoriesNotSelected}
      />

    const checkConflicts = (subject) => {
      return R.pipe(
        R.filter((c) => !R.propEq('id', subject.id, c)),
        R.map((cg) => ({
          group: cg.name,
          fields: [
            { field: "phases", label: "Phases", values: R.intersection(cg.phases, subject.phases) },
            { field: "weight_division_ids", label: "Weight Divisions", values: R.intersection(cg.weight_division_ids, subject.weight_division_ids) },
            { field: "age_division_ids", label: "Age Divisions", values: R.intersection(cg.age_division_ids, subject.age_division_ids) },
            { field: "gender_ids", label: "Gender", values: R.intersection(cg.gender_ids, subject.gender_ids) },
            { field: "belt_ids", label: "Belts", values: R.intersection(cg.belt_ids, subject.belt_ids) }
          ]
        })),
        R.filter((c) => R.reject(R.isEmpty, R.map(R.prop('values'), c.fields)).length > 4)
      )(selectedCategoryGroups)
    }

    return (
      <div className="auto-order-container">
        <div className="auto-order-container__category-groups">
          <OverlayTrigger trigger="click" placement="bottom" overlay={categoriesPopover}>
            <a>
              <AutoOrderCategoriesProgress
                total={categories.length}
                current={Math.abs(categoriesNotSelected.length - categories.length)}
              />
            </a>
          </OverlayTrigger>
          <div className="auto-order-container__template-select">
            <TournamentTemplateSelect
              tournamentTemplates={tournamentTemplates}
              value={selectedTournamentTemplateId}
              onChange={onChangeTournamentTemplate}
            />
            <a href="#" className="btn btn-default" onClick={() => this.onNewTemplate()}><i className="fa fa-file"></i></a>
            <a href="#" className="btn btn-default" disabled={editableTemplate.id !== null} onClick={() => this.onDuplicateTemplate()}><i className="fa fa-copy"></i></a>
            <a href="#" className="btn btn-default" onClick={() => this.onEditTemplate(selectedTournamentTemplateId)}><i className="fa fa-pencil"></i></a>
            <a href="#" className="btn btn-default btn-danger" onClick={() => this.onDeleteTournamentTemplate(selectedTournamentTemplateId)}><i className="fa fa-trash"></i></a>
          </div>
          <h2 className="text-center">Mats Configuration</h2>
          {
            selectedTournamentTemplateId &&
            <AutoOrderSetup
              categoryGroups={selectedCategoryGroups}
              mats={mats}
              categories={categories}
              onHighlight={this.onHighlightGroup.bind(this)}
              onNew={this.onNewGroup.bind(this)}
              onEdit={this.onEditGroup.bind(this)}
              onDelete={this.onDeleteGroup.bind(this)}
              onDropCategoryGroup={this.onDropCategoryGroup.bind(this)}
            />
          }
          <AutoOrderMats
            categoryGroups={selectedCategoryGroups}
            categories={categories}
            mats={mats}
          />
        </div>
        <TemplateModal
          data={editableTemplate}
          show={showNewTemplateModal}
          close={() => this.toggleNewTemplateModal(false)}
          onSubmit={this.onSubmitTemplate.bind(this)}
        />
        <GroupModal
          data={editableGroup}
          show={showNewGroupModal}
          checkConflicts={checkConflicts}
          mats={mats}
          close={() => this.toggleNewGroupModal(false)}
          onSubmit={this.onSubmitGroup.bind(this)}
        />
      </div>
    )
  }
}

export default AutoOrderContainer;
