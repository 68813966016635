import { PropTypes } from 'react';
import R from 'ramda';

import CategoryGroupDropArea from './category_group_drop_area';
import EstimateDisplay from './estimate_display';
import RemovableEstimateDisplayHOC from './removable_estimate_display_hoc';
import EstimateDisplayCalculatorHOC from './estimate_display_calculator_hoc';
import EstimateForm from './estimate_form';
import CategoriesAccordeon from '../../settings/components/categories_accordeon';

const CalculatedEstimateDisplay = EstimateDisplayCalculatorHOC(EstimateDisplay);
const RemovableEstimateDisplay = RemovableEstimateDisplayHOC(CalculatedEstimateDisplay);

function EstimateDay(props) {
  const idKey = `estimate-day-${Math.floor(Math.random() * 100000)}`

  return (
    <div className="panel panel-default estimate-day">
      <div className="panel-heading">
        <a data-target={`#${idKey}`} data-toggle="collapse" className="estimate-day__date">
          {props.day.date}
        </a>
        <CalculatedEstimateDisplay
          areas={props.day.areas}
          interval={props.day.interval}
          intervalUnit={props.day.interval_unit}
          categories={props.categories} />
      </div>
      <div className="panel-collapse collapse in" id={idKey}>
        <div className="panel-body">
          <EstimateForm onChange={props.onUpdateEstimateDay} target={props.day}/>
          <div className="row">
            <div className="col-xs-12 estimate-day__categories">
              <CategoryGroupDropArea onDropCategoryGroup={props.onDropCategoryGroup}>
                <CategoriesAccordeon 
                  categories={props.categories}
                  display={RemovableEstimateDisplay}
                  displayProps={{ 
                    areas: props.day.areas,
                    interval: props.day.interval,
                    intervalUnit: props.day.interval_unit,
                    onRemoveCategoryGroup: props.onRemoveCategoryGroup
                  }}
                />
              </CategoryGroupDropArea>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EstimateDay.propTypes = {
  day: PropTypes.shape({
    date: PropTypes.string,
    areas: PropTypes.number.isRequired,
    interval: PropTypes.number.isRequired,
    interval_unit: PropTypes.string.isRequired,
    categories: PropTypes.array,
  }),
  categories: PropTypes.array.isRequired
}

export default EstimateDay;
