import R from 'ramda';

import {
  tournament,
  matsWithMatchUpdate,
} from '../shared/reducers';

const initialState = {
  matchStates: ['created', 'assigned', 'checked_in', 'weighted', 'skipped'],
  tournament: {
    isFetching: false,
    result: {},
  },
  mats: {
    isFetching: false,
    result: [],
  },
}

export function presence(state = initialState, action) {
  return {
    ...state,
    tournament: tournament(state.tournament, action),
    mats: matsWithMatchUpdate(state.mats, action),
  }
}
