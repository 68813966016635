import R from 'ramda';

const MaterialCheckbox = (props) => {
  const inputProps = R.merge(R.omit('children', props), props.input || {});

  return (
    <div className="form-group">
      <div className="checkbox">
        <label>
          <input type="checkbox" {...inputProps} />
          <span className="checkbox-material"><span className="check"></span></span>
          {props.label}
        </label>
      </div>
    </div>
)}

export default MaterialCheckbox;
