import R from 'ramda';
import Routes from '../modules/routes.js.erb';

import {
  CALL_API,

  CHANGE_START_DATE,
  CHANGE_END_DATE,
  CHANGE_MATS_COUNT,

  SCHEDULE_SNAPSHOT_REQUEST,
  SCHEDULE_SNAPSHOT_RESPONSE_SUCCESS,
  SCHEDULE_SNAPSHOT_RESPONSE_FAIL,

  CACHE_OPC_RANKS_REQUEST,
  CACHE_OPC_RANKS_RESPONSE_SUCCESS,
  CACHE_OPC_RANKS_RESPONSE_FAIL
} from '../shared/constants';

import {
  fetchFilters,
  fetchCategories,
  fetchTournament,
  fetchMats,
  updateCategories,
} from '../shared/actions/api';

import {
  doHydrateCategories,
} from '../shared/actions/hydrate_categories';

export function loadData(tournamentId) {
  return (dispatch, getState) => {
    return dispatch(fetchTournament(tournamentId))
      .then(() => Promise.all([
        dispatch(fetchFilters()),
        dispatch(fetchCategories({ query: { with_competitors: true, paginated: false } })),
        dispatch(fetchCategories({ query: { with_possible_open_class: true, paginated: false } })),
      ]))
      .then(() => Promise.all([
        dispatch(changeStartDate()),
        dispatch(doHydrateCategories()),
      ]))
  }
}

export function changeStartDate(date) {
  return {
    type: CHANGE_START_DATE,
    payload: date
  }
}

export function changeEndDate(date) {
  return {
    type: CHANGE_END_DATE,
    payload: date
  }
}

export function changeMatsCount(tournamentDayIndex, count) {
  return {
    type: CHANGE_MATS_COUNT,
    payload: { tournamentDayIndex, count }
  }
}

export function updateOpenClassEstimatedStart(params) {
  return (dispatch, getState) => {
    return dispatch(updateCategories(params))
      .then(() => dispatch(doHydrateCategories()))
  }
}

export function cacheOpenClassRanks(tournamentId) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          CACHE_OPC_RANKS_REQUEST,
          CACHE_OPC_RANKS_RESPONSE_SUCCESS,
          CACHE_OPC_RANKS_RESPONSE_FAIL
        ],
        endpoint: Routes.cache_open_class_ranks_admin_tournament_path(tournamentId)
      }
    })
  }
}
