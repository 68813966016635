import { PropTypes } from 'react';
import MatchState from './match_state';

function MatchesDragBox(props) {
  const items = props.matches.map((match, i) => {
    return (
      <li key={i}>
        <div className={`match-display`}>
          <span title={"Order on mat"} className="match-display__order">{match.order + 1}</span>
          <span className="match-display__category">{match.category_abbr}</span>
          <span title={"Phase in the bracket"} className="match-display__phase">{match.phase}</span>
          <span title={"Bracket Page / Total pages in the bracket"} className="match-display__pagination">{match.page}</span>
          <span><MatchState state={match.state}/></span>
        </div>
      </li>
    )
  })

  return (
    <ul className="list-unstyled matches-drag-box">
      {items}
    </ul>
  )
}

MatchesDragBox.propTypes = {
  matches: PropTypes.array.isRequired
}

MatchesDragBox.defaultProps = {
  matches: []
}

export default MatchesDragBox;
