
import React from 'react';
import R from 'ramda';
import * as C from '../modules/category_constants';

const ModalConflicts = (props) => {
  const labels = {
    age_division_ids: R.invertObj(C.Age),
    gender_ids: R.invertObj(C.Gender),
    belt_ids: R.invertObj(C.Belt),
    weight_division_ids: R.invertObj(C.Weight),
    phases: { F: "F", SF: "SF", QF: "QF", EL: "EL" },
  }

  const conflict = (c) => (
    <li>
      <span>{`[${c.group}] also has:`}</span>
      <ul>
        {R.map((f) => (<div>{`${f.label}: [${R.join(", ", R.map((v) => labels[f.field][v], f.values))}]`}</div>), c.fields)}
      </ul>
    </li>
  )

  return (
    <div className="category-group-conflicts bg-danger text-danger">
      <span className="category-group-conflict__title"><i className="fa fa-exclamation-circle"/>Conflicts</span>
      <ul className="list-unstyled">
        {R.map((c) => conflict(c), props.conflicts)}
      </ul>
    </div>
  )
}

export default ModalConflicts;
