import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { PropTypes } from 'react';
import { childMatchLabel } from '../../modules/match_lenses';
import moment from 'moment-timezone';

const formatTime = (time) => moment(time).format('ddd HH:mm')

const childTooltipItem =
  (c) => (<span>{childMatchLabel(c)}</span>)

const competitorMatchTooltipItem = (c) => (
  <div>
    <span className="match-tooltip__competitor-name">{c.name}</span>
    <span>{c.winner && <i className="fa fa-trophy match-tooltip__winner-icon"/>}</span>
    <br/>
    <span className="match-tooltip__club-name">{c.club}</span>
  </div>
)

const renderListItem = (competitor) => {
  if (!competitor) { return null; }

  return competitor.kind === 'match' ?
    childTooltipItem(competitor) :
    competitorMatchTooltipItem(competitor);
}

function MatchTooltip(props) {
  const match = props.match;

  const tooltip = <Tooltip id={`match-tooltip-${match.id}`}>
    <div className="match-tooltip">
      <div className="match-tooltip__category-name">{match.category_name}</div>
      <div className="match-tooltip__start">{`Start: ${formatTime(match.start_time)}`}</div>
      <div className="match-tooltip__end"v>{match.end_time && `End: ${formatTime(match.start_time)}`}</div>
      <br/>
      <div className="match-tooltip__competitors">
        {renderListItem(match.competitors[0])}
        <div className="match-tooltip__competitors-separator">vs</div>
        {renderListItem(match.competitors[1])}
      </div>
    </div>
  </Tooltip>

  const component = match.id !== undefined ?
    <div className="match-tooltip-wrapper">
      <OverlayTrigger placement={"right"} overlay={tooltip} rootClose={true}>
        {props.children}
      </OverlayTrigger>
    </div> :
    <div>
      {props.children}
    </div>;

  return component;
}

MatchTooltip.propTypes = {
  match: PropTypes.object
}

MatchTooltip.defaultProps = {
  match: { competitors: [] }
}

export default MatchTooltip;
