import { CALL_API } from '../shared/constants';
import R from 'ramda';
import URLSearchParams from 'url-search-params';
import mountFetch from '../modules/mount_fetch';

function callApi (endpoint, options = {}) {
  return mountFetch(endpoint, options)
    .then(response =>
      response.json().then(json => ({ response, json }))
    ).then(({ json, response }) => {
      if (!(response.status >= 200 && response.status < 300)) {
        if (Array.isArray(json.errors)) alert(json.errors[0])
        return Promise.reject(json)
      }

      return {
        json: json.items ? json.items : json,
        meta: json.items ? json.meta : { current_page: 0, total_pages: 0 }
      }
    })
}

export default store => next => action => {

  if (!(action.type === CALL_API)) {
    return next(action)
  }

  const { endpoint, types, body } = action.payload;
  const [ requestType, successType, failureType ] = types;
  const method = action.payload.method || 'GET';
  const query  = action.payload.query || {};

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  next({ type: requestType, payload: query })

  return callApi(endpoint, { method, body, query }).then(
    response => next({
      type: successType,
      payload: response.json,
      meta: response.meta,
    }),
    error => next({
      type: failureType,
      payload: error.message || 'Something bad happened',
      error: true,
    })
  )
}
