function ServerEventsListener() {
  this.listeners = {};

  this.listen();
}

ServerEventsListener.prototype.on = function(eventName, callback) {
  this.listeners[eventName] ?
    this.listeners[eventName].push(callback) :
    this.listeners[eventName] = [callback];

  return this;
}

ServerEventsListener.prototype.notify = function(event) {
  this.listeners[event.type] &&
  this.listeners[event.type]
    .forEach(function(listener) {
      listener(event, event.payload)
    });
}

ServerEventsListener.prototype.ping = function() {
  var _this = this;

  $.ajax({
    url: '/admin/server_events',
    method: 'GET',
    dataType: 'json'
  }).then(function(events) {
    events.forEach(function(event) {
      _this.notify(event);
    })
  });
}

ServerEventsListener.prototype.listen = function() {
  setInterval(this.ping.bind(this), 5000);
}

module.exports = ServerEventsListener;
