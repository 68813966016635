import R from 'ramda';

export function FlashMessage() {
  this.container = $("#flash-messages")

  this.data = this.collectRailsFlashMessages()

  if (this.data.length > 0) {
    this.render()
  }
}

FlashMessage.prototype.collectRailsFlashMessages = function() {
  const messages = $("#flash-messages").data('messages'),
        r = [],
        messageTypes = {
          'notice': 'success',
          'warning': 'warning',
          'error': 'danger'
        };

  for (var type in messages) {
    r.push({ type: messageTypes[type] || 'success', text: messages[type] })
  }

  return r;
}

FlashMessage.prototype.render = function() {
  const template = R.map((message) => `
    <div class="alert alert-dismissible alert-${message.type} fade in">
      <button type="button" class="close" data-dismiss="alert">×</button>
      ${message.text}
    </div>
  `)

  const _this = this

  this.container.html(template(this.data))

  setTimeout(() => $('.alert').alert('close'), 4000);
}

FlashMessage.prototype.addMessage = function(type, message) {

  this.data.unshift({
    type: type,
    text: message
  })

  this.render()
}

FlashMessage.prototype.notice = function(message) {
  this.addMessage("info", message)
}

FlashMessage.prototype.error = function(message) {
  this.addMessage("danger", message)
}

FlashMessage.prototype.warning = function(message) {
  this.addMessage("warning", message)
}
