
export default (props) => {
  const { open, loading } = props;

  const text = open ? 'open' : 'closed';

  const button = open ?
    <button
      type="button"
      className='btn btn-default fa fa-unlock'
      disabled={loading}
      onClick={() => props.onToggle(true)}
      ></button> :
    <button
      type="button"
      className='btn btn-primary fa fa-lock'
      onClick={() => props.onToggle(false)}
      disabled={loading}
      ></button>;


  return (
    <div className={`opc-subscription-switch opc-subscription-switch--${text} opc-subscription-switch--admin`}>
      {button}
    </div>
  );
}
