import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import R from 'ramda';

import DraggableCategoryList from '../components/draggable_category_list';
import EditableEstimateDisplay from '../components/editable_estimate_display';
import DraggableEstimateDisplayHOC from '../components/draggable_estimate_display_hoc';

const EditableDraggableEstimateDisplay =
  DraggableEstimateDisplayHOC(EditableEstimateDisplay);

class DraggableCategoryListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  onChangeCompetitorCount(data) {
    this.setState(R.reduce((acc, categoryId) => {
      acc[categoryId] = data.value;

      return acc;
    }, this.state, data.categoryIds))
  }

  render() {
    return (<DraggableCategoryList
      {...this.props}
      competitorsCount={this.state}
      onChange={this.onChangeCompetitorCount.bind(this)}
      display={EditableDraggableEstimateDisplay}/>
    )
  }
}

function mapStateToProps(state, props) {
  return {}
}

export default connect(mapStateToProps)(DraggableCategoryListContainer);
