import {
  MEDAL_DELIVERED,
  MEDAL_DELIVERED_TO_REPRESENTATIVE,
  MEDAL_COMPETITOR_NOT_FOUND
} from '../shared/constants';

import R from 'ramda';
import moment from 'moment-timezone';

export const isUnchecked = (podium) => R.isNil(R.prop('checked_at', podium))

export const isChecked = (podium) => !R.isNil(R.prop('checked_at', podium))

export const isPrinted = (podium) => !R.isNil(R.prop('printed_at', podium))

export const hasPendingMedals = (podium) =>
  R.any(R.propEq('medal_delivery_id', MEDAL_COMPETITOR_NOT_FOUND))(podium.medals)

export const firstMedalCheckedIn = R.pipe(
  R.filter((m) => { return !R.isNil(m.medal_delivery_id) }),
  R.reduce(
    (acc, m) => {  return R.isNil(acc) || moment(m.updated_at).valueOf() < moment(acc.updated_at).valueOf() ? m : acc },
    null
  )
)

export const pendingMedals = R.filter(R.propEq('medal_delivery_id', null))

export const allDelivered = R.all((m) => !R.isNil(R.prop('medal_delivery_id', m)))
