import { Component } from 'react';

const input = ({ input, label, type, meta: { touched, error, warning } }) => {
  return (
    <div className={`form-group ${touched && error && 'has-error'}`}>
      <label for={input.name} className="control-label">{label}</label>
      <input
        type={type || "text"}
        {...input}
        className="form-control">
      </input>
      {
        touched &&
        (error && <span className="bmd-help text-danger">{error}</span>)
      }
    </div>
  )
}

export default input;
