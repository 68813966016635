import R from 'ramda';

import {
  tournament,
  categories,
  matches
} from '../shared/reducers';

const initialState = {
  tournament: {
    isFetching: false,
    result: {},
  },
  categories: {
    isFetching: false,
    result: [],
  },
  matches: {
    isFetching: false,
    result: [],
  },
  ui: {
    filters: {
      age_division_ids: [],
      weighte_division_ids: [],
      belt_ids: [],
      gender_ids: [],
    },
  },
}

export function singleCompetitor(state = initialState, action) {
  return {
    ...state,
    tournament: tournament(state.tournament, action),
    categories: categories(state.categories, action),
    matches: matches(state.matches, action),
  }
}
