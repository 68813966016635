import { PropTypes } from 'react';
import R from 'ramda';
import { Modal, Button } from 'react-bootstrap';
import { Component } from 'react';

class VictoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matchCompetitorId: null,
      matchDecisionId: null,
    }
  }

  componentWillReceiveProps(props){
    const winnerFn = props.winnerCompetitorId ?
      R.find(R.propEq('id', props.winnerCompetitorId)) :
      R.pipe(
        R.sort((a, b) =>
          b.points - a.points ||
          b.advantage_points - a.advantage_points ||
          a.foul_points - b.foul_points
        ),
        R.head
      );

    const winner = winnerFn(props.matchCompetitors) || {}

    this.setState({
      matchCompetitorId: winner.id,
      matchDecisionId: 2
    })
  }

  handleChange(ev) {
    this.setState(R.assoc(ev.target.name, ev.target.value, this.state));
  }

  setVictoryAndClose() {
    if (R.isNil(this.state.matchCompetitorId) || R.isNil(this.state.matchDecisionId)) {
      return;
    }

    this.props.setVictory(this.state.matchCompetitorId, this.state.matchDecisionId)
    this.props.close();
  }

  render() {
    const {
      matchCompetitors,
      matchDecisions,
      show,
      close,
      setVictory,
    } = this.props;

    const {
      matchDecisionId
    } = this.state;

    const validMatchDecisions =
      R.filter((d) => R.contains(d.id, [1,2,3,4]))(matchDecisions);

    const matchDecisionsRadioButtons = R.addIndex(R.map)((md, i) => (
      <div className="radio" key={i}>
        <label>
          <input
            type="radio"
            name={"matchDecisionId"}
            value={md.id}
            onChange={this.handleChange.bind(this)}
            checked={md.id === parseInt(matchDecisionId, 10)}
            />
          <span className="circle"></span>
          <span className="check"></span>
          {md.name}
        </label>
      </div>
    ))(validMatchDecisions)

    const isWinnerCompetitor = (matchCompetitorId) => matchCompetitorId === parseInt(this.state.matchCompetitorId, 10)

    const radio = (matchCompetitorId) => {
      return (
        <div className="radio">
          <label>
            <input
              type="radio"
              name="matchCompetitorId"
              value={matchCompetitorId}
              checked={isWinnerCompetitor(matchCompetitorId)}
              onChange={this.handleChange.bind(this)}
             />
            <span className="circle"></span>
            <span className="check"></span>
            {matchCompetitorId === parseInt(this.state.matchCompetitorId, 10) ? "WINNER" : ""}
          </label>
        </div>
      )
    }

    const winnerClass = (isWinner) => isWinner ? 'victory-modal__competitor--winner' : ''

    const competitors = R.addIndex(R.map)((mc, i) => (
      <div className="row" key={i}>
        <div className={`victory-modal__competitor ${winnerClass(isWinnerCompetitor(mc.id))} clearfix`}>
          <div className="col-md-8">
            <div className="victory-modal__club-name">
              {mc.club}
            </div>
            <div className="victory-modal__competitor-name">
              {mc.name}
            </div>
          </div>
          <div className="col-md-4">
            {radio(mc.id)}
          </div>
        </div>
      </div>
    ))(matchCompetitors)

    return (
      <Modal show={show} onHide={close} className="modal victory-modal">
        <Modal.Header closeButton>
          <Modal.Title className="victory-modal__title">Confirm Victory of</Modal.Title>
        </Modal.Header>
        <Modal.Body className="victory-modal__content">
          {competitors}
          <div className="row">
            <div className="col-md-12">
              <div className="victory-modal__match-decisions">
                {matchDecisionsRadioButtons}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.setVictoryAndClose.bind(this)} className="btn btn-primary">Confirm</Button>
          <Button onClick={close} className="btn">Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

VictoryModal.propType = {
  matchDecisions: PropTypes.array.isRequired,
  matchCompetitors: PropTypes.array.isRequired,
}

VictoryModal.defaultProps = {
  matchDecisions: [],
  matchCompetitors: []
}

export default VictoryModal;
