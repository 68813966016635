
import R from 'ramda';
import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import NestedCategoryList from "../../shared/components/nested_category_list";
import StartTimeSelectorDisplayHOC from "../components/start_time_selector_display_hoc"
import {
  updateOpenClassEstimatedStart
} from '../actions';

const StartTimeSelectorDisplay = StartTimeSelectorDisplayHOC(
  function (props) {
    return (<span>{props.children}</span>)
  }
);

class OpenClassCategoriesContainer extends React.Component {
  onChangeTime (field) {
    return (categories, value) => {

      this.props.updateOpenClassEstimatedStart({
        categoryIds: R.map(R.prop('id'), categories),
        category: {
          [`${field}_hour`]: value ? value.hour() : null,
          [`${field}_minute`]: value ? value.minute() : null
        }
      })
    }
  }

  onChangeDay(categories, value) {
    this.props.updateOpenClassEstimatedStart({
      categoryIds: R.map(R.prop('id'), categories),

      category: {
        subscription_tournament_day_id: value,
      }
    })
  }

  render () {
    const {
      categories,
      tournamentDays
    } = this.props;

    return (
      <NestedCategoryList
        collection={categories}
        tournamentDays={tournamentDays}
        onChangeDay={this.onChangeDay.bind(this)}
        onChangeEstimatedStartTime={this.onChangeTime('estimated_start_time').bind(this)}
        onChangeSubscriptionDeadline={this.onChangeTime('subscription_deadline').bind(this)}
        labelField="belt_name"
        valueField="belt_id"
        slugField="belt_slug"
        display={StartTimeSelectorDisplay}>
        <NestedCategoryList
          collection={categories}
          tournamentDays={tournamentDays}
          onChangeDay={this.onChangeDay.bind(this)}
          onChangeEstimatedStartTime={this.onChangeTime('estimated_start_time').bind(this)}
          onChangeSubscriptionDeadline={this.onChangeTime('subscription_deadline').bind(this)}
          labelField="age_division_name"
          valueField="age_division_id"
          display={StartTimeSelectorDisplay}>
          <NestedCategoryList
            collection={categories}
            tournamentDays={tournamentDays}
            onChangeDay={this.onChangeDay.bind(this)}
            onChangeEstimatedStartTime={this.onChangeTime('estimated_start_time').bind(this)}
            onChangeSubscriptionDeadline={this.onChangeTime('subscription_deadline').bind(this)}
            labelField="gender_name"
            valueField="gender_id"
            display={StartTimeSelectorDisplay}>
          </NestedCategoryList>
        </NestedCategoryList>
      </NestedCategoryList>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateOpenClassEstimatedStart
  }, dispatch);
}

export default connect((state, props) => props, mapDispatchToProps)(OpenClassCategoriesContainer);
