import {
  fetchTournament,
  fetchCategories,
  fetchFilters,
  fetchTimeLimits,
  fetchEstimate,
  updateEstimateDay
} from '../shared/actions/api';

import {
  doHydrateCategories,
} from '../shared/actions/hydrate_categories';

export function loadData(tournamentId, estimateId) {
  return (dispatch) => {
    return dispatch(fetchTournament(tournamentId))
      .then(() => Promise.all([
        dispatch(fetchFilters()),
        dispatch(fetchTimeLimits()),
      ]))
      .then(() => Promise.all([
        dispatch(fetchCategories({ query: { paginated: 'false', with_competitors: true } })),
        dispatch(fetchCategories({ query: { with_competitors: false, weight_division_ids: [10, 11, 12], paginated: 'false' } })),
      ]))
      .then(() => Promise.all([
        dispatch(doHydrateCategories()),
        dispatch(fetchEstimate(estimateId)),
      ]))
  }
}
