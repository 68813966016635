import React from 'react';
import R from 'ramda';
import moment from 'moment-timezone';
import { Row, Col, Tab, Tabs, Button } from 'react-bootstrap';
import { Field, FieldArray, reduxForm, updateSyncErrors } from 'redux-form';
import { required, url, file } from 'redux-form-validators';
import I18n from 'i18n-js';
import arrayOfNumbers from '../../modules/array_of_numbers';
import TournamentDayCategoriesInput from './tournament_day_categories_input';
import TournamentOpenClassCategoriesInput from './tournament_open_class_categories_input';
import {
  renderCheckbox, renderDateInput, renderField, renderSelect, renderTextarea, renderTimezoneSelect, FileField
} from '../../shared/components/form_inputs';

const hours = arrayOfNumbers({ to: 24 })
  .map((h) => ({ value: h, label: h }));

const minutes = [
  '00', '05', '10', '15', '20', '25',
  '30', '35', '40', '45', '50', '55',
].map((m) => ({ value: m, label: m }));

// in rails, in order to delete an associated element from a form submission,
// we have to pass the element with a '_destroy': true attribute.
// this function handles that for an array of redux form fields
const updateLength = (fields, targetCount) => {
  let currentSize = fields.length;
  const untaggedCount = R.reduce((acc, n) => n._destroy ? acc : acc + 1, 0, fields.getAll())

  const increase = (currentCount) => {
    if (currentCount < targetCount) {
      addOrUntagForDestruction(currentCount)
      increase(currentCount + 1)
    }
  }

  const addOrUntagForDestruction = (currentCount) => {
    if (currentCount >= currentSize) {
      fields.push({ number: currentCount + 1 })
    } else {
      fields.splice(currentCount, 1, R.dissoc('_destroy', fields.get(currentCount)))
    }
  }

  const decrease = (currentCount) => {
    if (currentCount > targetCount) {
      removeOrTagForDestruction(currentCount)
      decrease(currentCount - 1)
    }
  }

  const removeOrTagForDestruction = (currentCount) => {
    const cursor = currentCount - 1;

    if (fields.get(cursor).id === undefined) {
      fields.pop()
    } else {
      fields.splice(cursor, 1, R.assoc('_destroy', true, fields.get(cursor)))
    }
  }

  untaggedCount <= targetCount ? increase(untaggedCount) : decrease(untaggedCount);
}

const removeTournamentDay = (fields, index) =>
  fields.splice(index, 1, R.assoc('_destroy', true, fields.get(index)))

const renderTournamentDays = ({ fields, categories }) => {
  return (
    <Tabs defaultActiveKey={0} id="uncontrolled-tab-example" className="mb-3">
      {fields.map((member, index) => {
        if (fields.get(index)._destroy)
          return null
        else
          return (
            <Tab key={index} eventKey={index} title={moment(fields.get(index).date).format("dddd")}>
              <Row>
                <Col md={12}>
                  <Field
                    name={`${member}.started`}
                    type="text"
                    component={renderCheckbox}
                    label={I18n.t("activerecord.attributes.tournament_day.started")}
                  />
                </Col>
              </Row>
              <Row>
                <Field
                  name={`${member}.date`}
                  component={renderDateInput}
                  label={I18n.t("activerecord.attributes.tournament_day.date")}
                  isSize={6}
                />
                <Field
                  name={`${member}.start_hour`}
                  label={I18n.t("activerecord.attributes.tournament_day.start_time")}
                  component={renderSelect}
                  options={hours}
                  validate={[ required() ]}
                  isSize={3}
                />
                <Field
                  name={`${member}.start_minute`}
                  component={renderSelect}
                  options={minutes}
                  validate={[ required() ]}
                  isSize={3}
                />
              </Row>
              <Row>
                <Field
                  type="text"
                  name={`${member}.category_ids`}
                  component={TournamentDayCategoriesInput}
                  categories={categories}
                  label={I18n.t("activerecord.attributes.tournament_day.categories")}
                  isSize={12}
                />
              </Row>
              <FieldArray
                name={`${member}.mats`}
                rerenderOnEveryChange={true}
                component={renderMats}
              />
              <Row>
                <Col md={12}>
                  <button
                    type="button"
                    title="Remove Member"
                    onClick={() => confirm("Are you sure?") ? removeTournamentDay(fields, index) : null}
                    className="btn btn-danger pull-right"
                  >Remove Tournament Day</button>
                </Col>
              </Row>
            </Tab>
          )
      }
      )}
    </Tabs>
  )
}

const renderMats = ({ fields, meta: { error }}) => {
  const sectors = [
    { value: "blue", label: "Blue"},
    { value: "yellow", label: "Yellow"},
    { value: "white", label: "White"},
  ]

  return (
    <div>
      <Row>
        <Col className={'form-group'} md={3}>
          <label>{'Mat Count'}</label>
          <input
            type="number"
            className="form-control"
            value={R.reduce((acc, n) => n._destroy ? acc : acc + 1, 0, fields.getAll())}
            onChange={(v) => updateLength(fields, parseInt(v.target.value, 10))}
          />
        </Col>
      </Row>
      {fields.map((member, index) => {
        if (fields.get(index)._destroy)
          return null
        else
          return (
            <Row key={index}>
              <Col md={1}>
                <span>{fields.get(index).number}</span>
              </Col>
              <Field
                name={`${member}.streaming_link`}
                type="text"
                component={renderField}
                label="Streaming Link"
                isSize={9}
              />
              <Field
                name={`${member}.sector`}
                type="text"
                component={renderSelect}
                options={sectors}
                label="Sector"
                isSize={2}
              />
            </Row>
          )
        })
      }
    </div>
  )
}

let SettingsForm = props => {
  const { handleSubmit, categories } = props;
  const timeFormats = [
    { label: '12h', value: '12h' },
    { label: '24h', value: '24h' },
  ]
  const languages = [
    { label: 'English (US)', value: 'en' },
    { label: 'Português (Brasil)', value: 'pt-BR' }
  ]
  const streamingSources = [
    { label: '', value: '' },
    { label: 'FloGrappling', value: 'flo' },
    { label: 'Youtube Live', value: 'ytl' }
  ]
  const checkinAndWeight = [
    {label: "Separated", value: "separated"},
    {label: "Together", value: "together"}
  ]

  return (<form onSubmit={handleSubmit}>
    <Row>
      <Col md={4}>
        <img src={props.initialValues.logo_url}/>
      </Col>
      <Col md={8}>
        <h1>{props.initialValues.name}</h1>
      </Col>
    </Row>
    <Row>
      <Field
        name="logo"
        label={I18n.t("activerecord.attributes.tournament.logo")}
        component={FileField}
        isSize={4}
      />
      <Field
        name="message"
        label={I18n.t("activerecord.attributes.tournament.message")}
        component={renderTextarea}
        type="textarea"
        rows="5"
        validate={[ required() ]}
        hint="Para incluir links, use o formato: [texto do link](http://endereco.do.link)"
        isSize={8}
      />
    </Row>
    <Row>
      <Field
        label={I18n.t("activerecord.attributes.tournament.streaming_url")}
        name="stream_url"
        component={renderField}
        type="text"
        validate={[ url({if: (values) => values['streaming_source']}) ]}
        isSize={6}
      />
      <Field
        name="streaming_source"
        label={I18n.t("activerecord.attributes.tournament.streaming_source")}
        component={renderSelect}
        options={streamingSources}
        isSize={6}
      />
    </Row>
    <Row>
      <Field
        name="locale"
        label={I18n.t("activerecord.attributes.tournament.locale")}
        component={renderSelect}
        options={languages}
        validate={[ required() ]}
        isSize={4}
      />
      <Field
        label={I18n.t("activerecord.attributes.tournament.time_format")}
        name="time_format"
        component={renderSelect}
        options={timeFormats}
        validate={[ required() ]}
        isSize={4}
      />
      <Field
        label={I18n.t("activerecord.attributes.tournament.timezone")}
        name="timezone"
        component={renderTimezoneSelect}
        validate={[ required() ]}
        isSize={4}
      />
    </Row>
    <Row>
      <Field
        label={I18n.t("activerecord.attributes.tournament.match_interval")}
        name="match_interval"
        component={renderField}
        type="number"
        options={checkinAndWeight}
        min={0}
        validate={[ required() ]}
        isSize={6}
      />
      <Field
        label={I18n.t("activerecord.attributes.tournament.checkin_and_weight_strategy")}
        name="checkin_and_weight_strategy"
        component={renderSelect}
        options={checkinAndWeight}
        validate={[ required() ]}
        isSize={6}
      />
    </Row>
    <Field
      label={I18n.t("activerecord.attributes.tournament.publish_order_of_fights")}
      name="publish_order_of_fights"
      component={renderCheckbox}
    />
    <Field
      label={I18n.t("activerecord.attributes.tournament.display_opc_subscriptions")}
      name="display_opc_subscriptions"
      component={renderCheckbox}
    />
    <FieldArray
      name="tournament_days"
      component={renderTournamentDays}
      categories={categories}
    />
    <Row>
      <Field
        type="text"
        name={'categories'}
        component={TournamentOpenClassCategoriesInput}
        tournamentDays={props.initialValues.tournament_days}
        label={I18n.t("activerecord.attributes.tournament.open_class_categories")}
        isSize={12}
      />
    </Row>
    <button className="btn btn-primary" type="submit">Submit</button>
  </form>)

}

const validate = values => {
  let errors = {}

  if (
    R.any((c) =>
      R.isNil(c.estimated_start_time_hour) ||
      R.isNil(c.estimated_start_time_hour), values.categories || [])) {
    errors.categories = I18n.t("activerecord.errors.models.tournament.attributes.base.categories")
  }

  return errors;
 }


SettingsForm = reduxForm({
  form: 'settings',
  validate,
  enableReinitialize: true
})(SettingsForm);

export default SettingsForm;
