import moment from 'moment-timezone';

export const daysBetween = (startDate, endDate) => {
  if (!moment(startDate || "").isValid() || !moment(endDate || "").isValid()) {
    return [];
  }

  const start = moment(startDate);
  let dates = [start.format('YYYY-MM-DD')];
  let current = start;

  while (!current.isSameOrAfter(endDate)) {
    current = current.add(1, 'day')

    dates.push(current.format('YYYY-MM-DD'))
  }

  return dates;
}
