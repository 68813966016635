import R from 'ramda';

import {
  CATEGORIES_RESPONSE_SUCCESS,
  UPDATE_CATEGORIES_RESPONSE_SUCCESS,
} from '../shared/constants';

import {
  tournament,
  filters,
  categories,
} from '../shared/reducers';

const initialState = {
  tournament: {
    isFetching: false,
    result: { tournament_days: [] },
  },
  filters: {
    isFetching: false,
    result: [],
  },
  categories: {
    isFetching: false,
    result: [],
  },
}

const settings = (state = initialState, action) => {
  return {
    tournament: tournament(state.tournament, action),
    filters: filters(state.filters, action),
    categories: settingsCategories(state.categories, action),
  }
}

const settingsCategories = (state, action) => {
  switch (action.type) {
    case CATEGORIES_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.concat(state.result, action.payload)
      }
    case UPDATE_CATEGORIES_RESPONSE_SUCCESS:
      return {
        ...state,
        result: R.reduce((acc, payloadCategory) => {
          const index =
            R.findIndex((c) => c.id == payloadCategory.id, state.result)

          return R.update(index, payloadCategory, acc);
        }, state.result, action.payload)
      }
    default:
      return categories(state, action)
  }
}

export default settings;
