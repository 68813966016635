import React, { Component } from 'react';
import R from 'ramda';
import { Modal, Button } from 'react-bootstrap';
import * as C from '../modules/category_constants';
import Phase from '../modules/phase_constants';

import ModalConflicts from './modal_conflicts';

class GroupModal extends Component {
  constructor(props) {
    super(props);

    this.state = props.data;
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps.data);
  }

  onChange(e) {
    this.setState(R.assoc(e.target.name, e.target.value, this.state))
  }

  onChangeIds(e) {
    const { name, checked, value } = e.target
    const parsedValue = Number.isInteger(parseInt(e.target.value, 10)) ? parseInt(e.target.value, 10) : value

    this.setState(
      R.assoc(
        name,
        !R.contains(parsedValue, this.state[name]) && checked ?
          R.concat(this.state[name], [parsedValue]) :
          R.difference(this.state[name], [parsedValue]),
        this.state
      )
    )
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(this.state)

    this.props.close();
  }

  render() {
    const { checkConflicts, mats, show, close } = this.props;
    const data = this.state;

    const isSelected = (field, value) => R.contains(value, data[field])
    const conflicts = checkConflicts(this.state);
    const isValid = data.age_division_ids.length > 0 &&
                    data.gender_ids.length > 0 &&
                    data.weight_division_ids.length > 0 &&
                    data.phases.length > 0 &&
                    data.name !== "" &&
                    data.belt_ids.length > 0 &&
                    conflicts.length === 0;

    const renderIdsGroup = (name, label, group) => {
      return (
        <div>
          <h3>{label}</h3>
          <div className="category-group-modal__id-group">
            {
              R.addIndex(R.map)(([key, value], i) => {
                return (
                  <div key={i} className="checkbox category-group-modal__id-item">
                    <label>
                      <input
                        type="checkbox"
                        name={name}
                        value={value}
                        checked={isSelected(name, value)}
                        onChange={this.onChangeIds.bind(this)}/>{key}
                    </label>
                  </div>
                )
              }, group)
            }
          </div>
        </div>
      )
    }

    return (
      <Modal show={show} onHide={close} className="modal category-group-modal">
        <form onSubmit={this.onSubmit.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title className="category-group-modal__title">New Category Group</Modal.Title>
          </Modal.Header>
          <Modal.Body className="category-group-modal__content">
            <input type="hidden" name="tournament_template_id" value={data.tournament_template_id} onChange={this.onChange.bind(this)} />

            <div className="form-group">
              <label className="control-label">Group Name</label>
              <input type="text" name="name" value={data.name} onChange={this.onChange.bind(this)} className="form-control" />
            </div>

            {renderIdsGroup('age_division_ids', 'Age Divisions', R.toPairs(C.Age))}
            {renderIdsGroup('gender_ids', 'Gender', R.toPairs(C.Gender))}
            {renderIdsGroup('belt_ids', 'Belts', R.toPairs(C.Belt))}
            {renderIdsGroup('mat_numbers', 'Mats', R.map((mat) => [mat.number, mat.number], mats))}
            {renderIdsGroup('weight_division_ids', 'Weights', R.toPairs(C.Weight))}
            {renderIdsGroup('phases', 'Phase', R.map((p) => [p, p], R.values(Phase)))}
            {!R.isEmpty(conflicts) && <ModalConflicts conflicts={conflicts}/>}
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={!isValid} type="submit" className="btn btn-primary">Confirm</Button>
            <Button onClick={close} className="btn">Cancel</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default GroupModal;
