import { PropTypes } from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import R from 'ramda';
import moment from 'moment-timezone';
import { roundLabel } from '../../modules/round_label';
import { MATCH } from '../../shared/constants';

import MatchState from './match_state';

const matchSource = {
  beginDrag(props) {
    return {
      selectedMatches: [props.match]
    };
  }
}

function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}

class Match extends React.Component {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }

  render() {
    const {
      connectDragSource,
      isDragging,
      match,

      selectedMatches,
    } = this.props;

    return connectDragSource(
      <div className={`match-display`} >
        <span title={"Phase in the bracket"} className="match-display__phase">{match.phase}</span>
        <span title={"Bracket Page / Total pages in the bracket"} className="match-display__pagination">{match.page}</span>
        <span><MatchState state={match.state}/></span>
        {match.order !== null ? <span className="match-display__ordered"><i className="fa fa-caret-right"/></span> : null}
        {match.order !== null ? <span className="match-display__day">{`${moment.parseZone(match.current_start_time).format("ddd")}`}</span> : null}
        {match.order !== null ? <span className="match-display__mat">{`M${match.mat}:${match.order + 1}`} </span> : null}
        {match.order !== null ? <span className="match-display__est"> {moment.parseZone(match.current_start_time).format("h:mm A")} </span> : null}
      </div>
    );
  }
}

Match.propTypes = {
  match: PropTypes.object.isRequired,
}

export default DragSource(MATCH, matchSource, collectDragSource)(Match);
