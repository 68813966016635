import React, { PropTypes } from 'react';

export default function TimerControls (props) {
  const {
    timerIsRunning,
    start,
    pause,
    setSeconds
  } = props;

  return (
    <div className="score__timer-controls">
      <a className="btn btn-default score__timer-control" disabled={timerIsRunning} title="Rewind 1 minute" onClick={() => setSeconds(60)}><i className="fa fa-chevron-left fa-2x"/><i className="fa fa-chevron-left fa-2x"/></a>
      <a className="btn btn-default score__timer-control" disabled={timerIsRunning} title="Rewind 1 second" onClick={() => setSeconds(1)}><i className="fa fa-chevron-left fa-2x"/></a>
      <a className="btn btn-default score__timer-control" disabled={timerIsRunning} title="Start" onClick={start}><i className="fa fa-play fa-2x"/></a>
      <a className="btn btn-default score__timer-control" disabled={!timerIsRunning} title="Pause" onClick={pause}><i className="fa fa-pause fa-2x"/></a>
      <a className="btn btn-default score__timer-control" disabled={timerIsRunning} title="Advance 1 second" onClick={() => setSeconds(-1)}><i className="fa fa-chevron-right fa-2x"/></a>
      <a className="btn btn-default score__timer-control" disabled={timerIsRunning} title="Advance 1 minute" onClick={() => setSeconds(-60)}><i className="fa fa-chevron-right fa-2x"/><i className="fa fa-chevron-right fa-2x"/></a>
    </div>
  )
}

TimerControls.propTypes = {
  timerIsRunning: PropTypes.bool.isRequired,
}
