import R from 'ramda';

const process = R.reduce((acc, item) => {
  return R.assoc(item.id, { name: item.name, slug: item.slug, order: item.order }, acc)
}, {})

const findName = R.curry((collectionName, collection, category) => {
  const id = category[`${collectionName}_id`]

  return R.pipe(
    R.assoc(
      `${collectionName}_name`,
      collection[id].name
    ),
    R.assoc(
      `${collectionName}_slug`,
      collection[id].slug
    ),
    R.assoc(
      `${collectionName}_order`,
      collection[id].order
    )
  )(category)
})

const findNames = R.curry((collectionName, collection, category) => {
  const ids = category[`${collectionName}_ids`]

    return R.pipe(
      R.assoc(
        `${collectionName}_name`,
        R.pipe(
          R.map((id) => collection[id].name),
          R.join(" | ")
        )(ids)
      ),
      R.assoc(
        `${collectionName}_slug`,
        collection[ids[0]].slug
      ),
      R.assoc(
        `${collectionName}_id`,
        ids[0]
      ),
      R.assoc(
        `${collectionName}_order`,
        collection[ids[0]].order
      )
    )(category)

})

const assignTimeLimit = R.curry((timeLimits, category) => {
  const findTimeLimit = R.find((item) =>
    item.belt_id === category.belt_ids[0] &&
    item.age_division_id === category.age_division_id
  )

  const timeLimit = findTimeLimit(timeLimits);

  return R.assoc('time', (timeLimit ? timeLimit.time_in_minutes : 0))(category)
})

const assignCompetitorsCount = (category) =>
  R.assoc('competitors_count', category.competitors)(category)

export function hydrateCategories (categories, options) {
  const _options = {
    ageDivisions: [],
    weightDivisions: [],
    belts: [],
    genders: [],
    timeLimits: [],
    ...options
  }

  const _ageDivisions = process(_options.ageDivisions);
  const _weightDivisions = process(_options.weightDivisions);
  const _belts = process(_options.belts);
  const _genders = process(_options.genders);

  return R.pipe(
    R.map(findName('age_division', _ageDivisions)),
    R.map(findName('weight_division', _weightDivisions)),
    R.map(findName('gender', _genders)),
    R.map(findNames('belt', _belts)),
    R.map(assignCompetitorsCount),
    R.map(assignTimeLimit(options.timeLimits))
  )(categories)
}
