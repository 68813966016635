import { Component, PropTypes } from 'react';

class VictoryScreen extends Component {
  constructor(props) {
    super(props);

    setTimeout(() => {
      props.loadNextMatch()
    }, 10000)
  }

  render() {
    const { competitorName, clubName, color } = this.props;

    return (
      <div className={`row victory-screen victory-screen--${color}`}>
        <div className="col-xs-12">
          <div className="row">
            <div className="col-xs-12 victory-screen__label-area">
              <h1 className="victory-screen__victory-text">Victory</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 victory-screen__competitor-area">
              <h2 className="victory-screen__competitor-name">{competitorName}</h2>
              <h3 className="victory-screen__club-name">{clubName}</h3>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

VictoryScreen.propTypes = {
  loadNextMatch: PropTypes.func.isRequired,
  color: PropTypes.string
}

VictoryScreen.defaultProps = {
  color: 'white'
}

export default VictoryScreen;
