import Routes from '../../modules/routes.js.erb';
import React, { Component, PropTypes } from 'react';
import { connect } from "react-redux";
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import R from 'ramda';
import { bindActionCreators } from 'redux';
import {
  ORDERED_MATCH,
  GAP,
  CATEGORY_GROUP,
  CATEGORY
} from '../../shared/constants';

import { ContextMenu, MenuItem } from 'react-contextmenu';

import { daysBetween } from '../../modules/days_between';

import Pagination from '../components/pagination';
import MatchModalContainer from './match_modal_container';
import GapModalContainer from './gap_modal_container';
import MatList from '../../shared/components/mat_list';
import CollapseCategoryList from '../components/collapse_category_list';
import DateSwitch from '../components/date_switch';
import OptionsBar from '../components/options_bar';
import MatchesDragLayer from '../components/matches_drag_layer';
import CableSubscriber from '../../shared/components/cable_subscriber';
import MatContainer from '../containers/mat_container';
import CategoryFilter from '../../shared/components/category_filter';
import MatchGroupingSwitch from '../components/match_grouping_switch';
import HideMatsPopover from '../components/hide_mats_popover';
import MergePopoverContainer from './merge_popover_container';
import AutoOrderPopover from '../components/auto_order_popover';
import StandBy from '../../shared/components/stand_by';
import NotificationsContainer from './notifications_container';

const Mats = MatList(MatContainer);

import {
  loadData,
  changeDate,
  changeCategoryFilter,
  advancePage,
  rewindPage,
  hideMats,
  mergeAllMats,
  insertGap,
  editGap,
  unorderGap,
  changeMatchGrouping,
  autoOrderCategories,

  selectTournamentTemplate,
  createTournamentTemplateWrapper,
  duplicateTournamentTemplateWrapper,
  destroyTournamentTemplateWrapper,

  // ContextMenuActions
  showMatchModal,
  toggleHighlight,
  unorderMatches,
  unorderCategory,
  fetchMatsForCurrentDay,
} from '../actions';

import {
  createCategoryGroup,
  updateCategoryGroup,

  updateTournamentTemplate,

  fetchMatches,
  destroyCategoryGroup,
  destroyAllOrderedItems,
  updateMat,
  reloadMat,
  reloadMatch,
  processNotifications,
} from '../../shared/actions/api';

class ScheduleContainer extends React.Component {
  componentDidMount() {
    this.props.loadData(this.props.tournamentId)
  }

  onAdvanceDate() {
    this.props.changeDate('ADVANCE')
  }

  onRewindDate() {
    this.props.changeDate('REWIND')
  }

  onChangeMatPage(page) {
    this.props.fetchMatsForCurrentDay(page);
  }

  showMatchModal(_, attributes) {
    this.props.showMatchModal(attributes.match.id);
  }

  toggleHighlightCategory(_, attributes) {
    this.props.toggleHighlight(CATEGORY, attributes.match.category_id);
  }

  toggleHighlightCategoryGroup(categoryIds) {
    this.props.toggleHighlight(CATEGORY_GROUP, categoryIds);
  }

  unorderMatches(_, attributes) {
    if (confirm("confirm unorder?")) {
      this.props.unorderMatches(attributes);
    }
  }

  unorderCategory(_, attributes) {
    const { matId, match } = attributes;

    if (confirm("confirm unorder category?")) {
      this.props.unorderCategory(matId, match.category_id);
    }
  }

  insertGap(_, attributes) {
    const { matId, match } = attributes;

    this.props.insertGap(match.mat, match.order + 1);
  }

  editGap(_, attributes) {
    const { gap } = attributes;

    this.props.editGap(gap.mat, gap.order);
  }

  unorderGap(_, attributes) {
    const { gap } = attributes;

    this.props.unorderGap(gap);
  }

  openBracket(_, attributes) {
    const win = window.open(Routes.admin_tournament_category_path(this.props.tournamentId, attributes.match.category_id), '_blank');

    win.focus();
  }

  onChangeHiddenMats(){
    const hiddenMatIds = R.pipe(
      R.filter((i) => i.checked),
      R.map((i) => parseInt(i.value, 10))
    )(this.hiddenMats)

    this.props.onChange(hiddenMatIds);
  }

  autoOrderMats() {
    if (confirm("Are you sure you want to Run Auto Order?")) {
      this.props.autoOrderCategories();
    }
  }

  renderMatsSchedule() {
    const {
      tournament,
      tournamentId,
      tournamentDayIndex,
      selectedFilters,
      filters,
      mats,
      hiddenMats,
      categoriesPage,
      categoriesPageSize,
      categories,
      reloadMat,
      reloadMatch,
      processNotifications,
      advancePage,
      rewindPage,
      matchGrouping,
    } = this.props;

    const tournamentDay = (tournament.tournament_days || [])[tournamentDayIndex] || {}

    return (
      <div className="schedule__body">
        <div className="schedule__mats">
          <CableSubscriber tournamentId={+tournamentId} subscriptions={[
            { name: "MatsChannel", received: reloadMat, room_id: "+"},
            { name: "MatchesChannel", received: reloadMatch, room_id: "+" },
            { name: "NotificationsChannel", received: processNotifications, room_id: "+" }]} />
          <MatchModalContainer tournamentId={tournamentId}/>
          <GapModalContainer />
          <MatchesDragLayer />
          <ContextMenu id={ORDERED_MATCH}>
            <MenuItem onClick={this.showMatchModal.bind(this)}>Match Details</MenuItem>
            <MenuItem onClick={this.toggleHighlightCategory.bind(this)}>Highlight Category</MenuItem>
            <MenuItem onClick={this.openBracket.bind(this)}>Open Bracket</MenuItem>
            <MenuItem onClick={this.unorderMatches.bind(this)}>Unorder Selected</MenuItem>
            <MenuItem onClick={this.unorderCategory.bind(this)}>Unorder Category</MenuItem>
            <MenuItem onClick={this.insertGap.bind(this)}>Insert Gap</MenuItem>
          </ContextMenu>

          <ContextMenu id={GAP}>
            <MenuItem onClick={this.editGap.bind(this)}>Edit Gap</MenuItem>
            <MenuItem onClick={this.unorderGap.bind(this)}>Unorder Gap</MenuItem>
          </ContextMenu>

          <Mats
            mats={mats}
            matsCount={tournamentDay.mats_count - hiddenMats.length}
            onChangePage={this.onChangeMatPage.bind(this)}
            />

        </div>
        <div className="schedule__categories">
          <DateSwitch
            onAdvanceDate={this.onAdvanceDate.bind(this)}
            onRewindDate={this.onRewindDate.bind(this)}
            tournamentDay={tournamentDay} />

          <CategoryFilter
            selectedFilters={selectedFilters}
            filters={filters}
            onChange={this.props.changeCategoryFilter.bind(this)}/>

          <MatchGroupingSwitch
            onChange={this.props.changeMatchGrouping.bind(this)}
            value={matchGrouping}
            />

          <Pagination
            collection={categories}
            pageSize={categoriesPageSize}
            currentPage={categoriesPage}
            advancePage={this.props.advancePage.bind(this)}
            rewindPage={this.props.rewindPage.bind(this)}
            />

          <CollapseCategoryList
            categories={categories}
            grouping={matchGrouping}
            page={categoriesPage}
            />

        </div>
      </div>
    );
  }

  render() {
    const {
      tournament,
      tournamentId,
      tournamentDayIndex,
      mats,
      hiddenMats,
      categories,
      reloadMat,
      reloadMatch,
      processNotifications,
      advancePage,
      rewindPage,
      hideMats,
      mergeAllMats,
      matchGrouping,
      categoryGroups,
      tournamentTemplates,
      standBy,

      selectedTournamentTemplateId,
      selectTournamentTemplate,

      createCategoryGroup,
      updateCategoryGroup,
      destroyCategoryGroup,

      createTournamentTemplateWrapper,
      duplicateTournamentTemplateWrapper,
      updateTournamentTemplate,
      destroyTournamentTemplateWrapper,

      destroyAllOrderedItems,

    } = this.props;

    const tournamentDay = (tournament.tournament_days || [])[tournamentDayIndex] || {}

    return (
      <div className="schedule">
        { !R.isEmpty(standBy) && <StandBy standBy={standBy}/> }
        <OptionsBar>
          <AutoOrderPopover
            categoryGroups={R.sortBy(R.prop('order'))(categoryGroups)}

            tournamentTemplates={tournamentTemplates}
            selectedTournamentTemplateId={selectedTournamentTemplateId}
            onChangeTournamentTemplate={selectTournamentTemplate}

            mats={mats}
            categories={categories}
            onHighlightCategoryGroup={this.toggleHighlightCategoryGroup.bind(this)}
            onCreateGroup={createCategoryGroup}
            onUpdateGroup={updateCategoryGroup}
            onDeleteGroup={destroyCategoryGroup}
            onCreateTournamentTemplate={createTournamentTemplateWrapper}
            onDuplicateTournamentTemplate={duplicateTournamentTemplateWrapper}
            onUpdateTournamentTemplate={updateTournamentTemplate}
            onDeleteTournamentTemplate={destroyTournamentTemplateWrapper}
            onClear={() => destroyAllOrderedItems(tournamentDay)}
            onSubmit={this.autoOrderMats.bind(this)}
          />
          <MergePopoverContainer
            title={"Merge all mats"}
            onSubmit={mergeAllMats}
          />
          <HideMatsPopover
            mats={tournamentDay.mats}
            hiddenMats={hiddenMats}
            onChange={hideMats}
          />
          <NotificationsContainer
            />

        </OptionsBar>

        {this.renderMatsSchedule()}
      </div>
    );
  }
}

ScheduleContainer.propTypes = {
  tournamentId: PropTypes.number.isRequired,
  tournamentDayIndex: PropTypes.number.isRequired,
  tournament: PropTypes.object,
  mats: PropTypes.array,
  categories: PropTypes.array,
  hiddenMats: PropTypes.array,
}

ScheduleContainer.defaultProps = {
  tournament: {},
  categories: [],
}

function mapStateToProps(state, props) {
  const {
    tournament,
    mats,
    tournamentDayIndex,
    categories,
    filters,
    ui,
    matches,
    categoryGroups,
    tournamentTemplates,
  } = state.app;

  const visibleMats = R.filter((m) => !R.contains(m.id, ui.hiddenMats), mats.result)
  const selectedTournamentTemplateId = R.isEmpty(tournament.result) ?
    null :
    tournament.result.tournament_days[tournamentDayIndex].tournament_template_id;

  return {
    tournamentId: parseInt(props.tournamentId, 10),
    tournamentDayIndex,
    selectedTournamentTemplateId,
    tournament: tournament.result,
    mats: visibleMats,
    categories: categories.result,
    selectedFilters: ui.filters,
    filters: filters.result,
    hiddenMats: ui.hiddenMats,
    categoriesPage: ui.categoriesPage,
    categoriesPageSize: ui.categoriesPageSize,
    matchGrouping: ui.matchGrouping,
    standBy: ui.standBy,
    categoryGroups: categoryGroups.result,
    tournamentTemplates: tournamentTemplates.result,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadData,
    changeDate,
    changeCategoryFilter,
    updateMat,
    advancePage,
    rewindPage,
    hideMats,
    mergeAllMats,
    reloadMat,
    reloadMatch,
    processNotifications,
    fetchMatsForCurrentDay,
    insertGap,
    editGap,
    unorderGap,
    changeMatchGrouping,
    autoOrderCategories,

    selectTournamentTemplate,
    createCategoryGroup,
    updateCategoryGroup,
    destroyCategoryGroup,
    createTournamentTemplateWrapper,
    duplicateTournamentTemplateWrapper,
    updateTournamentTemplate,
    destroyTournamentTemplateWrapper,
    destroyAllOrderedItems,

    showMatchModal,
    toggleHighlight,
    unorderMatches,
    unorderCategory,
  }, dispatch);
}

export default DragDropContext(HTML5Backend)(connect(mapStateToProps, mapDispatchToProps)(ScheduleContainer));
