import React, { PropTypes, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { roundLabelHumanized } from '../../modules/round_label';
import R from 'ramda';

import {
  isMatchFinished,
  isMatchReady,
  isMatchInPreparation,
  winner
} from '../../modules/match_lenses';

import ScoreCompetitor from '../components/score_competitor';
import ScoreCompetitorControls from '../components/score_competitor_controls';
import ScoreTimer from '../components/score_timer';
import VictoryModal from '../components/victory_modal';
import VictoryScreen from '../components/victory_screen';
import TimerControls from '../components/timer_controls';
import PublicScore from '../components/public_score';
import NoMatch from '../components/no_match';
import CableSubscriber from '../../shared/components/cable_subscriber';
import WaitingMatch from '../components/waiting_match';

import {
  startMatch,
  startMatchOnDb,
  pauseMatch,
  switchCompetitorColors,
  resetCompetitorColors,
  scorePoint,
  scoreAdvantage,
  scoreFoul,
  setVictory,
  loadData,
  openVictoryModal,
  closeVictoryModal,
  selectMatch,
  reloadScoreMat,
  reloadScoreMatch
} from '../actions';

class ScoreContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: 60,
      elapsed: 0,
      intervalId: props.running ?  setInterval(this.timer.bind(this), 1000) : null
    }
  }

  phaseName(phase) {
    return {
      "F": "Final",
      "SF": "Semi-Final",
      "QF": "Quarter-Final",
    }[phase] || "Qualifier";
  }

  componentDidMount() {
    this.props.loadData(this.props.tournament_id, this.props.mat_id)
  }

  timer() {
    if (this.state.elapsed === this.state.timer) {
      clearInterval(this.state.intervalId);

      this.setState({
        intervalId: null
      })

      this.props.pauseMatch();
      this.props.openVictoryModal();
    } else {
      this.setState({ elapsed: this.state.elapsed + 1 })
    }
  }

  setTimerSeconds(seconds) {
    if (seconds + this.state.timer < 0 || this.props.timerIsRunning) {
      return;
    }

    this.setState({ timer: this.state.timer + seconds})
  }

  componentWillReceiveProps(props) {
    if (props.timerIsRunning && this.state.intervalId) {
      return;
    }

    const intervalId = props.timerIsRunning ?
      setInterval(this.timer.bind(this), 1000) :
      clearInterval(this.state.intervalId);

    this.setState({
      intervalId: intervalId,
    })

    if (!R.equals(props.match.id, this.props.match.id)) {
      this.setState({
        elapsed: 0,
        timer: props.match.duration * 60
      })

      this.props.resetCompetitorColors();
    }
  }

  competitorColorClass(inverted, reverse) {
    let _switch = reverse ? !inverted : inverted;

    return _switch ? "white" : "blue";
  }

  startMatchAndTimer(){
    this.props.startMatch()

    if (this.props.match.state == "weighted") {
      this.props.startMatchOnDb();
    }
  }

  openVictoryModal(competitorId){
    this.props.pauseMatch();
    this.props.openVictoryModal(competitorId);
  }

  score() {
    let {
      tournamentId,
      mat_id,
      match,
      timerIsRunning,
      colorsInverted,
      victoryModalIsOpen,
      matchDecisions,
      winnerCompetitorId,

      setVictory,
      openVictoryModal,
      closeVictoryModal,
      pauseMatch,
      reloadScoreMat,
      reloadScoreMatch,
    } = this.props;

    const scoreControls = (competitor) => <ScoreCompetitorControls
      match={match}
      competitor={competitor}
      scorePoint={this.props.scorePoint}
      scoreAdvantage={this.props.scoreAdvantage}
      scoreFoul={this.props.scoreFoul}
      openVictoryModal={this.openVictoryModal.bind(this)}
      />

    const competitorScores = R.pipe(
      R.addIndex(R.map)((competitor, i) => (
        <div className={`row score-competitor score-competitor--${this.competitorColorClass(colorsInverted, i % 2 === 1)}`}>
          <ScoreCompetitor
            competitor={competitor}
            controls={scoreControls(competitor)}
            tournamentId={tournamentId}
            />
        </div>
      ))
    )(match.competitors)

    return (
      <div className="container-fluid">
        <div className="score score--admin">
          {competitorScores[0]}
          <div className="row score__divider">
            <div className="col-xs-12">
              <div className="score__switch-colors">
                <a className="btn btn-primary btn-raised" onClick={this.props.switchCompetitorColors}><i className="fa fa-refresh"></i></a>
              </div>
            </div>
          </div>
          {competitorScores[1]}
          <VictoryModal
            winnerCompetitorId={winnerCompetitorId}
            matchCompetitors={match.competitors}
            matchDecisions={matchDecisions}
            show={victoryModalIsOpen}
            setVictory={setVictory}
            close={closeVictoryModal}
            />
          <div className={`row score__match-details ${timerIsRunning ? 'score__match-details--running' : ''}`}>
            <div className="col-xs-5">
              <div className="score__timer">
                <ScoreTimer
                  timer={this.state.timer}
                  elapsed={this.state.elapsed}
                  running={timerIsRunning}
                  />
                <TimerControls
                  timerIsRunning={timerIsRunning}
                  setSeconds={this.setTimerSeconds.bind(this)}
                  start={this.startMatchAndTimer.bind(this)}
                  pause={pauseMatch}
                  />
              </div>
            </div>
            <div className="col-xs-7">
              <div className="score__category-details">
                <div className="score__phase">
                  {this.phaseName(match.phase)}
                </div>
                <div className="score__category-name">
                  {match.category_name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  victoryScreen(match) {
    const {
      selectMatch,
      tournamentId,
      colorsInverted,
    } = this.props;

    const _winner = winner(match);
    const _winnerIndex = R.findIndex(R.propEq('id', _winner.id))(match.competitors)
    const color = this.competitorColorClass(colorsInverted, _winnerIndex % 2 === 1)

    return (
      <VictoryScreen
        competitorName={_winner.name}
        clubName={_winner.club}
        loadNextMatch={selectMatch}
        color={color}
        />
    )
  }

  noMatch() {
    return <NoMatch />
  }

  publicScore() {
    const {
      colorsInverted,
      timerIsRunning,
      matNumber,
      match,
      tournamentId,
    } = this.props;

    return (<PublicScore
      matNumber={matNumber}
      match={match}
      timer={this.state.timer}
      running={timerIsRunning}
      elapsed={this.state.elapsed}
      competitorColorClass={this.competitorColorClass.bind(this)}
      colorsInverted={colorsInverted}
      tournamentId={tournamentId}
      />)
  }

  scoreWaiting(match) {
    const {
      tournamentId
    } = this.props;

    return (<WaitingMatch tournamentId={tournamentId} match={match} />);
  }

  render() {
    let {
      mat_id,
      match,
      timerIsRunning,
      colorsInverted,
      victoryModalIsOpen,
      matchDecisions,
      tournamentId,

      setVictory,
      openVictoryModal,
      closeVictoryModal,
      pauseMatch,
      reloadScoreMat,
      reloadScoreMatch,
    } = this.props;

    let component;

    if (R.isNil(match.id)) {
      component = this.noMatch();
    } else if (isMatchFinished(match)) {
      component = this.victoryScreen(match);
    } else if (isMatchInPreparation(match)) {
      component = this.scoreWaiting(match);
    } else {
      component = this.score();
    }

    return (
      <div className="score-container">
        <CableSubscriber
          tournamentId={+tournamentId}
          subscriptions={[
            { name: "MatsChannel", room_id: mat_id, received: reloadScoreMat },
            { name: "MatchesChannel", received: reloadScoreMatch, room_id: "+" }
          ]} />
        {this.publicScore()}
        {component}
      </div>
    )
  }
}

ScoreContainer.propTypes = {
  match: PropTypes.object.isRequired,
  matchDecisions: PropTypes.array.isRequired,
  timerIsRunning: PropTypes.bool.isRequired,
  colorsInverted: PropTypes.bool.isRequired,

  startMatch: PropTypes.func.isRequired,
  startMatchOnDb: PropTypes.func.isRequired,
  pauseMatch: PropTypes.func.isRequired,
  switchCompetitorColors: PropTypes.func.isRequired,
  resetCompetitorColors: PropTypes.func.isRequired,
  scorePoint: PropTypes.func.isRequired,
  scoreAdvantage: PropTypes.func.isRequired,
  scoreFoul: PropTypes.func.isRequired,
  setVictory: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
  const {
    mat,
    match,
    matches,
    timeLimit,
    ui,
    matchDecisions,
  } = state.app;

  const p = {
    tournamentId: ownProps.tournament_id,
    matchId: ownProps.match_id,
    orderedMatch: {},
    matNumber: ownProps.mat_number,
    mat: {},
    match: match.result,
    matchDecisions: matchDecisions.result,
    winnerCompetitorId: ui.winnerCompetitorId,

    timerIsRunning: ui.timerIsRunning,
    colorsInverted: ui.colorsInverted,
    victoryModalIsOpen: ui.victoryModalIsOpen,
  };

  return p;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    startMatch,
    startMatchOnDb,
    pauseMatch,
    switchCompetitorColors,
    resetCompetitorColors,
    scorePoint,
    scoreAdvantage,
    scoreFoul,
    setVictory,
    loadData,
    openVictoryModal,
    closeVictoryModal,
    selectMatch,
    reloadScoreMat,
    reloadScoreMatch,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreContainer);
