import React, { Component, PropTypes } from 'react';
import R from 'ramda';
import moment from 'moment-timezone';
import arrayOfNumbers from '../../modules/array_of_numbers';

export function TimeSelect(props) {
  const {
    wrapperClass,
    inputWrappersClass,
    inputsClass,
    defaultValue,
    name
  } = props;

  let value = moment.parseZone(defaultValue);
  let hours = arrayOfNumbers({ to: 24 })
    .map((h) => <option key={h} value={h}>{h}</option>);
  let minutes = [
    '00', '05', '10', '15', '20', '25',
    '30', '35', '40', '45', '50', '55',
  ].map((m) => <option key={m} value={parseInt(m, 10)}>{m}</option>);

  return (
    <div className={`time-select ${wrapperClass}`} >
      <div className={`${inputWrappersClass}`}>
        <input type="hidden" name={`${name}(1i)`} value={value.year()}/>
        <input type="hidden" name={`${name}(2i)`} value={value.month() + 1}/>
        <input type="hidden" name={`${name}(3i)`} value={value.date()}/>
        <select name={`${name}(4i)`} defaultValue={value.hour()} className={`${inputsClass}`}>
          {hours}
        </select>
        <select name={`${name}(5i)`} defaultValue={value.minute()} className={`${inputsClass}`}>
          {minutes}
        </select>
      </div>
    </div>
  )
}

TimeSelect.propTypes = {
  wrapperClass: PropTypes.string,
  inputsClass: PropTypes.string,
}
