class MatMobile {
  scrollTo(competitorNumber) {
    $(".tournament-day__match--highlighted").toggleClass("tournament-day__match--highlighted")

    const competitor = $(`#competitor-${competitorNumber}`);

    if (competitor.length === 0) {
      return undefined;
    }

    Promise.resolve(
      $('.sliding-tabs__tab')[competitor.parents('.sliding-tab-panels__panel').index()].click()
    )
    .then((a) => {
      const li = competitor.parents("li").first()

      li.addClass("tournament-day__match--highlighted")

      window.scroll({
        top: li.offset().top - 100,
        left: 0,
        behavior: 'smooth'
      })
    })
  }
}

export default MatMobile;
