import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import MatchCompetitors from '../components/match_competitors';
import { updateMat } from '../../shared/actions/api';
import moveOrderedMatches from '../../modules/move_ordered_matches';


const mapStateToProps = (state, ownProps) => {
  return {
    mat: state.app.mats.result.filter((m) => m.id == ownProps.matId)[0],
    isFetchingMat: state.app.mats.isFetching
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateMat
  }, dispatch);
}

class MatchCompetitorsContainer extends React.Component {
  onChangeMatchOrder (matId, matchId, order) {
    const matches = this.props.mat.matches
    const selected = matches.filter((m) => m.id === matchId)

    this.props.updateMat({
      id: matId,
      ordered_items_attributes: moveOrderedMatches(matches, order, selected)
        .map((p) => ({
          order: p.order,
          ordenable_id: p.id,
          ordenable_type: p.kind,
          id: p.ordered_item_id
        }))
    })
  }

  render() {
    return (<MatchCompetitors {...this.props} onChangeMatchOrder={this.onChangeMatchOrder.bind(this)}/>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchCompetitorsContainer);
