import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip';

import { TournamentIndex } from './pages/tournament_index';
import { FlashMessage } from './modules/flash_message';
import { Framework } from "./pages/framework";
import { CategoriesIndex } from "./pages/categories_index";
import { TournamentDaysShow } from "./pages/tournament_days_show";
import { DashboardIndex } from "./pages/admin/dashboard_index";
import OpenClassesIndex from "./pages/open_classes_index";
import OpenClassesShow from "./pages/open_classes_show";
import { CategoryShow } from "./pages/category_show";
import { AdminTournamentIndex } from "./pages/admin/tournament_index";
import AdminTournamentShow from "./pages/admin/tournament_show";
import AdminOperators from "./pages/admin/operators_index";
import AdminCategoryShow from "./pages/admin/category_show";
import AdminOpenClassSubscriptionsIndex from "./pages/admin/open_class_subscriptions_index";
import AdminOpenClassSubscriptionsShow from "./pages/admin/open_class_subscriptions_show";
import AdminMedalGroupingNew from "./pages/admin/admin_medal_grouping_new";
import AdminMedalGroupingEdit from "./pages/admin/admin_medal_grouping_edit";
import AdminUserGroupNew from "./pages/admin/admin_user_group_new";
import AdminUserGroupEdit from "./pages/admin/admin_user_group_edit";
import I18n from './i18n/translations';

var React = window.React = global.React = require('react');
var ReactDOM = window.ReactDOM = global.ReactDOM = require('react-dom');

require('./components');

$('[data-toggle="tooltip"]').tooltip();

window.Tenkaichi = {}

let pathname = window.location.pathname
Tenkaichi.flashMessage = new FlashMessage();

switch (true) {
  case /^\/?$/.test(pathname):
  case /^\/tournaments\/?$/.test(pathname):
    var tournamentIndex = new TournamentIndex()
    break

  case /^\/framework\/?$/.test(pathname):
    var framework = new Framework()
    break

  case /^\/tournaments\/\d+\/categories\/?$/.test(pathname):
    var categoriesIndex = new CategoriesIndex()
    break

  case /^\/tournaments\/\d+\/tournament_days\/\d+\/?$/.test(pathname):
    var tournamentDaysShow = new TournamentDaysShow()
    break

  case /^\/admin\/tournaments\/\d+\/tournament_days\/\d+\/dashboard/.test(pathname):
    var dashboardIndex = new DashboardIndex()
    break

  case /^\/tournaments\/\d+\/open_classes\/?$/.test(pathname):
    var openClassesIndex = new OpenClassesIndex()
    break

  case /^\/tournaments\/\d+\/open_classes\/\d+\/?$/.test(pathname):
    var openClassesShow = new OpenClassesShow()
    break

  case /^\/tournaments\/\d+\/categories\/\d+\/?$/.test(pathname):
    var categoryShow = new CategoryShow()
    break

  case /^\/admin\/tournaments\/?$/.test(pathname):
    var adminTournamentIndex = new AdminTournamentIndex()
    break

  case /^\/admin\/tournaments\/\d+\/?$/.test(pathname):
    var adminTournamentShow = new AdminTournamentShow()
    break

  case /^\/admin\/operators/.test(pathname):
    var adminOperators = new AdminOperators()
    break

  case /^\/admin\/tournaments\/\d+\/categories\/\d+\/?$/.test(pathname):
    var adminCategoryShow = new AdminCategoryShow()
    break

  case /^\/admin\/tournaments\/\d+\/open_class_subscriptions\/?$/.test(pathname):
    var adminOpenClassSubscriptionsIndex = new AdminOpenClassSubscriptionsIndex()
    break

  case /^\/admin\/tournaments\/\d+\/open_class_subscriptions\/\d+\/?$/.test(pathname):
    var adminOpenClassSubscriptionsShow = new AdminOpenClassSubscriptionsShow()
    break

  case /^\/admin\/tournaments\/\d+\/medal_groupings\/new/.test(pathname):
    var adminMedalGroupingNew = new AdminMedalGroupingNew()
    break

  case /^\/admin\/tournaments\/\d+\/medal_groupings\/\d+\/edit/.test(pathname):
    var adminMedalGroupingEdit = new AdminMedalGroupingEdit()
    break

  case /^\/admin\/user_groups\/\d+\/edit/.test(pathname):
    var adminUserGroupEdit = new AdminUserGroupEdit()
    break

  case /^\/admin\/user_groups\/new/.test(pathname):
    var adminUserGroupNew = new AdminUserGroupNew()
    break
}

