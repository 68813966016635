import React, { Component, PropTypes } from 'react';
import R from 'ramda';
import { createConsumer  } from "@rails/actioncable"

function defaultConnected() {
  console.log("CONNECTED");
}

function defaultDisconnected() {
  console.log("DISCONNECTED");
}

function defaultReceived(data) {
  console.log("RECEIVED: ", data);
}

function defaultRejected(data) {
  console.log("REJECTED");
}

class CableSubscriber extends Component {

  constructor(props) {
    super(props);

    const cable = createConsumer();

    R.forEach((s) => {
      cable.subscriptions.create({
          channel: s.name,
          room_id: s.room_id,
          tournament_id: props.tournamentId
        }, {
          received: s.received || defaultReceived,
          rejected: s.rejected || defaultRejected,
          connected: s.connected || defaultConnected,
          disconnected: s.disconnected || defaultDisconnected,
        })
    })(props.subscriptions);

    this.state = {
      cable
    }
  }

  componentWillUnmount() {
    const { cable } = this.state;

    R.map((subscription) => cable.subscriptions.remove(subscription))(cable.subscriptions.subscriptions)
  }

  render () {
    return (<div className="cableSubscriber" />)
  }
}

CableSubscriber.propTypes = {
  tournamentId: PropTypes.number.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    connected: PropTypes.func,
    received: PropTypes.func,
    rejected: PropTypes.func,
  }))
}

export default CableSubscriber;
