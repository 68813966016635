
const TimeSelector = (props) => {
  const {
    label,
    dayValue,
    dayValues,
    timeValue
  } = props;

  const hours = [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8]
  const minutes = [0, 15, 30, 45]

  const times = R.addIndex(R.map)((hourAndMinute, i) => {
    const formattedTime = `${hourAndMinute[0].toString().padStart(2, '0')}:${hourAndMinute[1].toString().padStart(2, '0')}`;

    return (<option key={i} value={formattedTime}>{formattedTime}</option>);
  }, R.xprod(hours, minutes))

  return (
    <div>
      <div className="form-group">
        <label>{label}</label>
        <select defaultValue={dayValue} className="form-control input-sm" onChange={this.props.onChangeDay}>
          <option value="">--</option>
          {dates}
        </select>
      </div>
      <div className="form-group">
        <select defaultValue={timeValue} className="form-control input-sm" onChange={this.props.onChangeTime}>
          <option value="">--</option>
          {times}
        </select>
      </div>
    </div>
  )
}

export default TimeSelector;
