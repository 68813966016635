import R from 'ramda';
import React, { Component, Proptypes } from 'react';

class MobileTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      structure: {"id": "integer",
       "category_id": "integer",
       "competitor_id": "integer",
       "priority_number": "integer",
       "athlete_name": "string",
       "team": "string",
       "ruleset": "string",
       "world_championships_won": "string",
       "last_win": "string",
       "grand_slam_points": "integer",
       "forth_edition_winner": "string",
       "fifth_edition_winner": "string",
       "last_brown_belt_winner": "string",
       "overall_points": "integer",
       },
      data: [
        {"id": 43609,
        "category_id": 1418948,
        "competitor_id": 209571,
        "priority_number": 1,
        "athlete_name": "Jacob H Bornemann",
        "team": "Pedigo Submission Fighting",
        "ruleset": "adult_criteria",
        "world_championships_won": "",
        "last_win": "",
        "grand_slam_points": 108,
        "forth_edition_winner": "No",
        "fifth_edition_winner": "No",
        "last_brown_belt_winner": null,
        "overall_points": 108,
        "former_black_belt_year": null},
        {"id": 43609,
        "category_id": 1418948,
        "competitor_id": 209571,
        "priority_number": 1,
        "athlete_name": "Jacob H Bornemann",
        "team": "Pedigo Submission Fighting",
        "ruleset": "adult_criteria",
        "world_championships_won": "",
        "last_win": "",
        "grand_slam_points": 108,
        "forth_edition_winner": "No",
        "fifth_edition_winner": "No",
        "last_brown_belt_winner": null,
        "overall_points": 108,
        "former_black_belt_year": null},
        {"id": 43609,
        "category_id": 1418948,
        "competitor_id": 209571,
        "priority_number": 1,
        "athlete_name": "Jacob H Bornemann",
        "team": "Pedigo Submission Fighting",
        "ruleset": "adult_criteria",
        "world_championships_won": "",
        "last_win": "",
        "grand_slam_points": 108,
        "forth_edition_winner": "No",
        "fifth_edition_winner": "No",
        "last_brown_belt_winner": null,
        "overall_points": 108,
        "former_black_belt_year": null},
      ]
    }
  }

  renderFixedTable() {
    const { structure, data } = this.state;

    return this.renderTable(
      R.pipe(R.keys, R.slice(0, 1))(structure),
      R.map((each) => R.pipe(
        R.toPairs,
        R.slice(0, 1),
        R.fromPairs
      )(each), data)
    )
  }

  renderScrollableTable() {
    const { structure, data } = this.state;

    return this.renderTable(
      R.pipe(R.keys, R.slice(1, 4))(structure),
      R.map((each) => R.pipe(
        R.toPairs,
        R.slice(1, 4),
        R.fromPairs
      )(each), data)
    )
  }

  renderTable(headers, data) {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            {R.addIndex(R.map)((h, i) => <th key={i}>{h}</th>)(headers)}
          </tr>
        </thead>
        <tbody>
          {
            R.addIndex(R.map)((row, j) =>
              <tr key={j}>
                {R.pipe(R.values, R.addIndex(R.map)((field, i) => <td key={i}>{field}</td>))(row)}
              </tr>, data)
          }
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div className="mobile-table">
        <div className="mobile-table__fixed">
          {this.renderFixedTable()}
        </div>
        <div className="mobile-table__paginated">
          {this.renderScrollableTable()}
        </div>
      </div>
    )
  }
}

export default MobileTable;
