import moment from 'moment-timezone';

function ReloadTimer(options = {}) {
  const opt = {
    reloadInterval: 1,
    reloadIntervalUnit: 'minutes',
    ...options
  };
  const $timer = $(".reload-timer");
  const $display = $timer.find('.reload-timer__display');
  const timeToReload = moment().add(opt.reloadInterval, opt.reloadIntervalUnit);

  function paintClock (time) {
    $display.html(time);
  }

  const intervalId = setInterval(() => {
    const seconds = timeToReload.diff(moment(), 'seconds');
    const timer = `${Math.floor(seconds / 60)}:${`${seconds % 60}`.padStart(2, '0')}`;

    $display.html(timer);

    if (seconds <= 0) {
      window.location.reload(true);
      clearInterval(intervalId)
    }
  }, 1000)
}

export default ReloadTimer;
