
const Card = (props) => {
  const {
    title,
    onClickGo
  } = props;

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h2 className="panel-title">{title}</h2>
      </div>
      { props.children && <div className="panel-body">{props.children}</div> }
      <div className="panel-footer">
        <button onClick={onClickGo} className="btn btn-primary">OPEN</button>
      </div>
    </div>
  )
}

export default Card;
