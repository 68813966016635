import React, { Component, PropTypes } from 'react';
import Routes from '../../modules/routes.js.erb';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import R from 'ramda';
import moment from 'moment-timezone';
import { allDelivered, pendingMedals } from '../../modules/podium_utils';
import { MEDAL_COMPETITOR_NOT_FOUND } from '../../shared/constants';

import CableSubscriber from '../../shared/components/cable_subscriber';
import PodiumList from '../components/podium_list';
import PodiumFilter from '../components/podium_filter';
import CategoryFilter from '../../shared/components/category_filter';
import PublicPodium from '../components/public_podium';
import PodiumReceipt from '../../modules/podium_receipt';
import ReceiptPrintingTester from '../../shared/components/receipt_printing_tester';

import {
  deliverMedalToCompetitor,
  deliverMedalToRepresentative,
  undoResult,
  reportCompetitorNotFound,
  checkPodium,
  changePodiumFilter,
  deliverMedal,
  loadData,
  reloadPodiums,
  reloadPodium,
  changeCateg,
  print,
} from '../actions';

import {
  isUnchecked,
  isChecked,
  hasPendingMedals
} from '../../modules/podium_utils';

class PodiumContainer extends Component {
  componentDidMount() {
    this.props.loadData(this.props.tournamentId)
  }

  printReceipt(podium) {
    this.props.print(podium)
      .then((response) => fetch(
        `http://localhost:${this.props.receiptPrintingServerPort}/`,
        {
          method: "POST",
          body: JSON.stringify(new PodiumReceipt().printInstructions(podium))
        })
      )
  }

  timeout(podium) {
    Promise.all(
      R.map(
        (m) => this.props.deliverMedal(m.id, MEDAL_COMPETITOR_NOT_FOUND, podium.id),
        pendingMedals(podium.medals)
      )
    )
    .then(() => this.printReceipt(podium))
  }

  deliver(medalId, medalDeliveryId, podiumId) {
    this.props.deliverMedal(medalId, medalDeliveryId, podiumId)
      .then(() => {
        const podium = R.find(R.propEq('id', podiumId), this.props.podiums);

        if (allDelivered(podium.medals)) {
          this.printReceipt(podium)
        }
      })
  }

  render() {
    const {
      podiums,
      filters,
      medalDeliveries,
      podiumFilter,
      tournamentId,

      checkPodium,
      changePodiumFilter,
      deliverMedal,
      reloadPodiums,
      reloadPodium,
      receiptPrintingServerPort,
    } = this.props;

    const filteredPodiums = filterPodiums(podiums, podiumFilter);

    return (
      <div className="wrapper podium-container">
        <PublicPodium
          tournamentId={tournamentId}
          podiums={podiums}
          />

        <CableSubscriber tournamentId={+tournamentId} subscriptions={[{
          name: "PodiumsChannel",
          room_id: "*",
          received: reloadPodiums
        },{
          name: "PodiumsChannel",
          room_id: "+",
          received: reloadPodium
        }]} />


        <div className="sidebar">
          <ul className="list-unstyled">
            <p>
              <i className="fa fa-sm fa-filter"></i>
              &nbsp;Filter
            </p>
            <li>
              <form>
                <CategoryFilter
                  filters={filters}
                  onChange={changePodiumFilter}/>
                <PodiumFilter
                  activeFilter={podiumFilter.status}
                  change={changePodiumFilter}/>
              </form>
            </li>
            <li>
              <ReceiptPrintingTester printingServerPort={receiptPrintingServerPort} />
            </li>
          </ul>
        </div>
        <div className="content">
          <h3>{`Total: ${filteredPodiums.length}`}</h3>

          <PodiumList
            podiums={filteredPodiums}
            checkPodium={checkPodium}
            onTimeout={this.timeout.bind(this)}
            onReprint={this.printReceipt.bind(this)}
            medalDeliveries={medalDeliveries}
            deliverMedal={this.deliver.bind(this)}
            tournamentId={tournamentId}
            />
        </div>
      </div>
    )
  }
}

function filterPodiums(podiums, podiumFilter) {

  const ageFilter = (values) =>
    R.filter((p) => R.contains(p.category.age_division_id, values))
  const weightFilter = (values) =>
    R.filter((p) => R.contains(p.category.weight_division_id, values))
  const genderFilter = (values) =>
    R.filter((p) => R.contains(p.category.gender_id, values))
  const beltsFilter = (values) =>
    R.filter((p) => !R.isEmpty(R.intersection(R.map((v) => v, p.category.belt_ids), values)))

  const filterWhenValuesExist = (filterValues, filterFn) =>
    filterValues.length > 0 ? filterFn(filterValues) : R.identity

  return R.pipe(
    (status) => {
      return {
        'UNCHECKED': R.filter(isUnchecked),
        'CHECKED': R.filter(isChecked),
        'PENDING': R.filter(hasPendingMedals),
        'ALL': R.identity
      }[status](podiums)
    },
    filterWhenValuesExist(podiumFilter.age_division_ids, ageFilter),
    filterWhenValuesExist(podiumFilter.weight_division_ids, weightFilter),
    filterWhenValuesExist(podiumFilter.gender_ids, genderFilter),
    filterWhenValuesExist(podiumFilter.belt_ids, beltsFilter)
  )(podiumFilter.status)
}

function mapStateToProps(state, ownProps) {
  const {
    filters,
    podiums,
    medalDeliveries,
    ui,
  } = state.app;

  return {
    podiums: podiums.result,
    filters: filters.result,
    medalDeliveries: medalDeliveries.result,
    podiumFilter: ui.filters,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators({
    loadData,
    checkPodium,
    changePodiumFilter,
    reloadPodiums,
    reloadPodium,
    deliverMedal,
    print
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PodiumContainer);
