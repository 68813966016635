
import lensMatching from '../../modules/lens_matching';
import R from 'ramda';
import React from 'react';
import Card from '../components/card';
import QueryStringForm from '../components/query_string_form';

class CardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: props.form
    }
  }

  onChangeField(field) {
    const { form } = this.state;

    const lensByName = R.compose(lensMatching, R.propEq('name'));

    const fn = (value) => {
      this.setState({
        form: R.over(lensByName(field), R.assoc('value', value), form)
      });
    }

    return fn;
  }

  renderBody(form) {
    return (
      <QueryStringForm form={form} onChangeField={this.onChangeField.bind(this)}/>
    );
  }

  onClickGo() {
    const { url } = this.props;
    const { form } = this.state;

    const fullUrl = new URL(window.location.origin + url)
    const searchParams = new URLSearchParams(fullUrl.search)

    if (form) {
      R.forEach(
        (item) =>
          Array.isArray(item.value) ?
            R.map((v) => searchParams.append(item.name, v), item.value) :
            searchParams.set(item.name, item.value)
        , form);
    }

    window.location.href = fullUrl.pathname + '?' + searchParams.toString()
  }

  render() {
    const { title, url } = this.props;
    const { form } = this.state;

    return (
      <Card title={title} onClickGo={this.onClickGo.bind(this)}>
        { form && this.renderBody(form) }
      </Card>
    );
  }
}

export default CardContainer;
