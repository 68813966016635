
function ApiClient () {
  this.base_path = '/api/ibjjf'
}

ApiClient.prototype.wsTournament = function (options) {
  return this.request({ path: "/ws_tournaments/" + options.id })
}

ApiClient.prototype.tournament = function (options) {
  return this.request({ path: "/tournaments/" + options.id })
}

ApiClient.prototype.bracketUpdate = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/categories/" + options.categoryId +
                              "/brackets/" + options.bracketId,
                        method: "PATCH",
                        data: options.data })
}

ApiClient.prototype.durationEstimate = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/duration_estimates/" + options.id,
                        method: "GET",
                        data: options.data})
}

ApiClient.prototype.durationEstimateDayUpdate = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/duration_estimate_days/" + options.id,
                        method: "PATCH",
                        data: options.data})
}

ApiClient.prototype.categories = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/categories",
                        method: "GET",
                        data: options.data })
}

ApiClient.prototype.updateCategory = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/categories/" + options.categoryId,
                        method: "PATCH",
                        data: options.data })
}

ApiClient.prototype.updateCategories = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/categories/batch_update",
                        method: "PATCH",
                        data: options.data })
}

ApiClient.prototype.categoryFilters = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/category_filters",
                        method: "GET",
                        data: options.data })
}

ApiClient.prototype.timeLimits = function(options) {
  return this.request({ path: "/time_limits",
                        method: "GET",
                        data: options.data })
}

ApiClient.prototype.belts = function(options) {
  return this.request({ path: "/belts",
                        method: "GET",
                        data: options.data })
}

ApiClient.prototype.podiums = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/podiums",
                        method: "GET" })
}

ApiClient.prototype.updatePodium = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/podiums/" + options.id,
                        method: "PATCH",
                        data: options.data})
}

ApiClient.prototype.updateMedal = function(options) {
  return this.request({ path: "/tournaments/" + options.tournamentId +
                              "/medals/" + options.id,
                        method: "PATCH",
                        data: options.data})
}


ApiClient.prototype.request = function(options) {
  return $.ajax({
    url: this.base_path + options.path,
    method: options.method || "GET",
    dataType: "json",
    data: options.data
  })
}

module.exports = ApiClient
