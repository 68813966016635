
import React from 'react';
import {
  PHASE,
  PAGE
} from '../../shared/constants';

const MatchGroupingSwitch = (props) => {
  const { value, onChange } = props;

  return (
    <div className="btn-group btn-group-justified" role="group">
      <div className="btn-group">
        <button
          type="button"
          className={ `btn ${ value === PHASE ? 'btn-primary' : 'btn-default'}` }
          onClick={() => onChange(PHASE)}
          >Phase</button>
      </div>
      <div className="btn-group">
        <button
          type="button"
          className={ `btn ${ value === PAGE ? 'btn-primary' : 'btn-default'}` }
          onClick={() => onChange(PAGE)}
          >Page</button>
      </div>
    </div>
  )
}

export default MatchGroupingSwitch;
