export default (props) => {
  const { open } = props;

  const text = open ? 'open' : 'closed';

  return (
    <div className={`opc-subscription-switch opc-subscription-switch--${text} opc-subscription-switch--operator`}>
      {text}
    </div>
  );
}
