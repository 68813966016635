import R from 'ramda';

import {
  TOURNAMENT_REQUEST,
  TOURNAMENT_RESPONSE_SUCCESS,
  TOURNAMENT_RESPONSE_FAIL,

  MATCHES_REQUEST,
  MATCHES_RESPONSE_SUCCESS,
  MATCHES_RESPONSE_FAIL,

  MATCH_REQUEST,
  MATCH_RESPONSE_SUCCESS,
  MATCH_RESPONSE_FAIL,

  MATCH_DECISIONS_REQUEST,
  MATCH_DECISIONS_RESPONSE_SUCCESS,
  MATCH_DECISIONS_RESPONSE_FAIL,

  MEDAL_DELIVERIES_REQUEST,
  MEDAL_DELIVERIES_RESPONSE_SUCCESS,
  MEDAL_DELIVERIES_RESPONSE_FAIL,

  CATEGORIES_REQUEST,
  CATEGORIES_RESPONSE_SUCCESS,
  CATEGORIES_RESPONSE_FAIL,

  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_RESPONSE_SUCCESS,
  UPDATE_CATEGORY_RESPONSE_FAIL,

  UPDATE_CATEGORIES_REQUEST,
  UPDATE_CATEGORIES_RESPONSE_SUCCESS,
  UPDATE_CATEGORIES_RESPONSE_FAIL,

  AGE_DIVISIONS_REQUEST,
  AGE_DIVISIONS_RESPONSE_SUCCESS,
  AGE_DIVISIONS_RESPONSE_FAIL,

  WEIGHT_DIVISIONS_REQUEST,
  WEIGHT_DIVISIONS_RESPONSE_SUCCESS,
  WEIGHT_DIVISIONS_RESPONSE_FAIL,

  TIME_LIMITS_REQUEST,
  TIME_LIMITS_RESPONSE_SUCCESS,
  TIME_LIMITS_RESPONSE_FAIL,

  GENDERS_REQUEST,
  GENDERS_RESPONSE_SUCCESS,
  GENDERS_RESPONSE_FAIL,

  ESTIMATE_REQUEST,
  ESTIMATE_RESPONSE_SUCCESS,
  ESTIMATE_RESPONSE_FAIL,

  BELTS_REQUEST,
  BELTS_RESPONSE_SUCCESS,
  BELTS_RESPONSE_FAIL,

  UPDATE_MAT_REQUEST,
  UPDATE_MAT_RESPONSE_SUCCESS,
  UPDATE_MAT_RESPONSE_FAIL,

  PODIUMS_REQUEST,
  PODIUMS_RESPONSE_SUCCESS,
  PODIUMS_RESPONSE_FAIL,

  UPDATE_PODIUM_REQUEST,
  UPDATE_PODIUM_RESPONSE_SUCCESS,
  UPDATE_PODIUM_RESPONSE_FAIL,

  UPDATE_MEDAL_REQUEST,
  UPDATE_MEDAL_RESPONSE_SUCCESS,
  UPDATE_MEDAL_RESPONSE_FAIL,

  FILTERS_REQUEST,
  FILTERS_RESPONSE_SUCCESS,
  FILTERS_RESPONSE_FAIL,

  UPDATE_ESTIMATE_DAY_REQUEST,
  UPDATE_ESTIMATE_DAY_RESPONSE_SUCCESS,
  UPDATE_ESTIMATE_DAY_RESPONSE_FAIL,

  MATS_REQUEST,
  MATS_RESPONSE_SUCCESS,
  MATS_RESPONSE_FAIL,

  MAT_REQUEST,
  MAT_RESPONSE_SUCCESS,
  MAT_RESPONSE_FAIL,

  DISQUALIFICATIONS_REQUEST,
  DISQUALIFICATIONS_RESPONSE_SUCCESS,
  DISQUALIFICATIONS_RESPONSE_FAIL,

  COMPETITORS_REQUEST,
  COMPETITORS_RESPONSE_SUCCESS,
  COMPETITORS_RESPONSE_FAIL,

  UPDATE_MATCH_REQUEST,
  UPDATE_MATCH_RESPONSE_SUCCESS,
  UPDATE_MATCH_RESPONSE_FAIL,

  UPDATE_BRACKET_REQUEST,
  UPDATE_BRACKET_RESPONSE_SUCCESS,
  UPDATE_BRACKET_RESPONSE_FAIL,

  TOURNAMENT_SUBSCRIPTIONS_REQUEST,
  TOURNAMENT_SUBSCRIPTIONS_RESPONSE_SUCCESS,
  TOURNAMENT_SUBSCRIPTIONS_RESPONSE_FAIL,

  CREATE_TOURNAMENT_SUBSCRIPTION_REQUEST,
  CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS,
  CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL,

  DESTROY_TOURNAMENT_SUBSCRIPTION_REQUEST,
  DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS,
  DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL,

  CATEGORY_GROUPS_REQUEST,
  CATEGORY_GROUPS_RESPONSE_SUCCESS,
  CATEGORY_GROUPS_RESPONSE_FAIL,

  CREATE_CATEGORY_GROUP_REQUEST,
  CREATE_CATEGORY_GROUP_RESPONSE_SUCCESS,
  CREATE_CATEGORY_GROUP_RESPONSE_FAIL,

  UPDATE_CATEGORY_GROUP_REQUEST,
  UPDATE_CATEGORY_GROUP_RESPONSE_SUCCESS,
  UPDATE_CATEGORY_GROUP_RESPONSE_FAIL,

  DESTROY_CATEGORY_GROUP_REQUEST,
  DESTROY_CATEGORY_GROUP_RESPONSE_SUCCESS,
  DESTROY_CATEGORY_GROUP_RESPONSE_FAIL,

  TOURNAMENT_TEMPLATES_REQUEST,
  TOURNAMENT_TEMPLATES_RESPONSE_SUCCESS,
  TOURNAMENT_TEMPLATES_RESPONSE_FAIL,

  CREATE_TOURNAMENT_TEMPLATE_REQUEST,
  CREATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
  CREATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL,

  UPDATE_TOURNAMENT_TEMPLATE_REQUEST,
  UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
  UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL,

  DESTROY_TOURNAMENT_TEMPLATE_REQUEST,
  DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
  DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_FAIL,

  DUPLICATE_TOURNAMENT_TEMPLATE_REQUEST,
  DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
  DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL,

  DESTROY_ALL_ORDERED_ITEMS_REQUEST,
  DESTROY_ALL_ORDERED_ITEMS_RESPONSE_SUCCESS,
  DESTROY_ALL_ORDERED_ITEMS_RESPONSE_FAIL,

  UPDATE_TOURNAMENT_REQUEST,
  UPDATE_TOURNAMENT_RESPONSE_SUCCESS,
  UPDATE_TOURNAMENT_RESPONSE_FAIL,

  UPDATE_TOURNAMENT_DAY_REQUEST,
  UPDATE_TOURNAMENT_DAY_RESPONSE_SUCCESS,
  UPDATE_TOURNAMENT_DAY_RESPONSE_FAIL,

  RELOAD_MATCH,
  RELOAD_MAT,
  PROCESS_NOTIFICATIONS,

  CALL_API,
} from '../../shared/constants';

export function tournamentDay(state) {
  return state.tournament.result.tournament_days[state.tournamentDayIndex]
}

export function fetchFilters(tournamentId) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [FILTERS_REQUEST, FILTERS_RESPONSE_SUCCESS, FILTERS_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/category_filters`
      }
    })
  }
}

export function fetchTournament(tournamentId) {
  return (dispatch) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [TOURNAMENT_REQUEST, TOURNAMENT_RESPONSE_SUCCESS, TOURNAMENT_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${tournamentId}`
      }
    })
  }
}

export function fetchMatches(options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [MATCHES_REQUEST, MATCHES_RESPONSE_SUCCESS, MATCHES_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/matches`,
        query: options.query,
      }
    })
  }
}

export function fetchMatch(matchId, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [MATCH_REQUEST, MATCH_RESPONSE_SUCCESS, MATCH_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/matches/${matchId}`,
        query: options.query,
      }
    })
  }
}

export function fetchCategories(options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [CATEGORIES_REQUEST, CATEGORIES_RESPONSE_SUCCESS, CATEGORIES_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/categories`,
        query: options.query,
      }
    })
  }
}

export function updateCategory(data, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [UPDATE_CATEGORY_REQUEST, UPDATE_CATEGORY_RESPONSE_SUCCESS, UPDATE_CATEGORY_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/categories/${data.id}`,
        method: 'PATCH',
        body: JSON.stringify({ category: data })
      }
    })
  }
}

export function updateCategories(data, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [UPDATE_CATEGORIES_REQUEST, UPDATE_CATEGORIES_RESPONSE_SUCCESS, UPDATE_CATEGORIES_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/categories/batch_update`,
        method: 'PATCH',
        body: JSON.stringify({ category_ids: data.categoryIds, category: data.category })
      }
    })
  }
}

export function fetchAges() {
  return {
    type: CALL_API,
    payload: {
      types: [AGE_DIVISIONS_REQUEST, AGE_DIVISIONS_RESPONSE_SUCCESS, AGE_DIVISIONS_RESPONSE_FAIL],
      endpoint: `/api/ibjjf/age_divisions`,
    }
  }
}

export function fetchWeights() {
  return {
    type: CALL_API,
    payload: {
      types: [WEIGHT_DIVISIONS_REQUEST, WEIGHT_DIVISIONS_RESPONSE_SUCCESS, WEIGHT_DIVISIONS_RESPONSE_FAIL],
      endpoint: `/api/ibjjf/weight_divisions`,
    }
  }
}

export function fetchTimeLimits(options = {}) {
  return {
    type: CALL_API,
    payload: {
      types: [TIME_LIMITS_REQUEST, TIME_LIMITS_RESPONSE_SUCCESS, TIME_LIMITS_RESPONSE_FAIL],
      endpoint: `/api/ibjjf/time_limits`,
      query: options.query,
    }
  }
}

export function fetchGenders() {
  return {
    type: CALL_API,
    payload: {
      types: [GENDERS_REQUEST, GENDERS_RESPONSE_SUCCESS, GENDERS_RESPONSE_FAIL],
      endpoint: `/api/ibjjf/genders`,
    }
  }
}

export function fetchBelts() {
  return {
    type: CALL_API,
    payload: {
      types: [BELTS_REQUEST, BELTS_RESPONSE_SUCCESS, BELTS_RESPONSE_FAIL],
      endpoint: `/api/ibjjf/belts`,
    }
  }
}

export function fetchEstimate(estimateId) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [ESTIMATE_REQUEST, ESTIMATE_RESPONSE_SUCCESS, ESTIMATE_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/duration_estimates/${estimateId}`,
      }
    })
  }
}

export function updateMat(mat, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [UPDATE_MAT_REQUEST, UPDATE_MAT_RESPONSE_SUCCESS, UPDATE_MAT_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/mats/${mat.id}`,
        method: 'PATCH',
        body: JSON.stringify({ mat: mat }),
        query: options.query,
      }
    })
  }
}

export function updateEstimateDay (estimateDay = {}) {
  return (dispatch, getState) => {

    return dispatch({
      type: CALL_API,
      payload: {
        types: [UPDATE_ESTIMATE_DAY_REQUEST, UPDATE_ESTIMATE_DAY_RESPONSE_SUCCESS, UPDATE_ESTIMATE_DAY_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/duration_estimate_days/${estimateDay.id}`,
        method: 'PATCH',
        body: JSON.stringify({ duration_estimate_day: estimateDay })
      }
    })
  }
}

export function fetchMats(options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [MATS_REQUEST, MATS_RESPONSE_SUCCESS, MATS_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/mats`,
        query: options.query,
      }
    })
  }
}

export function fetchMat(matId, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [MAT_REQUEST, MAT_RESPONSE_SUCCESS, MAT_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/mats/${matId}`,
        query: options.query,
      }
    })
  }
}

export function fetchMatchDecisions(options) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [MATCH_DECISIONS_REQUEST, MATCH_DECISIONS_RESPONSE_SUCCESS, MATCH_DECISIONS_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/match_decisions`,
      }
    })
  }
}

export function fetchDisqualifications(options) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [DISQUALIFICATIONS_REQUEST, DISQUALIFICATIONS_RESPONSE_SUCCESS, DISQUALIFICATIONS_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/disqualifications`,
      }
    })
  }
}

export function fetchMedalDeliveries(options) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          MEDAL_DELIVERIES_REQUEST,
          MEDAL_DELIVERIES_RESPONSE_SUCCESS,
          MEDAL_DELIVERIES_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/medal_deliveries`,
      }
    })
  }
}

export function updateMatch(params) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          UPDATE_MATCH_REQUEST,
          UPDATE_MATCH_RESPONSE_SUCCESS,
          UPDATE_MATCH_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/matches/${params.matchId}`,
        method: 'PATCH',
        body: JSON.stringify({ command: params.command })
      }
    })
  }
}

export function fetchPodiums(tournamentId, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [PODIUMS_REQUEST, PODIUMS_RESPONSE_SUCCESS, PODIUMS_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/podiums`,
        query: options.query,
      }
    })
  }
}

export function fetchCompetitors(options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [COMPETITORS_REQUEST, COMPETITORS_RESPONSE_SUCCESS, COMPETITORS_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/competitors`,
        query: options.query,
      }
    })
  }
}

export function fetchTournamentSubscriptions(options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [TOURNAMENT_SUBSCRIPTIONS_REQUEST, TOURNAMENT_SUBSCRIPTIONS_RESPONSE_SUCCESS, TOURNAMENT_SUBSCRIPTIONS_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_subscriptions`,
        query: options.query,
      }
    })
  }
}

export function createTournamentSubscription(data, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [CREATE_TOURNAMENT_SUBSCRIPTION_REQUEST, CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS, CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_subscriptions`,
        method: 'POST',
        body: JSON.stringify({ tournament_subscription: data })
      }
    })
  }
}

export function destroyTournamentSubscription(subscriptionId, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [DESTROY_TOURNAMENT_SUBSCRIPTION_REQUEST, DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS, DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_subscriptions/${subscriptionId}`,
        method: 'DELETE',
        query: options.query
      }
    })
  }
}

export function updateBracket(data, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [UPDATE_BRACKET_REQUEST, UPDATE_BRACKET_RESPONSE_SUCCESS, UPDATE_BRACKET_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/categories/${data.category_id}/brackets/0`,
        method: 'PATCH',
        body: JSON.stringify({ bracket: R.addIndex(R.reduce)((acc, c, i) => R.assoc(i, c, acc), {}, data.competitors) })
      }
    })
  }
}

export function updatePodium(podium) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          UPDATE_PODIUM_REQUEST,
          UPDATE_PODIUM_RESPONSE_SUCCESS,
          UPDATE_PODIUM_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/podiums/${podium.id}`,
        method: 'PATCH',
        body: JSON.stringify({ podium: podium })
      }
    })
  }
}

export function updateMedal(medal) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          UPDATE_MEDAL_REQUEST,
          UPDATE_MEDAL_RESPONSE_SUCCESS,
          UPDATE_MEDAL_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/medals/${medal.id}`,
        method: 'PATCH',
        body: JSON.stringify({ medal: medal })
      }
    })
  }
}

export function fetchTournamentTemplates(options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          TOURNAMENT_TEMPLATES_REQUEST,
          TOURNAMENT_TEMPLATES_RESPONSE_SUCCESS,
          TOURNAMENT_TEMPLATES_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_templates`,
        query: options.query,
      }
    })
  }
}

export function fetchCategoryGroups(options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          CATEGORY_GROUPS_REQUEST,
          CATEGORY_GROUPS_RESPONSE_SUCCESS,
          CATEGORY_GROUPS_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/category_groups`,
        query: options.query,
      }
    })
  }
}

export function createTournamentTemplate(data, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          CREATE_TOURNAMENT_TEMPLATE_REQUEST,
          CREATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
          CREATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_templates`,
        method: 'POST',
        body: JSON.stringify({ tournament_template: data })
      }
    })
  }
}

export function duplicateTournamentTemplate(data, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          DUPLICATE_TOURNAMENT_TEMPLATE_REQUEST,
          DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
          DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_templates/${data.id}/duplicate`,
        method: 'POST',
        body: JSON.stringify({ tournament_template: {} })
      }
    })
  }
}

export function updateTournament(tournament) {
  return (dispatch) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          UPDATE_TOURNAMENT_REQUEST,
          UPDATE_TOURNAMENT_RESPONSE_SUCCESS,
          UPDATE_TOURNAMENT_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/tournaments/${tournament.id}`,
        method: 'PUT',
        body: JSON.stringify({ tournament: tournament })
      }
    })
  }
}

export function updateTournamentTemplate(tournamentTemplate) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          UPDATE_TOURNAMENT_TEMPLATE_REQUEST,
          UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
          UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_templates/${tournamentTemplate.id}`,
        method: 'PATCH',
        body: JSON.stringify({ tournament_template: tournamentTemplate })
      }
    })
  }
}

export function updateTournamentDay(tournamentDay) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          UPDATE_TOURNAMENT_DAY_REQUEST,
          UPDATE_TOURNAMENT_DAY_RESPONSE_SUCCESS,
          UPDATE_TOURNAMENT_DAY_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_days/${tournamentDay.id}`,
        method: 'PATCH',
        body: JSON.stringify({ tournament_day: tournamentDay })
      }
    })
  }
}

export function destroyTournamentTemplate(tournamentTemplateId, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          DESTROY_TOURNAMENT_TEMPLATE_REQUEST,
          DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS,
          DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_templates/${tournamentTemplateId}`,
        method: 'DELETE',
        query: options.query
      }
    })
  }
}
export function createCategoryGroup(data, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          CREATE_CATEGORY_GROUP_REQUEST,
          CREATE_CATEGORY_GROUP_RESPONSE_SUCCESS,
          CREATE_CATEGORY_GROUP_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/category_groups`,
        method: 'POST',
        body: JSON.stringify({ category_group: data })
      }
    })
  }
}

export function updateCategoryGroup(categoryGroup) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          UPDATE_CATEGORY_GROUP_REQUEST,
          UPDATE_CATEGORY_GROUP_RESPONSE_SUCCESS,
          UPDATE_CATEGORY_GROUP_RESPONSE_FAIL
        ],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/category_groups/${categoryGroup.id}`,
        method: 'PATCH',
        body: JSON.stringify({ category_group: categoryGroup })
      }
    })
  }
}

export function destroyCategoryGroup(categoryGroupId, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          DESTROY_CATEGORY_GROUP_REQUEST,
          DESTROY_CATEGORY_GROUP_RESPONSE_SUCCESS,
          DESTROY_CATEGORY_GROUP_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/category_groups/${categoryGroupId}`,
        method: 'DELETE',
        query: options.query
      }
    })
  }
}

export function destroyAllOrderedItems(tournamentDay, options = {}) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          DESTROY_ALL_ORDERED_ITEMS_REQUEST,
          DESTROY_ALL_ORDERED_ITEMS_RESPONSE_SUCCESS,
          DESTROY_ALL_ORDERED_ITEMS_RESPONSE_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_days/${tournamentDay.id}/ordered_items/destroy_all`,
        method: 'DELETE',
        query: options.query
      }
    })
  }
}

export function reloadMatch(match) {
  return {
    type: RELOAD_MATCH,
    payload: match
  }
}

export function reloadMat(mat) {
  return {
    type: RELOAD_MAT,
    payload: mat
  }
}

export function processNotifications(notifications) {
  console.log("Received notification")
  console.log(notifications)

  return {
    type: PROCESS_NOTIFICATIONS,
    payload: notifications
  }
}

