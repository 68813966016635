import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import apiMiddleware from '../middleware/api_middleware';

import SingleCompetitorContainer from './containers/single_competitor_container';
import { singleCompetitor } from './reducers';

const logger = createLogger();

const store = createStore(
  combineReducers({ app: singleCompetitor }),
  applyMiddleware(thunkMiddleware, apiMiddleware, logger)
);

class SingleCompetitorApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <SingleCompetitorContainer
          tournamentId={this.props.tournament_id}
          tournamentDayId={this.props.tournament_day_id}
          />
      </Provider>
    )
  }
}

export default SingleCompetitorApp;
