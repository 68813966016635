import formatMinutes from '../../modules/format_minutes';
import { PropTypes, Component } from 'react';

function EstimateDisplay(props) {
  const {
    time,
    categoriesCount,
    competitors
  } = props;

  return (
    <span className="estimate-display">
      {props.children}
      <span className="estimate-display__results">
        <span className="estimate-display__time">{formatMinutes(time)}</span>
        <span className="estimate-display__categories">{`${categoriesCount} categories`}</span>
        <span className="estimate-display__competitors">{`${competitors} competitors`}</span>
      </span>
    </span>
  )
}

EstimateDisplay.propTypes = {
  time: PropTypes.number,
  competitors: PropTypes.number,
  categoriesCount: PropTypes.number,
}

EstimateDisplay.defaultProps = {
  time: 0,
  competitors: 0,
  categoriesCount: 0,
}

export default EstimateDisplay;
