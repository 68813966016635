import R from 'ramda';

const TimezoneSelect = (props) => {
  const timezones = [
    {"name":"American Samoa","offset":-11},
    {"name":"International Date Line West","offset":-11},
    {"name":"Midway Island","offset":-11},
    {"name":"Samoa","offset":-11},
    {"name":"Hawaii","offset":-10},
    {"name":"Alaska","offset":-9},
    {"name":"Pacific Time (US & Canada)","offset":-8},
    {"name":"Tijuana","offset":-8},
    {"name":"Arizona","offset":-7},
    {"name":"Chihuahua","offset":-7},
    {"name":"Mazatlan","offset":-7},
    {"name":"Mountain Time (US & Canada)","offset":-7},
    {"name":"Central America","offset":-6},
    {"name":"Central Time (US & Canada)","offset":-6},
    {"name":"Guadalajara","offset":-6},
    {"name":"Mexico City","offset":-6},
    {"name":"Monterrey","offset":-6},
    {"name":"Saskatchewan","offset":-6},
    {"name":"Bogota","offset":-5},
    {"name":"Eastern Time (US & Canada)","offset":-5},
    {"name":"Indiana (East)","offset":-5},
    {"name":"Lima","offset":-5},
    {"name":"Quito","offset":-5},
    {"name":"Atlantic Time (Canada)","offset":-4},
    {"name":"Caracas","offset":-4},
    {"name":"Georgetown","offset":-4},
    {"name":"La Paz","offset":-4},
    {"name":"Santiago","offset":-4},
    {"name":"Newfoundland","offset":-4},
    {"name":"Brasilia","offset":-3},
    {"name":"Buenos Aires","offset":-3},
    {"name":"Greenland","offset":-3},
    {"name":"Montevideo","offset":-3},
    {"name":"Mid-Atlantic","offset":-2},
    {"name":"Azores","offset":-1},
    {"name":"Cape Verde Is.","offset":-1},
    {"name":"Casablanca","offset":0},
    {"name":"Dublin","offset":0},
    {"name":"Edinburgh","offset":0},
    {"name":"Lisbon","offset":0},
    {"name":"London","offset":0},
    {"name":"Monrovia","offset":0},
    {"name":"UTC","offset":0},
    {"name":"Amsterdam","offset":1},
    {"name":"Belgrade","offset":1},
    {"name":"Berlin","offset":1},
    {"name":"Bern","offset":1},
    {"name":"Bratislava","offset":1},
    {"name":"Brussels","offset":1},
    {"name":"Budapest","offset":1},
    {"name":"Copenhagen","offset":1},
    {"name":"Ljubljana","offset":1},
    {"name":"Madrid","offset":1},
    {"name":"Paris","offset":1},
    {"name":"Prague","offset":1},
    {"name":"Rome","offset":1},
    {"name":"Sarajevo","offset":1},
    {"name":"Skopje","offset":1},
    {"name":"Stockholm","offset":1},
    {"name":"Vienna","offset":1},
    {"name":"Warsaw","offset":1},
    {"name":"West Central Africa","offset":1},
    {"name":"Zagreb","offset":1},
    {"name":"Zurich","offset":1},
    {"name":"Athens","offset":2},
    {"name":"Bucharest","offset":2},
    {"name":"Cairo","offset":2},
    {"name":"Harare","offset":2},
    {"name":"Helsinki","offset":2},
    {"name":"Istanbul","offset":2},
    {"name":"Jerusalem","offset":2},
    {"name":"Kaliningrad","offset":2},
    {"name":"Kyiv","offset":2},
    {"name":"Pretoria","offset":2},
    {"name":"Riga","offset":2},
    {"name":"Sofia","offset":2},
    {"name":"Tallinn","offset":2},
    {"name":"Vilnius","offset":2},
    {"name":"Baghdad","offset":3},
    {"name":"Kuwait","offset":3},
    {"name":"Minsk","offset":3},
    {"name":"Moscow","offset":3},
    {"name":"Nairobi","offset":3},
    {"name":"Riyadh","offset":3},
    {"name":"St. Petersburg","offset":3},
    {"name":"Volgograd","offset":3},
    {"name":"Tehran","offset":3},
    {"name":"Abu Dhabi","offset":4},
    {"name":"Baku","offset":4},
    {"name":"Muscat","offset":4},
    {"name":"Samara","offset":4},
    {"name":"Tbilisi","offset":4},
    {"name":"Yerevan","offset":4},
    {"name":"Kabul","offset":4},
    {"name":"Ekaterinburg","offset":5},
    {"name":"Islamabad","offset":5},
    {"name":"Karachi","offset":5},
    {"name":"Tashkent","offset":5},
    {"name":"Chennai","offset":5},
    {"name":"Kolkata","offset":5},
    {"name":"Mumbai","offset":5},
    {"name":"New Delhi","offset":5},
    {"name":"Sri Jayawardenepura","offset":5},
    {"name":"Kathmandu","offset":5},
    {"name":"Almaty","offset":6},
    {"name":"Astana","offset":6},
    {"name":"Dhaka","offset":6},
    {"name":"Novosibirsk","offset":6},
    {"name":"Urumqi","offset":6},
    {"name":"Rangoon","offset":6},
    {"name":"Bangkok","offset":7},
    {"name":"Hanoi","offset":7},
    {"name":"Jakarta","offset":7},
    {"name":"Krasnoyarsk","offset":7},
    {"name":"Beijing","offset":8},
    {"name":"Chongqing","offset":8},
    {"name":"Hong Kong","offset":8},
    {"name":"Irkutsk","offset":8},
    {"name":"Kuala Lumpur","offset":8},
    {"name":"Perth","offset":8},
    {"name":"Singapore","offset":8},
    {"name":"Taipei","offset":8},
    {"name":"Ulaanbaatar","offset":8},
    {"name":"Osaka","offset":9},
    {"name":"Sapporo","offset":9},
    {"name":"Seoul","offset":9},
    {"name":"Tokyo","offset":9},
    {"name":"Yakutsk","offset":9},
    {"name":"Adelaide","offset":9},
    {"name":"Darwin","offset":9},
    {"name":"Brisbane","offset":10},
    {"name":"Canberra","offset":10},
    {"name":"Guam","offset":10},
    {"name":"Hobart","offset":10},
    {"name":"Melbourne","offset":10},
    {"name":"Port Moresby","offset":10},
    {"name":"Sydney","offset":10},
    {"name":"Vladivostok","offset":10},
    {"name":"Magadan","offset":11},
    {"name":"New Caledonia","offset":11},
    {"name":"Solomon Is.","offset":11},
    {"name":"Srednekolymsk","offset":11},
    {"name":"Auckland","offset":12},
    {"name":"Fiji","offset":12},
    {"name":"Kamchatka","offset":12},
    {"name":"Marshall Is.","offset":12},
    {"name":"Wellington","offset":12},
    {"name":"Chatham Is.","offset":12},
    {"name":"Nuku'alofa","offset":13},
    {"name":"Tokelau Is.","offset":13}
  ];

  const options = R.addIndex(R.map)((tz, i) => {
    return (
      <option key={i} value={tz.name}>
        {`${tz.name} (UTC ${tz.offset >= 0 ? "+" : ""}${tz.offset})`}
      </option>
    );
  })(timezones)

  return (
    <select {...props}>
      {options}
    </select>
  )
}

export default TimezoneSelect;
