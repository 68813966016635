import { Component } from 'react';

function RemovableEstimateDisplayHOC(WrappedComponent) {
  return class Removable extends Component {
    onClick() {
      this.props.onRemoveCategoryGroup(this.props.categories)
    }

    render() {
      return (
        <span className="removable-estimate-display">
          <i className="fa fa-times removable-estimate-display__remove-button" onClick={this.onClick.bind(this)}/>
          <WrappedComponent {...this.props}/>
        </span>
      )
    }
  }
}

export default RemovableEstimateDisplayHOC;
