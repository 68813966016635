export const checkin = (matchId, matchCompetitorId) => {
  return {
    matchId: matchId,
    command: {
      type: "checkin",
      payload: {
        match_competitor_id: matchCompetitorId
      }
    }
  }
}

export const undoCheckin = (matchId, matchCompetitorId) => {
  return {
    matchId: matchId,
    command: {
      type: "undo_checkin",
      payload: {
        match_competitor_id: matchCompetitorId
      }
    }
  }
}

export const reportNoShow = (matchId, matchCompetitorId, subscriptionId) => {
  return {
    matchId: matchId,
    command: {
      type: "no_show",
      payload: {
        match_competitor_id: matchCompetitorId,
        subscription_id: subscriptionId
      }
    }
  };
}

export const undoNoShow = (matchId, matchCompetitorId, subscriptionId) => {
  return {
    matchId: matchId,
    command: {
      type: "undo_no_show",
      payload: {
        match_competitor_id: matchCompetitorId,
        subscription_id: subscriptionId
      }
    }
  }
}

export const weight = (matchId, matchCompetitorId, subscriptionId) => {
  return {
    matchId: matchId,
    command: {
      type: "weight",
      payload: {
        match_competitor_id: matchCompetitorId,
        tournament_subscription_id: subscriptionId
      }
    }
  }
}

export const undoWeight = (matchId, matchCompetitorId, subscriptionId) => {
  return {
    matchId: matchId,
    command: {
      type: "undo_weight",
      payload: {
        match_competitor_id: matchCompetitorId,
        tournament_subscription_id: subscriptionId
      }
    }
  }
}

export const disqualify = (matchId, matchCompetitorId, disqualificationId) => {
  return {
    matchId: matchId,
    command: {
      type: "disqualify",
      payload: {
        match_competitor_id: matchCompetitorId,
        disqualification_id: disqualificationId
      }
    }
  }
}

export const undoDisqualify = (matchId, matchCompetitorId) => {
  return {
    matchId: matchId,
    command: {
      type: "undo_disqualify",
      payload: {
        match_competitor_id: matchCompetitorId,
      }
    }
  }
}

export const finish = (matchParams) => {
  return {
    matchId: matchParams.id,
    command: {
      type: "finish",
      payload: {
        match: matchParams,
      }
    }
  }
}

export const reset = (matchId) => {
  return {
    matchId: matchId,
    command: {
      type: "reset",
      payload: {}
    }
  }
}

export const disqualifyByWeight = (matchId, matchCompetitorId) => {
  return disqualify(matchId, matchCompetitorId, 1)
}

export const disqualifyByNoShow = (matchId, matchCompetitorId) => {
  return disqualify(matchId, matchCompetitorId, 2)
}

export const disqualifyByGi = (matchId, matchCompetitorId) => {
  return disqualify(matchId, matchCompetitorId, 3)
}
