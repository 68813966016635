import R from 'ramda';

import {
  CALL_API,
  FILTERS_RESPONSE_SUCCESS,
  OPC_UNSUBSCRIPTION_HISTORY_REQUEST,
  OPC_UNSUBSCRIPTION_HISTORY_RESPONSE_SUCCESS,
  OPC_UNSUBSCRIPTION_HISTORY_FAIL,
} from '../shared/constants';

import {
  fetchTournament,
  fetchCategories,
  fetchFilters,
  fetchCompetitors,
  fetchTournamentSubscriptions
} from '../shared/actions/api';

import {
  changeFilter,
  fetchFilteredCategories
} from '../shared/actions/filter';

export const loadData = (tournamentId) => {
  return (dispatch, getState) => {
    return dispatch(fetchTournament(tournamentId))
      .then(() => Promise.all([
        dispatch(fetchOpenClassCategories()),
        dispatch(fetchFilters())
      ]))
  }
}

export const changeCategoryFilters = (payload) => {
  return (dispatch, getState) => {
    return Promise.resolve(dispatch({ type: "CLEAN_CATEGORIES" }))
      .then(() => Promise.all([
        dispatch(changeFilter(payload)),
        dispatch(fetchOpenClassCategories())
      ]))
  }
}

export const reloadCategory = (category) => {
  return {
    type: "RELOAD_CATEGORY",
    payload: category
  }
}

const fetchOpenClassCategories = () => {
  return (dispatch, getState) => {
    const { filters } = getState().app.ui.filters;
    const openClassIds = { weight_division_ids: [10, 11, 12], paginated: false, with_possible_open_class: true }

    return dispatch(fetchFilteredCategories(R.mergeWith(R.concat, filters, openClassIds)))
  }
}

export const fetchValidOpenClassCompetitorsForCategory = (category) => {
  const weightDivisionRestrictionsFor = (openClassCategory) => {
    switch (openClassCategory.weight_division_id) {
      // Open Class Light only has weight divisions from Rooster to Light
      case 11:
        return [1, 2, 3, 4]
      // Open Class Light only has weight divisions from Medium Heavy to Ultra Heavy
      case 12:
        return [5, 6, 7, 8, 9]
      default:
        return null
    }
  }

  return (dispatch, getState) => {
    return Promise.resolve(dispatch({ type: "CLEAN_COMPETITORS" }))
      .then(() => Promise.all([
        dispatch(fetchCompetitors({ query: {
          age_division_id: category.age_division_id,
          gender_id: category.gender_id,
          belt_ids: category.belt_ids,
          // Any black belt competitor can register for the Open Class
          is_medalist: R.contains(9, category.belt_ids) && category.age_division_id == 15 ? null : true,
          weight_division_ids: weightDivisionRestrictionsFor(category),
          fields: 'opc'
        }})),
        dispatch(fetchTournamentSubscriptions({ query: { category_ids: [category.id] } }))
      ])
    )
  }
}

export function fetchUnsubscriptionHistory(competitor, category) {
  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [OPC_UNSUBSCRIPTION_HISTORY_REQUEST, OPC_UNSUBSCRIPTION_HISTORY_RESPONSE_SUCCESS, OPC_UNSUBSCRIPTION_HISTORY_FAIL],
        endpoint: `/api/ibjjf/tournaments/${getState().app.tournament.result.id}/tournament_subscriptions/unsubscription_history`,
        query: {competitor_id: competitor.id, category_id: category.id},
      }
    })
  }
}
