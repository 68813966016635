import R from 'ramda';
import { Component, PropTypes } from 'react';
import select from '../../shared/components/select';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { isNotEmpty } from '../../shared/validations';

const matchResultForm = props => {
  const {
    onCancel,
    competitors,
    matchDecisions,
    valid,
    submitting,
    handleSubmit
  } = props;

  const matchDecisionOptions = R.map((md) => ({ value: md.id, label: md.name }), matchDecisions)
  const competitorOptions = R.map((md) => ({ value: md.id, label: md.name }), competitors)

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-5">
          <Field
            name={'matchDecisionId'}
            component={select}
            options={matchDecisionOptions}
            label={`Match Decision`}
            validate={[isNotEmpty]}
          />
        </div>
        <div className="col-sm-5">
          <Field
            name={'matchCompetitorId'}
            component={select}
            options={competitorOptions}
            label={`Winner`}
            validate={[isNotEmpty]}
          />
        </div>
      </div>
      <button type="submit" className="btn btn-primary" disabled={valid || submitting}>
        Submit
      </button>
    </form>
  )
}

export default reduxForm({
  form: "MatchResultForm"
})(matchResultForm);

