import { roundLabelAbbrev, roundLabel } from '../../modules/round_label';

import React from 'react';

const MatchGroupPhaseLabel = (props) => (
  <span className={`round__handle round__handle--${roundLabel(props.matches[0].phase, { noSufix: true })}`}>
    <span className="round__label">{`${roundLabelAbbrev(props.matches[0].phase)}`}</span>
  </span>
)

export default MatchGroupPhaseLabel;
