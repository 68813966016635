import React, { Component } from 'react';
import R from 'ramda';
import { Modal, Button } from 'react-bootstrap';

class TemplateModal extends Component {
  constructor(props) {
    super(props);

    this.state = props.data;
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps.data);
  }

  onChange(e) {
    this.setState(R.assoc(e.target.name, e.target.value, this.state))
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(this.state)

    this.props.close();
  }

  render() {
    const { show, close } = this.props;

    const data = this.state;

    const isValid = data.name !== "";

    return (
      <Modal show={show} onHide={close} className="modal tournament-template-modal">
        <form onSubmit={this.onSubmit.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title className="category-group-modal__title">New Category Group</Modal.Title>
          </Modal.Header>
          <Modal.Body className="category-group-modal__content">

            <div className="form-group">
              <label className="control-label">Template Name</label>
              <input type="text" name="name" value={data.name} onChange={this.onChange.bind(this)} className="form-control" />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button disabled={!isValid} type="submit" className="btn btn-primary">Confirm</Button>
            <Button onClick={close} className="btn">Cancel</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default TemplateModal;
