import React, { Component, PropTypes } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import MergePopover from '../components/merge_popover';
import {
  changeMergeOptions,
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
  options: state.app.ui.merge,
  onSubmit: ownProps.onSubmit
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeMergeOptions
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MergePopover);
