import R from 'ramda';

import {

  CATEGORIES_REQUEST,
  CATEGORIES_RESPONSE_SUCCESS,
  CATEGORIES_RESPONSE_FAIL,

  HYDRATE_CATEGORIES,

} from '../shared/constants';

import {
  tournament,
  filters,
  timeLimits,
  estimate,
} from '../shared/reducers';

const initialState = {
  tournament: {
    isFetching: false,
    result: {},
  },
  estimate: {
    isFetching: false,
    result: {},
  },
  categories: {
    isFetching: false,
    meta: { current_page: 1, total_pages: 0 },
    result: [],
  },
  filters: {
    isFetching: false,
    result: [],
  },
  timeLimits: {
    isFetching: false,
    result: [],
  },
}

export default function estimates(state = initialState, action) {
  return {
    ...state,
    tournament: tournament(state.tournament, action),
    estimate: estimate(state.estimate, action),
    categories: categories(state.categories, action),
    filters: filters(state.filters, action),
    timeLimits: timeLimits(state.timeLimits, action),
  }
}

export const categories = (categories, action) => {
  switch (action.type) {
    case CATEGORIES_REQUEST:
      return { ...categories, isFetching: true }
      break;
    case CATEGORIES_RESPONSE_SUCCESS:
      return {
        ...categories,
        isFetching: false,
        result: R.pipe(
          R.concat(categories.result),
          R.uniqBy(R.prop('id'))
        )(action.payload),
        meta: action.meta }
      break;
    case CATEGORIES_RESPONSE_FAIL:
      return new Error('Not implemented!')
      break;
    case HYDRATE_CATEGORIES:
      return { ...categories, isFetching: false, result: action.payload, meta: action.meta }
      break;
    default:
      return categories;
  }
}

