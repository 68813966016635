import { PropTypes, Component } from 'react';

const MatList = (WrappedComponent) => class extends Component {
  constructor(props) {
    super(props);

    this.state = { page: 0, left: 0 };

    $(document).on('keydown', this.processKeyDown.bind(this));
  }

  processKeyDown(e) {
    switch (e.key) {
      case 'ArrowRight':
        return this.advance(e);
      case 'ArrowLeft':
        return this.rewind(e);
      default:
        return;
    }
  }

  advance(ev) {
    if (matsCount <= 4) { return; }

    const { mats, matsCount, onChangePage } = this.props;
    const { page } = this.state;

    const currentPage = Math.abs(page) > matsCount - 4 ?  page : page - 1;

    this.setState({ page: currentPage, left: 420 * currentPage })

    onChangePage && onChangePage(currentPage * -1);

    ev.preventDefault();
  }

  rewind(ev) {
    if (matsCount <= 4) { return; }

    const { mats, matsCount, onChangePage } = this.props;
    const { page } = this.state;

    const currentPage = page >= 0 ? page : page + 1;

    this.setState({ page: currentPage, left: 420 * currentPage })

    onChangePage && onChangePage(currentPage * -1);

    ev.preventDefault();
  }

  render() {
    const { left } = this.state;

    const mats = this.props.mats.map((mat, i) => {
      return (
        <WrappedComponent
          key={i}
          mat={mat}
          slug={this.props.slug} />
      );
    })

    return (
      <div className="mats">
        <a href="#" className="mats__previous" onClick={this.rewind.bind(this)}>
          <i className="fa fa-chevron-left" />
        </a>
        <div className="mats__items">
          <div className="mats__cursor" style={{marginLeft: `${left}px`}}></div>
          {mats}
        </div>
        <a href="#" className="mats__next" onClick={this.advance.bind(this)}>
          <i className="fa fa-chevron-right" />
        </a>
      </div>
    );
  }
}

MatList.propTypes = {
  mats: PropTypes.array.isRequired,
}

export default MatList;
