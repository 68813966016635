import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import apiMiddleware from '../middleware/api_middleware';

import ScheduleContainer from './containers/schedule_container';
import { schedule } from './reducers';

const logger = createLogger();

const store = createStore(
  combineReducers({ app: schedule, form }),
  applyMiddleware(thunkMiddleware, apiMiddleware, logger)
);

class ScheduleApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ScheduleContainer tournamentId={this.props.tournament_id} />
      </Provider>
    )
  }
}

export default ScheduleApp;
