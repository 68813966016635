import React, { Component, PropTypes } from 'react';
import Podium from './podium';

import R from 'ramda';

export default function PodiumList(props) {

  const podiumListItems = R.addIndex(R.map)((podium, i) => {
    return (
      <Podium
        key={i}
        podium={podium}
        medalDeliveries={props.medalDeliveries}
        onCheck={props.checkPodium}
        onDeliverMedal={props.deliverMedal}
        onTimeout={props.onTimeout}
        onReprint={props.onReprint}
        />
    )
  })(props.podiums)

  return (
    <div className="podiums">
      {podiumListItems}
    </div>
  )
}
