import R from 'ramda';
import React, { Component, PropTypes } from 'react';

class OptionsBar extends Component {
  render() {
    const { children } = this.props;

    return(
      <div className="options-bar">
        <div className="options-bar__hide-mats">
          <ul className="list-unstyled list-inline">
            {
              R.addIndex(R.map)((c, i) =>
                <li key={i}>
                  {c}
                </li>, children)
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default OptionsBar;
