export const OPEN_CLASS_IDS = [10, 11, 12]
// Redux Actions

export const ACCEPT_MATCH = 'ACCEPT_MATCH';
export const ACCEPT_MATCHES = 'ACCEPT_MATCHES';
export const REORDER_MATCHES = 'REORDER_MATCHES';
export const HIGHLIGHT_MATCHES = 'HIGHLIGHT_MATCHES';
export const REMOVE_MATCH = 'REMOVE_MATCH';
export const REMOVE_MATCHES = 'REMOVE_MATCHES';

export const FILTER_CATEGORIES = 'FILTER_CATEGORIES';

export const CHANGE_DATE = 'CHANGE_DATE';

export const CHANGE_START_DATE = 'CHANGE_START_DATE';
export const CHANGE_END_DATE = 'CHANGE_END_DATE';
export const CHANGE_MATS_COUNT = 'CHANGE_MATS_COUNT';

export const TOURNAMENT_REQUEST = 'TOURNAMENT_REQUEST';
export const TOURNAMENT_RESPONSE_SUCCESS = 'TOURNAMENT_RESPONSE_SUCCESS';
export const TOURNAMENT_RESPONSE_FAIL = 'TOURNAMENT_RESPONSE_FAIL';

export const MATS_REQUEST = 'MATS_REQUEST';
export const MATS_RESPONSE_SUCCESS = 'MATS_RESPONSE_SUCCESS';
export const MATS_RESPONSE_FAIL = 'MATS_RESPONSE_FAIL';

export const MAT_REQUEST = 'MATS_REQUEST';
export const MAT_RESPONSE_SUCCESS = 'MATS_RESPONSE_SUCCESS';
export const MAT_RESPONSE_FAIL = 'MATS_RESPONSE_FAIL';

export const MATCHES_REQUEST = 'MATCHES_REQUEST';
export const MATCHES_RESPONSE_SUCCESS = 'MATCHES_RESPONSE_SUCCESS';
export const MATCHES_RESPONSE_FAIL = 'MATCHES_RESPONSE_FAIL';

export const MEDAL_DELIVERIES_REQUEST = 'MEDAL_DELIVERIES_REQUEST';
export const MEDAL_DELIVERIES_RESPONSE_SUCCESS = 'MEDAL_DELIVERIES_RESPONSE_SUCCESS';
export const MEDAL_DELIVERIES_RESPONSE_FAIL = 'MEDAL_DELIVERIES_RESPONSE_FAIL';

export const RELOAD_MATCH = 'RELOAD_MATCH';
export const RELOAD_PODIUMS = 'RELOAD_PODIUMS';
export const RELOAD_PODIUM = 'RELOAD_PODIUM';

export const MATCH_REQUEST = 'MATCH_REQUEST';
export const MATCH_RESPONSE_SUCCESS = 'MATCH_RESPONSE_SUCCESS';
export const MATCH_RESPONSE_FAIL = 'MATCH_RESPONSE_FAIL';

export const UPDATE_MATCH_REQUEST = 'UPDATE_MATCH_REQUEST';
export const UPDATE_MATCH_RESPONSE_SUCCESS = 'UPDATE_MATCH_RESPONSE_SUCCESS';
export const UPDATE_MATCH_RESPONSE_FAIL = 'UPDATE_MATCH_RESPONSE_FAIL';

export const PODIUMS_REQUEST = 'PODIUMS_REQUEST';
export const PODIUMS_RESPONSE_SUCCESS = 'PODIUMS_RESPONSE_SUCCESS';
export const PODIUMS_RESPONSE_FAIL = 'PODIUMS_RESPONSE_FAIL';

export const UPDATE_PODIUM_REQUEST = 'UPDATE_PODIUM_REQUEST';
export const UPDATE_PODIUM_RESPONSE_SUCCESS = 'UPDATE_PODIUM_RESPONSE_SUCCESS';
export const UPDATE_PODIUM_RESPONSE_FAIL = 'UPDATE_PODIUM_RESPONSE_FAIL';

export const UPDATE_MEDAL_REQUEST = 'UPDATE_MEDAL_REQUEST';
export const UPDATE_MEDAL_RESPONSE_SUCCESS = 'UPDATE_MEDAL_RESPONSE_SUCCESS';
export const UPDATE_MEDAL_RESPONSE_FAIL = 'UPDATE_MEDAL_RESPONSE_FAIL';

export const UPDATE_BRACKET_REQUEST = 'UPDATE_BRACKET_REQUEST';
export const UPDATE_BRACKET_RESPONSE_SUCCESS = 'UPDATE_BRACKET_RESPONSE_SUCCESS';
export const UPDATE_BRACKET_RESPONSE_FAIL = 'UPDATE_BRACKET_RESPONSE_FAIL';

export const MATCH_DECISIONS_REQUEST = 'MATCH_DECISIONS_REQUEST';
export const MATCH_DECISIONS_RESPONSE_SUCCESS = 'MATCH_DECISIONS_RESPONSE_SUCCESS';
export const MATCH_DECISIONS_RESPONSE_FAIL = 'MATCH_DECISIONS_RESPONSE_FAIL';

export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_RESPONSE_SUCCESS = 'CATEGORIES_RESPONSE_SUCCESS';
export const CATEGORIES_RESPONSE_FAIL = 'CATEGORIES_RESPONSE_FAIL';

export const AGE_DIVISIONS_REQUEST = 'AGE_DIVISIONS_REQUEST';
export const AGE_DIVISIONS_RESPONSE_SUCCESS = 'AGE_DIVISIONS_RESPONSE_SUCCESS';
export const AGE_DIVISIONS_RESPONSE_FAIL = 'AGE_DIVISIONS_RESPONSE_FAIL';

export const WEIGHT_DIVISIONS_REQUEST = 'WEIGHT_DIVISIONS_REQUEST';
export const WEIGHT_DIVISIONS_RESPONSE_SUCCESS = 'WEIGHT_DIVISIONS_RESPONSE_SUCCESS';
export const WEIGHT_DIVISIONS_RESPONSE_FAIL = 'WEIGHT_DIVISIONS_RESPONSE_FAIL';

export const TIME_LIMITS_REQUEST = 'TIME_LIMITS_REQUEST';
export const TIME_LIMITS_RESPONSE_SUCCESS = 'TIME_LIMITS_RESPONSE_SUCCESS';
export const TIME_LIMITS_RESPONSE_FAIL = 'TIME_LIMITS_RESPONSE_FAIL';

export const GENDERS_REQUEST = 'GENDERS_REQUEST';
export const GENDERS_RESPONSE_SUCCESS = 'GENDERS_RESPONSE_SUCCESS';
export const GENDERS_RESPONSE_FAIL = 'GENDERS_RESPONSE_FAIL';

export const BELTS_REQUEST = 'BELTS_REQUEST';
export const BELTS_RESPONSE_SUCCESS = 'BELTS_RESPONSE_SUCCESS';
export const BELTS_RESPONSE_FAIL = 'BELTS_RESPONSE_FAIL';

export const ESTIMATE_REQUEST = 'ESTIMATE_REQUEST';
export const ESTIMATE_RESPONSE_SUCCESS = 'ESTIMATE_RESPONSE_SUCCESS';
export const ESTIMATE_RESPONSE_FAIL = 'ESTIMATE_RESPONSE_FAIL';

export const UPDATE_MAT_REQUEST = 'UPDATE_MAT_REQUEST';
export const UPDATE_MAT_RESPONSE_SUCCESS = 'UPDATE_MAT_RESPONSE_SUCCESS';
export const UPDATE_MAT_RESPONSE_FAIL = 'UPDATE_MAT_RESPONSE_FAIL';

export const UPDATE_ESTIMATE_DAY_REQUEST = 'UPDATE_ESTIMATE_DAY_REQUEST';
export const UPDATE_ESTIMATE_DAY_RESPONSE_SUCCESS = 'UPDATE_ESTIMATE_DAY_RESPONSE_SUCCESS';
export const UPDATE_ESTIMATE_DAY_RESPONSE_FAIL = 'UPDATE_ESTIMATE_DAY_RESPONSE_FAIL';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_RESPONSE_SUCCESS = 'UPDATE_CATEGORY_RESPONSE_SUCCESS';
export const UPDATE_CATEGORY_RESPONSE_FAIL = 'UPDATE_CATEGORY_RESPONSE_FAIL';

export const UPDATE_CATEGORIES_REQUEST = 'UPDATE_CATEGORIES_REQUEST';
export const UPDATE_CATEGORIES_RESPONSE_SUCCESS = 'UPDATE_CATEGORIES_RESPONSE_SUCCESS';
export const UPDATE_CATEGORIES_RESPONSE_FAIL = 'UPDATE_CATEGORIES_RESPONSE_FAIL';

export const TOURNAMENT_SUBSCRIPTIONS_REQUEST = 'TOURNAMENT_SUBSCRIPTIONS_REQUEST';
export const TOURNAMENT_SUBSCRIPTIONS_RESPONSE_SUCCESS = 'TOURNAMENT_SUBSCRIPTIONS_RESPONSE_SUCCESS';
export const TOURNAMENT_SUBSCRIPTIONS_RESPONSE_FAIL = 'TOURNAMENT_SUBSCRIPTIONS_RESPONSE_FAIL';

export const CREATE_TOURNAMENT_SUBSCRIPTION_REQUEST = 'CREATE_TOURNAMENT_SUBSCRIPTION_REQUEST';
export const CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS = 'CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS';
export const CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL = 'CREATE_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL';

export const DESTROY_TOURNAMENT_SUBSCRIPTION_REQUEST = 'DESTROY_TOURNAMENT_SUBSCRIPTION_REQUEST';
export const DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS = 'DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_SUCCESS';
export const DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL = 'DESTROY_TOURNAMENT_SUBSCRIPTION_RESPONSE_FAIL';

export const OPC_UNSUBSCRIPTION_HISTORY_REQUEST = 'OPC_UNSUBSCRIPTION_HISTORY_REQUEST';
export const OPC_UNSUBSCRIPTION_HISTORY_RESPONSE_SUCCESS = 'OPC_UNSUBSCRIPTION_HISTORY_RESPONSE_SUCCESS';
export const OPC_UNSUBSCRIPTION_HISTORY_FAIL = 'OPC_UNSUBSCRIPTION_HISTORY_FAIL';

export const FILTERS_REQUEST = 'FILTERS_REQUEST';
export const FILTERS_RESPONSE_SUCCESS = 'FILTERS_RESPONSE_SUCCESS';
export const FILTERS_RESPONSE_FAIL = 'FILTERS_RESPONSE_FAIL';

export const DISQUALIFICATIONS_REQUEST = 'DISQUALIFICATIONS_REQUEST';
export const DISQUALIFICATIONS_RESPONSE_SUCCESS = 'DISQUALIFICATIONS_RESPONSE_SUCCESS';
export const DISQUALIFICATIONS_RESPONSE_FAIL = 'DISQUALIFICATIONS_RESPONSE_FAIL';

export const COMPETITORS_REQUEST = 'COMPETITORS_REQUEST';
export const COMPETITORS_RESPONSE_SUCCESS = 'COMPETITORS_RESPONSE_SUCCESS';
export const COMPETITORS_RESPONSE_FAIL = 'COMPETITORS_RESPONSE_FAIL';

export const TOURNAMENT_TEMPLATES_REQUEST = 'TOURNAMENT_TEMPLATES_REQUEST';
export const TOURNAMENT_TEMPLATES_RESPONSE_SUCCESS = 'TOURNAMENT_TEMPLATES_RESPONSE_SUCCESS';
export const TOURNAMENT_TEMPLATES_RESPONSE_FAIL = 'TOURNAMENT_TEMPLATES_RESPONSE_FAIL';

export const CREATE_TOURNAMENT_TEMPLATE_REQUEST = 'CREATE_TOURNAMENT_TEMPLATE_REQUEST';
export const CREATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS = 'CREATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS';
export const CREATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL = 'CREATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL';

export const DUPLICATE_TOURNAMENT_TEMPLATE_REQUEST = 'DUPLICATE_TOURNAMENT_TEMPLATE_REQUEST';
export const DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS = 'DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS';
export const DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL = 'DUPLICATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL';

export const UPDATE_TOURNAMENT_REQUEST = 'UPDATE_TOURNAMENT_REQUEST';
export const UPDATE_TOURNAMENT_RESPONSE_SUCCESS = 'UPDATE_TOURNAMENT_RESPONSE_SUCCESS';
export const UPDATE_TOURNAMENT_RESPONSE_FAIL = 'UPDATE_TOURNAMENT_RESPONSE_FAIL';

export const UPDATE_TOURNAMENT_TEMPLATE_REQUEST = 'UPDATE_TOURNAMENT_TEMPLATE_REQUEST';
export const UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS = 'UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS';
export const UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL = 'UPDATE_TOURNAMENT_TEMPLATE_RESPONSE_FAIL';

export const DESTROY_TOURNAMENT_TEMPLATE_REQUEST = 'DESTROY_TOURNAMENT_TEMPLATE_REQUEST';
export const DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS = 'DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_SUCCESS';
export const DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_FAIL = 'DESTROY_TOURNAMENT_TEMPLATE_RESPONSE_FAIL';

export const CATEGORY_GROUPS_REQUEST = 'CATEGORY_GROUPS_REQUEST';
export const CATEGORY_GROUPS_RESPONSE_SUCCESS = 'CATEGORY_GROUPS_RESPONSE_SUCCESS';
export const CATEGORY_GROUPS_RESPONSE_FAIL = 'CATEGORY_GROUPS_RESPONSE_FAIL';

export const CREATE_CATEGORY_GROUP_REQUEST = 'CREATE_CATEGORY_GROUP_REQUEST';
export const CREATE_CATEGORY_GROUP_RESPONSE_SUCCESS = 'CREATE_CATEGORY_GROUP_RESPONSE_SUCCESS';
export const CREATE_CATEGORY_GROUP_RESPONSE_FAIL = 'CREATE_CATEGORY_GROUP_RESPONSE_FAIL';

export const UPDATE_CATEGORY_GROUP_REQUEST = 'UPDATE_CATEGORY_GROUP_REQUEST';
export const UPDATE_CATEGORY_GROUP_RESPONSE_SUCCESS = 'UPDATE_CATEGORY_GROUP_RESPONSE_SUCCESS';
export const UPDATE_CATEGORY_GROUP_RESPONSE_FAIL = 'UPDATE_CATEGORY_GROUP_RESPONSE_FAIL';

export const DESTROY_CATEGORY_GROUP_REQUEST = 'DESTROY_CATEGORY_GROUP_REQUEST';
export const DESTROY_CATEGORY_GROUP_RESPONSE_SUCCESS = 'DESTROY_CATEGORY_GROUP_RESPONSE_SUCCESS';
export const DESTROY_CATEGORY_GROUP_RESPONSE_FAIL = 'DESTROY_CATEGORY_GROUP_RESPONSE_FAIL';

export const CACHE_OPC_RANKS_REQUEST = 'CACHE_OPC_RANKS_REQUEST';
export const CACHE_OPC_RANKS_RESPONSE_SUCCESS = 'CACHE_OPC_RANKS_RESPONSE_SUCCESS';
export const CACHE_OPC_RANKS_RESPONSE_FAIL = 'CACHE_OPC_RANKS_RESPONSE_FAIL';

export const UPDATE_TOURNAMENT_DAY_REQUEST = 'UPDATE_TOURNAMENT_DAY_REQUEST';
export const UPDATE_TOURNAMENT_DAY_RESPONSE_SUCCESS = 'UPDATE_TOURNAMENT_DAY_RESPONSE_SUCCESS';
export const UPDATE_TOURNAMENT_DAY_RESPONSE_FAIL = 'UPDATE_TOURNAMENT_DAY_RESPONSE_FAIL';

export const SELECT_TOURNAMENT_TEMPLATE = 'SELECT_TOURNAMENT_TEMPLATE';

export const CHANGE_FILTER = 'CHANGE_FILTER';

export const CALL_API = 'CALL_API';

export const HYDRATE_CATEGORIES = 'HYDRATE_CATEGORIES';

export const HIDE_MATS = 'HIDE_MATS';
export const TOGGLE_HIGHLIGHT = "TOGGLE_HIGHLIGHT";

export const UPDATE_SCHEDULE_SETUP = "UPDATE_SCHEDULE_SETUP";

export const PRINT_FIGHT_ORDER_REQUEST          = 'PRINT_FIGHT_ORDER_REQUEST';
export const PRINT_FIGHT_ORDER_RESPONSE_SUCCESS = 'PRINT_FIGHT_ORDER_RESPONSE_SUCCESS';
export const PRINT_FIGHT_ORDER_RESPONSE_FAIL    = 'PRINT_FIGHT_ORDER_RESPONSE_FAIL';

export const DESTROY_ALL_ORDERED_ITEMS_REQUEST = 'DESTROY_ALL_ORDERED_ITEMS_REQUEST';
export const DESTROY_ALL_ORDERED_ITEMS_RESPONSE_SUCCESS = 'DESTROY_ALL_ORDERED_ITEMS_RESPONSE_SUCCESS';
export const DESTROY_ALL_ORDERED_ITEMS_RESPONSE_FAIL    = 'DESTROY_ALL_ORDERED_ITEMS_RESPONSE_FAIL';

export const INSERT_GAP = 'INSERT_GAP';
export const EDIT_GAP = 'EDIT_GAP';
export const UNORDER_GAP = 'UNORDER_GAP';
export const HIDE_GAP_MODAL = 'HIDE_GAP_MODAL';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const FETCH_MODAL_MATCH = 'FETCH_MODAL_MATCH';
export const FETCH_OVERLAY_MATCH = 'FETCH_OVERLAY_MATCH';

export const START_MATCH = 'START_MATCH';
export const PAUSE_MATCH = 'PAUSE_MATCH';
export const SWITCH_COMPETITOR_COLORS = 'SWITCH_OMPETITOR_COLORS';
export const RESET_COMPETITOR_COLORS = 'RESET_OMPETITOR_COLORS';
export const SCORE_POINTS = 'SCORE_POINTS';
export const SCORE_ADVANTAGE = 'SCORE_ADVANTAGE';
export const SCORE_FOUL = 'SCORE_FOUL';
export const SET_VICTORY = 'SET_VICTORY';
export const SET_TIMER = 'SET_TIMER';
export const OPEN_VICTORY_MODAL = 'OPEN_VICTORY_MODAL';
export const CLOSE_VICTORY_MODAL = 'CLOSE_VICTORY_MODAL';
export const TOGGLE_POPOUT = 'TOGGLE_POPOUT';
export const SELECT_MATCH = 'SELECT_MATCH';
export const RELOAD_MAT = 'RELOAD_MAT';
export const PROCESS_NOTIFICATIONS = 'PROCESS_NOTIFICATIONS';
export const SHOW_STAND_BY = 'SHOW_STAND_BY';
export const HIDE_STAND_BY = 'HIDE_STAND_BY';

export const DELIVER_MEDAL_TO_COMPETITOR = 'DELIVER_MEDAL_TO_COMPETITOR';
export const UNDO_RESULT = 'UNDO_RESULT';
export const DELIVER_MEDAL_TO_REPRESENTATIVE = 'DELIVER_MEDAL_TO_REPRESENTATIVE';
export const REPORT_COMPETITOR_NOT_FOUND = 'REPORT_COMPETITOR_NOT_FOUND';
export const CHECK_PODIUM = 'CHECK_PODIUM';
export const CHANGE_PODIUM_FILTER = 'CHANGE_PODIUM_FILTER';

// React Drag n Drop ItemTypes

export const MATCH = 'MATCH';
export const ORDERED_MATCH = 'ORDERED_MATCH';
export const ROUND = 'ROUND';
export const CATEGORY_GROUP = 'CATEGORY_GROUP';
export const CATEGORY = 'CATEGORY';

// Drag and Crazy Drop

export const SELECT_MATCHES = 'SELECT_MATCHES';
export const UNSELECT_MATCHES = 'UNSELECT_MATCHES';

// Context Menu Identifiers
export const MATCH_COMPETITOR = 'MATCH_COMPETITOR';
export const GAP = 'GAP';

// Match Grouping Switch
export const CHANGE_MATCH_GROUPING = 'CHANGE_MATCH_GROUPING';
export const PHASE = 'PHASE';
export const PAGE = 'PAGE';

export const CHANGE_MERGE_OPTIONS = 'CHANGE_MERGE_OPTIONS';
export const MERGE_ALL_MATS = 'MERGE_ALL_MATS';

// Notifications
export const CHANGE_NOTIFICATIONS = 'CHANGE_NOTIFICATIONS';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

// Categories pagination
export const RESET_PAGE = 'RESET_PAGE';
export const ADVANCE_PAGE = 'ADVANCE_PAGE';
export const REWIND_PAGE = 'REWIND_PAGE';

// Medal Deliveries
export const MEDAL_DELIVERED = 1;
export const MEDAL_DELIVERED_TO_REPRESENTATIVE = 2;
export const MEDAL_COMPETITOR_NOT_FOUND = 3;
