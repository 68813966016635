import { PropTypes } from 'react';
import moment from 'moment-timezone';

function DateSwitch(props) {
  return (
    <div className="date-switch">
      <div className="date-switch__previous">
        <a href="#" onClick={props.onRewindDate}><i className="fa fa-lg fa-chevron-circle-left"/></a>
      </div>
      <div className="date-switch__current">
        <span className="date-switch__current-date">{moment(props.tournamentDay.date).format('dddd, DD MMM')}</span>
      </div>
      <div className="date-switch__next">
        <a href="#" onClick={props.onAdvanceDate}><i className="fa fa-lg fa-chevron-circle-right"/></a>
      </div>
    </div>
  );
}

DateSwitch.propTypes = {
  tournamentDay: PropTypes.object,
  onAdvanceDate: PropTypes.func,
  onRewindDate: PropTypes.func,
}

DateSwitch.defaultProps = {
  tournamentDay: {}
}

export default DateSwitch;
