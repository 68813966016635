import { PropTypes } from 'react';
import moment from "moment";
import { ContextMenuTrigger } from 'react-contextmenu';
import { ORDERED_MATCH } from '../../shared/constants';
import R from 'ramda';
import MatchState from './match_state';

function collect(params) {
  const {
    attributes
  } = params;

  return {
    matchId: attributes.matchId,
    categoryId: attributes.categoryId,
  }
}

function MatchDisplay(props) {
  const {
    selected,
    highlighted,
    switchColor,

    order,
    categoryAbbr,
    phase,
    n,
    page,
    totalPages,
    currentState,
    disciplinaryDisqualification,
  } = props;


  return (
    <div className={`match-display ${selected ? 'match-display--selected' : ''} ${highlighted ? `match-display--${highlightedClass(phase)}` : ''} ${switchColor && 'match-display--switch-color'}`}>
      <ContextMenuTrigger holdToDisplay={-1} id={ORDERED_MATCH} collect={collect} attributes={props}>
        <div className="match-display__content">
          {!R.isNil(order) && <span title={"Order on mat"} className="match-display__order">{order + 1}</span>}
          {disciplinaryDisqualification && <span className="match-display__disciplinary-disqualification"><i className="fa fa-exclamation"/></span> }
          {categoryAbbr && <span className="match-display__category">{categoryAbbr}</span>}
          {phase && <span title={"Phase in the bracket"} className="match-display__phase">{phase}</span>}
          <span title={"Match number in the bracket"} className="match-display__n">{n}</span>
          {page && <span title={"Bracket Page / Total pages in the bracket"} className="match-display__pagination">{`${page}/${totalPages}`}</span>}
          <span><MatchState state={currentState}/></span>
          {props.children}
        </div>
      </ContextMenuTrigger>
    </div>
  )
}

MatchDisplay.propTypes = {
  order: PropTypes.number,
  highlighted: PropTypes.bool,
  selected: PropTypes.bool,

  categoryAbbr: PropTypes.string,
  categoryName: PropTypes.string,
  categoryId: PropTypes.number,
  phase: PropTypes.string,
  n: PropTypes.number,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  currentState: PropTypes.string,
}

export default MatchDisplay;
