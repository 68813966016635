import moment from 'moment-timezone';
import { roundLabelHumanized } from '../../modules/round_label';
import { lensMatching } from '../../modules/lens_matching';
import { Modal, Button } from 'react-bootstrap';
import { Component, PropTypes } from 'react';
import ModalPodium from './modal_podium';
import MatchForm from './match_form';
import PodiumForm from './podium_form';
import MatchResultForm from './match_result_form';
import MatchSchedule from './match_schedule';
import CompetitorActions from './competitor_actions';
import MatchState from './match_state';
import R from 'ramda';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  childMatchLabel,
  isMatchFinished,
  isDisqualified
} from '../../modules/match_lenses';

import {
  checkin,
  undoCheckin,
  reportNoShow,
  undoNoShow,
  weight,
  undoWeight,
  disqualify,
  undoDisqualify,
  finish,
  reset,
} from '../../modules/competitor_action_params';

class MatchModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matchCompetitorId: null,
      matchDecisionId: null,
      showPodiumForm: false,
      currentState: 'show'
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!R.isNil(nextProps.match.competitors)) {
      const matchDecision = nextProps.matchDecisions[0]

      this.setState({
        matchDecisionId: matchDecision ? matchDecision.id : null,
      })
    }
  }

  handleChange(ev) {
    this.setState(R.assoc(ev.target.name, ev.target.value, this.state));
  }

  submitMatchResult(e) {
    const { match, updateMatch, disqualifications, matchDecisions } = this.props;
    const { matchDecisionId, matchCompetitorId } = this.state;

    const matchDecision =
      R.find(R.propEq('id', parseInt(matchDecisionId, 10)), matchDecisions);

    const disqualificationId = matchDecision.disqualification ?
      R.find(R.propEq('slug', matchDecision.slug), disqualifications).id :
      null;

    updateMatch(finish({
      id: match.id,
      match_decision_id: matchDecisionId,
      start_time: Date.now(),
      end_time: Date.now(),
      match_competitors_attributes: R.map((c) => {
        const winner = c.match_competitor_id === parseInt(matchCompetitorId, 10);

        return {
          id: c.match_competitor_id,
          winner: winner,
          disqualification_id: winner ? null : disqualificationId,
        }
      })(match.competitors)
    }))
  }


  matchCompetitors(match) {
    return R.pipe(
      R.addIndex(R.map)(
        R.ifElse(
          (c) => c.kind === 'match',
          this.child.bind(this),
          this.matchCompetitor.bind(this)
        )
      )
    )(match.competitors)
  }

  child(child, i) {
    return (
      <tr key={i}>
        <td className="match-modal__competitor-state match-modal__child-state"></td>
        <td className="match-modal__competitor">
          {childMatchLabel(child)}
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    )
  }

  matchCompetitor(c, i) {
    const { match, disqualifications, updateMatch } = this.props;

    const disqualificationTooltip = isDisqualified(c) ?
      <Tooltip>
        {c.disqualification}
      </Tooltip> :
      <span/>;

    return (
      <tr key={i}>
        <td className={`match-modal__competitor-state match-competitor--${c.state}`}>
          <OverlayTrigger placement={"left"} overlay={disqualificationTooltip} rootClose={true}>
            <span>{c.state}</span>
          </OverlayTrigger>
        </td>
        <td className="match-modal__competitor">
          <span className="match-modal__competitor-name">{c.name}</span>
          <br/>
          <span className="match-modal__club-name">{c.club}</span>
        </td>
        <td className="match-modal__checkin">{this.checkin(c)}</td>
        <td className="match-modal__weight">{this.weighing(c)}</td>
        <td className="match-modal__actions">
          <CompetitorActions
            competitor={c}
            match={match}
            disqualifications={disqualifications}
            updateMatch={updateMatch}
          />
        </td>
      </tr>
    )
  }

  checkin(competitor) {
    return competitor.checked_in_at ?
      <span>{moment.parseZone(competitor.checked_in_at).format('ddd HH:MM')}</span> :
      <span>-</span>
  }

  weighing(competitor) {
    return competitor.weighing_id ?
      <span>{moment.parseZone(competitor.weighted_at).format('ddd HH:MM')}</span> :
      <span>-</span>;
  }

  finishMatchForm(match, matchDecisions) {

    // return (
    //   <MatchResultForm
    //     initialValues={match}
    //     matchDecisions={matchDecisions}
    //     competitors={match.competitors}
    //     onSubmit={this.submitMatchResult.bind(this)}
    //   />
    // )

    switch (match.state) {
      case "weighted":
      case "started":
        return (
          <div>
            <h4>Set Result</h4>
            <div className="form-horizontal">
              <div className="form-group">
                <label className="col-md-2 control-label">Match Decision</label>
                <div className="col-md-4">
                  {this.matchDecisionsSelect(matchDecisions)}
                </div>
                <label className="col-md-2 control-label">Winner</label>
                <div className="col-md-4">
                  {this.matchCompetitorsSelect(match)}
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-2">
                  <button href="#" onClick={this.submitMatchResult.bind(this)} className="btn btn-default btn-raised">Submit</button>
                </div>
              </div>
            </div>
          </div>
        )
      case "finished":
        return (
          <button
            onClick={() => this.props.updateMatch(reset(match.id))}
            className="btn btn-default btn-sm">Undo Result</button>
        )
      default:
        return;
    }
  }

  matchDecisionsSelect(matchDecisions) {
    return (
      <select name="matchDecisionId"
        onChange={this.handleChange.bind(this)}
        className="form-control">
          {R.addIndex(R.map)((m, i) => <option key={i} value={m.id}>{m.name}</option>)(matchDecisions)}
      </select>
    )
  }

  matchCompetitorsSelect(match) {
    if (R.isNil(match.competitors)) {
      return;
    }

    const options = R.addIndex(R.map)((c, i) =>
      <option key={i} value={c.match_competitor_id}>{c.name}</option>
    )(match.competitors)

    return (
      <select name="matchCompetitorId"
        onChange={this.handleChange.bind(this)}
        className="form-control">
        <option val="" selected="true"></option>
        {options}
      </select>
    )
  }

  onClickEditMatchBtn() {
    const { fetchCategoryCompetitors, match } = this.props;

    fetchCategoryCompetitors(match.category_id);

    this.setState({ showMatchForm: true });
  }

  onClickEditPodiumBtn() {
    const { fetchCategoryCompetitors, match } = this.props;

    fetchCategoryCompetitors(match.category_id);

    this.setState({ showPodiumForm: true });
  }

  onCancelPodiumForm() {
    this.setState({ showPodiumForm: false })
  }

  onSubmitPodiumForm(data) {
    const { submitPodiumForm, match } = this.props;

    submitPodiumForm(match.id, R.pipe(
      R.assoc('medals_attributes', data.medals),
      R.dissoc('medals')
    )(data))

    this.setState({ showPodiumForm: false })
  }

  onSubmitMatchForm(data) {
    this.props.submitMatchForm(data)

    this.setState({ showMatchForm: false });
  }

  onCancelMatchForm() {
    this.setState({ showMatchForm: false })
  }

  onClose() {
    const { close } = this.props;

    this.setState({ showMatchForm: false });

    this.props.close();
  }

  renderBody(match, competitors) {
    return this.state.showMatchForm ?
      this.renderModalForm(match, competitors) :
      this.renderShowBody(match, competitors);
  }

  renderModalForm(match, competitors) {
    const matchData = {
      id: match.id,
      category_id: match.category_id,
      competitors: R.map((c) => ({
        competitor_id: c.id,
        match_competitor_id: c.match_competitor_id
      }), match.competitors)
    }

    return <MatchForm
      initialValues={matchData}
      competitors={competitors}
      onSubmit={this.onSubmitMatchForm.bind(this)}
      onCancel={this.onCancelMatchForm.bind(this)}
      />
  }

  renderShowBody(match, competitors) {
    const { matchDecisions } = this.props;
    const { showPodiumForm } = this.state;

    const matchDecision = R.find(R.propEq('id', match.match_decision_id))(matchDecisions)

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="match-modal__competitors">
              <table className="">
                <thead>
                  <tr>
                    <th>
                      {
                        !isMatchFinished(match) &&
                        <a
                          href="#"
                          onClick={this.onClickEditMatchBtn.bind(this)}
                          className="match-modal__edit-match">
                          <i className="fa fa-pencil"></i>
                        </a>
                      }
                    </th>
                    <th></th>
                    <th className="match-modal__checkin"><i className="fa fa-user" title="Checkin Time"></i></th>
                    <th className="match-modal__weight"><i className="fa fa-weight" title="Weight Time"></i></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.matchCompetitors(match)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.finishMatchForm(match, matchDecisions)}
        {
          match.podium && (
          <div className="modal-podium">
            <h4>
              Podium
              <a href="#"
                onClick={this.onClickEditPodiumBtn.bind(this)}
                className="match-modal__edit-podium">
                <i className="fa fa-pencil"></i>
              </a>
            </h4>
            {
              showPodiumForm ?
                <PodiumForm
                  initialValues={match.podium}
                  competitors={competitors}
                  onCancel={this.onCancelPodiumForm.bind(this)}
                  onSubmit={this.onSubmitPodiumForm.bind(this)}
                  /> :
                <ModalPodium podium={match.podium}/>
            }
          </div>)
        }
      </div>
    );
  }

  render() {
    const { match, competitors, show, updateMatch } = this.props;

    return (
      <Modal show={show} onHide={this.onClose.bind(this)} className="modal match-modal">
        <Modal.Header closeButton>
          <span className="match-modal__debug-infos">match={match.id} category={match.category_id}</span>
          <h4 className="match-modal__title">{match.category_name}</h4>
          <h4 className="match-modal__subtitle">
            {roundLabelHumanized(match.phase)}
            <MatchState state={match.state}/>
          </h4>
          { match.is_resolution &&
          <span className="label label-default">resolution</span>}
        </Modal.Header>
        <Modal.Body className="match-modal__content">
          <div className="row">
            <div className="col-md-12">
              <MatchSchedule match={match}/>
            </div>
          </div>
          {this.renderBody(match, competitors)}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    )
  }
}

MatchModal.propTypes = {
  match: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  tournamentId: PropTypes.number.isRequired,

  close: PropTypes.func.isRequired,
  updateMatch: PropTypes.func.isRequired,
}

MatchModal.defaultProps = {
  match: { competitors: [] },
  show: false
}

export default MatchModal;
