import R from 'ramda';

function roundPrefix(depth) {
  switch (depth) {
    case 'F':
      return 'final';
      break;
    case 'SF':
      return 'semi-final';
      break;
    case 'QF':
      return 'quarter';
      break;
    default:
      return 'elim';
  }
}

function matchedRoundPhase(phase, hashObject, options = {}) {
  const opt = R.merge({ noSufix: false, space: true }, options);

  const matches = /([A-Z]+)([^A-Z]+)?/g.exec(phase || "") || [];

  const prefix = hashObject[roundPrefix(matches[1])];
  const space = opt.space && !opt.noSufix && matches[2] ? " " : "";
  const sufix = opt.noSufix ? "" : (matches[2] || "");

  return prefix + space + sufix;
}

export function roundLabel(phase, options) {
  return matchedRoundPhase(phase, {
    'final' : 'final',
    'semi-final' : 'semi-final',
    'quarter' : 'quarter',
    'elim' : 'elim',
  }, options)
}

export function roundLabelHumanized(phase) {
  return matchedRoundPhase(phase, {
    'final' : 'Final',
    'semi-final' : 'Semi-final',
    'quarter' : 'Quarter-final',
    'elim' : 'Qualifier',
  })
}

export function roundLabelAbbrev(phase) {
  return phase;
}

