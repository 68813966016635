import R from 'ramda';
import React, { PropTypes } from 'react';
import { connect } from "react-redux";
import moment from 'moment-timezone';
import { Modal, Button } from 'react-bootstrap';

class OpcUnsubscriptionHistoryDialog extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.forceUpdate()
    }
  }

  renderUnsubscription(unsubscription) {
    return (
      <li key={`unsubscription-${unsubscription.id}`}>
        <p>{ unsubscription.reason }</p>

        <p>
          <strong>observation</strong>
          <span>{ unsubscription.observation || ' - ' }</span>
        </p>

        <p>
          <strong>Admin / Operator</strong>
          <span>{ unsubscription.author }</span>
        </p>

        <p>
          <strong>Date</strong>
          <span>{ moment.parseZone(unsubscription.created_at).format('YYYY-MM-DD HH:mm') }</span>
        </p>
      </li>
    )
  }

  render() {
    const data = this.props.data || []

    return(
      <Modal className="opc-unsubscription-history-dialog" show={this.props.show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <small>Open Class Unsubscription History</small>
            { this.props.competitorName }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          { !this.props.isFetching && !R.isEmpty(data) && (
            <ul>
              { data.map((unsubscription) => this.renderUnsubscription(unsubscription)) }
            </ul>
          )}

          { !this.props.isFetching && R.isEmpty(data) && (
            <div className="opc-unsubscription-history-dialog__no-records-title">No Records</div>
          )}

          { this.props.isFetching && (
            <div className="opc-unsubscription-history-dialog__fetching-title">fetching...</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="default" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

OpcUnsubscriptionHistoryDialog.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.array,
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.app.unsubscriptionHistory.result,
    isFetching: state.app.unsubscriptionHistory.isFetching
  };
}

export default connect(mapStateToProps)(OpcUnsubscriptionHistoryDialog);