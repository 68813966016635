let helperInput;
import { Component } from 'react';

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = { checked: props.checked };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ checked: newProps.checked })
  }

  toggle (ev) {
    this.setState({ checked: ev.target.checked });
  }

  render () {
    const { name, checked, label } = this.props;
    const _checked = this.state.checked;

    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            name={name}
            checked={_checked}
            onChange={this.toggle.bind(this)}
            />
          <input
            type="hidden"
            name={name}
            value={_checked ? "on" : ""}
            />
          {label}
        </label>
      </div>
    )
  }
}

export default Checkbox;
