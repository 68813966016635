import R from 'ramda';
import { PropTypes, Component } from 'react';

class Notifications extends Component {
  categoryNewFights(notifications) {
    const { setCategoryFilter } = this.props;

    return R.addIndex(R.map)((n, i) => {
      return (
        <div key={i} className="alert alert-danger alert-dismissable">
          <button type="button" className="close" aria-label="Close" onClick={() => this.props.dismissNotification('categoryNewFights', i)}>
            <span aria-hidden="true">&times;</span>
          </button>
          <span>
            <div className="notifications__body">
              <i className="fa fa-info-circle"></i>
              {`You have new fights to order for ${n.category_name}`}
            </div>
            <div className="notifications__actions">
              <button className="btn btn-default" onClick={() => setCategoryFilter(n.category_id)}>Show Category</button>
            </div>
          </span>
        </div>
      )
    }, notifications)
  }

  renderNotifications(notifications) {
    return (
      R.pipe(
        R.mapObjIndexed((value, key, _) => this[`${key}`](value)),
        R.values
      )(notifications)
    )
  }

  render() {
    const {
      notifications
    } = this.props;

    return (
      <div className="notifications">
        {
          R.isEmpty(notifications) ?
            "No notifications" :
            this.renderNotifications(notifications)
        }
      </div>
    )
  }
}

export default Notifications;

Notifications.defaultProps = {
  notifications: []
}
