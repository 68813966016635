import React, { Component } from 'react';
import R from 'ramda';
import Select from 'react-select';

const TournamentTemplateSelect = (props) => {

  const options = R.map((option) => ({
    value: option.id,
    label: option.name,
  }), props.tournamentTemplates)

  const selectedOption = props.value ? R.find(R.propEq('value', props.value), options) : null;

  const handleChange = (option) => {
    props.onChange(option ? option.value : null);
  }

  const handleInputChange = () => {
    console.log("handleInputChange")
  }

  return (
    <div className="tournament-template-select">
      <Select
        isClearable
        isSearchable
        value={selectedOption}
        options={options}
        onChange={handleChange}
        />
    </div>
  )
}

export default TournamentTemplateSelect;
