import { ContextMenuTrigger } from 'react-contextmenu';
import {
  GAP
} from '../../shared/constants';

function collectContextMenu(params) {
  const { gap } = params.attributes;

  return {
    gap
  }
}

const Gap = (props) => {
  const { gap } = props;

  return (
    <ContextMenuTrigger holdToDisplay={-1} id={GAP} collect={collectContextMenu} attributes={props}>
      <div className={`match-display`}>
        <table>
          <tbody>
            <tr>
              <td title={"Order on mat"} className="match-display__order">{gap.order + 1}</td>
              <td>{`${gap.description} (${gap.duration})`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ContextMenuTrigger>
  )
}

export default Gap;
