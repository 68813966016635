import R from 'ramda';
import CategoryGroupDropArea from "../../estimates/components/category_group_drop_area"
import DraggableEstimateDisplayHOC from "../../estimates/components/draggable_estimate_display_hoc"
import RemovableEstimateDisplayHOC from "../../estimates/components/removable_estimate_display_hoc"
import { CATEGORY_GROUP } from '../../shared/constants';
import CategoriesAccordeon, { Display } from './categories_accordeon';
import { inputWrapper } from '../../shared/components/form_inputs';

const DraggableDisplay = DraggableEstimateDisplayHOC(
  Display,
  CATEGORY_GROUP,
  categoryGroupSource
);

const RemovableDisplay = RemovableEstimateDisplayHOC(
  Display
);

const categoryGroupSource = {
  beginDrag(props, monitor, component) {
    return {
      categories: props.categories,
    }
  }
}

const TournamentDayCategoriesInput = (props) => {
  const { categories, input: { value, onChange} } = props;

  const selectedCategories =
    R.filter((c) => R.contains(R.prop('id', c), value), categories)

  const availableCategories =
    R.filter((c) => !R.contains(R.prop('id', c), value), categories)

  const onDropCategoryGroup = (addedCategories) =>
    onChange(R.concat(value, addedCategories))

  const onRemoveCategoryGroup = (removedCategories) =>
    onChange(R.difference(value, removedCategories))

  return inputWrapper(
    <div className="row settings-container__categories">
      <div className="form-group col-md-6">
        <CategoryGroupDropArea
          onDropCategoryGroup={(payload) => onDropCategoryGroup(R.map(R.prop('categoryId'), payload.categories))}>
          <div className="settings-container__selected-categories">
            <CategoriesAccordeon 
              categories={selectedCategories}
              display={RemovableDisplay}
              displayProps={{ 
                onRemoveCategoryGroup: (payload) => onRemoveCategoryGroup(R.map(R.prop('id'), payload))
              }}
            />
          </div>
        </CategoryGroupDropArea>
      </div>
      <div className="form-group col-md-6">
        <div className="settings-container__available-categories">
          <CategoriesAccordeon
            categories={availableCategories}
            display={DraggableDisplay}
          />
        </div>
      </div>
    </div>
  )(props)
}

export default TournamentDayCategoriesInput;