import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { lensMatching } from '../../modules/lens_matching';

import MatList from '../../shared/components/mat_list';
import StatelessMatContainer from '../../shared/containers/stateless_mat_container';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import CableSubscriber from '../../shared/components/cable_subscriber';
import R from 'ramda';
import ColorLegend from '../../shared/components/color_legend';
import StandBy from '../../shared/components/stand_by';

const WeighingMatList = MatList(StatelessMatContainer);

import {
  loadData,
} from '../../presence/actions';

import {
  updateMatch,
  reloadMat,
  reloadMatch
} from '../../shared/actions/api';

import {
  weight,
  disqualifyByWeight,
  disqualifyByNoShow,
  disqualifyByGi,
} from '../../modules/competitor_action_params';

class WeighingContainer extends Component {
  componentDidMount() {
    this.props.loadData(this.props.tournamentId, this.props.tournamentDayId, this.props.matNumbers)
  }

  disqualifyByWeight(_, params) {
    this.disqualify(disqualifyByWeight(params.matchId, params.matchCompetitorId))
  }

  disqualifyByNoShow(_, params) {
    this.disqualify(disqualifyByNoShow(params.matchId, params.matchCompetitorId))
  }

  disqualifyByGi(_, params) {
    this.disqualify(disqualifyByGi(params.matchId, params.matchCompetitorId))
  }

  disqualify(params) {
    if (confirm("confirm disqualification?")) {
      this.props.updateMatch(params);
    }
  }

  weightCompetitor(match, params) {
    return this.props.updateMatch(weight(
      params.matchId,
      params.matchCompetitorId,
      params.tournamentSubscriptionId));
  }

  render() {
    const {
      tournamentId,
      mats,
      matchStatesShown,
      reloadWeighingMatches,

      reloadMat,
      reloadMatch
    } = this.props;

    return (
      <div className="checkin-container">
        <CableSubscriber tournamentId={+tournamentId} subscriptions={[
          { name: "MatsChannel", received: reloadMat, room_id: "+" },
          { name: "MatchesChannel", received: reloadMatch, room_id: "+" }
        ]} />

        <ContextMenu id="CHECKED_IN_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.weightCompetitor.bind(this)}>OK</MenuItem>
          <MenuItem onClick={this.disqualifyByWeight.bind(this)}>Disqualify by weight</MenuItem>
          <MenuItem onClick={this.disqualifyByGi.bind(this)}>Disqualify by gi</MenuItem>
          <MenuItem onClick={this.disqualifyByNoShow.bind(this)}>Disqualify by no show</MenuItem>
        </ContextMenu>

        <ContextMenu id="NO_SHOW_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.disqualifyByNoShow.bind(this)}>Disqualify by no show</MenuItem>
        </ContextMenu>

        <ColorLegend label="WEIGH IN"/>

        {mats.length ? (
          <WeighingMatList mats={mats} slug={"weighing-mat-list"}/>
        ) : (
          <StandBy standBy="Loading mats, please wait..." />
        )}
      </div>
    )
  }
}

WeighingContainer.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  mats: PropTypes.array.isRequired,

  loadData: PropTypes.func.isRequired,
  weightCompetitor: PropTypes.func.isRequired,
  disqualify: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
  return {
    mats: state.app.mats.result,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadData,
    updateMatch,

    reloadMat,
    reloadMatch
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WeighingContainer);
