import R from 'ramda';

import {
  CHANGE_FILTER,
} from '../../shared/constants';

import {
  fetchCategories,
} from './api';

export function changeFilter(payload) {
  return { type: CHANGE_FILTER, payload }
}

export function fetchFilteredCategories(options = {}) {
  return (dispatch, getState) => {
    const opt = {
      age_division_ids: [],
      weight_division_ids: [],
      belt_ids: [],
      gender_ids: [],
      ids: [],
      ...options,
    }

    const { filters } = getState().app.ui;

    return dispatch(fetchCategories({ query: {
      paginated: R.isNil(options.paginated) ? null : options.paginated,
      matches: R.isNil(options.matches) ? null : options.matches,
      page: R.isNil(options.page) ? 1 : options.page,
      per_page: R.isNil(options.per_page) ? undefined : options.per_page,
      with_competitors: R.isNil(options.with_competitors) ? false : options.with_competitors,
      with_possible_open_class: R.isNil(options.with_possible_open_class) ? false : options.with_possible_open_class,
      ids: R.isEmpty(opt.ids) ? null : opt.ids,
      tournament_day_id: R.isNil(opt.tournament_day_id) ? null : opt.tournament_day_id,
      with_fights: R.isEmpty(opt.with_fights) ? null : opt.with_fights,
      age_division_ids: R.isEmpty(opt.age_division_ids) ? filters.age_division_ids : opt.age_division_ids,
      weight_division_ids: R.isEmpty(opt.weight_division_ids) ? filters.weight_division_ids : opt.weight_division_ids,
      gender_ids: R.isEmpty(opt.gender_ids) ? filters.gender_ids : opt.gender_ids,
      belt_ids: R.isEmpty(opt.belt_ids) ? filters.belt_ids : opt.belt_ids,
    }}))
  }
}
