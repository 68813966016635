function leftPad(pad, count, number) {
  const string = number + ""
  const length = (count - string.length) < 0 ?
    0 :
    count - string.length

  return Array(length).fill(pad).join('') + string
}

module.exports = leftPad
