
/* TODO: manter como constante? obter categorias do banco? */
const Belt = {
  white: 1,
  grey: 2,
  yellow: 3,
  orange: 4,
  green: 5,
  blue: 6,
  purple: 7,
  brown: 8,
  black: 9
}

const Age = {
  mighty_mite: 24,
  mighty_mite_1: 1,
  mighty_mite_2: 2,
  mighty_mite_3: 3,
  pee_wee: 25,
  pee_wee_1: 4,
  pee_wee_2: 5,
  pee_wee_3: 6,
  junior: 26,
  junior_1: 7,
  junior_2: 8,
  junior_3: 9,
  teen: 27,
  teen_1: 10,
  teen_2: 11,
  teen_3: 12,
  juvenile: 23,
  juvenile_1: 13,
  juvenile_2: 14,
  adult: 15,
  master_1: 16,
  master_2: 17,
  master_3: 18,
  master_4: 19,
  master_5: 20,
  master_6: 21,
  master_7: 28
}

const Weight = {
  rooster: 1,
  light_feather: 2,
  feather: 3,
  light: 4,
  middle: 5,
  medium_heavy: 6,
  heavy: 7,
  super_heavy: 8,
  ultra_heavy: 9,
  open_class: 10,
  open_class_light: 11,
  open_class_heavy: 12
}

const Gender = {
  male: 1,
  female: 2
}

export {
  Age,
  Belt,
  Weight,
  Gender
}
