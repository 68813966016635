import R from 'ramda';
import React, { Component, PropTypes } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import AutoOrderContainer from '../../auto_order/auto_order_container';

class AutoOrderPopover extends Component {
  render() {
    const popover =
      <Popover id="options-bar__auto-order-popover" className="auto-order-popover" title="Auto Order Setup">
        <AutoOrderContainer {...this.props}/>
        <div className="auto-order-popover__actions">
          <a href="#" className="btn btn-danger" onClick={this.props.onClear}>
            Clear All Mats
          </a>
          <a href="#" className="btn btn-primary" onClick={this.props.onSubmit}>
            Order
          </a>
        </div>
      </Popover>

    return (
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
        <i className="fa fa-flask"/>
      </OverlayTrigger>
    )
  }

}

AutoOrderPopover.propTypes = {
  mats: PropTypes.array,
  tournamentTemplates: PropTypes.array,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDestroy: PropTypes.func,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func,
}

AutoOrderPopover.defaultProps = {
  categoryGroups: [],
  mats: [],
  onCreate: () => {},
  onDelete: () => {},
  onClear: () => {},
  onSubmit: () => {}
}

export default AutoOrderPopover;
