import Xwiper from 'xwiper';

class SlidingTabs {
  constructor (options = { currentPosition: 0 }) {
    const slidingTabs = $(".sliding-tabs")
    const tabs = slidingTabs.find(".sliding-tabs__tab")
    const tabWidth = tabs.outerWidth(true)
    const cursor = slidingTabs.find(".sliding-tabs__cursor")
    this.currentPosition = parseInt(options.currentPosition, 10);
    const _this = this;

    function margin(position) {
      return tabs.toArray().reduce((acc, tab) => {
        return position > $(tab).data("index") ?
          acc + $(tab).outerWidth() :
          acc;
      }, 0)
    }

    function moveCursor(position) {
      if (position >= 0 && position < tabs.length) {
        _this.currentPosition = position;

        slidingTabs.trigger('sliding-tabs:change', { position: _this.currentPosition })

        cursor.css("margin-left", margin(position) * -1);
      }
    }

    function selectTab(position, currentTarget) {
      moveCursor(position)

      tabs.removeClass("sliding-tabs__tab--active")
      $(currentTarget).addClass("sliding-tabs__tab--active")

      // $(".sliding-tab-panels__panel").hide()
      // $(`.sliding-tab-panels__panel:nth-child(${position + 1})`).show()
    }

    tabs.on('click', (e) => selectTab($(e.currentTarget).data("index"), e.currentTarget));

    const xwiper = new Xwiper(".sliding-tabs")
    xwiper.onSwipeLeft(() => moveCursor(this.currentPosition + 1));
    xwiper.onSwipeRight(() => moveCursor(this.currentPosition - 1));

    $(tabs[this.currentPosition]).click();
  }

}

export default SlidingTabs;
