import R from 'ramda';
import moment from 'moment-timezone';
import arrayOfNumbers from '../modules/array_of_numbers';
import { Age, Belt } from '../modules/category_constants';
import {
  orderCategories,
  flattenCategoryMatches
} from '../modules/order_categories';

import {
  CHANGE_DATE,
  HIDE_MATS,
  TOGGLE_HIGHLIGHT,

  ADVANCE_PAGE,
  REWIND_PAGE,
  RESET_PAGE,

  TOGGLE_SCHEDULE_SETUP,
  UPDATE_SCHEDULE_SETUP,

  CALL_API,

  PRINT_FIGHT_ORDER_REQUEST,
  PRINT_FIGHT_ORDER_RESPONSE_SUCCESS,
  PRINT_FIGHT_ORDER_RESPONSE_FAIL,

  MATCH_RESPONSE_SUCCESS,
  HIDE_MODAL,

  SELECT_MATCHES,
  UNSELECT_MATCHES,

  FETCH_MODAL_MATCH,
  FETCH_OVERLAY_MATCH,

  INSERT_INDEX,

  INSERT_GAP,
  EDIT_GAP,
  HIDE_GAP_MODAL,

  CHANGE_MATCH_GROUPING,

  CHANGE_MERGE_OPTIONS,

  MERGE_ALL_MATS,

  SELECT_TOURNAMENT_TEMPLATE,

  SHOW_STAND_BY,
  HIDE_STAND_BY,

  DISMISS_NOTIFICATION

} from '../shared/constants';

import {
  fetchTournament,
  fetchMatches,
  fetchMatch,
  fetchFilters,
  fetchMats,
  updateMat,
  fetchMatchDecisions,
  fetchDisqualifications,
  fetchCompetitors,
  fetchCategoryGroups,
  fetchTournamentTemplates,
  updateBracket,
  updatePodium,

  createTournamentTemplate,
  duplicateTournamentTemplate,
  destroyTournamentTemplate,

  destroyAllOrderedItems,

  tournamentDay,
  updateTournamentDay,
} from '../shared/actions/api';

import {
  changeFilter,
  fetchFilteredCategories,
} from '../shared/actions/filter';

import {
  mergeMatCategories,
  unmergeMatCategories,
} from '../modules/merge_mat_categories';

export function loadData(tournamentId, estimateId) {
  return (dispatch, getState) => {
    return dispatch(fetchTournament(tournamentId))
      .then(() => Promise.all([
        Promise.resolve(dispatch(changeDate('FIND_CURRENT'))),
        dispatch(fetchMatsForCurrentDay()),
        dispatch(fetchCategoriesForCurrentDay()),
        dispatch(fetchFilters()),
        dispatch(fetchDisqualifications()),
        dispatch(fetchMatchDecisions()),
      ]))
      .then(() => dispatch(fetchTournamentTemplates()))
  }
}

function visibleMats(matsCount, hiddenMats, offset) {
  return R.pipe(
    R.map(R.add(1)),
    R.difference(R.__, hiddenMats),
    R.drop(offset),
  )(arrayOfNumbers({ to: matsCount }));
}

export function fetchMatsForCurrentDay(pageOffset = 0) {
  return (dispatch, getState) => {
    const {
      tournament,
      tournamentDayIndex,
      ui,
      mats,
    } = getState().app;

    const tournamentDay = tournament.result.tournament_days[tournamentDayIndex]
    const hiddenMatsNumbers = R.map((n) => R.pipe(R.find(R.propEq('id', n)), R.prop('number'))(tournamentDay.mats))(ui.hiddenMats)
    const matNumbers = visibleMats(R.pipe(R.map(R.prop('id')), R.length)(tournamentDay.mats), hiddenMatsNumbers, pageOffset);

    return dispatch(fetchMats({ query: { numbers: matNumbers, tournament_day_ids: [tournamentDay.id] }}));
  }
}

export function fetchCategoriesForCurrentDay(opt = {}) {
  return (dispatch, getState) => {
    const td = tournamentDay(getState().app);
    const filters = getState().app.ui.filters;
    const page = getState().app.categories.meta.current_page;

    return dispatch(fetchFilteredCategories({
      paginated: false,
      tournament_day_id: td.id,
      with_fights: true,
      matches: 'simple',
    }))
    .then((r) => Promise.resolve(dispatch({ type: RESET_PAGE })))

    // return dispatch(fetchFilteredCategories({
    //   paginated: R.isNil(opt.paginated) ? true : opt.paginated,
    //   page: opt.page || page,
    //   per_page: 10,
    //   tournament_day_id: td.id,
    //   with_fights: true,
    //   matches: 'simple',
    //   age_division_ids: R.isEmpty(filters.age_division_ids) ? null : filters.age_division_ids,
    //   weight_division_ids: R.isEmpty(filters.weight_division_ids) ? null : filters.weight_division_ids,
    //   gender_ids: R.isEmpty(filters.gender_ids) ? null : filters.gender_ids,
    //   belt_ids: R.isEmpty(filters.belt_ids) ? null : filters.belt_ids,
    // }))
  }
}

export function changeDate(operation) {
  return (dispatch, getState) => {
    let dates = getState().app.tournament.result.tournament_days || [];

    switch (operation) {
      case 'ADVANCE':
        dispatch({
          type: CHANGE_DATE,
          payload: Math.abs(getState().app.tournamentDayIndex + 1) % dates.length,
        });
        break;
      case 'REWIND':
        dispatch({
          type: CHANGE_DATE,
          payload: Math.abs(getState().app.tournamentDayIndex - 1) % dates.length,
        });
        break;
      case 'FIND_CURRENT':
        const currentDayIndex = R.findIndex((td) =>
          moment(td.date).isSame(moment(), "day")
        )(getState().app.tournament.result.tournament_days)

        dispatch({
          type: CHANGE_DATE,
          payload: currentDayIndex !== -1 ? currentDayIndex : 0
        });
      default:
        dispatch({
          type: CHANGE_DATE,
          payload: getState().app.tournamentDayIndex,
        });
    }

    return Promise.all([
      dispatch(fetchMatsForCurrentDay()),
      dispatch(resetCategoryPage()),
    ])
  }
}

export function changeCategoryFilter(payload) {
  return (dispatch, getState) => {
    return Promise.resolve(dispatch(changeFilter(payload)))
      .then(() => dispatch(resetCategoryPage()))
  }
}

export function setCategoryFilter(categoryId) {
  return (dispatch, getState) => {
    return Promise
    .resolve(R.find((c) => c.id === categoryId, getState().app.categories.result))
      .then((c) => {
        dispatch(changeCategoryFilter({
          age_division_ids: [c.age_division_id],
          weight_division_ids: [c.weight_division_id],
          gender_ids: [c.gender_id],
          belt_ids: c.belt_ids,
        }))
      })
  }
}

export function resetCategoryPage() {
  return (dispatch, getState) => {
    dispatch(fetchCategoriesForCurrentDay({ page: 1 }))
  }
}

export function advancePage() {
  return (dispatch, getState) => {
    return Promise.resolve(dispatch({ type: ADVANCE_PAGE }))
  }
}

export function rewindPage() {
  return (dispatch, getState) => {
    return Promise.resolve(dispatch({ type: REWIND_PAGE }))
  }
}

export function orderMatch(mat) {
  return (dispatch, getState) => {
    return dispatch(updateMat(mat, { query: { matches: 'simple' } }))
      .then(() => Promise.all([
        Promise.resolve(dispatch(unselectMatches())),
        dispatch(fetchCategoriesForCurrentDay())
      ]))
  }
}

export function hideMats(matIds) {
  return (dispatch, getState) => {
    console.log('MatIds: ' + matIds);

    return Promise.resolve(dispatch({ type: HIDE_MATS, payload: matIds }))
      .then(() => dispatch(fetchMatsForCurrentDay()))
  }
}

export function toggleHighlight(kind, id) {
  return {
    type: TOGGLE_HIGHLIGHT,
    payload: { kind: kind, id: id }
  }
}

export function printFightOrder(matIds, options = {}) {
  const _options = { onlyUnfinishedMatches: false, ...options }

  return (dispatch, getState) => {
    return dispatch({
      type: CALL_API,
      payload: {
        types: [
          PRINT_FIGHT_ORDER_REQUEST,
          PRINT_FIGHT_ORDER_RESPONSE_SUCCESS,
          PRINT_FIGHT_ORDER_RESPONSE_FAIL
        ],
        endpoint: `/admin/tournaments/${getState().app.tournament.result.id}/reports`,
        query: { report: 'fight_order', mat_ids: matIds, only_unfinished_matches: _options.onlyUnfinishedMatches },
      }
    })
  }
}

export function showMatchModal(matchId) {
  return (dispatch) => {
    return Promise.resolve(dispatch({ type: FETCH_MODAL_MATCH }))
      .then(() => dispatch(fetchMatch(matchId)));
  }
}

export function fetchCategoryCompetitors(categoryId) {
  return (dispatch, getState) => {
    return Promise.resolve(dispatch(fetchCompetitors({ query: { category_id: categoryId } })));
  }
}

export function submitMatchForm(data) {
  return (dispatch, getState) => {
    return Promise.resolve(dispatch(updateBracket(data)))
      .then(() => dispatch(showMatchModal(data.id)));
  }
}

export function submitPodiumForm(matchId, data) {
  return (dispatch, getState) => {
    return Promise.resolve(dispatch(updatePodium(data)))
      .then(() => dispatch(showMatchModal(matchId)))
  }
}

export function hideMatchModal(matchId) {
  return {
    type: HIDE_MODAL,
  }
}

export function selectMatches(matchIds) {
  return {
    type: SELECT_MATCHES,
    payload: matchIds
  }
}

export function unselectMatches() {
  return {
    type: UNSELECT_MATCHES
  }
}

export function changeMergeOptions(options) {
  return {
    type: CHANGE_MERGE_OPTIONS,
    payload: options
  }
}

export function mergeAllMats(options) {
  return (dispatch, getState) => {
    const {
      tournament,
      tournamentDayIndex,
      ui,
      mats,
    } = getState().app;

    const tournamentDay = tournament.result.tournament_days[tournamentDayIndex]
    const fetchedMatIds = R.map(R.prop('id'), mats.result);
    const matNumbers = R.pipe(
      R.reject((mat) => R.contains(mat.id, fetchedMatIds)),
      R.map(R.prop('number'))
    )(tournamentDay.mats)

    return Promise.resolve(dispatch(showStandBy("Merging Fights. Please stand by.")))
      .then(() => dispatch(fetchMats({ query: { numbers: matNumbers, tournament_day_ids: [tournamentDay.id] }})))
      .then(() => Promise.all(
        R.map((mat) =>
          R.pipe(
            (ms) => mergeMat(ms, options),
            (ms) => buildMatFromMatches(mat.id, ms),
            (mat) => dispatch(updateMat(mat, { query: { matches: 'simple' } }))
          )(mat.matches)
        , mats.result)
      ))
      .then(() => dispatch(hideStandBy()))
  }
}

export function autoOrderCategories() {
  return (dispatch, getState) => {
    const {
      tournamentDayIndex,
      tournament,
      categoryGroups,
      mats,
    } = getState().app;

    const tournamentDay = tournament.result.tournament_days[tournamentDayIndex]

    if (!tournamentDay.tournament_template_id) { return }

    return Promise.resolve(dispatch(showStandBy("Running Auto Order. Please stand by.")))
      .then(() => dispatch(destroyAllOrderedItems(tournamentDay)))
      .then(() => {
        const selectedCategoryGroups = R.filter(
          (cg) =>
            R.propEq('tournament_template_id', tournamentDay.tournament_template_id, cg) &&
            !R.isEmpty(cg.mat_numbers)
        )(categoryGroups.result)

        const orderedMats =
          orderCategories(flattenCategoryMatches(getState().app.categories.result), mats.result.length, R.sortBy(R.prop('order'), selectedCategoryGroups))

        return Promise.all(
          R.addIndex(R.map)(
            (mat, i) => dispatch(updateMat(buildMatFromMatches(mat.id, orderedMats[i]), { query: { matches: 'simple' } })),
            getState().app.mats.result
          )
        )
      })
      .then(() => dispatch(hideStandBy()))
  }
}

export function mergeMat(matches, options) {
  return R.pipe(
    unmergeMatCategories,
    R.ifElse((ms) => options.type === "MERGE", (ms) => mergeMatCategories(ms, options.payload), (ms) => ms),
    R.addIndex(R.map)((match, i) => R.assoc('order', i, match ))
  )(matches)
}

export function unorderMatches(params) {
  return (dispatch, getState) => {
    const {
      mats
    } = getState().app;

    const {
      match,
      matId
    } = params;

    let orderCount = 0;

    const mat = {
      id: matId,
      ordered_items_attributes: R.pipe(
        R.reduce((acc, m) => {

          if (m.id === match.id) {
            return R.append({ id: m.ordered_item_id, _destroy: true }, acc);
          } else {
            const _m = R.append({ id: m.ordered_item_id, order: orderCount }, acc);
            orderCount += 1;

            return _m;
          }

        }, [])
      )(R.find(R.propEq('id', matId), mats.result).matches)
    }

    return dispatch(orderMatch(mat))
  }
}

export function insertGap(matId, index) {
  return {
    type: INSERT_GAP,
    payload: {
      mat: matId,
      order: index
    }
  }
}

export function hideGapModal() {
  return {
    type: HIDE_GAP_MODAL,
  }
}

export function changeMatchGrouping(matchGrouping) {
  return {
    type: CHANGE_MATCH_GROUPING,
    payload: matchGrouping
  }
}

export function editGap(mat, order) {
  return (dispatch, getState) => {
    const gap = R.pipe(
      R.find(R.propEq('number', mat)),
      R.prop('matches'),
      R.nth(order)
    )(getState().app.mats.result)

    return dispatch({
      type: EDIT_GAP,
      payload: gap
    })
  }
}

export function unorderGap(gap) {
  return (dispatch, getState) => {
    const mat = R.find(R.propEq('number', gap.mat))(getState().app.mats.result)

    return dispatch(updateMat({
      id: mat.id,
      ordered_items_attributes: [
        R.pipe(
          R.find(R.propEq('order', gap.order)),
          (oi) => ({ id: oi.ordered_item_id, _destroy: true })
        )(mat.matches)
      ]
    }))
  }
}

export function buildMatFromMatches(matId, matches) {
  return {
    id: matId,
    ordered_items_attributes: R.addIndex(R.map)(
      (p, i) => ({ order: p.order || i, ordenable_id: p.id, ordenable_type: p.kind, id: p.ordered_item_id })
    )(matches)
  }
}

export function submitGap(gap) {
  return (dispatch, getState) => {
    const mat = R.find(R.propEq('number', gap.mat))(getState().app.mats.result)

    const gap_attributes = {
      id: gap.id,
      description: gap.description,
      duration_in_minutes: gap.duration
    }

    const action = R.isNil(gap.id) ?
      R.insert :
      R.update;

    return dispatch(updateMat({
      id: mat.id,
      ordered_items_attributes: R.pipe(
        action(gap.order, { id: gap.id, ordered_item_id: gap.ordered_item_id, order: gap.order, kind: 'Gap', ordenable_attributes: gap_attributes }),
        R.addIndex(R.map)(
          (oi, i) => {
            return oi.kind == 'Gap' ?
              { order: i, ordenable_id: oi.id, ordenable_type: 'Gap', id: oi.ordered_item_id, ordenable_attributes: oi.ordenable_attributes } :
              { order: i, ordenable_id: oi.id, ordenable_type: 'Match', id: oi.ordered_item_id }
          }
        )
      )(mat.matches)
    }))
  }
}


export function unorderCategory(matId, categoryId) {
  return (dispatch, getState) => {
    const matches = R.pipe(
      R.find(R.propEq('id', matId)),
      R.prop('matches'),
      R.filter(R.propEq('category_id', categoryId)),
    )(getState().app.mats.result);

    return dispatch(orderMatch({
      id: matId,
      ordered_items_attributes: R.map(
        (m) => ({ id: m.ordered_item_id, _destroy: true }),
        matches
      )
    }))
  }
}

export function fetchOverlayMatch(matchId) {
  return (dispatch, getState) => {
    return Promise.resolve(dispatch({ type: FETCH_OVERLAY_MATCH }))
      .then(() => dispatch(fetchMatch(matchId)));
  }
}

export function updateScheduleSetup(data) {
  return {
    type: UPDATE_SCHEDULE_SETUP,
    payload: data
  }
}

export function showStandBy(description) {
  return {
    type: SHOW_STAND_BY,
    payload: description
  }
}

export function hideStandBy() {
  return {
    type: HIDE_STAND_BY,
  }
}

function categoryGroupWrapper(fn, data) {
  return (dispatch, getState) => {
    const td = tournamentDay(getState().app);

    return dispatch(fetchFilteredCategories({
      paginated: false,
      tournament_day_id: td.id,
      with_fights: false,
      age_division_ids: data.age_division_ids,
      weight_division_ids: data.weight_division_ids,
      gender_ids: data.gender_ids,
      belt_ids: data.belt_ids,
    }))
      .then(() => dispatch(fn(data)))
  }
}

export function selectTournamentTemplate(tournamentTemplateId) {
  return (dispatch, getState) => {
    const {
      tournamentDayIndex,
      tournament: { result: { tournament_days } }
    } = getState().app;

    const tournamentDay = R.pipe(
      R.assoc('tournament_template_id', tournamentTemplateId),
      R.pick(['id', 'tournament_template_id'])
    )(tournament_days[tournamentDayIndex])

    return dispatch(updateTournamentDay(tournamentDay))
      .then(() => dispatch({
        type: SELECT_TOURNAMENT_TEMPLATE,
        payload: tournamentTemplateId
    }))
  }

}

export function createTournamentTemplateWrapper(data) {
  return (dispatch, getState) => {
    return dispatch(createTournamentTemplate(data))
      .then((response) => Promise.resolve(dispatch(selectTournamentTemplate(response.payload.id))))
  }
}

export function duplicateTournamentTemplateWrapper(data) {
  return (dispatch, getState) => {
    return dispatch(duplicateTournamentTemplate(data))
      .then((response) => Promise.resolve(dispatch(selectTournamentTemplate(response.payload.id))))
  }
}

export function destroyTournamentTemplateWrapper(data) {
  return (dispatch, getState) => {
    return dispatch(destroyTournamentTemplate(data))
      .then((response) => Promise.resolve(dispatch(selectTournamentTemplate(null))))
  }
}

export function dismissNotification(subject, index) {
  return {
    type: DISMISS_NOTIFICATION,
    payload: { subject, index }
  }
}
