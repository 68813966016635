
import React, { Component } from 'react';
import R from 'ramda';
import { Popover } from 'react-bootstrap';

const AutoOrderCategoriesPopover = (props) => {
  const categories = R.map((c) => <li>{c.name}</li>)(props.categories)

  return (
    <Popover id="auto-order-categories-popover" className="auto-order-categories-popover" title="Unselected Categories">
      <ul className="auto-order-categories list-unstyled">
        {categories}
      </ul>
    </Popover>
  )
}

export default AutoOrderCategoriesPopover;
