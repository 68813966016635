
import R from 'ramda';

const getAny = (candidates) =>
  ({ chosenMatches: R.pipe(R.values, R.head, R.head)(candidates), isChunk: false })

const getChunkWhenPossible = (restSize, candidate) => {
  // console.log("candidate: " + JSON.stringify(candidate))

  // return restSize > 0 && candidate[0].length > restSize && candidate[0].phase !== "SF" ?
  return restSize > 0 && candidate[0].length > restSize && candidate[0].phase !== "SF" ?
    { chosenMatches: R.take(restSize, candidate[0]), isChunk: true } :
    { chosenMatches: candidate[0], isChunk: false }
}

const getWithoutRest = (rests, candidatesOrder, candidates) => {
  const validCategoryId = R.difference(
    candidatesOrder,
    R.map(R.prop('category_id'), rests)
  )[0]

  const matchesNeededForRest = (rests[0] || { rest: 0 }).rest

  // console.log("VALID: " + validCategoryIds)

  return validCategoryId ?
    getChunkWhenPossible(matchesNeededForRest, candidates[validCategoryId]) :
    null
}

const chooseMatches = (rests, candidatesOrder, candidates) =>
  getWithoutRest(rests, candidatesOrder, candidates) || getAny(candidates)

const groupCandidates = R.pipe(
  R.reduce((acc, m) =>
    R.assoc(
      m.category_id,
      acc[m.category_id] ? R.append(m, acc[m.category_id]) : [m],
      acc
    ), {}),
  R.map((c) =>
    c[0].bracket_size === 3 ?
      R.aperture(1, c) :
      R.pipe(R.groupBy(R.prop('depth')), R.values, R.reverse)(c)
  )
)

const restFor = (options, match) => {
  switch (match.depth) {
    case 3:
    case 2: return options.standardRest;
    case 1: return options.finalRest;
    default:   return 0;
  }
}

const updateRests = (options, rests, chosenMatches, isChunk) => {
  const lastChosenMatch = chosenMatches[chosenMatches.length -1]

  return R.pipe(
    R.map((g) => ({
      category_id: g.category_id,
      rest: g.category_id !== lastChosenMatch.category_id ? R.subtract(g.rest, chosenMatches.length) : g.rest
    })),
    R.ifElse(
      () => isChunk,
      R.identity,
      R.append({
        category_id: lastChosenMatch.category_id,
        rest: restFor(options, lastChosenMatch)
      })
    ),
    R.reject((g) => g.rest <= 0 || (lastChosenMatch.category_id === g.category_id && lastChosenMatch.depth === "F")),
  )(rests)
}

const updateCandidatesOrder = (matches, merged) => {
  const ids = R.map(R.prop('category_id'))
  const originalOrder = R.uniq(ids(matches))

  return R.pipe(
    ids,
    R.uniq,
    R.sortBy(R.indexOf(originalOrder))
  )(R.difference(matches, merged))
}

const mergeMatCategories  = (matches, options = {}) => {
  const _options = R.merge({
    standardRest: 2,
    finalRest: 3
  }, options);

  let rests = []
  let merged = []
  let candidates = groupCandidates(matches)
  let candidatesOrder = updateCandidatesOrder(matches, merged)

  // console.log("CANDIDATES: " + JSON.stringify(candidates))

  while (!R.pipe(R.values, R.all(R.isEmpty))(candidates)) {
    const { chosenMatches, isChunk } = chooseMatches(rests, candidatesOrder, candidates)

    // console.log("CHOSEN: " + JSON.stringify({ chosenMatches, isChunk }))

    rests = updateRests(_options, rests, chosenMatches, isChunk)
    merged = R.concat(merged, chosenMatches)
    candidatesOrder = updateCandidatesOrder(matches, merged)
    candidates = groupCandidates(R.difference(matches, merged))
  }

  return merged;
}

const unmergeMatCategories = (matches) => {
  let candidates = groupCandidates(matches)
  let candidatesOrder = updateCandidatesOrder(matches, [])

  return R.pipe(
    R.reduce((acc, id) => R.append(R.flatten(candidates[id]), acc), []),
    R.flatten
  )(candidatesOrder)
}

export {
  mergeMatCategories,
  unmergeMatCategories
};
