import { PropTypes } from 'react';

export default function ScoreControl(props) {
  return (
    <div className="score-control">
      <a onClick={props.upFn} className="btn btn-default btn-sm">
        <i className="fa fa-plus"></i>
      </a>
      <span className="score-control__description">{props.description}</span>
      <a onClick={props.downFn} className="btn btn-default btn-sm">
        <i className="fa fa-minus"></i>
      </a>
    </div>
  )
}

ScoreControl.propTypes = {
  upFn: PropTypes.func.isRequired,
  downFn: PropTypes.func.isRequired,
  description: PropTypes.string,
}
