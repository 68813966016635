import React, { PropTypes } from 'react';
import { connect } from "react-redux";
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import R from 'ramda';
import moment from 'moment-timezone';
import { daysBetween } from '../../modules/days_between';
import { bindActionCreators } from 'redux';
import Routes from '../../modules/routes.js.erb';

import { loadData, cacheOpenClassRanks } from '../actions';
import { updateTournament } from '../../shared/actions/api';

import SettingsForm from "../components/settings_form";

import { OPEN_CLASS_IDS } from '../../shared/constants';

class SettingsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = props.tournament;
  }

  componentWillReceiveProps(props) {
    const { tournament, categories } = props;

    const hydrateTournament = R.pipe(
      R.over(
        R.lensProp('tournament_days'), 
        R.map((td) => R.assoc('mats_count', td.mats.length, td))
      ),
      R.assoc('categories', R.filter(c => R.contains(c.weight_division_id, OPEN_CLASS_IDS), categories))
    )

    this.setState(hydrateTournament(tournament));
  }

  componentDidMount() {
    this.props.loadData(this.props.tournamentId)
  }

  encodeLogo(inputFile) {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };

      temporaryFileReader.readAsDataURL(inputFile);
    });
  }

  async submit(values) {
    const renameKeys = R.curry((keysMap, obj) =>
      R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj))
    );

    const apiAdapter = R.pipe(
      renameKeys({ tournament_days: 'tournament_days_attributes' }),
      renameKeys({ categories: 'categories_attributes' }),
      R.assoc('logo_base', values.logo ? await this.encodeLogo(values.logo) : null),
      R.over(R.lensProp('tournament_days_attributes'), R.map(renameKeys({ mats: 'mats_attributes'})))
    )

    this.props.updateTournament(apiAdapter(values))
      .then(() => window.location.href = Routes.admin_tournament_path(values.id))
  }

  render () {
    const tournament = this.state;
    const { categories } = this.props;

    return (
      <div className="settings-container">
        <SettingsForm 
          initialValues={tournament}
          categories={categories}
          onSubmit={this.submit.bind(this)}
        />
      </div>
    );
  }
}

SettingsContainer.propTypes = {
  tournamentId: PropTypes.number.isRequired,
  tournament: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  authenticityToken: PropTypes.string.isRequired,
}

const setTournamentDays = (tournament) => {
  const startDate = tournament.start_date;
  const endDate = tournament.end_date;
  const days = daysBetween(startDate, endDate);

  const visibleDays = R.map(R.prop('date'), tournament.tournament_days)

  const newDays = R.difference(days, visibleDays)

  const addDays = (tournamentDays) =>
    R.reduce((acc, newDay) => {
      const tdIndex = R.findIndex((td) => td.date === newDay, acc);

      return tdIndex !== -1 ?
        R.update(tdIndex, R.dissoc('_destroy', acc[tdIndex]), acc) :
        R.append({
          tournament_id: tournament.id,
          date: newDay,
          start_time: moment(newDay).hour(9).valueOf(),
          category_ids: [],
          mats: [],
        }, acc);
    }, tournamentDays, newDays)

  return tournament.tournament_days.length == 0 ? 
    R.over(R.lensProp('tournament_days'), addDays)(tournament) :
    tournament;
}

function mapStateToProps(state, props) {
  const {
    tournament,
    categories,
  } = state.app;

  const tournamentWithMats = R.pipe(
    setTournamentDays,
  )(tournament.result);

  return {
    tournamentId: parseInt(props.tournament_id, 10),
    tournament: tournamentWithMats,
    categories: categories.result,
    authenticityToken: props.authenticity_token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadData,
    cacheOpenClassRanks,
    updateTournament
  }, dispatch);
}

export default DragDropContext(HTML5Backend)(connect(mapStateToProps, mapDispatchToProps)(SettingsContainer));
