import { jsPlumb } from 'jsplumb';
import BracketPlumbing from '../modules/bracket_plumbing';
import BracketMobile from '../modules/bracket_mobile';

export class CategoryShow {
  constructor() {
    new BracketPlumbing();

    const desktopConnections = {
      "1": { target: "9", anchor: ["RightMiddle", "LeftMiddle"] },
      "2": { target: "9", anchor: ["RightMiddle", "LeftMiddle"] },
      "3": { target: "10", anchor: ["RightMiddle", "LeftMiddle"] },
      "4": { target: "10", anchor: ["RightMiddle", "LeftMiddle"] },
      "5": { target: "11", anchor: ["LeftMiddle", "RightMiddle"] },
      "6": { target: "11", anchor: ["LeftMiddle", "RightMiddle"] },
      "7": { target: "12", anchor: ["LeftMiddle", "RightMiddle"] },
      "8": { target: "12", anchor: ["LeftMiddle", "RightMiddle"] },
      "9": { target: "13", anchor: ["Bottom", "Top"] },
      "10": { target: "14", anchor: ["Top", "Bottom"] },
      "11": { target: "13", anchor: ["Bottom", "Top"] },
      "12": { target: "14", anchor: ["Top", "Bottom"] },
      "13": { target: "15", anchor: ["RightMiddle", [0.44, 1, 0, 1]] },
      "14": { target: "15", anchor: ["LeftMiddle", [0.56, 1, 0, 1]] },
    }

    const mobileConnections = {
      "00": { target: "10",  anchor: ["RightMiddle", "LeftMiddle"] },
      "01": { target: "10",  anchor: ["RightMiddle", "LeftMiddle"] },
      "02": { target: "11", anchor: ["RightMiddle", "LeftMiddle"] },
      "03": { target: "11", anchor: ["RightMiddle", "LeftMiddle"] },
      "04": { target: "12", anchor: ["RightMiddle", "LeftMiddle"] },
      "05": { target: "12", anchor: ["RightMiddle", "LeftMiddle"] },
      "06": { target: "13", anchor: ["RightMiddle", "LeftMiddle"] },
      "07": { target: "13", anchor: ["RightMiddle", "LeftMiddle"] },
      "10": { target: "20",  anchor: ["LeftMiddle", "RightMiddle"] },
      "11": { target: "20", anchor: ["LeftMiddle", "RightMiddle"] },
      "12": { target: "21", anchor: ["LeftMiddle", "RightMiddle"] },
      "13": { target: "21", anchor: ["LeftMiddle", "RightMiddle"] },
      "20": { target: "30", anchor: ["LeftMiddle", "RightMiddle"] },
      "21": { target: "30", anchor: ["LeftMiddle", "RightMiddle"] },
    }

    $(".tournament-category__bracket").each((i, bracket) => {
      new BracketPlumbing(bracket, i, desktopConnections);
    });

    const brackets = $('.bracket-mobile').map((i, bracket) => {
      new BracketPlumbing($(bracket).find('.bracket-mobile__panels'), i, mobileConnections);

      return new BracketMobile(bracket);
    });

    $('.btn-submit').on('click', (e) => {
      brackets.each((i, bracket) => {
        bracket.scrollTo($('.bracket-mobile__competitor-filter').val())
      })
    })

  }
}

