import { jsPlumb } from 'jsplumb';

class BracketPlumbing {
  constructor(bracket, bracketIndex, connections) {
    const _this = this;
    this.jsps = [];

    const instance = jsPlumb.getInstance();

    instance.setContainer($(bracket));

    for (var key in connections) {
      this.connect(instance,
        `match-${bracketIndex + 1}-${key}`,
        `match-${bracketIndex + 1}-${connections[key].target}`,
        connections[key].anchor
      )
    }

    this.jsps.push(instance);

    $(window).resize(function(){
      _this.jsps.map((jsp) => jsp.repaintEverything());
    })
  }

  isVisible (node) {
    return $(`#${node}`).css("visibility") !== "hidden"
  }

  connect (instance, origin, target, anchor) {
    if (this.isVisible(origin) && this.isVisible(target)) {
      instance.connect({
        source: origin,
        target: target,
        anchor: anchor,
        connector: ["Flowchart", { stub: 8 }],
        endpoint: "Blank",
      });
    }
  }
}

export default BracketPlumbing;
