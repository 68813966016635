import R from 'ramda';

import {
  checkin,
  undoCheckin,
  reportNoShow,
  undoNoShow,
  weight,
  undoWeight,
  disqualify,
  undoDisqualify,
} from '../../modules/competitor_action_params';

import {
  isMatchFinished
} from '../../modules/match_lenses';

const CompetitorActions = props => {
  const {
    match, competitor, disqualifications, updateMatch
  } = props;

  const actionsPerState = {
    'assigned':     ['checkin', 'reportNoShow'],
    'checked_in':   ['weight', 'undoCheckin', 'disqualify'],
    'ready':        ['disqualify', 'undoCheckin', 'undoWeight'],
    'weighted':     ['checkin', 'undoWeight', 'reportNoShow'],
    'no_show':      ['disqualify', 'checkin', 'undoNoShow'],
    'disqualified': ['undoDisqualify'],
    'winner':       [],
    'loser':        []
  }

  const actions = {
    checkin: {
      label: 'Checkin',
      params: checkin(match.id, competitor.match_competitor_id)
    },
    undoCheckin: {
      label: 'Undo Checkin',
      params: undoCheckin(match.id, competitor.match_competitor_id)
    },
    weight: {
      label: 'Weight',
      params: weight(match.id, competitor.match_competitor_id, competitor.subscription_id)
    },
    undoWeight: {
      label: 'Undo Weight',
      params: undoWeight(match.id, competitor.match_competitor_id, competitor.subscription_id)
    },
    reportNoShow: {
      label: 'No Show',
      params: reportNoShow(match.id, competitor.match_competitor_id, competitor.subscription_id)
    },
    undoNoShow: {
      label: 'Undo No Show',
      params: undoNoShow(match.id, competitor.match_competitor_id, competitor.subscription_id)
    },
    disqualify: {
      label: 'Disqualify',
      params: R.map((dq) => ({ label: dq.name, values: disqualify(match.id, competitor.match_competitor_id, dq.id) }), disqualifications)
    },
    undoDisqualify: {
      label: 'Undo Disqualify',
      params: undoDisqualify(match.id, competitor.match_competitor_id),
      constraint: () => !isMatchFinished(match)
    },
  }

  const renderButton = (buttonSpecs) =>
    <button className="btn btn-sm" onClick={() => updateMatch(buttonSpecs.params)}>{buttonSpecs.label}</button>

  const renderDropDownButton = (buttonSpecs) =>
    <div className="dropdown">
      <button className="btn btn-sm dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        {buttonSpecs.label}
        <span className="caret"></span>
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
        {
          R.addIndex(R.map)((spec, i) =>
            <li key={i}>
              <a href="#" onClick={() => updateMatch(spec.values)}>{spec.label}</a>
            </li>
          )(buttonSpecs.params)
        }
      </ul>
    </div>

  const renderAction = (action) =>
    Array.isArray(actions[action].params) ?
      renderDropDownButton(actions[action]) :
      renderButton(actions[action])

  return (
    <div className="competitor-actions">
      {
        R.pipe(
          R.prop('state'),
          R.prop(R.__, actionsPerState),
          R.map((action) =>
            R.isNil(actions[action].constraint) || actions[action].constraint() ?
              renderAction(action) :
              undefined
          ),
          R.reject(R.isNil)
        )(props.competitor)
      }
    </div>
  )
}

export default CompetitorActions;
