import { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { updateEstimateDay } from '../../shared/actions/api';
import R from 'ramda';

import EstimateDay from '../components/estimate_day';

class EstimateDayContainer extends Component {
  onDropCategoryGroup(params = {}) {
    const buildDurationCategoryEstimate = (category) => {
      return {
        category_id: category.categoryId,
        competitors_count: category.competitors
      }
    }

    const estimateDay = {
      id: this.props.day.id,
      duration_category_estimates_attributes: R.map(buildDurationCategoryEstimate)(params.categories),
    }

    return this.props.dispatch(updateEstimateDay(estimateDay))
  }

  onRemoveCategoryGroup(categories = []) {
    const categoryIds = R.map(R.prop('id'), categories)

    const markCategoriesToDestroy = R.reduce((acc, estimate) => {
      return R.append(
        R.contains(estimate.category_id, categoryIds) ?
          R.assoc('_destroy', true, estimate) :
          estimate,
        acc
      )
    }, [])

    const estimateDay = {
      id: this.props.day.id,
      duration_category_estimates_attributes: markCategoriesToDestroy(this.props.day.duration_category_estimates)
    }

    return this.props.dispatch(updateEstimateDay(estimateDay))
  }

  onUpdateEstimateDay(estimateDay) {
    return this.props.dispatch(updateEstimateDay(estimateDay))
  }

  render() {
    return (
      <div className="estimate-day-container">
        <EstimateDay {...this.props}
          onUpdateEstimateDay={this.onUpdateEstimateDay.bind(this)}
          onDropCategoryGroup={this.onDropCategoryGroup.bind(this)}
          onRemoveCategoryGroup={this.onRemoveCategoryGroup.bind(this)}/>
      </div>
    )
  }
}

EstimateDayContainer.propTypes = {
  day: PropTypes.shape({
    date: PropTypes.string,
    categories: PropTypes.array,
  }),
  categories: PropTypes.array.isRequired,
}

EstimateDayContainer.defaultProps = {
  categories: []
}

const mapStateToProps = (state, ownProps) => {
  const { categories } = state.app;

  const getCategory = (durationCategoryEstimate) => {
    const c = R.find((category) => category.id === durationCategoryEstimate.category_id, categories.result)

    return durationCategoryEstimate.competitors_count ?
      R.assoc('competitors_count', durationCategoryEstimate.competitors_count)(c) :
      c
  }

  const assignedCategories = R.pipe(
    R.map(getCategory),
    R.reject(R.isNil)
  )(ownProps.day.duration_category_estimates)

  return {
    day: ownProps.day,
    categories: assignedCategories,
  }
}

export default connect(mapStateToProps)(EstimateDayContainer);
