import { Component, PropTypes } from 'react';
import R from 'ramda';

class EstimateForm extends Component {
  onChange(e) {
    const updateTarget = R.assoc(e.target.name, e.target.value)

    this.props.onChange(updateTarget(this.props.target))
  }

  render() {
    return(
      <div className="estimate-form form-horizontal">
        <div className="form-group form-group-sm">
          <label className="control_label col-xs-2">Areas</label>
          <div className="col-xs-4">
            <input type="number" min={1} name="areas" className="form-control" onChange={this.onChange.bind(this)} defaultValue={this.props.target.areas}/>
          </div>
          <label className="control_label col-xs-2">Interval</label>
          <div className="col-xs-4">
            <input type="number" min={0} name="interval" className="form-control estimate-day__interval-unit" onChange={this.onChange.bind(this)} defaultValue={this.props.target.interval}/>
            <select name="interval_unit" className="form-control estimate-day__interval-unit" onChange={this.onChange.bind(this)} defaultValue={this.props.target.interval_unit}>
              <option value="min">min</option>
              <option value="%">%</option>
            </select>
          </div>
        </div>
      </div>
    )
  }
}

export default EstimateForm;
