import Xwiper from 'xwiper';

class BracketMobile {
  constructor(el) {
    this.$el = $(el);
    this.titlesWrapper = this.$el.find('.bracket-mobile__titles');
    this.panelsWrapper = this.$el.find('.bracket-mobile__panels')
    this.titles = this.$el.find('.bracket-mobile__title');
    this.columnWidth = -300; // get from title css
    this.currentPosition = 0;

    this.titles.on('click', (e) => this.click($(e.target).index()));
    const xwiper = new Xwiper(el)
    xwiper.onSwipeLeft(() => this.swipe(this.currentPosition + 1));
    xwiper.onSwipeRight(() => this.swipe(this.currentPosition - 1));
  }

  click(index) {
    $(this.titlesWrapper).css('transform', `translateX(${index * this.columnWidth}px)`);
    $(this.panelsWrapper).css('transform', `translateX(${index * this.columnWidth}px)`);

    this.currentPosition = index;
  }

  swipe(index) {
    if (index >= 0 && index < this.titles.length) {
      this.click(index);
    }
  }

  scrollTo(matchNumber) {
    const match = $(`.match-${matchNumber}`);

    if (match.length === 0) {
      return undefined;
    }

    Promise.resolve(
      window.scroll({
        top: match.offset().top - 10,
        left: 0,
        behavior: 'smooth'
      })
    )
    .then(() => {
      this.click(match.parents('.bracket-mobile__panel').index())
    })
  }
}

export default BracketMobile;
