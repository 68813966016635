import NestedCategoryList from "../../shared/components/nested_category_list";

function Display(props) {
  return (<span className="simple-display">{props.children}</span>)
}

const CategoriesAccordeon = (props) => {
  const { categories, 
    display, 
    displayProps
  } = props;

  return (
    <NestedCategoryList
      collection={categories}
      labelField="belt_name"
      valueField="belt_id"
      slugField="belt_slug"
      display={display || Display}
      displayProps={displayProps}>
      <NestedCategoryList
        collection={categories}
        labelField="age_division_name"
        valueField="age_division_id"
        display={display || Display}
        displayProps={displayProps}>
        <NestedCategoryList
          collection={categories}
          labelField="gender_name"
          valueField="gender_id"
          display={display || Display}
          displayProps={displayProps}>
          <NestedCategoryList
            collection={categories}
            labelField="weight_division_name"
            valueField="weight_division_id"
            display={display || Display}
            displayProps={displayProps}>
          </NestedCategoryList>
        </NestedCategoryList>
      </NestedCategoryList>
    </NestedCategoryList>
  )
}

export {
  Display
}
export default CategoriesAccordeon;