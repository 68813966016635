import SlidingColumns from '../modules/sliding_columns';
import SlidingTabs from '../modules/sliding_tabs';
import ReloadTimer from '../modules/reload_timer';
import TournamentDaySelector from '../modules/tournament_day_selector';
import MatMobile from '../modules/mat_mobile';
import MatDesktop from '../modules/mat_desktop';
import QueryStringUpdater from '../modules/query_string_updater';
import 'imports-loader?window=>global&window.jQuery=>jQuery!sticky-kit/dist/sticky-kit.js';

export function TournamentDaysShow () {
  const searchParams = new URLSearchParams(window.location.search);

  ReloadTimer();

  const slidingColumns = new SlidingColumns({
    position: parseInt(searchParams.get('position')),
    timer: searchParams.get('timer')
  });

  // const slidingTabs = new SlidingTabs({
  //   currentPosition: parseInt(searchParams.get('page') || 1, 10) - 1,
  // });

  const queryStringUpdater = new QueryStringUpdater(window.location);

  const matMobile = new MatMobile();
  const matDesktop = new MatDesktop(slidingColumns);

  const tournamentDaySelector = new TournamentDaySelector();

  $('.sticky-panel').stick_in_parent();

  $('.sliding-columns').on('sliding-columns:change', (e, data) => {
    queryStringUpdater.update({ 'position': data.position })
  })

  $('.sliding-tabs').on('sliding-tabs:change', (e, data) => {
    queryStringUpdater.update({ 'position': data.position })
  })
}

