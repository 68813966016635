import formatMinutes from '../../modules/format_minutes';
import React, { Component, PropTypes } from 'react';
import R from 'ramda';

class NestedCategoryList extends Component {
  collapseKey(item) {
    const { valueField } = this.props;
    const n = Math.floor(Math.random() * 100000)

    return `collapse-${valueField}-${item[valueField]}-${n}`;
  }

  buildItem(item, index) {
    const idKey = this.collapseKey(item);

    const childCollection = R.filter((childItem) =>
      childItem[this.props.valueField] === item[this.props.valueField]
    )(this.props.collection)

    const children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        collection: childCollection,
        slugField: this.props.slugField
      });
    })

    return (
      <div className="panel panel-default" key={index}>
        <div className={`panel-heading panel-heading--${item[this.props.slugField]}`}>
          <this.props.display categories={childCollection} {...this.props.displayProps}>
            <a data-target={`#${idKey}`} data-toggle="collapse" className="nested-category-list__label">
              <span className="nested-category-list__label-text">{`${item[this.props.labelField]}`}</span>
              <i className="fa fa-chevron-down nested-category-list__arrow"/>
            </a>
          </this.props.display>
        </div>
        <div className="panel-collapse collapse" id={`${idKey}`}>
          <div className="panel-body">
            {children}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const items = this.props.collection.length > 0 ?
      R.pipe(
        R.uniqBy(R.prop(this.props.valueField)),
        R.sortBy(R.prop(this.props.valueField)),
        R.addIndex(R.map)(this.buildItem.bind(this))
      )(this.props.collection) :
      [<div className="nested-category-list__empty" key={0}>{`No categories`}</div>]

    return (
      <div className="nested-category-list panel-group">
        {items}
      </div>
    );
  }
}

NestedCategoryList.propTypes = {
  collection: PropTypes.array.isRequired,
  labelField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  display: PropTypes.func.isRequired,
  displayProps: PropTypes.object
}

export default NestedCategoryList;
