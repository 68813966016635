import R from 'ramda';
import lensById from '../modules/lens_by_id';
import lensMatching from '../modules/lens_matching';

import {
  MATCH_REQUEST,
  MATCH_RESPONSE_SUCCESS,
  MATCH_RESPONSE_FAIL,

  UPDATE_MATCH_REQUEST,
  UPDATE_MATCH_RESPONSE_SUCCESS,
  UPDATE_MATCH_RESPONSE_FAIL,

  START_MATCH,
  PAUSE_MATCH,
  SWITCH_COMPETITOR_COLORS,
  RESET_COMPETITOR_COLORS,
  SCORE_POINTS,
  SCORE_ADVANTAGE,
  SCORE_FOUL,
  SET_VICTORY,
  SET_TIMER,
  SELECT_MATCH,
  RELOAD_MAT,
  RELOAD_MATCH,

  OPEN_VICTORY_MODAL,
  CLOSE_VICTORY_MODAL
} from '../shared/constants';

import {
  tournament,
  mat,
  matches,
  matchDecisions,
  disqualifications,
} from '../shared/reducers';

let initialState = {
  ui: {
    colorsInverted: false,
    timerIsRunning: false,
    victoryModalIsOpen: false,
    matchHasStarted: false,
    winnerCompetitorId: null,
  },
  mat: {
    isFetching: false,
    result: {
      number: 1
    }
  },
  match: {
    isFetching: false,
    result: {}
  },
  matchDecisions: {
    isFetching: false,
    result: [],
  },
  disqualifications: {
    isFetching: false,
    result: [],
  },
  tournament: {
    isFetching: false,
    result: {}
  }
}

let lensByCompetitorId = R.compose(lensMatching, R.propEq('id'));

export function score(state = initialState, action) {
  return {
    ui: ui(state.ui, action),
    mat: scoreMat(state.mat, action),
    match: match(state.match, action),
    matchDecisions: matchDecisions(state.matchDecisions, action),
    disqualifications: disqualifications(state.disqualifications, action),
    tournament: tournament(state.tournament, action),
  };
}

export function scoreMat(state, action) {
  switch (action.type) {
    case RELOAD_MAT:
      return { ...state, result: action.payload };
    case UPDATE_MATCH_RESPONSE_SUCCESS:
      const index = R.findIndex(R.propEq('id', action.payload.id), state.result.matches);

      return {
        ...state,
        result: R.over(
          R.lensProp('matches'),
          R.update(index, action.payload),
          state.result
        )
      }

    default:
      return mat(state, action);
  }
}

export function match(match, action) {
  let m = R.over(
    R.lensProp('result'),
    R.assoc('competitors', matchCompetitors(match.result.competitors, action)),
    match
  )

  switch (action.type) {
    case MATCH_REQUEST:
      return { ...m, isFetching: true }
    case MATCH_RESPONSE_SUCCESS:
    case SELECT_MATCH:
    case UPDATE_MATCH_RESPONSE_SUCCESS:
      return { ...m, result: action.payload || {}, isFetching: false }
    case SET_VICTORY:
      return R.over(
        R.lensProp('result'),
        R.assoc('match_decision_id', action.payload.matchDecisionId),
        m
      )
    case MATCH_RESPONSE_FAIL:
    case UPDATE_MATCH_RESPONSE_FAIL:
      return m;
    default:
      return m;
  }
}

export function matchCompetitors(matchCompetitors, action) {
  const { competitorId, amount } = action.payload || {};

  const updatePoints = (matchCompetitors, attribute, competitorId, amount) => {
    const oldValue = R.pipe(
      R.find(R.propEq('id', competitorId)),
      R.prop(attribute)
    )(matchCompetitors)

    const newValue = oldValue + action.payload.amount;

    return R.over(
      lensById(action.payload.competitorId),
      R.assoc(attribute, newValue >= 0 ? newValue : oldValue),
      matchCompetitors
    );
  }

  switch (action.type) {
    case SCORE_POINTS:
      return updatePoints(matchCompetitors, 'points', competitorId, amount)
    case SCORE_ADVANTAGE:
      return updatePoints(matchCompetitors, 'advantage_points', competitorId, amount)
    case SCORE_FOUL:
      return updatePoints(matchCompetitors, 'foul_points', competitorId, amount)
    case SET_VICTORY:
      return R.over(
        lensById(parseInt(action.payload.competitorId, 10)),
        R.assoc('winner', true),
        matchCompetitors
      );
    default:
      return matchCompetitors;
  }
}

function ui(ui, action) {
  switch (action.type) {
    case RESET_COMPETITOR_COLORS:
      return { ...ui, colorsInverted: false }
    case SWITCH_COMPETITOR_COLORS:
      return { ...ui, colorsInverted: !ui.colorsInverted }
    case SET_TIMER:
      return { ...ui, timer: ui.timer + action.payload }
    case START_MATCH:
      return { ...ui, timerIsRunning: true }
    case PAUSE_MATCH:
      return { ...ui, timerIsRunning: false }
    case OPEN_VICTORY_MODAL:
      return { ...ui, victoryModalIsOpen: true, winnerCompetitorId: action.payload }
    case CLOSE_VICTORY_MODAL:
      return { ...ui, victoryModalIsOpen: false }
    default:
      return ui
  }
}

