import React from 'react';
import { DragLayer } from 'react-dnd';
import { ORDERED_MATCH, CATEGORY, MATCH, ROUND } from '../../shared/constants';
import MatchesDragBox from './matches_drag_box';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  opacity: .7
};

function getItemStyles(props) {
  const { initialOffset, currentOffset } = props;

  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform: transform,
    WebkitTransform: transform
  };
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
  }
}

function dragBoxElement(props) {
  switch (props.itemType) {
    case CATEGORY:
    case ORDERED_MATCH:
    case ROUND:
    case MATCH:
      return <MatchesDragBox matches={props.item.selectedMatches} />
    default:
      return null;
  }
}

class MatchesDragLayer extends React.Component {
  render() {
    return (
      <div style={layerStyles}>
        <div style={getItemStyles(this.props)} className="matches-drag-layer">
          {dragBoxElement(this.props)}
        </div>
      </div>
    )
  }
}

export default DragLayer(collect)(MatchesDragLayer);
