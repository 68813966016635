
import { Component } from 'react';
import R from 'ramda';
import moment from 'moment-timezone';

class CountdownTimer extends Component {
  constructor(props) {
    super(props);

    const {
      time,
      intervalInMinutes,
      onTimeup,
      secondsLeft
    } = props;

    this.state = {
      secondsLeft: Math.max(0, moment().diff(moment(time).add(intervalInMinutes, 'minutes'), 'seconds') * -1),
      intervalId: setInterval(() => {
        if (this.state.secondsLeft <= 0) {
          clearInterval(this.state.intervalId);
          onTimeup();
        } else {
          this.setState({ secondsLeft: this.state.secondsLeft - 1 })
        }
      }, 1000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  formattedClock(secondsLeft) {
    return Math.floor(secondsLeft / 60).toString().padStart(2, '0') + ':' + (secondsLeft % 60).toString().padStart(2, '0');
  }

  render () {
    const { secondsLeft } = this.state;

    return (
      <span className="podiums__countdown">{this.formattedClock(secondsLeft)}</span>
    )
  }
}

export default CountdownTimer;
