
class AdminOpenClassSubscriptionsIndex {
  constructor() {
    $('i.fa-lock').on('click', (ev) => {
      if (!confirm("Are you sure you want to reopen this category?")) {
        return false;
      }
    })
  }
}

export default AdminOpenClassSubscriptionsIndex;
