import React, { PropTypes } from 'react';

export default function PodiumFilter(props) {
  const {
    activeFilter,
    change
  } = props;

  return (
    <div>
      <div className="radio">
        <label>
          <input
            name="podium-filter"
            type="radio"
            checked={activeFilter === "ALL"}
            onClick={() => change({ status: 'ALL' })} />
          <span className="circle"/>
          <span className="check"/>
          All
        </label>
      </div>
      <div className="radio">
        <label>
          <input
            name="podium-filter"
            type="radio"
            checked={activeFilter === "UNCHECKED"}
            onClick={() => change({ status: 'UNCHECKED' })} />
          <span className="circle"/>
          <span className="check"/>
          Unchecked
        </label>
      </div>
      <div className="radio">
        <label>
          <input
            name="podium-filter"
            type="radio"
            checked={activeFilter === "CHECKED"}
            onClick={() => change({ status: 'CHECKED' })} />
          <span className="circle"/>
          <span className="check"/>
          Checked
        </label>
      </div>
      <div className="radio">
        <label>
          <input
            name="podium-filter"
            type="radio"
            checked={activeFilter === "PENDING"}
            onClick={() => change({ status: 'PENDING' })} />
          <span className="circle"/>
          <span className="check"/>
          Pending
        </label>
      </div>
    </div>
  )
}

PodiumFilter.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
}
