import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import apiMiddleware from '../middleware/api_middleware';

import ScoreContainer from './containers/score_container';
import { score } from './reducers';

const logger = createLogger();

const store = createStore(
  combineReducers({ app: score }),
  applyMiddleware(thunkMiddleware, apiMiddleware, logger)
);

class ScoreApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ScoreContainer {...this.props}/>
      </Provider>
    )
  }
}

export default ScoreApp;
