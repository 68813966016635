import React, { PropTypes } from 'react';

function displayTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainder = seconds % 60;

  return `${minutes}:${remainder < 10 ? `0${remainder}` : remainder}`;
}

function ScoreTimer(props) {
  let { timer, elapsed, running } = props;

  return (
    <div className={`score-timer ${running ? 'score-timer--running' : ''}`}>
      <div className='score-timer__time'>
        {displayTime(timer - elapsed)}
        <br/>
      </div>
    </div>
  )
}

ScoreTimer.propTypes = {
  timer: PropTypes.number.isRequired,
  elapsed: PropTypes.number.isRequired,
}

export default ScoreTimer;
