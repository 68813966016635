import MatchModal from '../components/match_modal';
import R from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateMatch,
} from '../../shared/actions/api';

import {
  hideMatchModal,
  fetchCategoryCompetitors,
  submitMatchForm,
  submitPodiumForm,
} from '../actions';

function mapStateToProps(state, ownProps) {
  const { competitors, matchDecisions, disqualifications, ui } = state.app;
  const { tournamentId } = ownProps;
  const modalMatch = ui.matchModal.result;

  return {
    match: modalMatch,
    matchDecisions: matchDecisions.result,
    disqualifications: disqualifications.result,
    tournamentId: tournamentId,
    competitors: competitors.result,
    show: R.not(R.isNil(modalMatch))
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    close: hideMatchModal,
    updateMatch,
    fetchCategoryCompetitors,
    submitMatchForm,
    submitPodiumForm
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchModal);
