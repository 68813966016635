import R from 'ramda';
import { Component } from 'react';

const select = ({ options, disabled, input, label, type, meta: { touched, error, warning } }) => {
  return (
    <div className={`form-group ${touched && error && 'has-error'}`}>
      <label for={input.name} className="control-label">{label}</label>
      <select
        type="text"
        {...input}
        className="form-control"
        disabled={disabled}>
        {R.pipe(
          R.prepend({ id: null, value: null }),
          R.map((o) => <option value={o.value}>{o.label}</option>)
        )(options)}
      </select>
      {
        touched && (error && <span className="bmd-help text-danger">{error}</span>)
      }
    </div>
  )
}

export default select;
