
import R from 'ramda';

import { Age, Belt } from './category_constants';
import { sortAssignedMatches } from './sort_assigned_matches';

const INTERVAL = 2;

const orderCategories = (categories, mats_count, templates) => {
  return R.reduce(
    (acc, template) => {
      const orderedTemplate = orderTemplate(template, matsForTemplate(acc, template.mat_numbers), categories)

      return R.addIndex(R.reduce)(
        (_acc, mat_number, i) => R.update(mat_number -1, orderedTemplate[i], _acc),
        acc,
        template.mat_numbers
      )
    },
    R.times(() => [], mats_count),
  )(templates)
}

const matsForTemplate = (allMats, templateMatNumbers) =>
  R.map((templateMatNumber) => allMats[templateMatNumber - 1], templateMatNumbers)

const orderTemplate = (template, mats, categories) => {
  return orderGroup(mats, categoriesIn(template, categories))
}

const orderGroup = (mats, categories) =>
  R.reduce(
    (acc, category) => orderCategory(acc, category),
    mats,
    categories
  )

const orderCategory = (mats, category) =>
{
  return category.length > 15 && mats.length > 1 ?
    splitCategory(mats, category) :
    orderOnMat(mats, getShorterMatIndexes(mats, 1)[0], category)
}

const splitCategory = (mats, category) => {
  const matIndexes = getShorterMatIndexes(mats, splitMatCount(category.length))

  return R.reduce(
    (acc, phase) => R.addIndex(R.reduce)((_acc, split, i) => orderOnMat(_acc, matIndexes[i], sortAssignedMatches(split)), acc, phase),
    mats,
    splitPhases(category)
  )
}

const orderOnMat = (mats, matIndex, category) =>
  R.over(R.lensIndex(matIndex), R.concat(R.__, category), mats)

const splitMatCount = (matchesCount) =>
  2 ** (Math.floor(Math.log2(matchesCount)) - 3)

const splitPhases = (category) =>
  R.pipe(
    R.sort((a, b) => a.n - b.n),
    R.groupBy(R.prop('phase')),
    R.values,
    R.map((phase) => R.splitEvery(phase.length / splitMatCount(category.length), phase))
  )(category)

const getShorterMatIndexes = (mats, count) =>
{
  const matDuration = R.reduce((acc, match) => acc + match.duration + INTERVAL, 0)

  return R.pipe(
    R.addIndex(R.reduce)((acc, mat, i) => R.append([i, matDuration(mat)], acc), []),
    R.sort((a, b) => a[1] - b[1]),
    R.take(count),
    R.map(R.head)
  )(mats)
}

const phasesIncludes = (matchPhase, groupPhases) => {
  const hasQualifiers = R.indexOf("EL", groupPhases) > -1;

  return R.contains(matchPhase, groupPhases) || (hasQualifiers ? /EL/.test(matchPhase) : false)
}

const categoriesIn = (categoryGroup, categories) => {
  return R.pipe(
    R.filter(
      (c) =>
        (phasesIncludes(c.phase, categoryGroup["phases"])) &&
        (R.contains(c.weight_division_id, categoryGroup["weight_division_ids"])) &&
        (R.isEmpty(categoryGroup["age_division_ids"]) || R.contains(c.age_division_id, categoryGroup["age_division_ids"])) &&
        (R.isEmpty(categoryGroup["gender_ids"]) || R.contains(c.gender_id, categoryGroup["gender_ids"])) &&
        (R.isEmpty(categoryGroup["belt_ids"]) || R.difference(categoryGroup["belt_ids"], c.belt_ids).length < categoryGroup["belt_ids"].length)
    ),
    R.groupBy(R.prop('category_id')),
    R.values,
    R.map(sortAssignedMatches),
    R.sort((a, b) => b.length - a.length)
  )(categories)
}

const flattenCategoryMatches = (categories) => R.flatten(
  R.map((c) =>
    R.map((m) =>
      R.merge(m, {
        age_division_id: c.age_division_id,
        weight_division_id: c.weight_division_id,
        belt_ids: c.belt_ids,
        gender_id: c.gender_id,
        ordered_item_id: null,
        order: null,
      })
    , c.matches),
  categories)
)

export {
  orderCategories,
  flattenCategoryMatches
}
