import R from 'ramda';

import {
  START_MATCH,
  PAUSE_MATCH,
  SWITCH_COMPETITOR_COLORS,
  RESET_COMPETITOR_COLORS,
  SCORE_POINTS,
  SCORE_ADVANTAGE,
  SCORE_FOUL,
  SET_VICTORY,
  SET_TIMER,
  OPEN_VICTORY_MODAL,
  CLOSE_VICTORY_MODAL,
  SELECT_MATCH,

  CALL_API,

  UPDATE_MATCH_REQUEST,
  UPDATE_MATCH_RESPONSE_SUCCESS,
  UPDATE_MATCH_RESPONSE_FAIL,
} from '../shared/constants';

import {
  fetchTournament,
  fetchMat,
  fetchMatches,
  fetchMatch,
  fetchMatchDecisions,
  fetchDisqualifications,
  updateMatch,

  reloadMat,
} from '../shared/actions/api';

import {
  isMatchStarted,
  isMatchFinished,
} from '../modules/match_lenses';

import lensById from '../modules/lens_by_id';

export function firstNotFinishedMatch(matches) {
  return R.pipe(
    R.filter((m) => m.state !== "finished"),
    R.head
  )(matches)
}

export function loadData(tournamentId, matId) {
  return (dispatch, getState) => {
    return dispatch(fetchTournament(tournamentId))
      .then(() => Promise.all([
        dispatch(fetchMat(matId)),
      ]))
      .then(() => Promise.all([
        dispatch(selectMatch()),
        dispatch(fetchMatchDecisions()),
        dispatch(fetchDisqualifications()),
      ]))
  }
}

export function startMatch() {
  return {
    type: START_MATCH,
  }
}

export function pauseMatch() {
  return {
    type: PAUSE_MATCH,
  }
}

export function switchCompetitorColors() {
  return {
    type: SWITCH_COMPETITOR_COLORS,
  }
}

export function resetCompetitorColors() {
  return {
    type: RESET_COMPETITOR_COLORS,
  }
}

export function scorePoint(competitorId, amount) {
  return {
    type: SCORE_POINTS,
    payload: {
      competitorId,
      amount
    }
  }
}

export function scoreAdvantage(competitorId, amount) {
  return {
    type: SCORE_ADVANTAGE,
    payload: {
      competitorId,
      amount
    }
  }
}

export function scoreFoul(competitorId, amount) {
  return {
    type: SCORE_FOUL,
    payload: {
      competitorId,
      amount
    }
  }
}

export function setVictory(matchCompetitorId, matchDecisionId) {
  return (dispatch, getState) => {
    const {
      match,
      matchDecisions,
      disqualifications,
    } = getState().app;

    const matchDecision =
      R.find(R.propEq('id', parseInt(matchDecisionId, 10)), matchDecisions.result);

    const disqualificationId = matchDecision.disqualification ?
      R.find(R.propEq('slug', matchDecision.slug), disqualifications.result).id :
      null;

    const params = {
      id: match.result.id,
      match_decision_id: matchDecisionId,
      end_time: Date.now(),
      match_competitors_attributes: R.map((cm) => {
        const winner = cm.id === parseInt(matchCompetitorId, 10);

        return {
          id: cm.match_competitor_id,
          winner: winner,
          disqualification_id: winner ? null : disqualificationId,
          points: cm.points,
          advantage_points: cm.advantage_points,
          foul_points: cm.foul_points,
        }
      })(match.result.competitors)
    }

    return dispatch(updateMatch({
      matchId: params.id,
      command: {
        type: "finish",
        payload: {
          match: params,
        }
      }
    }))
  }
}

export function setTimerSeconds(seconds) {
  return {
    type: SET_TIMER,
    payload: seconds
  }
}

export function openVictoryModal(competitorId) {
  return {
    type: OPEN_VICTORY_MODAL,
    payload: competitorId
  }
}

export function closeVictoryModal() {
  return {
    type: CLOSE_VICTORY_MODAL,
  }
}

export function startMatchOnDb() {
  return (dispatch, getState) => {
    const match = getState().app.match.result;

    return dispatch(updateMatch({
      matchId: match.id,
      command: {
        type: "start",
        payload: {}
      }
    }))
  }
}

export function selectMatch() {
  return (dispatch, getState) => {
    const {
      mat
    } = getState().app;

    const selectedMatch = firstNotFinishedMatch(mat.result.matches);

    if (!R.isNil(selectedMatch)) {
      dispatch(fetchMatch(selectedMatch.id))
        .then((data) => {
          return dispatch({
            type: SELECT_MATCH,
            payload: data.payload
          })
        })
    }
  }
}

export function reloadScoreMat(mat) {
  return (dispatch, getState) => {
    const {
      match,
    } = getState().app;

    dispatch(reloadMat(mat));

    if (R.isNil(match.result) ||
        (!isMatchStarted(match.result) && !isMatchFinished(match.result))) {
      return dispatch(selectMatch())
    }
  }
}

export function reloadScoreMatch(payload) {
  return (dispatch, getState) => {
    const {
      mat,
    } = getState().app;

    if (payload.mat !== mat.result.number) { return; }

    dispatch(reloadScoreMat(R.over(
      R.compose(R.lensProp('matches'), lensById(payload.id)),
      () => payload,
      mat.result
    )));
  }
}
