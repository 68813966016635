import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import apiMiddleware from '../middleware/api_middleware';

import PodiumContainer from './containers/podium_container';
import { podium } from './reducers';

const logger = createLogger();

const store = createStore(
  combineReducers({ app: podium }),
  applyMiddleware(thunkMiddleware, apiMiddleware, logger)
);

class PodiumApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PodiumContainer
          tournamentId={this.props.tournament_id}
          receiptPrintingServerPort={this.props.receipt_printing_server_port}
          />
      </Provider>
    )
  }
}

export default PodiumApp;
