import Routes from '../../modules/routes.js.erb';

class AdminOperators {
  constructor() {
    $('.operators__search-submit').on('click', () => {
      const tournamentId = $('.operators__search-field option:selected').val();

      window.location = Routes.admin_operator_path(tournamentId);
    })
  }
}

export default AdminOperators;
