import R from 'ramda';
import React, { Component, PropTypes } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

class HideMatsPopover extends Component {
  constructor(props) {
    super(props);

    this.state = this.updateHiddenMats(props.mats, props.hiddenMats);
  }

  componentWillReceiveProps(newProps) {
    this.setState(this.updateHiddenMats(newProps.mats, newProps.hiddenMats));
  }

  updateHiddenMats (mats, hiddenMats) {
    return R.reduce((acc, mat) =>
      R.assoc(mat.id, R.contains(mat.id, hiddenMats), acc), {}, mats)
  }

  onChange(matId, checked) {
    this.setState(R.assoc(matId, checked, this.state), (a) => {
      const hiddenMatIds = R.pipe(
        R.toPairs,
        R.reduce((acc, pair) => {
          const [ key, value ] = pair;

          return value ? R.append(parseInt(key, 10), acc) : acc;
        }, []),
        R.reject(R.isNil)
      )(this.state)

      this.props.onChange(hiddenMatIds);
    })
  }

  render() {
    const popover =
      <Popover id="options-bar__hide-mats-popover" title="Hide Mats">
        <ul className="list-unstyled list-inline">
          {R.addIndex(R.map)((m, i) =>
            <li key={i}>
              <div className="checkbox">
                <label>
                  <input type="checkbox"
                    value={m.id}
                    checked={this.state[m.id]}
                    onChange={(ev) => this.onChange(m.id, ev.target.checked)} />
                  <span className="checkbox-material"><span className="check"></span></span>
                  {`Mat ${m.number}`}
                </label>
              </div>
            </li>
          )(this.props.mats)}
        </ul>
      </Popover>

    return (
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
        <i className="fa fa-eye"/>
      </OverlayTrigger>
    )
  }

}

HideMatsPopover.propTypes = {
  mats: PropTypes.array,
  hiddenMats: PropTypes.array,
}

HideMatsPopover.defaultProps = {
  mats: [],
  hiddenMats: [],
}

export default HideMatsPopover;
