
export default class QueryStringUpdater {
  constructor(uri) {
    this.uri = uri
  }

  update(params) {
    for (var param in params) {
      if (params[param] !== undefined) {
        this.updateQueryStringParameter(this.uri, param, params[param]);
      }
    }
  }

  updateQueryStringParameter(uri, key, value) {
    var searchParams = new URLSearchParams(this.uri.search)

    searchParams.set(key, value);

    var newRelativePathQuery = this.uri.pathname + '?' + searchParams.toString();

    history.pushState(null, '', newRelativePathQuery);
  }
}
