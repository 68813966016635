import R from 'ramda';
import React, { PropTypes } from 'react';
import Popout from 'react-popout';
import ScoreCompetitor from './score_competitor';
import TimerControls from './score_timer';
import VictoryScreen from './victory_screen';
import ScoreTimer from './score_timer';
import NoMatch from './no_match';
import {
  isMatchFinished,
  isMatchReady,
  isMatchInPreparation,
  winner,
} from '../../modules/match_lenses';

function victoryScreen(props) {
  const { match, tournamentId, colorsInverted } = props;

  const _winner = winner(match);
  const _winnerIndex = R.findIndex(R.propEq('id', _winner.id))(match.competitors)
  const color = props.competitorColorClass(colorsInverted, _winnerIndex % 2 === 1)

  return (
    <VictoryScreen
      competitorName={_winner.name}
      clubName={_winner.club}
      color={color}
      />
  )
}

function phaseName(phase) {
  return {
    "F": "Final",
    "SF": "Semi-Final",
    "QF": "Quarter-Final",
  }[phase] || "Qualifier";
}


function publicScore(props) {
  const {
    match,
    tournamentId,

    timer,
    elapsed,
    running,

    competitorColorClass,
    colorsInverted
  } = props;

  const competitorScores = R.addIndex(R.map)((cm, i) => (
      <div className={`score-competitor score-competitor--${competitorColorClass(colorsInverted, i % 2 === 1)}`}>
      <ScoreCompetitor
        match={match}
        competitor={cm}
        tournamentId={tournamentId}
        />
    </div>
  ))(match.competitors)

  return(
    <div className="public-score">
      <div className="row">
        <div className="col-xs-12">
          <div className="score__competitors">
            {competitorScores[0]}
            {competitorScores[1]}
          </div>
        </div>
      </div>
      <div className={`row score__match-details ${running ? 'score__match-details--running' : ''}`}>
        <div className="col-xs-5">
          <ScoreTimer
            timer={timer}
            running={running}
            elapsed={elapsed}
            />
        </div>
        <div className="col-xs-7">
          <div className="score__category-details">
            <div className="score__phase">
              {phaseName(match.phase)}
            </div>
            <div className="score__category-name">
              {match.category_name}
            </div>
          </div>
        </div>
      </div>
    </div>)
}

export default function PublicScore(props) {
  const {
    matNumber,
    match,

    timer,
    elapsed,
    timerIsRunning,

    competitorColorClass,
    colorsInverted
  } = props;

  const noMatch = () =>
    <NoMatch matNumber={matNumber}/>

  let component;

  if (R.isNil(match.id) || isMatchInPreparation(match)) {
    component = noMatch();
  } else if (isMatchFinished(match)) {
    component = victoryScreen(props);
  } else {
    component = publicScore(props);
  }

  return (
    <Popout
      url="/admin/tournaments/652/tournament_days/5/mats/2/public?fluid=true"
      title='public score'
      options={{
        height: (options, w) => { return w.outerHeight },
        width: (options, w) => { return w.outerWidth },
        scrollbars: false
      }}>
      {component}
    </Popout>
  );
}

PublicScore.propTypes = {
  match: PropTypes.object.isRequired,
}
