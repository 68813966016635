import React, { Component, PropTypes } from 'react';

export default function ColorLegend(props) {
  const {
    label
  } = props;

  return (
    <div className="color-legend">
      <span className="color-legend__title">
        <i className="fa fa-tag fa-2x"/>
        <span className="color-legend__label">{props.label || "Label"}</span>
      </span>
      <ul className="color-legend__list list-unstyled list-inline">
        <li>
          <span className="color-legend__color-box match-competitor--assigned"></span>
          <span className="color-legend__color-name">Weight not verified</span>
        </li>
        <li>
          <span className="color-legend__color-box match-competitor--checked_in"></span>
          <span className="color-legend__color-name">Check-in done. <br/>Go to weigh in</span>
        </li>
        <li>
          <span className="color-legend__color-box match-competitor--ready"></span>
          <span className="color-legend__color-name">Verified. Ready to fight</span>
        </li>
        <li>
          <span className="color-legend__color-box match-competitor--weighted"></span>
          <span className="color-legend__color-name">Waiting Check-in</span>
        </li>
        <li>
          <span className="color-legend__color-box match-competitor--no_show"></span>
          <span className="color-legend__color-name">No Show Reported</span>
        </li>
      </ul>
    </div>
  )
}
