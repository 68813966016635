import R from 'ramda';
import formatMinutes from '../modules/format_minutes';

import constById from './const_by_id';
import Badge from './badge';

const CategoryGroup = (props) => {
  const {
    categoryGroup: {
      id,
      name,
      age_division_ids,
      weight_division_ids,
      belt_ids,
      gender_ids,
      mat_numbers,
      phases,
    },
    onHighlight,
    onEdit,
    onDelete,
    isOver,
    connectDragSource,
    connectDropTarget,
    duration
  } = props;

  const renderBadges = (model, id) =>
    R.map((id) => (<Badge key={id} name={constById[model][id]}/>), id)

  const renderMats = R.map((number) =>
    (<Badge key={number} name={number}/>))

  return (connectDragSource(connectDropTarget(
    <div className={`category-group panel panel-default ${isOver ? 'category-group--is-over' : ''}`}>
      <div className="panel-body">
        <div className="category-group__attributes">
          <span className="category-group__name">{name}</span>
          <div className="category-group__duration"><Badge name={formatMinutes(duration)}/></div>
          <div className="category-group__age-divisions">{renderBadges('Age', age_division_ids)}</div>
          <div className="category-group__belts">{renderBadges('Belt', belt_ids)}</div>
          <div className="category-group__gender">{renderBadges('Gender', gender_ids)}</div>
          <div className="category-group__weight-divisions">{renderBadges('Weight', weight_division_ids)}</div>
          <div className="category-group__mats">{renderMats(mat_numbers.sort())}</div>
          <div className="category-group__phases">{renderMats(phases)}</div>
          <div className="category-group__actions">
            <a href="#" onClick={() => onHighlight(props.categoryGroup)} className="btn btn-default" >
              <i className="fa fa-lightbulb-o"/>
            </a>
            <a href="#" onClick={() => onEdit(id)} className="btn btn-default" >
              <i className="fa fa-pencil"/>
            </a>
            <a href="#" onClick={() => onDelete(id)} className="btn btn-default btn-danger" >
              <i className="fa fa-trash"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )))
}

export default CategoryGroup;
