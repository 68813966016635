
class SelectAll {
  constructor(selectAll, checkboxes) {
    selectAll.on("click", (e) => {
      checkboxes.prop("checked", selectAll.prop("checked"));
    })
  }
}

export default SelectAll;
