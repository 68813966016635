import R from 'ramda';
import { DragSource } from 'react-dnd';
import { Component } from 'react';

import { CATEGORY_GROUP } from '../../shared/constants';

const categoryGroupSource = {
  beginDrag(props, monitor, component) {
    const cs = R.map((category) => {
      return { categoryId: category.id, competitors: props.competitors }
    })(props.categories)

    return {
      categories: cs,
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

function DraggableEstimateDisplayHOC(WrappedComponent, source = CATEGORY_GROUP, sourceFn = categoryGroupSource) {
  const Draggable = class extends Component {
    constructor(props) {
      super(props);
      this.state = { competitors: null };
    }

    onChange(newState) {
      this.setState({ competitors: newState });
    }

    render() {
      const { connectDragSource } = this.props;

      return connectDragSource(
        <span className="draggable-estimate-display">
          <WrappedComponent onChange={this.onChange.bind(this)} {...this.props} />
        </span>
      )
    }
  }

  return DragSource(source, sourceFn, collect)(Draggable);
}

export default DraggableEstimateDisplayHOC;
