
import React, { PropTypes } from 'react';

import CompetitorsListContainer from '../containers/competitors_list_container';

export default class OpcCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false || props.isOpen }
  }

  componentDidMount() {
    const { category } = this.props;
    const _this = this;

    $(document).on("show.bs.collapse", `#collapse-${category.id}`, (ev) => {
      _this.props.onOpen && _this.props.onOpen(category);
      _this.setState({ open: true });
    })

    $(document).on("hide.bs.collapse", `#collapse-${category.id}`, (ev) => {
      _this.setState({ open: false });
    })
  }

  render() {
    const { category, color, admin } = this.props;

    const competitors = this.state.open ?
      <CompetitorsListContainer
        category={category}
        showSubscribeButton={admin && !category.subscriptions_closed}
        printReceipt={this.props.printReceipt}
        onSubscribe={this.props.onSubscribe}
        onUnsubscribe={this.props.onUnsubscribe}
        onShowUnsubscriptionHistoryDialog={this.props.onShowUnsubscriptionHistoryDialog} /> :
      null;

    const toggleSwitch = this.props.toggleSwitch ?
      <this.props.toggleSwitch/> :
      null;

    return (
      <div className="panel panel-default">
        <div className={`panel-heading panel-heading--${color} opc-category--${!category.subscriptions_closed ? 'open' : 'closed'}`} role="tab" id={`${category.id}`}>
          <h4 className="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#opc-list" href={`#collapse-${category.id}`}>
              {category.name}
            </a>
            {toggleSwitch}
          </h4>
        </div>
        <div id={`collapse-${category.id}` } className="panel-collapse collapse" role="tabpanel">
          <div className="panel-body">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Division</th>
                  <th>Placement</th>
                  <th>Academy</th>
                  <th></th>
                </tr>
              </thead>
              {competitors}
            </table>
          </div>
        </div>
      </div>
    )
  }
}

OpcCategory.propTypes = {
  category: PropTypes.object.isRequired,
  color: PropTypes.string,
  toggleSwitch: PropTypes.func,
  onOpen: PropTypes.func,
}
