import { transliterate } from 'transliteration';
import moment from 'moment-timezone';

export default class OpcReceipt {
  printInstructions(tournamentSubscription) {
    const receipt = JSON.stringify({
      epson_epagente_instructions: this.epsonEpagenteInstructions(tournamentSubscription),
      esc_pos_instructions: this.escPosInstructions(tournamentSubscription)
    })

    return {
      epson_epagente_instructions: this.epsonEpagenteInstructions(tournamentSubscription),
      esc_pos_instructions: this.escPosInstructions(tournamentSubscription)
    }
  }

  escPosInstructions(ts) {
    return `
      printer
        .align('ct')

        .size(2, 2)
        .style('b')
        .text('------------------------')
        .text('${this.receiptPresenter(ts).subscription_id}')
        .text('------------------------')

        .text('')

        .size(1, 1)
        .align('lt')

        .style('b')
        .text('TOURNAMENT')
        .style('NORMAL')
        .text("${this.sanitizeForEscPosInstructions(this.receiptPresenter(ts).tournament)}")

        .text('')

        .style('b')
        .text('COMPETITOR')
        .style('NORMAL')
        .text("${this.sanitizeForEscPosInstructions(this.receiptPresenter(ts).competitor)}")

        .text('')

        .style('b')
        .text('CLUB')
        .style('NORMAL')
        .text("${this.sanitizeForEscPosInstructions(this.receiptPresenter(ts).club)}")

        .text('')

        .style('b')
        .text('CATEGORY')
        .style('NORMAL')
        .text("${this.sanitizeForEscPosInstructions(this.receiptPresenter(ts).category)}")

        .text('')

        .cut()
        .close()
    `
  }

  epsonEpagenteInstructions(ts) {
    return `
      ImprimeTextoTag|<ce><bmp></bmp></ce>
      |ImprimeTextoTag|<ce><b>--------------</b></ce>
      |ImprimeTextoTag|<ce><xl>${this.receiptPresenter(ts).subscription_id}</xl></ce>
      |ImprimeTextoTag|<ce><b>--------------</b></ce>
      |ImprimeTextoTag|
        <b>Tournament:</b>
        <n>${this.receiptPresenter(ts).tournament}</n>
      |ImprimeTextoTag|
        <b>Competitor:</b>
        <n>${this.receiptPresenter(ts).competitor}</n>
      |ImprimeTextoTag|
        <b>Club:</b>
        <n>${this.receiptPresenter(ts).club}</n>
      |ImprimeTextoTag|
        <b>Category:</b>
        <n>${this.receiptPresenter(ts).category}</n>
      |ImprimeTextoTag|<gui></gui>
      |
      .
    `
  }

  sanitizeForEscPosInstructions(content) {
    return transliterate(content).replace("\"", "'");
  }

  receiptPresenter(ts) {
    return {
      subscription_id: String(ts.subscription_id).padStart(12, '0'),
      tournament: ts.tournament,
      competitor: ts.competitor,
      club: ts.club,
      category: ts.category.replace('|', '/'),
      created_at: moment
        .tz(ts.created_at, moment.tz.guess())
        .format("dddd, MMMM Do YYYY, h:mm:ss a"),
    }
  }
}
