import { DropTarget } from 'react-dnd';
import { CATEGORY_GROUP } from '../../shared/constants';

const categoryGroupTarget = {
  drop(props, monitor) {
    props.onDropCategoryGroup(monitor.getItem())
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

function CategoryGroupDropArea(props) {
  const {
    connectDropTarget,
  } = props;

  return connectDropTarget(
    <div className="droppable-category-list">
      {props.children}
    </div>
  )
}

export default DropTarget(CATEGORY_GROUP, categoryGroupTarget, collect)(CategoryGroupDropArea);
