import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import settings from './reducers';
import thunkMiddleware from 'redux-thunk';
import apiMiddleware from '../middleware/api_middleware';
import SettingsContainer from './containers/settings_container';
import createLogger from 'redux-logger';
import { reducer as formReducer } from 'redux-form';
import I18n from 'i18n-js';

const logger = createLogger();

const store = createStore(
  combineReducers({ app: settings, form: formReducer }),
  applyMiddleware(thunkMiddleware, apiMiddleware, logger)
);

class SettingsApp extends React.Component {
  render() {
    I18n.locale = this.props.locale;

    return (
      <Provider store={store}>
        <SettingsContainer {...this.props}/>
      </Provider>
    )
  }
}

export default SettingsApp;
