
import R from 'ramda';
import * as C from '../modules/category_constants';

let constById = {}

R.forEachObjIndexed((value, key) => {
  constById = R.assoc(key, R.invertObj(value), constById)
}, C)

export default constById;
