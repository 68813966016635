
import R from 'ramda';
import React from 'react';

class QueryStringForm extends React.Component {
  renderArrayMultipleField(item, index) {

    const onClick = (e) => {
      const value = parseInt(e.target.dataset.value, 10)

      this.props.onChangeField(item.name)(
        isChecked(value) ?
          R.reject(R.equals(value), item.value) :
          R.union([value], item.value)
      );
    }

    const isChecked = R.contains(R.__, item.value)

    return (
      <div key={index} className="form-group">
        <label className="control-label">{item.label}</label>
        <div>
          {
            R.addIndex(R.map)((arrayItem, j) => (
              <button
                key={j}
                data-value={arrayItem}
                onClick={onClick.bind(this)}
                className={`btn ${isChecked(arrayItem) ? 'btn-primary' : 'btn-default'}`}
                value={item.value}>{arrayItem}</button>), item.items)
          }
        </div>
      </div>
    );
  }

  renderNumberField(item, index) {
    const onChange = (e) => {
      this.props.onChangeField(item.name)(e.target.value);
    }

    return (
      <div key={index} className="form-group">
        <label className="control-label">{item.label}</label>
        <input onChange={onChange.bind(this)} className="form-control" type="number" min={item.min} max={item.max} step={item.step} value={item.value}/>
      </div>
    );
  }

  renderForm(form) {
    return (R.addIndex(R.map)((item, i) => {
      return (this[`render${item.kind}`](item, i));
    }, form));
  }

  render () {
    return (
      <div>
        {this.renderForm(this.props.form)}
      </div>
    );
  }
}

export default QueryStringForm;
