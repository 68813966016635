import R from 'ramda';
import React, { PropTypes } from 'react';
import { Modal, Button, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';

export default class OpcUnsubscribeDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {data: props.data, isSubmitting: false}
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.setState({data: this.props.data, isSubmitting: false})
    }
  }

  handleChange(evt) {
    this.setState({data: R.merge(this.state.data, {[evt.target.name]: evt.target.value})})
  }

  handleSubmit(evt) {
    evt.preventDefault()
    this.setState({isSubmitting: true})
    this.props.onSubmit(this.state.data)
  }

  render() {
    return(
      <Modal className="opc-unsubscribe-dialog" show={this.props.show} onHide={this.props.onClose}>
        <form onSubmit={this.handleSubmit} noValidate autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>OPC Unsubscription Confirmation</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormGroup>
              <ControlLabel>Please, select a reason:</ControlLabel>
              <FormControl componentClass="select" placeholder="Reason" name="reason" onChange={this.handleChange}>
                <option value="coach_request">Coach Request</option>
                <option value="athlete_request">Athlete Request</option>
                <option value="other_reason">Other Reason</option>
              </FormControl>
            </FormGroup>

            <FormGroup>
              <ControlLabel>Observation (optional)</ControlLabel>
              <FormControl componentClass="textarea" name="observation" onChange={this.handleChange} />
            </FormGroup>
          </Modal.Body>

          <Modal.Footer>
            <Button bsStyle="default" onClick={this.props.onClose}>
              Close
            </Button>
            <Button type="submit" bsStyle="danger" onClick={this.onSubmit} disabled={this.state.isSubmitting}>
              Confirm
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

OpcUnsubscribeDialog.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.object
}


