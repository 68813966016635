import R from 'ramda';
import { Component } from 'react';
import { Panel } from 'rc-collapse';
import { DragSource } from 'react-dnd';
import { CATEGORY } from '../../shared/constants';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { sortAssignedMatches } from '../../modules/sort_assigned_matches';

const categorySource = {
  beginDrag(props) {
    return {
      selectedMatches: sortAssignedMatches(props.matches)
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}

class DraggablePanel extends Component {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }

  render() {
    const {
      connectDragSource,
      isDragging,
    } = this.props;

    return connectDragSource(<div><Panel {...this.props} /></div>);
  }
}

export default DragSource(CATEGORY, categorySource, collect)(DraggablePanel);
