import R from 'ramda';

class DurationCalculator {
  matchesNumber(category) {
    const subscriptionsCount = category.competitors_count;

    switch(subscriptionsCount) {
      case 0:
        return 0;
      case 1:
        return 0;
      case 3:
        return 3;
      default:
        return subscriptionsCount - 1;
    }
  }

  totalTime(categories, areas = 1, interval = 0, intervalUnit = 'min') {

    const oneCategoryTime = (category) =>
      category.time * this.matchesNumber(category)

    const intervalTime = intervalUnit === 'min' ?
      (category) => interval * this.matchesNumber(category) :
      (category) => oneCategoryTime(category) * (interval / 100)

    const oneCategoryTimeWithInterval = (category) =>
      oneCategoryTime(category) + intervalTime(category)

    return R.pipe(
      R.map(oneCategoryTimeWithInterval),
      R.sum,
      R.divide(R.__, areas)
    )(categories)
  }

  totalCompetitors(categories) {
    return R.pipe(
      R.map(R.prop('competitors_count')),
      R.sum
    )(categories)
  }
}

export default DurationCalculator;
