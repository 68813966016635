import R from 'ramda';
import CategoriesAccordeon, { Display } from './categories_accordeon';
import StartTimeSelectorDisplayHOC from "./start_time_selector_display_hoc"
import { inputWrapper } from '../../shared/components/form_inputs';

const StartTimeSelectorDisplay = StartTimeSelectorDisplayHOC(
  Display
);

const TournamentOpenClassCategoriesInput = (props) => {
  const { tournamentDays, input } = props;

  const openClassCategories =
      R.filter((c) => R.contains(R.prop('weight_division_id', c), [10, 11, 12]), input.value)

  const handleUpdate = (field, updatedCategories, value) => { 
    const previousValue = Array.from(input.value)
    const updatedIds = R.map(R.prop('id'), updatedCategories)

    input.onChange(
      R.pipe(
        R.reduce((acc, category) => {
          return R.contains(category.id, updatedIds) ? R.append(R.assoc(field, value, category), acc) : acc
        }, []),
        R.unionWith(R.eqBy(R.prop('id')), R.__, previousValue)
      )(openClassCategories)
    )
  }

  const handleTimeUpdate = (field, updatedCategories, value) => {
    const [_, hour, minute] = value.match(/(\d+):(\d+)/)
    const previousValue = Array.from(input.value)
    const updatedIds = R.map(R.prop('id'), updatedCategories)

    input.onChange(
      R.pipe(
        R.reduce((acc, category) => {
          if (R.contains(category.id, updatedIds)) {
            return R.pipe(
              R.assoc(`${field}_hour`, hour),
              R.assoc(`${field}_minute`, minute),
              R.append(R.__, acc) 
            )(category)
          }
          else 
            return acc

        }, []),
        R.unionWith(R.eqBy(R.prop('id')), R.__, previousValue)
      )(openClassCategories)
    )
  }

  return inputWrapper(
    <CategoriesAccordeon 
      categories={openClassCategories}
      display={StartTimeSelectorDisplay}
      displayProps={{ 
        tournamentDays: tournamentDays,
        onChangeDay: (categories, value) => handleUpdate('subscription_tournament_day_id', categories, value),
        onChangeEstimatedStartTime: (categories, value) => handleTimeUpdate('estimated_start_time', categories, value),
        onChangeSubscriptionDeadline: (categories, value) => handleTimeUpdate('subscription_deadline', categories, value)
      }}
    />
  )(props)
}

export default TournamentOpenClassCategoriesInput;