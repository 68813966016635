import R from 'ramda';
import moment from 'moment-timezone';
import Popout from 'react-popout';
import ColorLegend from '../../shared/components/color_legend';

PublicCheckin.defaultProps = {
  autoscroll: true
}

function PublicCheckin(props) {
  const {
    tournamentId,
    tournamentDayId
  } = props;

  return (
    <Popout
      url={`/admin/tournaments/${tournamentId}/tournament_days/${tournamentDayId}/checkins/public`}
      title='Public Checkin'
      options={{
        height: (options, w) => { return w.outerHeight },
        width: (options, w) => { return w.outerWidth },
        scrollbars: false
      }}>
      {content(props)}
    </Popout>
  )
}

function content(props) {
  const { autoscroll } = props;

  return (<div className="tournament-day__grid--checkin">
    <ColorLegend label={'Checkin'}/>
    <div className="sliding-columns" data={{ autoscroll: autoscroll }} >
      <div className="sliding-columns__button-left">
        <i className="fa fa-lg fa-chevron-left"></i>
      </div>
      <ul className="list-unstyled sliding-columns__columns">
        <div className="sliding-columns__cursor"></div>
        {R.map(column)(props.mats)}
      </ul>
    </div>
  </div>);
}

function column(mat) {
  return (
    <li className="sliding-columns__column">
      <div className="grid-column__header">{`Mat ${mat.number}`}</div>
      <ul className="list-unstyled tournament-day__mats">
        {R.pipe(R.reject((m) => R.contains(R.prop('state', m), ['finished', 'started'])), R.take(5), R.map(card))(mat.matches)}
      </ul>
    </li>
  );
}

function card(card) {
  switch (card.kind) {
    case 'Match':
      return matchCard(card);
    case 'Gap':
      return gapCard(card);
    default:
      return null;
  }
}

function matchCard(match) {
  const classes = (competitor, color) => R.join(' ')([
    `match-card__competitor-state--${color}`,
    `match-competitor--${competitor['state']}`,
  ]);

  return (
    <li>
      {matchHeader(match)}
      <div className={`match-card match-card--${match.phase}`}>
        <div className={`match-card__competitor match-card__competitor--red ${classes(match.competitors[0], 'red')}`}>
          {slot(match.competitors[0])}
        </div>
        <hr/>
        <div className={`match-card__competitor ${classes(match.competitors[1], 'blue')}`}>
          {slot(match.competitors[1])}
        </div>
      </div>
    </li>
  );
}

function gapCard(gap) {
  return (
    <div className="gap-card panel panel-default">
      <span style={{ marginRight: 5 }}>{gap.description}</span>
      <span>({gap.duration} minutes)</span>
    </div>
  )
}

function matchHeader(match) {
  const when = (
    <span>
      <span>{`${moment.parseZone(match['current_start_time']).format('h:mm A')}:`}</span>
      <span className='match-header__fight'>{`FIGHT ${match['order'] + 1}:`}</span>
    </span>
  );

  const phase = `(${match['phase']})`;

  return (
    <div className="match-header">
      <div className="match-header__bracket">
        <span className="match-header__when">{when}</span>
        <span className="match-header__phase">{phase}</span>
      </div>
      <div className="match-header__category-name">{match['category_name']}</div>
    </div>
  );
}

function slot(subject) {
  switch (subject.kind) {
    case 'competitor':
      return competitorSlot(subject);
    case 'match':
      return childMatchSlot(subject);
    default:
      return placeholderSlot();
  }
}

function competitorSlot(competitor) {
  return (
    <div>
      <span className={`match-card__competitor-description`}>
        <div className="match-card__competitor-name">{competitor.name}</div>
        <div className="match-card__club-name">{competitor.club}</div>
      </span>
    </div>
  );
}

function childMatchSlot(match) {
  const where = match["order"] !== undefined ?
    `${match.origin === 'loser' ? 'Defeated' : 'Winner'} of fight ${match.order + 1}, Mat ${match.mat}` :
    '-'

  const when = match["current_start_time"] !== undefined ?
    moment(match["current_start_time"]).format("dddd") :
    '-'

  return (
    <span className="match-card__child-description">
      <div className="match-card__child-where">{where}</div>
      <div className="match-card__child-where">{when}</div>
    </span>
  );
}

function placeholderSlot() {
  return (
    <div>
      <span className="match-card__competitor-n"></span>
      <span className={`match-card__competitor-description`}>
        BYE
      </span>
    </div>
  );
}

export default PublicCheckin;
