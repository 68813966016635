
import ManualDraw from "../../modules/category/manual_draw";
import ApiClient from "../../modules/api_client";
import BracketPlumbing from '../../modules/bracket_plumbing';

function AdminCategoryShow () {
  this.manualDraw = new ManualDraw(".tournament-category",
    { flashMessage: Tenkaichi.flashMessage,
      apiClient: new ApiClient() })
  new BracketPlumbing();

    const desktopConnections = {
      "1": { target: "9", anchor: ["RightMiddle", "LeftMiddle"] },
      "2": { target: "9", anchor: ["RightMiddle", "LeftMiddle"] },
      "3": { target: "10", anchor: ["RightMiddle", "LeftMiddle"] },
      "4": { target: "10", anchor: ["RightMiddle", "LeftMiddle"] },
      "5": { target: "11", anchor: ["LeftMiddle", "RightMiddle"] },
      "6": { target: "11", anchor: ["LeftMiddle", "RightMiddle"] },
      "7": { target: "12", anchor: ["LeftMiddle", "RightMiddle"] },
      "8": { target: "12", anchor: ["LeftMiddle", "RightMiddle"] },
      "9": { target: "13", anchor: ["Bottom", "Top"] },
      "10": { target: "13", anchor: ["Top", "Bottom"] },
      "11": { target: "14", anchor: ["Bottom", "Top"] },
      "12": { target: "14", anchor: ["Top", "Bottom"] },
      "13": { target: "15", anchor: ["RightMiddle", [0.44, 1, 0, 1]] },
      "14": { target: "15", anchor: ["LeftMiddle", [0.56, 1, 0, 1]] },
    }

    $(".tournament-category__bracket").each((i, bracket) => {
      new BracketPlumbing(bracket, i, desktopConnections);
    });

  $('[data-toggle="tooltip"]').tooltip();
}

export default AdminCategoryShow;
