import R from 'ramda';

export const compareBy = R.curry(function (fields, a, b) {
  return fields.reduce(function(memo, field){
    if (R.equals(a[field], b[field]) || memo !== 0)
      return memo;

    return (a[field] > b[field]) ? 1 : -1;
  }, 0)
})
