/*
File generated by js-routes 1.3.0
Based on Rails routes of Tenkaichi::Application
 */

(function() {
  var NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, Utils, createGlobalJsRoutesObject, defaults, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message) {
    this.message = message;
  };

  ParameterMissing.prototype = new Error();

  defaults = {
    prefix: "",
    default_url_options: {}
  };

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  SpecialOptionsKey = "_options";

  ReservedOptions = ['anchor', 'trailing_slash', 'host', 'port', 'protocol'];

  Utils = {
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    custom_serializer: null,
    serialize: function(object) {
      if ((this.custom_serializer != null) && this.get_object_type(this.custom_serializer) === "function") {
        return this.custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[SpecialOptionsKey];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[SpecialOptionsKey] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          property = object.to_param;
        } else if ("id" in object) {
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(default_parts, required_parameters, optional_parts, actual_parameters) {
      var i, j, key, len, options, result, url_parameters, value;
      options = this.extract_options(required_parameters.length, actual_parameters);
      if (actual_parameters.length > required_parameters.length) {
        throw new Error("Too many parameters provided for path");
      }
      options = this.merge(defaults.default_url_options, default_parts, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      for (i = j = 0, len = required_parameters.length; j < len; i = ++j) {
        value = required_parameters[i];
        if (i < actual_parameters.length) {
          url_parameters[value] = actual_parameters[i];
        }
      }
      return result;
    },
    build_route: function(required_parameters, optional_parts, route, default_parts, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(default_parts, required_parameters, optional_parts, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          if (value != null) {
            delete parameters[left];
            return this.path_identifier(value);
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, "") !== left) {
        route[1] = left = left.replace(/^\*/i, "");
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = defaults.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(required_parts, optional_parts, route_spec, default_parts, full_url) {
      var path_fn;
      path_fn = function() {
        return Utils.build_route(required_parts, optional_parts, route_spec, default_parts, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      protocol = route_defaults.protocol || Utils.current_protocol();
      hostname = route_defaults.host || window.location.hostname;
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + hostname + port;
    },
    has_location: function() {
      return typeof window !== 'undefined' && typeof window.location !== 'undefined';
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    }
  };

  createGlobalJsRoutesObject = function() {
    var namespace;
    namespace = function(mainRoot, namespaceString) {
      var current, parts;
      parts = (namespaceString ? namespaceString.split(".") : []);
      if (!parts.length) {
        return;
      }
      current = parts.shift();
      mainRoot[current] = mainRoot[current] || {};
      return namespace(mainRoot[current], parts.join("."));
    };
    namespace(root, "Routes");
    root.Routes = {
// admin => /admin(.:format)
  // function(options)
  admin_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]], {}),
// admin_operator => /admin/operators/:id(.:format)
  // function(id, options)
  admin_operator_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"operators",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// admin_operators => /admin/operators(.:format)
  // function(options)
  admin_operators_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"operators",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], {}),
// admin_server_events => /admin/server_events(.:format)
  // function(options)
  admin_server_events_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"server_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], {}),
// admin_sidekiq_web => /admin/sidekiq
  // function(options)
  admin_sidekiq_web_path: Utils.route([], [], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"sidekiq",false]]]], {}),
// admin_tournament => /admin/tournaments/:id(.:format)
  // function(id, options)
  admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// admin_tournament_category => /admin/tournaments/:tournament_id/categories/:id(.:format)
  // function(tournament_id, id, options)
  admin_tournament_category_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// admin_tournament_dashboard_index => /admin/tournaments/:tournament_id/dashboard(.:format)
  // function(tournament_id, options)
  admin_tournament_dashboard_index_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournament_duration => /admin/tournaments/:tournament_id/durations/:id(.:format)
  // function(tournament_id, id, options)
  admin_tournament_duration_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"durations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// admin_tournament_durations => /admin/tournaments/:tournament_id/durations(.:format)
  // function(tournament_id, options)
  admin_tournament_durations_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"durations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournament_medal_grouping => /admin/tournaments/:tournament_id/medal_groupings/:id(.:format)
  // function(tournament_id, id, options)
  admin_tournament_medal_grouping_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"medal_groupings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// admin_tournament_medal_groupings => /admin/tournaments/:tournament_id/medal_groupings(.:format)
  // function(tournament_id, options)
  admin_tournament_medal_groupings_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"medal_groupings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournament_open_class_subscriptions => /admin/tournaments/:tournament_id/open_class_subscriptions(.:format)
  // function(tournament_id, options)
  admin_tournament_open_class_subscriptions_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"open_class_subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournament_podiums => /admin/tournaments/:tournament_id/podiums(.:format)
  // function(tournament_id, options)
  admin_tournament_podiums_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"podiums",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournament_publications => /admin/tournaments/:tournament_id/publications(.:format)
  // function(tournament_id, options)
  admin_tournament_publications_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"publications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournament_reports => /admin/tournaments/:tournament_id/reports(.:format)
  // function(tournament_id, options)
  admin_tournament_reports_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournament_schedules => /admin/tournaments/:tournament_id/schedules(.:format)
  // function(tournament_id, options)
  admin_tournament_schedules_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournament_single_competitors => /admin/tournaments/:tournament_id/single_competitors(.:format)
  // function(tournament_id, options)
  admin_tournament_single_competitors_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"single_competitors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournament_tournament_day => /admin/tournaments/:tournament_id/tournament_days/:id(.:format)
  // function(tournament_id, id, options)
  admin_tournament_tournament_day_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// admin_tournament_tournament_day_checkins => /admin/tournaments/:tournament_id/tournament_days/:tournament_day_id/checkins(.:format)
  // function(tournament_id, tournament_day_id, options)
  admin_tournament_tournament_day_checkins_path: Utils.route(["tournament_id","tournament_day_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"checkins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// admin_tournament_tournament_day_dashboard_index => /admin/tournaments/:tournament_id/tournament_days/:tournament_day_id/dashboard(.:format)
  // function(tournament_id, tournament_day_id, options)
  admin_tournament_tournament_day_dashboard_index_path: Utils.route(["tournament_id","tournament_day_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// admin_tournament_tournament_day_mat => /admin/tournaments/:tournament_id/tournament_days/:tournament_day_id/mats/:id(.:format)
  // function(tournament_id, tournament_day_id, id, options)
  admin_tournament_tournament_day_mat_path: Utils.route(["tournament_id","tournament_day_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"mats",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], {}),
// admin_tournament_tournament_day_presences => /admin/tournaments/:tournament_id/tournament_days/:tournament_day_id/presences(.:format)
  // function(tournament_id, tournament_day_id, options)
  admin_tournament_tournament_day_presences_path: Utils.route(["tournament_id","tournament_day_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"presences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// admin_tournament_tournament_day_weighings => /admin/tournaments/:tournament_id/tournament_days/:tournament_day_id/weighings(.:format)
  // function(tournament_id, tournament_day_id, options)
  admin_tournament_tournament_day_weighings_path: Utils.route(["tournament_id","tournament_day_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"weighings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// admin_tournament_tournament_days => /admin/tournaments/:tournament_id/tournament_days(.:format)
  // function(tournament_id, options)
  admin_tournament_tournament_days_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// admin_tournaments => /admin/tournaments(.:format)
  // function(options)
  admin_tournaments_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], {}),
// admin_user => /admin/users/:id(.:format)
  // function(id, options)
  admin_user_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// admin_user_group => /admin/user_groups/:id(.:format)
  // function(id, options)
  admin_user_group_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// admin_user_groups => /admin/user_groups(.:format)
  // function(options)
  admin_user_groups_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], {}),
// admin_users => /admin/users(.:format)
  // function(options)
  admin_users_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], {}),
// api_ibjjf_age_divisions => /api/ibjjf/age_divisions(.:format)
  // function(options)
  api_ibjjf_age_divisions_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"age_divisions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// api_ibjjf_belts => /api/ibjjf/belts(.:format)
  // function(options)
  api_ibjjf_belts_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"belts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// api_ibjjf_disqualifications => /api/ibjjf/disqualifications(.:format)
  // function(options)
  api_ibjjf_disqualifications_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"disqualifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// api_ibjjf_genders => /api/ibjjf/genders(.:format)
  // function(options)
  api_ibjjf_genders_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"genders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// api_ibjjf_match_decisions => /api/ibjjf/match_decisions(.:format)
  // function(options)
  api_ibjjf_match_decisions_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"match_decisions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// api_ibjjf_medal_deliveries => /api/ibjjf/medal_deliveries(.:format)
  // function(options)
  api_ibjjf_medal_deliveries_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"medal_deliveries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// api_ibjjf_time_limits => /api/ibjjf/time_limits(.:format)
  // function(options)
  api_ibjjf_time_limits_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"time_limits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// api_ibjjf_tournament => /api/ibjjf/tournaments/:id(.:format)
  // function(id, options)
  api_ibjjf_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// api_ibjjf_tournament_categories => /api/ibjjf/tournaments/:tournament_id/categories(.:format)
  // function(tournament_id, options)
  api_ibjjf_tournament_categories_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// api_ibjjf_tournament_category => /api/ibjjf/tournaments/:tournament_id/categories/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_category_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_category_bracket => /api/ibjjf/tournaments/:tournament_id/categories/:category_id/brackets/:id(.:format)
  // function(tournament_id, category_id, id, options)
  api_ibjjf_tournament_category_bracket_path: Utils.route(["tournament_id","category_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"brackets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_category_filters => /api/ibjjf/tournaments/:tournament_id/category_filters(.:format)
  // function(tournament_id, options)
  api_ibjjf_tournament_category_filters_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"category_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// api_ibjjf_tournament_category_group => /api/ibjjf/tournaments/:tournament_id/category_groups/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_category_group_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"category_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_category_groups => /api/ibjjf/tournaments/:tournament_id/category_groups(.:format)
  // function(tournament_id, options)
  api_ibjjf_tournament_category_groups_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"category_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// api_ibjjf_tournament_competitors => /api/ibjjf/tournaments/:tournament_id/competitors(.:format)
  // function(tournament_id, options)
  api_ibjjf_tournament_competitors_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"competitors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// api_ibjjf_tournament_duration_estimate => /api/ibjjf/tournaments/:tournament_id/duration_estimates/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_duration_estimate_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"duration_estimates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_duration_estimate_day => /api/ibjjf/tournaments/:tournament_id/duration_estimate_days/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_duration_estimate_day_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"duration_estimate_days",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_mat => /api/ibjjf/tournaments/:tournament_id/mats/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_mat_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"mats",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_match => /api/ibjjf/tournaments/:tournament_id/matches/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_match_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"matches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_matches => /api/ibjjf/tournaments/:tournament_id/matches(.:format)
  // function(tournament_id, options)
  api_ibjjf_tournament_matches_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"matches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// api_ibjjf_tournament_mats => /api/ibjjf/tournaments/:tournament_id/mats(.:format)
  // function(tournament_id, options)
  api_ibjjf_tournament_mats_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"mats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// api_ibjjf_tournament_medal => /api/ibjjf/tournaments/:tournament_id/medals/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_medal_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"medals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_podium => /api/ibjjf/tournaments/:tournament_id/podiums/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_podium_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"podiums",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_podiums => /api/ibjjf/tournaments/:tournament_id/podiums(.:format)
  // function(tournament_id, options)
  api_ibjjf_tournament_podiums_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"podiums",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// api_ibjjf_tournament_tournament_day => /api/ibjjf/tournaments/:tournament_id/tournament_days/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_tournament_day_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_tournament_subscription => /api/ibjjf/tournaments/:tournament_id/tournament_subscriptions/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_tournament_subscription_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_tournament_subscriptions => /api/ibjjf/tournaments/:tournament_id/tournament_subscriptions(.:format)
  // function(tournament_id, options)
  api_ibjjf_tournament_tournament_subscriptions_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// api_ibjjf_tournament_tournament_template => /api/ibjjf/tournaments/:tournament_id/tournament_templates/:id(.:format)
  // function(tournament_id, id, options)
  api_ibjjf_tournament_tournament_template_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// api_ibjjf_tournament_tournament_templates => /api/ibjjf/tournaments/:tournament_id/tournament_templates(.:format)
  // function(tournament_id, options)
  api_ibjjf_tournament_tournament_templates_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// api_ibjjf_weight_divisions => /api/ibjjf/weight_divisions(.:format)
  // function(options)
  api_ibjjf_weight_divisions_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"weight_divisions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// api_ibjjf_ws_tournament => /api/ibjjf/ws_tournaments/:id(.:format)
  // function(id, options)
  api_ibjjf_ws_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"ws_tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// batch_update_api_ibjjf_tournament_categories => /api/ibjjf/tournaments/:tournament_id/categories/batch_update(.:format)
  // function(tournament_id, options)
  batch_update_api_ibjjf_tournament_categories_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"batch_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// by_category_tournament_tournament_days => /tournaments/:tournament_id/tournament_days/by_category(.:format)
  // function(tournament_id, options)
  by_category_tournament_tournament_days_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[6,"by_category",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// by_club_tournament_tournament_days => /tournaments/:tournament_id/tournament_days/by_club(.:format)
  // function(tournament_id, options)
  by_club_tournament_tournament_days_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[6,"by_club",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// by_competitor_tournament_tournament_days => /tournaments/:tournament_id/tournament_days/by_competitor(.:format)
  // function(tournament_id, options)
  by_competitor_tournament_tournament_days_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[6,"by_competitor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// cache_open_class_ranks_admin_tournament => /admin/tournaments/:id/cache_open_class_ranks(.:format)
  // function(id, options)
  cache_open_class_ranks_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cache_open_class_ranks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// clear_admin_tournament_publications => /admin/tournaments/:tournament_id/publications/clear(.:format)
  // function(tournament_id, options)
  clear_admin_tournament_publications_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"publications",false],[2,[7,"/",false],[2,[6,"clear",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// destroy_all_api_ibjjf_tournament_tournament_day_ordered_items => /api/ibjjf/tournaments/:tournament_id/tournament_days/:tournament_day_id/ordered_items/destroy_all(.:format)
  // function(tournament_id, tournament_day_id, options)
  destroy_all_api_ibjjf_tournament_tournament_day_ordered_items_path: Utils.route(["tournament_id","tournament_day_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"ordered_items",false],[2,[7,"/",false],[2,[6,"destroy_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], {}),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], {}),
// download_admin_tournament => /admin/tournaments/:id/download(.:format)
  // function(id, options)
  download_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// duplicate_api_ibjjf_tournament_tournament_template => /api/ibjjf/tournaments/:tournament_id/tournament_templates/:id/duplicate(.:format)
  // function(tournament_id, id, options)
  duplicate_api_ibjjf_tournament_tournament_template_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], {}),
// edit_admin_tournament => /admin/tournaments/:id/edit(.:format)
  // function(id, options)
  edit_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// edit_admin_tournament_category => /admin/tournaments/:tournament_id/categories/:id/edit(.:format)
  // function(tournament_id, id, options)
  edit_admin_tournament_category_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// edit_admin_tournament_medal_grouping => /admin/tournaments/:tournament_id/medal_groupings/:id/edit(.:format)
  // function(tournament_id, id, options)
  edit_admin_tournament_medal_grouping_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"medal_groupings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// edit_admin_tournament_setting => /admin/tournaments/:tournament_id/settings/:id/edit(.:format)
  // function(tournament_id, id, options)
  edit_admin_tournament_setting_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// edit_admin_user => /admin/users/:id/edit(.:format)
  // function(id, options)
  edit_admin_user_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// edit_admin_user_group => /admin/user_groups/:id/edit(.:format)
  // function(id, options)
  edit_admin_user_group_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// edit_settings_admin_tournament => /admin/tournaments/:id/edit_settings(.:format)
  // function(id, options)
  edit_settings_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// framework => /framework(.:format)
  // function(options)
  framework_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"framework",false],[1,[2,[8,".",false],[3,"format",false]],false]]], {}),
// hide_rankings_admin_tournament => /admin/tournaments/:id/hide_rankings(.:format)
  // function(id, options)
  hide_rankings_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"hide_rankings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// new_admin_tournament_duration => /admin/tournaments/:tournament_id/durations/new(.:format)
  // function(tournament_id, options)
  new_admin_tournament_duration_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"durations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// new_admin_tournament_medal_grouping => /admin/tournaments/:tournament_id/medal_groupings/new(.:format)
  // function(tournament_id, options)
  new_admin_tournament_medal_grouping_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"medal_groupings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// new_admin_user => /admin/users/new(.:format)
  // function(options)
  new_admin_user_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// new_admin_user_group => /admin/user_groups/new(.:format)
  // function(options)
  new_admin_user_group_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], {}),
// operators => /operators(.:format)
  // function(options)
  operators_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"operators",false],[1,[2,[8,".",false],[3,"format",false]],false]]], {}),
// print_admin_tournament => /admin/tournaments/:id/print(.:format)
  // function(id, options)
  print_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"print",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// print_with_schedule_admin_tournament => /admin/tournaments/:id/print_with_schedule(.:format)
  // function(id, options)
  print_with_schedule_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"print_with_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// progression_admin_tournament_category => /admin/tournaments/:tournament_id/categories/:id/progression(.:format)
  // function(tournament_id, id, options)
  progression_admin_tournament_category_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"progression",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// public_admin_tournament_podiums => /admin/tournaments/:tournament_id/podiums/public(.:format)
  // function(tournament_id, options)
  public_admin_tournament_podiums_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"podiums",false],[2,[7,"/",false],[2,[6,"public",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// public_admin_tournament_tournament_day_checkins => /admin/tournaments/:tournament_id/tournament_days/:tournament_day_id/checkins/public(.:format)
  // function(tournament_id, tournament_day_id, options)
  public_admin_tournament_tournament_day_checkins_path: Utils.route(["tournament_id","tournament_day_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"checkins",false],[2,[7,"/",false],[2,[6,"public",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], {}),
// public_admin_tournament_tournament_day_mat => /admin/tournaments/:tournament_id/tournament_days/:tournament_day_id/mats/:id/public(.:format)
  // function(tournament_id, tournament_day_id, id, options)
  public_admin_tournament_tournament_day_mat_path: Utils.route(["tournament_id","tournament_day_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"mats",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"public",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], {}),
// public_admin_tournament_tournament_day_presences => /admin/tournaments/:tournament_id/tournament_days/:tournament_day_id/presences/public(.:format)
  // function(tournament_id, tournament_day_id, options)
  public_admin_tournament_tournament_day_presences_path: Utils.route(["tournament_id","tournament_day_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"presences",false],[2,[7,"/",false],[2,[6,"public",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], {}),
// publication_tournament_categories => /publication/tournaments/:tournament_id/categories(.:format)
  // function(tournament_id, options)
  publication_tournament_categories_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"publication",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// publication_tournament_clubs => /publication/tournaments/:tournament_id/clubs(.:format)
  // function(tournament_id, options)
  publication_tournament_clubs_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"publication",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"clubs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// publication_tournament_gaps => /publication/tournaments/:tournament_id/gaps(.:format)
  // function(tournament_id, options)
  publication_tournament_gaps_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"publication",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"gaps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// publication_tournament_matches => /publication/tournaments/:tournament_id/matches(.:format)
  // function(tournament_id, options)
  publication_tournament_matches_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"publication",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"matches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// publication_tournament_open_class_subscriptions => /publication/tournaments/:tournament_id/open_class_subscriptions(.:format)
  // function(tournament_id, options)
  publication_tournament_open_class_subscriptions_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"publication",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"open_class_subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// publication_tournament_podiums => /publication/tournaments/:tournament_id/podiums(.:format)
  // function(tournament_id, options)
  publication_tournament_podiums_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"publication",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"podiums",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// publication_tournament_tournament_day_mats => /publication/tournaments/:tournament_id/tournament_days/:tournament_day_id/mats(.:format)
  // function(tournament_id, tournament_day_id, options)
  publication_tournament_tournament_day_mats_path: Utils.route(["tournament_id","tournament_day_id"], ["format"], [2,[7,"/",false],[2,[6,"publication",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"mats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// publish_admin_tournament => /admin/tournaments/:id/publish(.:format)
  // function(id, options)
  publish_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"publish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// publish_podiums_admin_tournament => /admin/tournaments/:id/publish_podiums(.:format)
  // function(id, options)
  publish_podiums_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"publish_podiums",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route(["signed_blob_id","variation_key","filename"], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route(["inbound_email_id"], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route(["encoded_key","filename"], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route(["signed_id","filename"], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// receipt_admin_tournament_open_class_subscription => /admin/tournaments/:tournament_id/open_class_subscriptions/:id/receipt(.:format)
  // function(tournament_id, id, options)
  receipt_admin_tournament_open_class_subscription_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"open_class_subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// redraw_admin_tournament_categories => /admin/tournaments/:tournament_id/categories/redraw(.:format)
  // function(tournament_id, options)
  redraw_admin_tournament_categories_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"redraw",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], {}),
// root => /
  // function(options)
  root_path: Utils.route([], [], [7,"/",false], {}),
// schedule_tournament => /tournaments/:id/schedule(.:format)
  // function(id, options)
  schedule_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// set_subscription_date_warning_admin_tournament => /admin/tournaments/:id/set_subscription_date_warning(.:format)
  // function(id, options)
  set_subscription_date_warning_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"set_subscription_date_warning",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// show_rankings_admin_tournament => /admin/tournaments/:id/show_rankings(.:format)
  // function(id, options)
  show_rankings_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_rankings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// single_competitors_tournament => /tournaments/:id/single_competitors(.:format)
  // function(id, options)
  single_competitors_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"single_competitors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// snapshot_admin_tournament => /admin/tournaments/:id/snapshot(.:format)
  // function(id, options)
  snapshot_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"snapshot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// tournament_categories => /tournaments/:tournament_id/categories(.:format)
  // function(tournament_id, options)
  tournament_categories_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// tournament_category => /tournaments/:tournament_id/categories/:id(.:format)
  // function(tournament_id, id, options)
  tournament_category_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// tournament_open_class => /tournaments/:tournament_id/open_classes/:id(.:format)
  // function(tournament_id, id, options)
  tournament_open_class_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"open_classes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// tournament_open_classes => /tournaments/:tournament_id/open_classes(.:format)
  // function(tournament_id, options)
  tournament_open_classes_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"open_classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], {}),
// tournament_tournament_day => /tournaments/:tournament_id/tournament_days/:id(.:format)
  // function(tournament_id, id, options)
  tournament_tournament_day_path: Utils.route(["tournament_id","id"], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// tournaments => /tournaments(.:format)
  // function(options)
  tournaments_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"tournaments",false],[1,[2,[8,".",false],[3,"format",false]],false]]], {}),
// unpublish_admin_tournament => /admin/tournaments/:id/unpublish(.:format)
  // function(id, options)
  unpublish_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unpublish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// unset_subscription_date_warning_admin_tournament => /admin/tournaments/:id/unset_subscription_date_warning(.:format)
  // function(id, options)
  unset_subscription_date_warning_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unset_subscription_date_warning",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// unsubscription_history_api_ibjjf_tournament_tournament_subscriptions => /api/ibjjf/tournaments/:tournament_id/tournament_subscriptions/unsubscription_history(.:format)
  // function(tournament_id, options)
  unsubscription_history_api_ibjjf_tournament_tournament_subscriptions_path: Utils.route(["tournament_id"], ["format"], [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ibjjf",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_subscriptions",false],[2,[7,"/",false],[2,[6,"unsubscription_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], {}),
// update_admin_tournament_tournament_day_dashboard => /admin/tournaments/:tournament_id/tournament_days/:tournament_day_id/dashboard/:id(.:format)
  // function(tournament_id, tournament_day_id, id, options)
  update_admin_tournament_tournament_day_dashboard_path: Utils.route(["tournament_id","tournament_day_id","id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"tournament_id",false],[2,[7,"/",false],[2,[6,"tournament_days",false],[2,[7,"/",false],[2,[3,"tournament_day_id",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], {}),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route(["encoded_token"], ["format"], [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// update_settings_admin_tournament => /admin/tournaments/:id/update_settings(.:format)
  // function(id, options)
  update_settings_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// upload_admin_tournament => /admin/tournaments/:id/upload(.:format)
  // function(id, options)
  upload_admin_tournament_path: Utils.route(["id"], ["format"], [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tournaments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], {}),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], {}),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([], ["format"], [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], {})}
;
    root.Routes.options = defaults;
    root.Routes.default_serializer = function(object, prefix) {
      return Utils.default_serializer(object, prefix);
    };
    return root.Routes;
  };

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return createGlobalJsRoutesObject();
    });
  } else {
    createGlobalJsRoutesObject();
  }

}).call(this);

