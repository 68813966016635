import React from 'react';
import R from 'ramda';
import Select from 'react-select';

export const filterCategories = R.curry((filter, categories) => 
  R.pipe(
    (categories) =>
      filter.age_division_ids.length > 0 ?
        R.filter((c) => R.contains(c.age_division_id, filter.age_division_ids), categories) :
        categories,
    (categories) =>
      filter.weight_division_ids.length > 0 ?
        R.filter((c) => R.contains(c.weight_division_id, filter.weight_division_ids), categories) :
        categories,
    (categories) =>
      filter.gender_ids.length > 0 ?
        R.filter((c) => R.contains(c.gender_id, filter.gender_ids), categories) :
        categories,
    (categories) =>
      filter.belt_ids.length > 0 ?
        R.filter((c) => R.intersection(c.belt_ids, filter.belt_ids).length > 0, categories) :
        categories
  )(categories)
)

export default class CategoryFilter extends React.Component {
  onChange(selected) {
    this.props.onChange && this.props.onChange(
      selected
        .reduce((acc, item) => {
          acc[item.param] = acc[item.param].concat(item.id)

          return acc
        }, {
          age_division_ids: [],
          weight_division_ids: [],
          gender_ids: [],
          belt_ids: []
        })
    )
  }

  render() {
    const {
      filters
    } = this.props;

    const options =
      filters
        .reduce((acc, item) => {

          const options = item.options
            .map((option) => ({
              value: option.name,
              label: option.name,
              param: item.param,
              id: option.id,
            }))

          return acc.concat({ label: item.name, options });
        }, [])

    return (
      <div className="category-filter">
        <Select
          isMulti={true}
          isSearchable={true}
          options={options}
          onChange={this.onChange.bind(this)}/>
      </div>
    )

  }
}
