import SelectAll from '../select_all';

function TournamentForm (el, options) {
  this.$el = $(el)
  this.flashMessage = options.flashMessage

  this.$selectAll          = this.$el.find(".tournament-form__select-all")
  this.$categoryCheckboxes = this.$el.find('.tournament-form__select')
  this.$actionButtons      = $('.batch-action')

  this.selectAll = new SelectAll(this.$selectAll, this.$categoryCheckboxes);

  this.$actionButtons.on("click", this.submitAction.bind(this))
}

TournamentForm.prototype.submitAction = function(e) {
  var $target = $(e.target),
      $form   = this.$el.get(0)

  e.preventDefault();
  e.stopPropagation();

  if (!this.anySelected()) {
     this.flashMessage.error("No Categories selected")
     return false
  } else {
    if ($target.data('action') !== undefined)
      $form.setAttribute('action', $target.data('action'))

    $form.submit()

    return false
  }
}

TournamentForm.prototype.anySelected = function(e) {
  return this.$el.find('input[type="checkbox"]:checked').length > 0
}

module.exports = TournamentForm
