import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import R from 'ramda';
import { ContextMenuTrigger } from 'react-contextmenu';

import { ContextMenu, MenuItem } from 'react-contextmenu';
import ColorLegend from '../../shared/components/color_legend';

import {
  matchCompetitorCompetitorName,
  matchCompetitorClubName,
} from '../../modules/match_lenses';

import {
  loadData,
} from '../actions';

import {
  updateMatch,
} from '../../shared/actions/api';

import {
  checkin,
  reportNoShow,
  weight,
  reset,
  undoCheckin,
  undoWeight,
  disqualifyByNoShow,
  disqualifyByWeight,
} from '../../modules/competitor_action_params';

class SingleCompetitorContainer extends Component {
  componentDidMount() {
    this.props.loadData(this.props.tournamentId)
  }

  checkin(fn, props) {
    return this.props.updateMatch(checkin(props.matchId, props.matchCompetitorId));
  }

  reportNoShow(fn, props) {
    return this.props.updateMatch(reportNoShow(props.matchId, props.matchCompetitorId));
  }

  weight(fn, params) {
    return this.props.updateMatch(weight(
      params.matchId,
      params.matchCompetitorId,
      params.tournamentSubscriptionId));
  }

  reset(_, params) {
    return this.props.updateMatch(reset(params.matchId))
  }

  undoCheckin(_, params) {
    this.props.updateMatch(undoCheckin(params.matchId, params.matchCompetitorId))
  }

  undoWeight(_, params) {
    return this.props.updateMatch(undoWeight(params.matchId, params.matchCompetitorId, params.tournamentSubscriptionId))
  }

  disqualifyByNoShow(_, params) {
    this.props.updateMatch(disqualifyByNoShow(params.matchId, params.matchCompetitorId))
  }

  disqualifyByWeight(_, params) {
    this.props.updateMatch(disqualifyByWeight(params.matchId, params.matchCompetitorId))
  }

  renderMatches(matches) {
    return R.addIndex(R.map)((match, i) =>
      this.renderMatch(match, i), matches);
  }

  renderMatch(match, i) {
    const competitor = match.competitors[0];

    function collect(props) {
      return {
        matchCompetitorId: competitor.match_competitor_id,
        matchId: match.id,
        competitorId: competitor.id,
        tournamentSubscriptionId: competitor.subscription_id,
      }
    }

    return (
      <div className="single-competitor-container__match" key={i}>
        <div className="match-header__category-name">
          {match.category_name}
        </div>
        <div className="match-card">

          <ContextMenuTrigger
            id={`${R.toUpper(competitor.state)}_MATCH_COMPETITOR_CONTEXT_MENU`}
            collect={collect}
            attributes={match}>
            <div className="match-card__competitor">
              <span className={`match-card__competitor-state match-card__competitor-state--red match-competitor--${competitor.state}`}></span>
              <span className="match-card__competitor-description">
                <div className="match-card__competitor-name">{competitor.name}</div>
                <div className="match-card__club-name">{competitor.club}</div>
              </span>
            </div>
          </ContextMenuTrigger>
        </div>
      </div>
    )
  }

  render() {
    const { matches } = this.props;

    return (
      <div className="container single-competitor-container">
        <ContextMenu id="ASSIGNED_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.checkin.bind(this)}>Checkin</MenuItem>
          <MenuItem onClick={this.reportNoShow.bind(this)}>Report No Show</MenuItem>
        </ContextMenu>

        <ContextMenu id="NO_SHOW_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.checkin.bind(this)}>Checkin</MenuItem>
          <MenuItem onClick={this.disqualifyByNoShow.bind(this)}>Disqualify by no show</MenuItem>
        </ContextMenu>

        <ContextMenu id="CHECKED_IN_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.weight.bind(this)}>OK</MenuItem>
          <MenuItem onClick={this.undoCheckin.bind(this)}>Undo Checkin</MenuItem>
          <MenuItem onClick={this.disqualifyByWeight.bind(this)}>Disqualify by weight</MenuItem>
        </ContextMenu>

        <ContextMenu id="WINNER_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.reset.bind(this)}>Reset</MenuItem>
        </ContextMenu>

        <ContextMenu id="READY_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.undoWeight.bind(this)}>UndoWeight</MenuItem>
        </ContextMenu>

        <ContextMenu id="DISQUALIFIED_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.reset.bind(this)}>Undo Disqualify</MenuItem>
        </ContextMenu>

        <ContextMenu id="WEIGHTED_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.checkin.bind(this)}>Checkin</MenuItem>
          <MenuItem onClick={this.reportNoShow.bind(this)}>Report No Show</MenuItem>
        </ContextMenu>

        <div className="col-xs-12">
          <ColorLegend />
          <div className="single-competitor-container__matches">
            {this.renderMatches(matches)}
          </div>
        </div>
      </div>
    )
  }
}

SingleCompetitorContainer.propTypes = {
  loadData: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,

  updateMatch: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
  const { matches, categories } = state.app;

  return {
    matches: matches.result,
    categories: categories.result,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadData,
    updateMatch,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleCompetitorContainer);
