import Collapse from 'rc-collapse';
import React, { PropTypes, Component } from 'react';
import R from 'ramda';
import CategoryPanel from './category_panel';
import DraggablePanel from './draggable_panel';
import MatchGroup from './match_group';
import MatchGroupPhaseLabel from './match_group_phase_label';
import MatchGroupPageLabel from './match_group_page_label';
import { PHASE } from '../../shared/constants';

import {
  isOrdered
} from '../../modules/match_lenses';

class CollapseCategoryList extends Component {
  renderMatchesByPhase(matches) {
    return R.pipe(
      R.groupBy(R.prop('phase')),
      R.toPairs,
      R.addIndex(R.map)(([phase, matches], i) =>
        <MatchGroup
          key={i}
          round={phase}
          matches={matches}
          label={MatchGroupPhaseLabel}
        />
      ),
    )(matches)
  }

  renderMatchesByPage(matches) {
    return R.pipe(
      R.groupBy(R.prop('page')),
      R.toPairs,
      R.addIndex(R.map)(([phase, matches], i) =>
        <MatchGroup
          key={i}
          round={phase}
          matches={matches}
          label={MatchGroupPageLabel}
        />
      ),
    )(matches)
  }

  renderCategories(categories, grouping) {
    const { page } = this.props;
    const pageSize = 20;

    return R.pipe(
      R.drop(pageSize * (page > 1 ? page - 1 : 0)),
      R.map((category) => {

        const allOrdered = R.all((m) => !R.propEq('ordered_item_id', null, m))(category.matches)

        const [regularMatches, resolutionMatches] = R.reduce((acc, match) => {
          return R.over(R.lensIndex(match.is_resolution ? 1 : 0), R.append(match), acc)
        }, [[], []], category.matches)

        return (
          <DraggablePanel
            header={`${category.name} (${category.matches.length})`}
            key={category.id}
            matches={category.matches}
            className={allOrdered ? "rc-collapse-item--ordered" : null}>
            <div className="category-panel">
              {
                grouping == PHASE ?
                  this.renderMatchesByPhase(regularMatches) :
                  this.renderMatchesByPage(regularMatches)
              }
              <div>Resolution</div>
              {
                grouping == PHASE ?
                  this.renderMatchesByPhase(resolutionMatches) :
                  this.renderMatchesByPage(resolutionMatches)
              }
            </div>
          </DraggablePanel>
        )
      }),
      R.take(pageSize)
    )(categories)
  }

  render() {
    const { categories, grouping } = this.props;

    return (
      <div className="collapse-category-list">
        <Collapse accordion={false}>
          {this.renderCategories(categories, grouping)}
        </Collapse>
      </div>
    );
  }
}

CollapseCategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
}

export default CollapseCategoryList;
