import R from 'ramda';

import {
  COMPETITORS_REQUEST,
  COMPETITORS_RESPONSE_SUCCESS,
  COMPETITORS_RESPONSE_FAIL,
  OPC_UNSUBSCRIPTION_HISTORY_REQUEST,
  OPC_UNSUBSCRIPTION_HISTORY_RESPONSE_SUCCESS,
  OPC_UNSUBSCRIPTION_HISTORY_FAIL,
} from '../shared/constants';

import {
  tournament,
  categories,
  filters,
  tournamentSubscriptions,
  ui,
} from '../shared/reducers';

const initialState = {
  ui: {
    filters: {
      age_division_ids: [],
      weight_division_ids: [],
      belt_ids: [],
      gender_ids: [],
    },
    gap: {},
    matchModal: {
      isFetching: false,
      result: undefined,
    },
    matchOverlay: {
      isFetching: false,
      result: undefined,
    },
  },
  tournament: {
    isFetching: false,
    result: { id: 900 },
  },
  categories: {
    isFetching: false,
    result: [],
  },
  filters: {
    isFetching: false,
    result: [],
  },
  competitors: {
    isFetching: false,
    result: [],
  },
  tournamentSubscriptions: {
    isFetching: false,
    result: [],
  },
  unsubscriptionHistory: {
    isFetching: false,
    result: [],
  }
}

export const opc = (state = initialState, action) => {
  return {
    ...state,
    ui: ui(state.ui, action),
    tournament: tournament(state.tournament, action),
    categories: opcCategories(state.categories, action),
    filters: filters(state.filters, action),
    competitors: competitors(state.competitors, action),
    tournamentSubscriptions: tournamentSubscriptions(state.tournamentSubscriptions, action),
    unsubscriptionHistory: unsubscriptionHistory(state.unsubscriptionHistory, action),
  }
}

const competitors = (state, action) => {
  switch (action.type) {
    case COMPETITORS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
      break;
    case COMPETITORS_RESPONSE_FAIL:
      return {
        ...state,
        isFetching: false
      }
      break;
    case COMPETITORS_RESPONSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        result: action.payload
      }
      break;
    case "CLEAN_COMPETITORS":
      return {
        ...state,
        result: []
      }
      break;
    default:
      return state;
  }
}

const opcCategories = (state, action) => {
  switch (action.type) {
    case "CLEAN_CATEGORIES":
      return {
        ...state,
        result: []
      }
      break;
    case "RELOAD_CATEGORY":
      return {
        ...state,
        result: R.update(
          R.findIndex(R.propEq('id', action.payload.id), state.result),
          action.payload,
          state.result
        )
      }
      break;
    default:
      return categories(state, action)
  }
}

const unsubscriptionHistory = (state, action) => {
  switch (action.type) {
    case OPC_UNSUBSCRIPTION_HISTORY_REQUEST:
      return { ...state, result: null, isFetching: true }
      break;
    case OPC_UNSUBSCRIPTION_HISTORY_RESPONSE_SUCCESS:
      return { ...state, result: action.payload, isFetching: false }
      break;
    case OPC_UNSUBSCRIPTION_HISTORY_FAIL:
      return new Error('Fail to fetch OPC Unsubscription History')
      break;
    default:
      return state;
  }
}



