import R from 'ramda';
import moment from 'moment-timezone';
import { pendingMedals } from '../modules/podium_utils';

import {
  DELIVER_MEDAL_TO_COMPETITOR,
  DELIVER_MEDAL_TO_REPRESENTATIVE,
  UNDO_RESULT,
  REPORT_COMPETITOR_NOT_FOUND,
  CHANGE_PODIUM_FILTER,
  RELOAD_PODIUMS,
  RELOAD_PODIUM,

  MEDAL_COMPETITOR_NOT_FOUND
} from '../shared/constants';

import {
  fetchTournament,
  fetchFilters,
  fetchPodiums,
  fetchMedalDeliveries,
  updatePodium,
  updateMedal,
} from '../shared/actions/api';

export function loadData(tournamentId, tournamentDayId, matchStates) {
  return (dispatch, getState) => {
    return dispatch(fetchTournament(tournamentId))
      .then(() => Promise.all([
        dispatch(fetchPodiums()),
        dispatch(fetchFilters()),
        dispatch(fetchMedalDeliveries()),
      ]))
  }
}

export function reportCompetitorNotFound(competitorId) {
  return {
    type: REPORT_COMPETITOR_NOT_FOUND,
    competitorId: competitorId
  }
}

export function deliverToResponsible(competitorId) {
  return {
    type: DELIVER_MEDAL_TO_REPRESENTATIVE,
    competitorId: competitorId
  }
}

export function undoResult(matchId) {
  return {
    type: UNDO_RESULT,
    matchId: matchId
  }
}

export function checkPodium(podiumId, checked) {
  return (dispatch, getState) => {
    return dispatch(updatePodium({
      id: podiumId,
      checked_at: checked ? moment().toISOString() : null,
    }))
  }
}


export function deliverMedal(medalId, medalDeliveryId, podiumId) {
  return (dispatch, getState) => {
    return dispatch(updateMedal({
      id: medalId,
      delivered_at: medalDeliveryId ? moment().toISOString() : null,
      medal_delivery_id: medalDeliveryId
    }))
  }
}

export function print(podium) {
  return (dispatch, getState) => {
    return dispatch(updatePodium({
      id: podium.id,
      printed_at: moment().toISOString(),
    }))
  }
}

export function changePodiumFilter(filter) {
  return {
    type: CHANGE_PODIUM_FILTER,
    payload: filter,
  }
}

export function reloadPodiums(podiums) {
  return {
    type: RELOAD_PODIUMS,
    payload: podiums
  }
}

export function reloadPodium(podium) {
  return {
    type: RELOAD_PODIUM,
    payload: podium
  }
}
