class MatDesktop {
  constructor(slidingColumns) {
    this.slidingColumns = slidingColumns
  }

  scrollTo(competitorNumber) {
    $(".tournament-day__match--highlighted").toggleClass("tournament-day__match--highlighted")

    const competitor = $(`#competitor-${competitorNumber}`);

    if (competitor.length === 0) {
      return undefined;
    }

    const index = -1 * (competitor.parents('.sliding-columns__column').index() - 1)

    Promise.resolve(
      this.slidingColumns.scrollToPosition(index)
    )
    .then((a) => {
      const li = competitor.parents("li").first()

      li.addClass("tournament-day__match--highlighted")

      window.scroll({
        top: li.offset().top - 100,
        left: 0,
        behavior: 'smooth'
      })
    })
  }
}

export default MatDesktop;
