import {
  fetchTournament,
  fetchMats,
} from '../shared/actions/api';

export function loadData(tournamentId, tournamentDayId, matNumbers) {
  return (dispatch, getState) => {
    const {
      matchStates
    } = getState();

    return dispatch(fetchTournament(tournamentId))
      .then(() => dispatch(fetchMatsForCurrentDay(tournamentDayId, matNumbers)))
  }
}

export function fetchMatsForCurrentDay(tournamentDayId, matNumbers) {
  return (dispatch, getState) => {
    return dispatch(fetchMats({ query: {
      matches: 'detailed',
      numbers: matNumbers || [],
      tournament_day_ids: [tournamentDayId]
    }}));
  }
}
