import React, { Component } from 'react';
import R from 'ramda';
import formatMinutes from '../modules/format_minutes';

import {
  orderCategories,
  flattenCategoryMatches
} from '../modules/order_categories';

const AutoOrderMats = (props) => {
  const {
    mats,
    categoryGroups,
    categories
  } = props;

  const groupsForMat = (number, categoryGroups) =>
    R.filter((cg) => R.contains(number, cg.mat_numbers), categoryGroups)
  const INTERVAL = 2

  const duration = R.map(
    (mat) => R.reduce((acc, m) => acc + m.duration + INTERVAL, 0, mat),
    orderCategories(flattenCategoryMatches(categories), mats.length, categoryGroups)
  )

  return (
    <div className="auto-order-mats">
      {
        R.addIndex(R.map)((mat, j) => {

          const groups = groupsForMat(mat.number, categoryGroups)

          return (
            <div key={j} className="auto-order-mats__mat panel panel-default">
              <div className="panel-heading">
                <div className="panel-title">
                  {`Mat ${mat.number}`}
                  <span className="auto-order-mats__mat-duration">
                    {formatMinutes(duration[j])}
                  </span>
                </div>
              </div>
              <div className="panel-body">
                {R.addIndex(R.map)((group, i) => (<div key={i} className="auto-order-mats__badge label label-default">{group.name}</div>), groups)}
              </div>
            </div>
          )
        }, mats)
      }
    </div>
  )
}

export default AutoOrderMats;
