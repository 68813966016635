import PageFlipper from '../modules/page_flipper';
import ReloadTimer from '../modules/reload_timer';

class OpenClassesShow {
  constructor() {
    const query = new URLSearchParams(window.location.search);

    this.flipper = new PageFlipper({ interval: parseInt(query.get('timer') || '10', 10) });

    ReloadTimer();
  }
}

export default OpenClassesShow;
