import SelectAll from './select_all';

const selectRow = (className) => {

}

const selectCol = (classNameAll, classNameOne) => {
  $(classNameAll).on('click', (el) => { $(classNameOne).prop('checked', $(el.target).prop("checked")) })
}

class UserGroupsForm {
  constructor() {
    selectCol('.can-read-all', '.can-read')
    selectCol('.can-create-all', '.can-create')
    selectCol('.can-update-all', '.can-update')
    selectCol('.can-delete-all', '.can-delete')

    $(".can-manage").on('click', (el) => {
      $(el.target)
        .parents("tr")
        .find('input[type="checkbox"]')
        .prop('checked', $(el.target).prop('checked'))
    })
  }
}

export default UserGroupsForm;
