import DurationCalculator from '../modules/duration_calculator';
import R from 'ramda';
import { DragSource, DropTarget } from 'react-dnd';
import { CATEGORY_GROUP } from '../shared/constants';

import CategoryGroup from './category_group';

const categoryGroupDropTarget = {
  drop(props, monitor, component) {
    const dropped = monitor.getItem();

    component.props.onDrop(dropped.order, props.categoryGroup.order);
  }
}

function collectDropTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

const categoryGroupDragSource = {
  beginDrag(props, monitor, component) {
    return {
      order: props.categoryGroup.order
    };
  },
}

function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}

const DraggableCategoryGroup = DropTarget(CATEGORY_GROUP, categoryGroupDropTarget, collectDropTarget)(DragSource(CATEGORY_GROUP, categoryGroupDragSource, collectDragSource)(CategoryGroup));

const AutoOrderSetup = (props) => {
  const {
    categoryGroups,
    categories,
    onHighlight,
    onNew,
    onEdit,
    onDelete,
    onDropCategoryGroup,
  } = props;

  const calculator = new DurationCalculator();

  return (
    <span className="auto-order-setup">
      <div className="auto-order-setup__new-category-group">
        <span className="text-center">Category Groups</span>
        <a className="btn btn-success text-center" onClick={() => onNew()}>New Category Group</a>
      </div>
    {R.addIndex(R.map)((cg, i) => {

      const time = cg.mat_numbers.length === 0 ? 0 : R.pipe(
        R.filter((c) => R.contains(c.age_division_id, cg.age_division_ids) &&
            R.contains(c.gender_id, cg.gender_ids) &&
            R.intersection(c.belt_ids, cg.belt_ids).length > 0
        ),
        R.map((c) =>
          R.pipe(
            R.assoc('competitors_count', c.competitors),
            R.assoc('time', c.matches[0].duration)
          )(c)
        ),
        (categories) => calculator.totalTime(categories, cg.mat_numbers.length, 2, 'min')
      )(categories)

      return (
        <DraggableCategoryGroup
          key={i}
          categoryGroup={cg}
          duration={time}
          onHighlight={onHighlight}
          onEdit={onEdit}
          onDrop={onDropCategoryGroup}
          onDelete={onDelete}/>
      )
    }, categoryGroups)}
    </span>
  )
}

export default AutoOrderSetup;
