import R from 'ramda';

import {
  fetchTournament,
  fetchMatches,
} from '../shared/actions/api';

export function loadData(tournamentId) {
  return (dispatch, getState) => {
    return dispatch(fetchTournament(tournamentId))
      .then((action) =>
        dispatch(fetchMatches({ query: { paginated: false, bracket_size: 1, order_by: "category" }})))
  }
}
