import R from 'ramda';

function ManualDraw (el, options) {
  this.locked = true
  this.flashMessage = options.flashMessage
  this.apiClient = options.apiClient

  this.detachedCompetitors = [];
  this.detachedSpots = [];

  this.$el                     = $(el)
  this.$operators              = $('.tournament-category__operators')
  this.$brackets               = this.$el.find('.tournament-category__bracket')
  this.$competitorsListWrapper = this.$el.find('.tournament-category__competitors-list')
  this.$competitorsList        = this.$competitorsListWrapper.find('ul')
  this.$competitorsListItem    = this.$competitorsList.find("li")
  this.$lockBtn                = this.$el.find('.tournament-category__lock input')
  this.$competitorSpot         = this.$el.find('.match-card__competitor-description')
  this.$removeCompetitorBtn    = this.$competitorSpot.find('.match-card__remove-competitor')
  this.$bracketMatches         = this.$el.find('.match-card')
  this.$removeAllBtn           = this.$el.find('.bracket__remove-all-btn')

  this.$competitorSpot
    .droppable()
  this.$lockBtn
    .on('change', this.toggleLock.bind(this))
  this.$competitorSpot
    .on('click', '.match-card__remove-competitor', this.removeFromSpot.bind(this))
  this.$competitorSpot
    .on('drop', this.addToSpot.bind(this))
  this.$removeAllBtn
    .on('click', this.removeAll.bind(this))

  this.checkState()
}

ManualDraw.prototype.isLegalState = function(){
  // get's all competitors from the first fights on the bracket (including byes)
  const competitors = $('.match-card__competitor-description[data-is-first-round]')
    .toArray()
    .map((c) => this.competitor($(c)));

  // Gets all competitors from the bracket, maps their club_id, splits the
  // bracket in the middle, and checks if there are no club_id duplicates on
  // each side
  return R.pipe(
    R.map(R.prop('clubId')),
    R.splitAt((Math.ceil(competitors.length / 2))),
    R.map(R.reject((id) => id === undefined)),
    R.all((clubIds) => R.equals(clubIds.length,  R.uniq(clubIds).length))
  )(competitors)
}

ManualDraw.prototype.removeAll = function(e) {
  this.$el.find('.match-card__remove-competitor:visible').trigger('click')
}

ManualDraw.prototype.toggleLock = function (e) {
  this.locked = !this.locked

  var state = this.locked ? "disable" : "enable"

  this.$operators.toggle()
  this.$competitorSpot.droppable(state)
  this.$competitorsListItem.draggable(state)
  this.$removeCompetitorBtn.toggle()
  this.$competitorsListWrapper.toggle()
  this.$lockBtn.prop('checked', !this.locked)

  if (this.locked)
    if (this.isValidState()) {
      var _this = this

      this.save()
        .then(
            function(){
              console.log(true)
            },
          function(data){
            _this.flashMessage.error("Could not save the bracket")
            _this.toggleLock()
          })
    } else {
      this.flashMessage.error("There are still competitors to place")
      this.toggleLock()
    }
}

ManualDraw.prototype.removeFromSpot = function (e) {
  const selectedCompetitor = $(e.delegateTarget)

  const removedCompetitor = this.competitor(selectedCompetitor);
  const removedCompetitorSpots = $(`.match-card__competitor-description[data-competitor-id=${removedCompetitor.competitorId}]`)

  this.detachedCompetitors.push(removedCompetitor);
  this.detachedSpots.push(removedCompetitorSpots);

  this.sortCompetitorsList()
  this.updateCompetitorList()
  this.cleanSpot(removedCompetitorSpots)

}

ManualDraw.prototype.competitor = function($competitorSpot) {
  return {
    competitorId: $competitorSpot.data('competitor-id'),
    clubId: $competitorSpot.data('club-id'),
    name: $competitorSpot.find('.match-card__competitor-name').text().trim(),
    club: $competitorSpot.find('.match-card__club-name').text().trim()
  }
}

ManualDraw.prototype.updateCompetitorList = function () {
  this.$competitorsList.html(() =>
    R.map((competitor) => {
      return `<li>
        <div class="match-card match-card--detached">
          <div class="match-card__competitor">
            <span class="match-card__competitor-description" data-competitor-id=${competitor.competitorId} data-club-id=${competitor.clubId}>
              <div class="match-card__competitor-name">${competitor.name}</div>
              <div class="match-card__club-name">${competitor.club}</div>
            </span>
          </div>
        </div>
      </li>`
    }, this.detachedCompetitors)
  )

  this.$competitorsList.find(".match-card").draggable({
    appendTo: ".tournament-category__operators",
    revert: true,
    helper: "clone",
    drag: (event, ui) => {
      ui.position.top = event.pageY - (ui.helper.outerHeight(true) / 2);
    }
  });
}

ManualDraw.prototype.sortCompetitorsList = function() {
  this.detachedCompetitors = R.sortBy(R.prop('name'), this.detachedCompetitors);
}

ManualDraw.prototype.addToSpot = function (e, ui) {
  var $draggable = $(ui.draggable),
      competitor = this.competitor($draggable.find('.match-card__competitor-description')),
      $target = $(e.target);

  $(ui.helper).remove();

  const spotGroupIndex = R.findIndex((spotGroup) => R.any((spot) => spot === $target.get(0), spotGroup), this.detachedSpots);

  if (spotGroupIndex === -1)
    return;

  this.detachedSpots[spotGroupIndex].each((i, spot) => {
    const $spot = $(spot);

    $spot.data('competitor-id', competitor.competitorId)
    $spot.data('club-id', competitor.clubId)
    $spot.attr('data-competitor-id', competitor.competitorId)
    $spot.attr('data-club-id', competitor.clubId)
    $spot.find('.match-card__competitor-name').text(competitor.name)
    $spot.find('.match-card__club-name').text(competitor.club)
    $spot.find('.match-card__remove-competitor').show()
  })

  this.detachedCompetitors = R.reject(R.equals(competitor), this.detachedCompetitors);
  this.detachedSpots = R.remove(spotGroupIndex, 1, this.detachedSpots);
  this.updateCompetitorList()

  this.checkState()
}

ManualDraw.prototype.checkState = function() {
  if(!this.isLegalState()) {
    this.flashMessage.error("There are competitors from the same club on the same side of a bracket")
  }
}

ManualDraw.prototype.cleanSpot = function ($competitorSpots) {
  $competitorSpots.each((i, spot) => {
    const $spot = $(spot);

    $spot.removeData('competitor-id')
    $spot.removeData('club-id')
    $spot.removeAttr('data-competitor-id')
    $spot.removeAttr('data-club-id')
    $spot.find('.match-card__competitor-name').text('-')
    $spot.find('.match-card__club-name').text('-')
    $spot.find('.match-card__remove-competitor').hide()
  })
}

ManualDraw.prototype.bracketData = function(brackets) {
  return brackets.get().map(function(bracket){
    return $(bracket).find('.match-card').map(function(index, item){
      return $(item).find('.match-card__competitor-description').map(function(index, competitor){
        const $competitor = $(competitor);

        return {
          match_competitor_id: $competitor.data('match-competitor-id'),
          competitor_id: $competitor.data('competitor-id'),
        }
      }).get()
    }).get()
  }).reduce((acc, a) => acc.concat(a), [])
}

ManualDraw.prototype.isValidState = function () {
  return this.$competitorsList.find("li").length == 0
}

ManualDraw.prototype.save = function() {
  var _this = this

  return _this.apiClient.bracketUpdate({
    categoryId: this.$el.data('category-id'),
    tournamentId: _this.$el.data('tournament-id'),
    bracketId: 0,
    data: { bracket: this.bracketData(this.$brackets) }
  })
}

export default ManualDraw;
