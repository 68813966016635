import { Component } from 'react';
import DurationCalculator from '../../modules/duration_calculator';
import R from 'ramda';

function EstimateDisplayCalculatorHOC(WrappedComponent) {
  return class Calculator extends Component {
    render () {
      const calculator = new DurationCalculator();

      const {
        areas,
        interval,
        intervalUnit,
        categories
      } = this.props;

      const childProps = {
        time: calculator.totalTime(categories, areas, interval, intervalUnit),
        competitors: calculator.totalCompetitors(categories),
        categoriesCount: categories.length,
        categories: categories,
      }

      return (<WrappedComponent {...R.pick(['children','onChange'])(this.props)} {...childProps}/>);
    }
  }
}

export default EstimateDisplayCalculatorHOC;
