import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MatList from '../../shared/components/mat_list';
import StatelessMatContainer from '../../shared/containers/stateless_mat_container';
import CableSubscriber from '../../shared/components/cable_subscriber';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import ColorLegend from '../../shared/components/color_legend';
import PublicCheckin from '../../checkin/components/public_checkin';
import StandBy from '../../shared/components/stand_by';

const PresenceMatList = MatList(StatelessMatContainer);

import {
  loadData,
} from '../actions';

import {
  updateMatch,
  reloadMat,
  reloadMatch,
} from '../../shared/actions/api';

import {
  checkin,
  reportNoShow,
  weight,
  disqualifyByWeight,
  disqualifyByNoShow,
  disqualifyByGi,
} from '../../modules/competitor_action_params';

class PresenceContainer extends Component {
  componentDidMount() {
    this.props.loadData(this.props.tournamentId, this.props.tournamentDayId, this.props.matNumbers)
  }

  checkinCompetitor(fn, props) {
    return this.props.updateMatch(checkin(props.matchId, props.matchCompetitorId));
  }

  disqualifyByNoShow(fn, props) {
    if (confirm("confirm disqualification?")) {
      return this.props.updateMatch(reportNoShow(props.matchId, props.matchCompetitorId))
        .then(() => this.disqualify(disqualifyByNoShow(props.matchId, props.matchCompetitorId), true));
    }
  }

  disqualifyByWeight(_, params) {
    this.disqualify(disqualifyByWeight(params.matchId, params.matchCompetitorId))
  }

  disqualifyByGi(_, params) {
    this.disqualify(disqualifyByGi(params.matchId, params.matchCompetitorId))
  }

  disqualify(params, skipConfirm = false) {
    if (skipConfirm || confirm("confirm disqualification?")) {
      this.props.updateMatch(params);
    }
  }

  weightCompetitor(match, params) {
    return this.props.updateMatch(weight(
      params.matchId,
      params.matchCompetitorId,
      params.tournamentSubscriptionId));
  }

  render() {
    const {
      mats,
      matchStatesShown,
      reloadMat,
      reloadMatch,
      tournamentDayId,
      tournamentId
    } = this.props;

    return (
      <div className="checkin-container">
        <PublicCheckin
          tournamentId={tournamentId}
          tournamentDayId={tournamentDayId}
          mats={mats} />

        <CableSubscriber tournamentId={+tournamentId} subscriptions={[
          { name: "MatsChannel", received: reloadMat, room_id: "+" },
          { name: "MatchesChannel", received: reloadMatch, room_id: "+" }
        ]} />

        <ContextMenu id="ASSIGNED_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.checkinCompetitor.bind(this)}>Checkin</MenuItem>
          <MenuItem onClick={this.disqualifyByNoShow.bind(this)}>Disqualify by no show</MenuItem>
        </ContextMenu>

        <ContextMenu id="NO_SHOW_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.checkinCompetitor.bind(this)}>Checkin</MenuItem>
          <MenuItem onClick={this.disqualifyByNoShow.bind(this)}>Disqualify by no show</MenuItem>
        </ContextMenu>

        <ContextMenu id="WEIGHTED_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.checkinCompetitor.bind(this)}>Checkin</MenuItem>
          <MenuItem onClick={this.disqualifyByNoShow.bind(this)}>Disqualify by no show</MenuItem>
        </ContextMenu>

        <ContextMenu id="CHECKED_IN_MATCH_COMPETITOR_CONTEXT_MENU">
          <MenuItem onClick={this.weightCompetitor.bind(this)}>Weight OK</MenuItem>
          <MenuItem onClick={this.disqualifyByWeight.bind(this)}>Disqualify by weight</MenuItem>
          <MenuItem onClick={this.disqualifyByGi.bind(this)}>Disqualify by gi</MenuItem>
          <MenuItem onClick={this.disqualifyByNoShow.bind(this)}>Disqualify by no show</MenuItem>
        </ContextMenu>

        <ColorLegend label="CHECKIN AND WEIGHT"/>

        {mats.length ? (
          <PresenceMatList mats={mats} slug={"checkin-mat-list"} />
        ) : (
          <StandBy standBy="Loading mats, please wait..." />
        )}
      </div>
    )
  }
}

PresenceContainer.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  mats: PropTypes.array.isRequired,

  updateMatch: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
  return {
    mats: state.app.mats.result,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadData,
    updateMatch,
    reloadMat,
    reloadMatch,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PresenceContainer);
