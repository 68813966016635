import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { reducer as form } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import apiMiddleware from '../middleware/api_middleware';

import OpcContainer from './containers/opc_container';
import { opc } from './reducers';

const logger = createLogger();

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'Tenkaichi OPC',
      })
    : compose;

const store = createStore(
  combineReducers({ app: opc, form }),
  composeEnhancers(applyMiddleware(thunkMiddleware, apiMiddleware, logger))
);

class OpcApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <OpcContainer
          tournamentId={this.props.tournament_id}
          isAdmin={this.props.is_admin}
          opcPrintingServerPort={this.props.opc_printing_server_port}
        />
      </Provider>
    );
  }
}

export default OpcApp;
