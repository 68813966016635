import React, { Component, PropTypes } from 'react';
import { connect } from "react-redux";
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import R from 'ramda';

import DraggableCategoryListContainer from './draggable_category_list_container';
import EstimateDayContainer from './estimate_day_container';
import EstimateDisplay from '../components/estimate_display';
import EditableEstimateDisplay from '../components/editable_estimate_display';
import EstimateDisplayCalculatorHOC from '../components/estimate_display_calculator_hoc';
import DraggableEstimateDisplayHOC from '../components/draggable_estimate_display_hoc';
import CategoriesAccordeon from '../../settings/components/categories_accordeon';
import { loadData } from '../actions';
import { CATEGORY_GROUP } from '../../shared/constants';

const categoryGroupSource = {
  beginDrag(props, monitor, component) {
    const cs = R.map((category) => {
      return { categoryId: category.id, competitors: component.state.competitors }
    })(props.categories)

    return {
      categories: cs,
    }
  }
}

const DraggableEstimateDisplay = DraggableEstimateDisplayHOC(
  EstimateDisplayCalculatorHOC(EstimateDisplay),
  CATEGORY_GROUP,
  categoryGroupSource,
);

const EditableDraggableEstimateDisplay = DraggableEstimateDisplayHOC(
  EstimateDisplayCalculatorHOC(EditableEstimateDisplay),
  CATEGORY_GROUP,
  categoryGroupSource,
);

import { compareBy } from '../../modules/compare_by';

class EstimatesContainer extends Component {
  componentDidMount() {
    this.props.dispatch(loadData(this.props.tournamentId, this.props.estimateId))
  }

  render(){
    const { categories, openClass, days } = this.props;

    const orderedOpenClass = R.sort(
      compareBy(['belt_order', 'age_division_order', 'gender_order', 'weight_division_order'])
    )(openClass)

    const estimates = R.pipe(
      R.sortBy(R.prop('date')),
      R.addIndex(R.map)((day, index) =>
        <EstimateDayContainer day={day} key={index} />
      )
    )(days);

    return (
      <div className="estimates">
        <div className="estimates-container">
          <div className="row">
            <div className="col-md-6 estimates__categories">
              <CategoriesAccordeon 
                categories={categories}
                display={DraggableEstimateDisplay}
              />
              <br/>
              <br/>
              <DraggableCategoryListContainer collection={orderedOpenClass}/>
            </div>
            <div className="col-md-6 estimates__days">
              {estimates}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EstimatesContainer.propTypes = {
  categories: PropTypes.array.isRequired,
  openClass: PropTypes.array.isRequired,
  days: PropTypes.array,
}

EstimatesContainer.defaultProps = {
  days: [],
  openClass: [],
}

function mapStateToProps(state) {
  const {
    estimate,
    categories,
  } = state.app;

  const assignedCategories = R.pipe(
    R.map(R.prop('duration_category_estimates')),
    R.map(R.map(R.prop('category_id'))),
    R.flatten
  )(estimate.result.days || [])

  const notAssignedCategories = R.filter(
    (category) => !R.contains(category.id, assignedCategories)
  )(categories.result)

  const isOpenClass = (c) => R.contains(c.weight_division_id, [10, 11, 12])
  const isNotOpenClass = (c) => R.not(R.contains(c.weight_division_id, [10, 11, 12]))

  return {
    categories: R.filter(isNotOpenClass)(notAssignedCategories),
    openClass: R.filter(isOpenClass)(notAssignedCategories),
    days: estimate.result.days
  };
}

export default DragDropContext(HTML5Backend)(connect(mapStateToProps)(EstimatesContainer));
