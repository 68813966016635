import { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import Mat from '../components/mat';
import R from 'ramda';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { throttle } from 'throttle-debounce';

import moveOrderedMatches from '../../modules/move_ordered_matches';

import {
  CATEGORY,
  CATEGORY_GROUP
} from '../../shared/constants';

import {
  buildMatFromMatches,
  orderMatch,
  printFightOrder,
  selectMatches,
  mergeMat,

  fetchOverlayMatch,
} from '../actions'

class MatContainer extends Component {
  constructor(props) {
    super(props);

    const { mat } = props;

    this.state = { matches: mat.matches };
  }

  componentWillReceiveProps(props) {
    // check if is dragging
    this.setState({ matches: props.mat.matches });
  }

  printMat(options) {
    const { mat } = this.props;

    this.props.printFightOrder(mat.id, options)

    Tenkaichi.flashMessage.notice("Você receberá um email com a ordem de luta em alguns minutos")
  }

  mergeMat(options) {
    this.setState({ matches: mergeMat(this.state.matches, options) }, () => this.updateMat())
  }

  onOrderedMatchClick (matchId) {
    this.props.fetchOverlayMatch(matchId);
  }

  onOrderedMatchBeginDrag (selectedMatches) {
    this.props.selectMatches({
      controlPressed: false,
      matches: selectedMatches
    })
  }

  hoverMat() {
    const newState = R.over(
      R.lensProp('matches'),
      R.reject(R.propEq('ordering', true)),
      this.state
    )

    if (!R.equals(newState, this.state)) {
      this.setState(newState);
    }
  }

  hoverOrderedMatch(match) {
    this.props.fetchOverlayMatch(match.id);
  }

  dropMat(selectedMatches) {
    const isFromAnotherMat = (match) => match.mat !== this.props.mat.number

    const matches = R.any(isFromAnotherMat, selectedMatches) ?
      moveOrderedMatches(this.state.matches, undefined, selectedMatches) :
      this.props.mat.matches;

    this.setState({ matches })

    this.updateMat();
  }

  updateMat() {
    this.props.orderMatch(buildMatFromMatches(this.props.mat.id, this.state.matches));
  }

  moveMatches(hoverIndex, selectedMatches) {
    const newState = {
      matches: moveOrderedMatches(this.state.matches, hoverIndex, selectedMatches)
    }

    if (!R.equals(newState, this.state)) {
      this.setState(newState)
    }
  }

  render() {
    const {
      matchStates,
      selectedMatches,
      isHighlighted
    } = this.props;

    let switchColor = false;
    let categoryId;

    const matMatches = R.map((m) => {
      if (categoryId !== m.category_id) {
        switchColor = !switchColor;
      }

      categoryId = m.category_id;

      return R.pipe(
        R.assoc('selected', R.contains(m.id, selectedMatches)),
        R.assoc('highlighted', isHighlighted(m)),
        R.assoc('switchColor', switchColor)
      )(m)
    }, this.state.matches);

    return (
      <Mat
        {...this.props}
        number={this.props.mat.number}
        matches={matMatches}
        mergeOptions={this.props.mergeOptions}

        onOrderedMatchClick={this.onOrderedMatchClick.bind(this)}
        onOrderedMatchBeginDrag={this.onOrderedMatchBeginDrag.bind(this)}
        onOrderedMatchHover={throttle(1000, false, (match) => this.hoverOrderedMatch(match))}

        onMoveMatches={this.moveMatches.bind(this)}
        onHover={this.hoverMat.bind(this)}
        onDrop={this.dropMat.bind(this)}
        onMatchDrop={this.updateMat.bind(this)}
        onPrintMat={this.printMat.bind(this)}
        onMergeMat={this.mergeMat.bind(this)}
      />
    )
  }
}

MatContainer.propTypes = {
  mat: PropTypes.object.isRequired,

  selectedMatches: PropTypes.array,
  highlight: PropTypes.number,

  printFightOrder: PropTypes.func,
  orderMatch: PropTypes.func,
  selectMatches: PropTypes.func,
}

function mapStateToProps(state, ownProps) {
  let isHighlighted;
  const {
    tournament,
    ui: {
      selectedMatches,
      highlight,
    }
  } = state.app;

  const isInHighlightedCategory = (match) =>
    highlight.id === match.category_id

  const isInHighlightedCategoryGroup = (match) =>
    R.contains(match.category_id, highlight.id)

  switch (highlight.kind) {
    case CATEGORY:
      isHighlighted = isInHighlightedCategory;
      break;
    case CATEGORY_GROUP:
      isHighlighted = isInHighlightedCategoryGroup;
      break;
    default:
      isHighlighted = () => false;
  };

  return {
    ...ownProps,
    selectedMatches,
    isHighlighted,
    interval: tournament.result.match_interval,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    orderMatch,
    printFightOrder,
    selectMatches,
    fetchOverlayMatch,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MatContainer);
