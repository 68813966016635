import SelectAll from './select_all';

class MedalGroupingsForm {
  constructor() {
    new SelectAll($('#select_all_ages'), $('.medal-grouping__age-checkbox'));
    new SelectAll($('#select_all_weights'), $('.medal-grouping__weight-checkbox'));
    new SelectAll($('#select_all_belts'), $('.medal-grouping__belt-checkbox'));
    new SelectAll($('#select_all_genders'), $('.medal-grouping__gender-checkbox'));
  }
}

export default MedalGroupingsForm;
