
import ServerEventsListener from "../../modules/server_events_listener"
import ApiClient from "../../modules/api_client"

export function AdminTournamentIndex() {
  this.flashMessage = Tenkaichi.flashMessage;
  this.serverEventsListener = new ServerEventsListener(new ApiClient())

  var _this = this;
  this.serverEventsListener
    .on('tournament_import_success', function(event, payload) { window.location.reload() } )
    .on('tournament_import_error', function(event, payload) {
      setTimeout(function() { window.location.reload() }, 5000)
      _this.flashMessage.error(payload)
    })
    .on('tournament_print_success', function(event, payload) { _this.flashMessage.notice(payload) } )
    .on('tournament_print_error', function(event, payload) { _this.flashMessage.error(payload) })
}
