import R from 'ramda';

class OpenClassesIndex {
  constructor() {
    this.queryString = new URLSearchParams(window.location.search)
    this.categoryFilter = $(".open-class__category-filter")

    $(document).on('show.bs.tab', (e) => {
      this.queryString.set('tab_index', $(e.target).parents('li').index());

      this.updateUrl()
    })

    this.categoryFilter.on('change', (e) => {
      if (e.target.value === "") {
        this.queryString.delete('category_id')
        window.location.search = this.queryString.toString();

        return null;
      }

      const category = $(`#c-${e.target.value}`)
      const tabPane = category.parents('.tab-pane')
      const tab = $(`a[href="#${tabPane.prop('id')}"]`).tab('show')

      $('.open-class__category-panel').hide()

      category.show()

      $(`#category-${e.target.value}`).collapse('show')
    })

    $('[data-toggle="tooltip"]').tooltip();
  }

  updateUrl() {
    const newRelativePathQuery = window.location.origin + window.location.pathname + '?' + this.queryString.toString();

    history.pushState(null, '', newRelativePathQuery);
  }

}

export default OpenClassesIndex;
